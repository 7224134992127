import { v4 as uuidv4 } from 'uuid'
import { Genes } from './Genes'

export interface GenesDTO {
  id: string | undefined
  name: string

}

export function emptyGenesDTO(): GenesDTO {
  return {
    id: uuidv4(),
    name: ''
  }
}

export function fromModel(c: Genes): GenesDTO {
  return {
    id: c.id,
    name: c.name
  }
}

export function toModel(c: GenesDTO): Genes {
  return new Genes(c)
}
