import i18n from 'i18n/i18n'
import { Option, OptionValue } from '../../../common/utils/form-generation/types'

export enum RoleType {
  BAAdmin = 1,
  ClinicUser,
}

export const optionRoleType = (): Option<OptionValue>[] => [
  { value: RoleType.BAAdmin, label: 'BAAdmin' },
  { value: RoleType.ClinicUser, label: 'ClinicUser' },
]

export const roleTypes = (): Record<RoleType, string> => ({
  [RoleType.BAAdmin]: i18n.t('BAAdmin'),
  [RoleType.ClinicUser]: i18n.t('ClinicUser'),
})

export const clinicUserRoles = (): {[RoleType.ClinicUser]: string } => ({
  [RoleType.ClinicUser]: i18n.t('ClinicUser'),
})