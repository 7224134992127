import {emptyList, ItemList} from '../../../common/models/ItemList'
import {Observable, of} from 'rxjs'
import {Container, IInit} from '../../../common/container/Container'
import {HTTP_CLIENT_KEY, IHTTPClient} from '../../../common/api/HTTPClient'
import {IStatusService} from '../../../common/status/StatusService'
import {STATUS_SERVICE_KEY} from '../../../container/app'
import {Query} from '../../../common/api/Query'
import {Client, ClientOdoo, ClientQuery, ClientRegister} from '../models/Client'
import {ClientContainerConfig} from '../container'
import {ClientDTO, ClientRegisterDTO, toModel, toModelCR} from '../models/ClientDTO'
import {prepareURL} from '../../../common/api/http-helpers'
import {catchError, map} from 'rxjs/operators'

export interface IClientApi extends IInit {
    getByID(id: string): Observable<Client | undefined>

    getByUserID(userID: string): Observable<Client | undefined>

    getFilteredList(q: Query<ClientQuery>): Observable<ItemList<Client>>

    add(e: ClientDTO): Observable<Client | undefined>

    update(e: ClientDTO): Observable<Client | undefined>

    delete(id: string): Observable<boolean>

    addAndRegister(cr: ClientRegisterDTO): Observable<ClientRegister | undefined>

    getClinicAndUsers(clinicID: string): Observable<any | undefined>

    getClinicsOdoo(): Observable<ItemList<ClientOdoo>>
}

export class ClientApi implements IClientApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as ClientContainerConfig).moduleFullUrl
    }

    add(e: ClientDTO): Observable<Client | undefined> {
        return this._httpClient.post<Client>({url: this._url, body: e}).pipe(
            map<ClientDTO, Client>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({url: this._url + '/' + id}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<Client | undefined> {
        return this._httpClient.get<Client>({url: `${this._url}/${id}`}).pipe(
            map<ClientDTO, Client>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    getByUserID(userID: string): Observable<Client | undefined> {
        return this._httpClient.get<Client>({url: `${this._url}/${userID}`}).pipe(
            map<ClientDTO, Client>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    getClinicAndUsers(clinicID: string): Observable<any | undefined> {
        return this._httpClient.get<any>({url: `${this._url}/getClinicAndUsers/${clinicID}`}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    getFilteredList(q: Query<ClientQuery>): Observable<ItemList<Client>> {
        return this._httpClient.get<ItemList<Client>>({url: prepareURL(this._url, q)}).pipe(
            map<ItemList<ClientDTO>, ItemList<Client>>((dto) => {
                const itemList = emptyList<Client>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(emptyList<Client>())
            })
        )
    }

    update(e: ClientDTO): Observable<Client | undefined> {
        return this._httpClient.put<Client>({url: this._url, body: e}).pipe(
            map<ClientDTO, Client>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    addAndRegister(cr: ClientRegisterDTO): Observable<ClientRegister | undefined> {
        return this._httpClient.post<ClientRegister>({url: this._url + '/clientRegister', body: cr}).pipe(
            map<ClientRegisterDTO, ClientRegister>((d) => toModelCR(d)),
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    getClinicsOdoo(): Observable<ItemList<ClientOdoo>> {
        return this._httpClient.get<ItemList<ClientOdoo>>({ url: this._url + "/getclinics/odoo" }).pipe(
          map<ItemList<ClientOdoo>, ItemList<ClientOdoo>>((dto) => {
            const itemList = emptyList<ClientOdoo>()
            itemList.count = dto.count
            itemList.items = dto.items
            return itemList
          }),
          catchError((err) => {
            this._statusService.sendStatus({ variant: 'error', error: err })
            return of(emptyList<ClientOdoo>())
          })
        )
      }
}
