export enum Permission {
  viewUsers = 'ViewUsers',
  editAllUsers = 'EditAllUsers',
  editUsers = 'EditUsers',
  deleteUsers = 'DeleteUsers',
  viewOwnClinics = 'ViewOwnClinics',
  viewClinics = 'ViewClinics',
  viewAll = 'ViewAll',
  editClinics = 'EditClinics',
  deleteClinics = 'DeleteClinics',
  viewTestTypes = 'ViewTestTypes',
  editTestTypes = 'EditTestTypes',
  deleteTestTypes = 'DeleteTestTypes',
  viewGeneTypes = 'ViewGeneTypes',
  editGeneTypes = 'EditGeneTypes',
  deleteGeneTypes = 'DeleteGeneTypes',
  viewPatientParams = 'ViewPatientParams',
  editPatientParams = 'EditPatientParams',
  deletePatientParams = 'DeletePatientParams',
  viewTranslations = 'ViewTranslations',
  editTranslations = 'EditTranslations',
  deleteTranslations = 'DeleteTranslations',
  viewPatients = 'ViewPatients',
  editPatients = 'EditPatients',
  deletePatients = 'DeletePatients',
  viewPatientTests = 'ViewPatientTests',
  editPatientTests = 'EditPatientTests',
  deletePatientTests = 'DeletePatientTests',
  viewMatchings = 'ViewMatchings',
  editMatchings = 'EditMatchings',
  deleteMatchings = 'DeleteMatchings',
  viewReports = 'ViewReports',
  editReports = 'EditReports',
  deleteReports = 'DeleteReports',
  uploadResults = 'UploadResults',
  seeResultsRegister = 'SeeResultsRegister',
  changeClinicCredit = 'ChangeClinicCredit',
  changeScript = 'ChangeScript',
  changeClinicAssignment = 'ChangeClinicAssignment',
  changeClinicState = 'ChangeClinicState',
  viewReceptiveness = 'ViewReceptiveness',
  viewFaqs = "ViewFAQs",
  createFaqs = "CreateFAQs",
  editFaqs = "EditFAQs",
  createRequest = "CreateRequest",
  viewRequest = "ViewRequest",
  viewOwnRequest = "ViewOwnRequest",
  editRequest = "EditRequest",
  viewClients = "ViewClients",
  createClients = "CreateClients",
  seeRequestIDOnRequests = "SeeRequestIDOnRequests",
  seeClientOnRequests = "SeeClientOnRequests",
  addResults = "AddResults",
  seeResults = "SeeResults",
  addStatistics = "AddStatistics",
  sendFeedback = "SendFeedback",
  viewAllStatistics = "ViewAllStatistics",
  createRequestToOtherClient = "CreateRequestToOtherClient",
  assignOtherClientToClient = "AssignOtherClientToClient",
  seeAllPatients = "SeeAllPatients",
  deleteResults = "DeleteResults"
}
