export enum Month {
    January = "jan",
    February = "feb",
    March = "mar",
    April = "apr",
    May = "may",
    June = "jun",
    July = "jul",
    August = "aug",
    September = "sep",
    October = "oct",
    November = "nov",
    December = "dec",
}