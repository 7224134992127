import { COLOR_CLIENTS } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Client, ClientQuery } from '../../modules/clients/models/Client'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { getClientContainer } from '../../container/client-modules'
import { ClientService } from '../../modules/clients/services/ClientService'
import { CLIENTS_SERVICE_KEY } from '../../modules/clients'
import { navigate } from '@reach/router'
import { URL_APPLICATION_CLIENT_SHOW, URL_CLIENTS_ID } from '../../routes/routes-constants'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import { Box, Modal } from '@material-ui/core'
import { Permission } from '../../common/enums/Permissions'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { commonStyles } from '../../common/styles/Styles'

const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [selectedClinic, setSelectedClinic] = useState<Client | undefined>()
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Client[]>([])
  const [sort, setSort] = useState<SortParam<Client>>({
    field: 'taxName',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<ClientQuery>[]>([ 
    {
      name: 'taxName',
      label: t('search')
    },
  ])

  const styles = commonStyles()

  useEffect(() => {
    if (!isLoading) {
      return
    }

    let value: string = searcher.length > 0 ? searcher[0].value || "" : ""

    clientService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
          query: [new QueryParam<ClientQuery>('all', value)],
        }),
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading, searcher])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<Client>[] = [
    {
      sortable: true,
      searchable: true,
      label: t('client'),
      name: 'taxName',
    },
    {
      sortable: true,
      searchable: true,
      label: t('taxIdentity'),
      name: 'taxIdentity',
    },
    {
      sortable: true,
      searchable: true,
      label: t('address'),
      name: 'city',
    },
  ]

  const viewRequests = (c: Client) => navigate(URL_APPLICATION_CLIENT_SHOW.replace(':id', `${c.id}`))

  const editClient = (c: Client) => navigate(URL_CLIENTS_ID.replace(':id', `${c.id}`))

  const selectClinic = (c: Client) => {
    setSelectedClinic(c)
    setOpen(true)
  }

  const deleteClinic = () =>
    selectedClinic?.id && clientService.delete(selectedClinic.id).subscribe(() => {
      setSelectedClinic(undefined)
      setIsLoading(true)
    })

  const actions: Actions<Client> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewRequests,
        icon: seeIcon,
        label: t('Show'),
      },
      {
        handler: editClient,
        icon: editIcon,
        label: t('Edit'),
        hidden: () => !authService.userCan(Permission.editClinics),
      },
      {
        handler: selectClinic,
        icon: deleteIcon,
        label: t('Delete'),
        hidden: () => !authService.userCan(Permission.deleteClinics),
      },
    ],
  }

  const search: Search<ClientQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<ClientQuery>[]) => {
      setPage(0)
      setSearcher(svs)
      setIsLoading(true)
    },
  }

  const sortable: Sort<Client> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const handleDelete = () => {
    setOpen(false)
    deleteClinic()
  }

  const closeModal = () => {
    setOpen(false)
    setSelectedClinic(undefined)
  }

  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: closeModal,
    },
  ]

  return (
    <>
      <AppTable
        styleHeader={{ color: COLOR_CLIENTS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        search={search}
        sort={sortable}
      />
      <Modal open={open}>
        <Box className={styles.modal}>
          <form onSubmit={handleDelete}>
            <Box mt={2} textAlign={'justify'}>
              {t('clinicConfirmationMessage')}
            </Box>
            <FormActions actions={actionsDelete} message={t('confirm')} />
          </form>
        </Box>
      </Modal>
    </>
  )
}
