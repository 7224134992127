import { MonthlyData } from "./MonthlyData";
import {MonthFromIndex} from "../utils/Month";
import {PathologyFromIndex} from "../utils/Pathology";
import {Month} from "../enum/Month";
import {Pathology} from "../enum/Pathology";

export interface MonthlyDataDTO {
    month: Month
    euploidyRate: number
    trisomyRate: number
    monosomyRate: number
    duplicationRate: number
    deletionRate: number
    mosaicismRate: number
    chaoticRate: number
    nonAmplifiedRate: number
    nonInformativeRate: number
}

export function emptyMonthlyDTO(): MonthlyDataDTO {
    return {
        month: Month.January,
        euploidyRate: 0,
        trisomyRate: 0,
        monosomyRate: 0,
        duplicationRate: 0,
        deletionRate: 0,
        mosaicismRate: 0,
        chaoticRate: 0,
        nonAmplifiedRate: 0,
        nonInformativeRate: 0,
    }
}

export function fromModel(m: MonthlyData): MonthlyDataDTO {
    return {
        month: m.month,
        euploidyRate: m.euploidyRate,
        monosomyRate: m.monosomyRate,
        trisomyRate: m.trisomyRate,
        duplicationRate: m.duplicationRate,
        deletionRate: m.deletionRate,
        mosaicismRate: m.mosaicismRate,
        chaoticRate: m.chaoticRate,
        nonAmplifiedRate: m.nonAmplifiedRate,
        nonInformativeRate: m.nonInformativeRate,
    }
}

export function toModel(m: MonthlyDataDTO): MonthlyData {
    return new MonthlyData(m)
}

export function getPathologyValue(monthlyData: MonthlyDataDTO, pathology: Pathology): number {
    switch (pathology) {
        case Pathology.EuploidyRate:
            return monthlyData.euploidyRate

        case Pathology.TrisomyRate:
            return monthlyData.trisomyRate

        case Pathology.MonosomyRate:
            return monthlyData.monosomyRate

        case Pathology.DuplicationRate:
            return monthlyData.duplicationRate
        case Pathology.DeletionRate:
            return monthlyData.deletionRate

        case Pathology.MosaicismRate:
            return monthlyData.mosaicismRate

        case Pathology.ChaoticRate:
            return monthlyData.chaoticRate

        case Pathology.NonAplifiedRate:
            return monthlyData.nonAmplifiedRate

        case Pathology.NonInformativeRate:
            return monthlyData.nonInformativeRate
        default:
            return -1
    }
}

export function modifyPathologyValue(monthlyData: MonthlyDataDTO, pathology: Pathology, value: number) {
    const newMonthlyData = { ...monthlyData }
    switch (pathology) {
        case Pathology.EuploidyRate:
            newMonthlyData.euploidyRate = value
            break
        case Pathology.TrisomyRate:
            newMonthlyData.trisomyRate = value
            break
        case Pathology.MonosomyRate:
            newMonthlyData.monosomyRate = value
            break
        case Pathology.DuplicationRate:
            newMonthlyData.duplicationRate = value
            break
        case Pathology.DeletionRate:
            newMonthlyData.deletionRate = value
            break
        case Pathology.MosaicismRate:
            newMonthlyData.mosaicismRate = value
            break
        case Pathology.ChaoticRate:
            newMonthlyData.chaoticRate = value
            break
        case Pathology.NonAplifiedRate:
            newMonthlyData.nonAmplifiedRate = value
            break
        case Pathology.NonInformativeRate:
            newMonthlyData.nonInformativeRate = value
            break
    }
    return newMonthlyData
}
