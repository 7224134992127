export type SampleNGSProps = {
    _id: string | undefined
    _typeSample: string
    _extractionDate: Date
    _codeSample: string
    _idOdoo: string

}

export class SampleNGS {
    private readonly _id: string | undefined
    _typeSample: string
    _extractionDate: Date
    _codeSample: string
    _idOdoo: string

    constructor(p: SampleNGSProps) {
        this._id = p._id
        this._typeSample = p._typeSample
        this._extractionDate = p._extractionDate
        this._codeSample = p._codeSample
        this._idOdoo = p._idOdoo
    }

    get id(): string | undefined {
        return this._id
    }

    get typeSample(): string {
        return this._typeSample
    }

    set typeSample(value: string) {
        this._typeSample = value
    }

    get extractionDate(): Date {
        return this._extractionDate
    }

    set extractionDate(value: Date) {
        this._extractionDate = value
    }

    get codeSample(): string {
        return this._codeSample
    }

    set codeSample(value: string) {
        this._codeSample = value
    }

    get idOdoo(): string {
        return this._idOdoo
    }

    set idOdoo(value: string) {
        this._idOdoo = value
    }

}
