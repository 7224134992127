import { useEffect, useState, useRef } from 'react'
import { useStyles } from './Home.styles'
import { COLOR_PRIMARY } from "../../routes/color-constants";
import { Button, MenuItem, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { BoolQueryParam, Query, QueryParam } from "../../common/api/Query";
import { getRequestContainer } from "../../container/request-modules"
import { REQUEST_SERVICE_KEY } from "../../modules/request"
import { Request } from '../../modules/request/models/Request'
import { RequestService } from '../../modules/request/services/RequestService'
import estadisticas from '../../assets/Iconos_muestras/estadisticas.svg'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { getPathologyValue, MonthlyDataDTO } from "../../modules/statistics/models/MonthlyDataDTO";
import { MonthlyDataQuery } from "../../modules/statistics/models/MonthlyData";
import FormControl from '@mui/material/FormControl';
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from "@mui/material/Grid";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { es } from 'date-fns/locale';
import { Permission } from "../../common/enums/Permissions";
import { getAuthContainer } from "../../container/auth-modules";
import { AuthService } from "../../modules/auth/services/AuthService";
import { AUTH_SERVICE_KEY } from "../../modules/auth";
import { getStatisticsContainer } from "../../container/statistics-modules";
import { StatisticsService } from "../../modules/statistics/services/StatisticsService";
import { STATISTICS_SERVICE_KEY } from "../../modules/statistics";
import Pathologies from "../../modules/statistics/models/Pathologies";
import { StringToAge } from "../../modules/statistics/utils/Age";
import { PathologyToIndex, StringToPathology } from "../../modules/statistics/utils/Pathology";
import { Pathology } from "../../modules/statistics/enum/Pathology";
import { Age } from "../../modules/statistics/enum/Age";
import { downloadFile } from "../../common/files/file";
import { emptyFileDTO, File as F } from "../../modules/files/models/File";
import { svgToPng } from "../../common/utils/FileUtils"
import { v4 as uuidv4 } from 'uuid'

const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const statisticsService = getStatisticsContainer().get<StatisticsService>(STATISTICS_SERVICE_KEY)

interface PlotFilters {
    pathology: Pathology,
    since?: Date,
    until?: Date,
    age?: Age
}

const getPathologyAccumulatedValue = (monthlyData: MonthlyDataDTO[], pathology: Pathology) => {
    return monthlyData.reduce((accumulator, currentValue) => accumulator + getPathologyValue(currentValue, pathology), 0)
}

const lastMonth = (dateFrom: Date | undefined, dateTo: Date | undefined): number | undefined => {
    if (dateTo && dateFrom?.getFullYear() === dateTo.getFullYear()) {
        return dateTo.getMonth() + 1
    }
    return undefined
}

const formatDate = (date: Date): string => {
    const twoDigitNumber = (n: number): string => {
        return ("0" + n).slice(-2)
    }

    return `${date.getFullYear()}-${twoDigitNumber(date.getMonth() + 1)}-${twoDigitNumber(date.getDate())}T00:00:00.000Z`
}

export const Statistic = () => {
    const classes = useStyles({ color: COLOR_PRIMARY })
    const { t, i18n } = useTranslation()
    const [items, setItems] = useState<Request[]>([])
    const [monthlyData, setMonthlyData] = useState<MonthlyDataDTO[]>([])
    const [filters, setFilters] = useState<PlotFilters>({ pathology: Pathology.AllPathologies })
    let chartRef = useRef(null)
    let chartLegendRef = useRef(null)

    useEffect(() => {
        requestService
            .getFilteredList(
                new Query({})
            )
            .subscribe((res) => {
                setItems(res.items)
            })
    }, [])

    useEffect(() => {
        const adminAllStatistics = authService.userCan(Permission.viewAllStatistics)
        const params = [
            new QueryParam<MonthlyDataQuery>("seeAll", new BoolQueryParam(adminAllStatistics)),
            new QueryParam<MonthlyDataQuery>("type", PathologyToIndex(filters.pathology))
        ]
        if (filters.since) {
            params.push(new QueryParam<MonthlyDataQuery>("since", formatDate(filters.since)))
        }

        if (filters.until) {
            params.push(new QueryParam<MonthlyDataQuery>("until", formatDate(filters.until)))
        }

        if (filters.age) {
            params.push(new QueryParam<MonthlyDataQuery>(filters.age, new BoolQueryParam(true)))
        }

        statisticsService.getGraphData(new Query<MonthlyDataQuery>({
            query: params
        })).subscribe(res => {
            setMonthlyData(res.slice(filters.since?.getMonth(), lastMonth(filters.since, filters.until)))
        })
    }, [filters])

    const CustomTooltip = ({ active, payload }: TooltipProps<number, string>) => {
        const formatPercentage = (value: number): string => {
            return `${value.toFixed(2)}%`
        }

        if (active && payload && payload.length && payload[0].value) {
            return (
                <div className={`custom-tooltip ${classes.customTooltip}`}>
                    <div className={classes.colorSample} style={{ backgroundColor: `${payload[0].color}` }}></div>
                    <p className="label">{formatPercentage(payload[0].value)}</p>
                </div>
            );
        }

        return null;
    };

    const handlePathologyFilterChange = (event: SelectChangeEvent<Pathology>) => {
        const newPathology = StringToPathology(event.target.value);
        if (newPathology) {
            setFilters({ ...filters, pathology: newPathology });
        } else {
            throw new Error("Bad pathology selected");
        }
    };

    const handleAgeFilterChange = (event: SelectChangeEvent<Age>) => {
        const newAge = StringToAge(event.target.value);
        setFilters({ ...filters, age: newAge });
    }

    const handleDateChange = (filter: string, newDate: Date | null) => {
        if (newDate === null) {
            switch (filter) {
                case "since":
                    setFilters({ ...filters, since: undefined })
                    break
                case "until":
                    setFilters({ ...filters, until: undefined })
                    break
            }
        } else if (!isNaN(newDate.getDate())) {
            switch (filter) {
                case "since":
                    setFilters({ ...filters, since: newDate })
                    break
                case "until":
                    setFilters({ ...filters, until: newDate })
                    break
            }
        }
    }

    function blobToBase64(blob: Blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const download = async (type: string) => {
        if (type == "xls") {
            const params = [
                new QueryParam<MonthlyDataQuery>("seeAll", new BoolQueryParam(authService.userCan(Permission.viewAllStatistics))),
                new QueryParam<MonthlyDataQuery>("type", PathologyToIndex(filters.pathology))
            ]

            if (filters.since) {
                params.push(new QueryParam<MonthlyDataQuery>("since", formatDate(filters.since)))
            }

            if (filters.until) {
                params.push(new QueryParam<MonthlyDataQuery>("until", formatDate(filters.until)))
            }

            if (filters.age) {
                params.push(new QueryParam<MonthlyDataQuery>(filters.age, new BoolQueryParam(true)))
            }

            statisticsService.generateXLS(new Query({
                query: params,
            })).subscribe((res) => {
                res && downloadFile("Statistics-" + new Date().toLocaleDateString() + ".xls", res.mimeType, res.base64)
            })
        } else if (type == "pdf") {
            const adminAllStatistics = authService.userCan(Permission.viewAllStatistics)

            const params = [
                new QueryParam<MonthlyDataQuery>("seeAll", new BoolQueryParam(adminAllStatistics)),
                new QueryParam<MonthlyDataQuery>("type", PathologyToIndex(filters.pathology))
            ]

            if (filters.since) {
                params.push(new QueryParam<MonthlyDataQuery>("since", formatDate(filters.since)))
            }

            if (filters.until) {
                params.push(new QueryParam<MonthlyDataQuery>("until", formatDate(filters.until)))
            }

            if (filters.age) {
                params.push(new QueryParam<MonthlyDataQuery>(filters.age, new BoolQueryParam(true)))
            }

            //@ts-ignore
            let svg = chartRef?.current?.container.children[0]

            //@ts-ignore
            let WIDTH = 1160;
            let HEIGHT = 450;
            let pngData = await svgToPng(svg, WIDTH, HEIGHT);

            WIDTH = 1160;
            HEIGHT = 100;

            let file = emptyFileDTO()
            //@ts-ignore
            file.base64 = pngData.substring(pngData.indexOf(",") + 1, pngData.length)
            file.ownerID = uuidv4()

            statisticsService.generatePDF(new Query({
                query: params
            }), file).subscribe((res) => {
                res && downloadFile("Statistics-" + new Date().toLocaleDateString() + ".pdf", res.mimeType, res.base64)
            })
        }
    }

    return (
        <>
            <div className={classes.br}></div>
            <div style={{ display: "flex", justifyContent: "left" }}>
                <img className={classes.titleImage} src={estadisticas} />
                <Typography className={classes.title} variant={'h4'} align="left">
                    {t('statistics')}
                </Typography>
            </div>
            <div className={classes.table}>
                <Grid container spacing={1} style={{ padding: "0 1em 1em", }}>
                    <Grid item xs={3}>
                        <FormControl size="small" sx={{ width: "100%" }}>
                            <Select
                                id="pathology-filter-select"
                                value={filters.pathology}
                                onChange={handlePathologyFilterChange}
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                }}
                            >
                                <MenuItem value={Pathology.AllPathologies}>{t(Pathology.AllPathologies).toUpperCase()}</MenuItem>
                                {
                                    Object.values(Pathology).filter(p => p !== Pathology.AllPathologies).map(pathology =>
                                        <MenuItem value={pathology}>{t(pathology).toUpperCase()}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl
                            sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                }
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}
                                adapterLocale={i18n.language === "es" ? es : undefined}
                            >
                                <DatePicker
                                    label={t("since")}
                                    value={filters.since}
                                    timezone={"UTC"}
                                    onChange={value => handleDateChange("since", value)}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl
                            sx={{
                                width: "100%",
                                "& .MuiOutlinedInput-root": {
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                }
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDateFns}
                                adapterLocale={i18n.language === "es" ? es : undefined}
                            >
                                <DatePicker
                                    label={t("until")}
                                    value={filters.until}
                                    onChange={value => handleDateChange("until", value)}
                                    timezone={"UTC"}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl size="small" sx={{ width: "100%" }}>
                            <InputLabel id="age-filter-label">{t("age")}</InputLabel>
                            <Select
                                id="age-filter-select"
                                value={filters.age}
                                labelId={"age-filter-label"}
                                label={t("age").toUpperCase()}
                                onChange={handleAgeFilterChange}
                                style={{
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                }}
                            >
                                {filters.age && <MenuItem value={""}><em>{t("removeAgeFilter")}</em></MenuItem>}
                                {
                                    Object.values(Age).map(age =>
                                        <MenuItem value={age}>{t(age).toUpperCase()}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item xs={1}>
                        <Button
                            className={classes.button}
                            variant={'contained'}
                            size={"small"}
                            onClick={() => download("xls")}
                            style={{ float: 'right' }}>
                            {"XLS"}<FileDownloadIcon fontSize={"small"} />
                        </Button>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            className={classes.button}
                            variant={'contained'}
                            size={"small"}
                            onClick={() => download("pdf")}
                            style={{ float: 'right' }}
                        >
                            {"PDF"}<FileDownloadIcon fontSize={"small"} />
                        </Button>
                    </Grid>
                </Grid>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={100}
                        height={50}
                        data={monthlyData}
                        ref={chartRef}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        style={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="month" tickFormatter={value => t(value)} />
                        <YAxis tickFormatter={value => `${value}%`} ticks={[0, 20, 40, 60, 80, 100]} domain={[0, 100]} />
                        <Tooltip content={<CustomTooltip />} shared={false} />
                        {Pathologies.map(item => {
                            return <Bar maxBarSize={150} dataKey={`${item.name}`} stackId="a" fill={`${item.color}`} />
                        })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className={classes.customLegend}>
                <ResponsiveContainer width="95%" height="95%">
                    <BarChart ref={chartLegendRef}
                        data={monthlyData}
                        style={{
                            backgroundColor: "white"
                        }}
                    >
                        <Legend
                            iconType={"rect"} align={"center"} verticalAlign={"middle"}
                            formatter={(value, entry, index) =>
                                <span style={{ color: "black" }}>{value}</span>}
                        />
                        {Pathologies
                            .filter(item => getPathologyAccumulatedValue(monthlyData, item.name) > 0)
                            .map(item => {
                                return <Bar dataKey={`${item.name}`} stackId="a" fill={`${item.color}`} name={t(item.name)} />
                            })}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>
    )
}