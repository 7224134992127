import { v4 as uuidv4 } from 'uuid'
import { PatientNGS } from "./PatientNGS";
import { SampleNGS } from "../../../sampleType/models/sampleForm/SampleNGS";
import { emptySampleNGSDTO } from "../../../sampleType/models/sampleForm/SampleNGSDTO";

export interface PatientNGSDTO {
    _id: string | undefined;
    _firstName: string;
    _lastName: string;
    _dob: Date;
    _nhc: string;
    _nss: string
    _province: string
    _zipCode: string
    _phone: string;
    _email: string;
    _indication: string
    _clinicHistory: string
    _sample: SampleNGS
    _sex: string
}

export function emptyPatientNGSDTO(): PatientNGSDTO {
    return {
        _id: uuidv4(),
        _firstName: '',
        _lastName: '',
        _dob: new Date(),
        _nhc: '',
        _nss: '',
        _province: '',
        _zipCode: '',
        _phone: '',
        _email: '',
        _indication: '',
        _clinicHistory: '',
        _sample: new SampleNGS(emptySampleNGSDTO()),
        _sex: "0",
    }
}

export function fromPatientNGSModel(c: PatientNGS): PatientNGSDTO {
    return {
        _id: c.id,
        _firstName: c.firstName,
        _lastName: c.lastName,
        _dob: c.dob,
        _nhc: c.nhc,
        _nss: c.nss,
        _province: c.province,
        _zipCode: c.zipCode,
        _phone: c.phone,
        _email: c.email,
        _indication: c.indication,
        _clinicHistory: c.clinicHistory,
        _sample: c.sample,
        _sex: c.sex,
    }
}

export function toModelPatientNGS(c: PatientNGSDTO): PatientNGS {
    return new PatientNGS(c)
}

export function toModelPatientNGSList(c: PatientNGSDTO[]): PatientNGS[] {
    let list: PatientNGS[] = []
    c?.forEach((p) => {
        list.push(new PatientNGS(p))
    })

    return list
}