import { useTranslation } from 'react-i18next'
import { getRequestContainer } from '../../container/request-modules'
import { REQUEST_SERVICE_KEY } from '../../modules/request'
import { RequestService } from '../../modules/request/services/RequestService'
import { Button, Grid } from '@material-ui/core'
import { useRef, useState } from 'react'
import { useStyles } from '../../features/application/Application.styles'
import { COLOR_PRIMARY } from 'routes/color-constants'
import { getAuthContainer } from 'container/auth-modules'
import { AuthService } from 'modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from 'modules/auth'
import { emptyFileDTO, File, toModel, fromModel, FileDTO } from '../../modules/files/models/File'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { useSnackbar } from 'notistack'
import { Query, QueryParam } from 'common/api/Query'

const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

type ResultsProps = {
  handleChangeIsLoading: (isLoad: boolean) => void
  files: File[]
  setFiles: (file: File[]) => void
}

export const Results = (props: ResultsProps) => {
  const { t } = useTranslation()
  const [file, setFile] = useState<File[]>(props.files)
  const classes = useStyles({ color: COLOR_PRIMARY })
  const inputFile = useRef<HTMLInputElement | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleDelete = (i: number) => {
    let arr = new Array()
    file &&
      file.map((element, index) => {
        if (i != index) {
          arr.push(element)
        }
      })
    setFile(arr)
  }

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL = ''
      let reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result as string
        resolve(baseURL)
      }
    })
  }

  const handleFile = () => {
    inputFile.current?.click()
  }

  const handleSave = (e: any) => {
    setIsLoading(true)
    let aux: any[] = []

    if (file) {
      aux = [...file]
    }

    Array.from(e.target.files).forEach(async (fi: any, i) => {
      let result: any = await getBase64(fi)
      if (typeof result == 'string') {
        let x = emptyFileDTO()
        const extention = fi.name.split('.')
        x = Object.assign(
          { ...x },
          {
            name: fi.name,
            size: fi.size,
            mimeType: fi.type,
            base64: result.substring(result.indexOf(',') + 1),
            extension: extention[extention.length - 1],
            ownerID: authService.get().id,
          }
        )
        aux.push(toModel(x))
      }

      if (i == Array.from(e.target.files).length - 1) {
        setFile(aux)
        props.setFiles(aux)
        setIsLoading(false)
      }
    })
  }

  const handleSend = async () => {
    setIsLoading(true)

    //1. Todos los ficheros deben corresponder a la misma solicitud
    let names: string[] = file?.map((f) => f.name.split('_')[0]) || []

    //2. Solicitud que exista
    let requests = await requestService
      .getFilteredList(
        new Query({
          query: [new QueryParam('newID', names[0])],
        })
      )
      .toPromise()

    if (requests && requests?.count > 0) {
      if (requests.items[0].status == 'Eraser') {
        setIsLoading(false)
        enqueueSnackbar(t('statusCantBeEraser'), { variant: 'error' })
        props.setFiles([])
        return
      }
    } else {
      setIsLoading(false)
      enqueueSnackbar(t('noSuchRequestID') + names[0], { variant: 'error' })
      props.setFiles([])
      return
    }

    let arrToSend: FileDTO[] = []
    let ok = true
    file?.forEach((f) => {
      if (f.size > 20971520) {
        ok = false
        enqueueSnackbar(t('maximumFileSize')+". "+f.name+" "+t("notFulfillTheRequirement"), { variant: 'error' })
        return
      }

      arrToSend.push(fromModel(f))
    })

    if (!ok) {
      setIsLoading(false)
      return;
    }

    requestService.sendResultFiles(arrToSend).subscribe(() => {
      props.setFiles([])
      props.handleChangeIsLoading(true)
      setTimeout(() => {
        setFile([])
        setIsLoading(false)
        enqueueSnackbar(t('filesCorrectlyAdded'), { variant: 'success' })
      }, 1500)
    })
  }

  return (
    <>
      {!isLoading ? (
        <Grid container spacing={4} style={{ marginTop: '1%' }}>
          <Grid item xs={8} style={{ textAlign: 'left' }}>
            <div style={{ margin: '0 0 0 10px ' }}>{t('addResultsReport')}</div>
            {file?.map((elementF: File, indexF: number) => {
              return (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1.5%' }}
                  key={elementF.id}>
                  <div key={indexF.toString()} className={classes.titleProduct}>
                    {elementF.name}
                  </div>
                  <Button
                    className={classes.button}
                    type={'button'}
                    onClick={() => handleDelete(indexF)}>
                    {t('delete')}
                  </Button>
                </div>
              )
            })}
            <div
              style={{
                marginTop: '5%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <input
                type="file"
                id="file"
                multiple={true}
                ref={inputFile}
                onChange={handleSave}
                style={{ display: 'none' }}
              />
              <Button className={classes.buttonSecondary} onClick={handleFile} type={'button'}>
                {t('selectFile')}
              </Button>
              {file && file?.length > 0 && (
                <Button className={classes.button} onClick={handleSend} type={'button'}>
                  {t('send')}
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </>
  )
}
