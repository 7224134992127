import { Container } from '../../common/container/Container'

export type AuthContainerConfig = {
  moduleFullUrl: string
}

export type AuthProps = {
  parentContainer: Container
  config: AuthContainerConfig
}

export const AUTH_API_KEY = Symbol('AUTH_API_KEY')
export const AUTH_SERVICE_KEY = Symbol('AUTH_SERVICE_KEY')
export const AUTHS_MODULE = Symbol('AUTHS_MODULE')
