import {
    COLOR_CHAOTIC_RATE,
    COLOR_DELETION_RATE,
    COLOR_DUPLICATION_RATE,
    COLOR_EUPLOIDY_RATE,
    COLOR_MONOSOMY_RATE,
    COLOR_MOSAICISM_RATE,
    COLOR_NON_AMPLIFIED_RATE,
    COLOR_NON_INFORMATIVE_RATE,
    COLOR_TRISOMY_RATE
} from "../../../routes/color-constants";

import {Pathology} from "../enum/Pathology";

const MonthlyDataPathologies = [
    {
        name: Pathology.EuploidyRate,
        color: COLOR_EUPLOIDY_RATE
    },
    {
        name: Pathology.TrisomyRate,
        color: COLOR_TRISOMY_RATE
    },
    {
        name: Pathology.MonosomyRate,
        color: COLOR_MONOSOMY_RATE
    },
    {
        name: Pathology.DuplicationRate,
        color: COLOR_DUPLICATION_RATE
    },
    {
        name: Pathology.DeletionRate,
        color: COLOR_DELETION_RATE
    },
    {
        name: Pathology.MosaicismRate,
        color: COLOR_MOSAICISM_RATE
    },
    {
        name: Pathology.ChaoticRate,
        color: COLOR_CHAOTIC_RATE
    },
    {
        name: Pathology.NonAplifiedRate,
        color: COLOR_NON_AMPLIFIED_RATE
    },
    {
        name: Pathology.NonInformativeRate,
        color: COLOR_NON_INFORMATIVE_RATE
    },
]

export default MonthlyDataPathologies
