import { Container, ContainerItem } from '../common/container/Container'
import { ClientProps } from '../modules/clients/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { CLIENTS_API_KEY, CLIENTS_SERVICE_KEY, CLIENTS_MODULE } from '../modules/clients'
import { ClientService } from '../modules/clients/services/ClientService'
import { ClientApi } from '../modules/clients/api/ClientApi'

let container: Container

function init(p: ClientProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(CLIENTS_API_KEY, new ContainerItem({ instance: new ClientApi() }))
  items.set(
    CLIENTS_SERVICE_KEY,
    new ContainerItem({
      instance: new ClientService({ apiKey: CLIENTS_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getClientContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[CLIENTS_MODULE],
      },
    })
  }

  return container
}
