export type StatisticsProps = {
  id: string
  requestID: string
  nEmbEuploides: number
  nEmbTrisonomy: number
  nEmbMonosomy: number
  nEmbDups: number
  nEmbDels: number
  nEmbMosaicism: number
  nEmbCaothics: number
  nEmbNoAmp: number
  nEmbNoInf: number
}


export class Statistics {
  private readonly _id: string
  private _requestID: string
  private _nEmbEuploides: number
  private _nEmbTrisonomy: number
  private _nEmbMonosomy: number
  private _nEmbDups: number
  private _nEmbDels: number
  private _nEmbMosaicism: number
  private _nEmbCaothics: number
  private _nEmbNoAmp: number
  private _nEmbNoInf: number

  constructor(p: StatisticsProps) {
    this._id = p.id
    this._requestID = p.requestID
    this._nEmbEuploides = p.nEmbEuploides
    this._nEmbTrisonomy = p.nEmbTrisonomy
    this._nEmbMonosomy = p.nEmbMonosomy
    this._nEmbDups = p.nEmbDups
    this._nEmbDels = p.nEmbDels
    this._nEmbMosaicism = p.nEmbMosaicism
    this._nEmbCaothics = p.nEmbCaothics
    this._nEmbNoAmp = p.nEmbNoAmp
    this._nEmbNoInf = p.nEmbNoInf
  }

  get id(): string {
    return this._id
  }

  get requestID(): string {
    return this._requestID
  }

  set requestID(value: string) {
    this._requestID = value
  }

  get nEmbEuploides(): number {
    return this._nEmbEuploides
  }

  set nEmbEuploides(value: number) {
    this._nEmbEuploides = value
  }

  get nEmbTrisonomy(): number {
    return this._nEmbTrisonomy
  }

  set nEmbTrisonomy(value: number) {
    this._nEmbTrisonomy = value
  }

  get nEmbMonosomy(): number {
    return this._nEmbMonosomy
  }

  set nEmbMonosomy(value: number) {
    this._nEmbMonosomy = value
  }

  get nEmbDups(): number {
    return this._nEmbDups
  }

  set nEmbDups(value: number) {
    this._nEmbDups = value
  }

  get nEmbDels(): number {
    return this._nEmbDels
  }

  set nEmbDels(value: number) {
    this._nEmbDels = value
  }

  get nEmbMosaicism(): number {
    return this._nEmbMosaicism
  }

  set nEmbMosaicism(value: number) {
    this._nEmbMosaicism = value
  }

  get nEmbCaothics(): number {
    return this._nEmbCaothics
  }

  set nEmbCaothics(value: number) {
    this._nEmbCaothics = value
  }

  get nEmbNoAmp(): number {
    return this._nEmbNoAmp
  }

  set nEmbNoAmp(value: number) {
    this._nEmbNoAmp = value
  }

  get nEmbNoInf(): number {
    return this._nEmbNoInf
  }

  set nEmbNoInf(value: number) {
    this._nEmbNoInf = value
  }
}

export interface StatisticsQuery {
  id: string
}

