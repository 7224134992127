import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Statistics, StatisticsQuery } from '../models/Statistics'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { StatisticsDTO } from '../models/StatisticsDTO'
import { IStatisticsApi } from '../api/StatisticsApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { File as F, FileDTO } from "../../../modules/files/models/File"
import { MonthlyDataQuery } from "../models/MonthlyData";
import { MonthlyDataDTO } from "../models/MonthlyDataDTO";

type Props = {
    apiKey: symbol
}

export interface IStatisticsService extends IInit {
    getByID(id: string): Observable<Statistics | undefined>

    getFilteredList(q: Query<StatisticsQuery>): Observable<ItemList<Statistics>>

    add(e: Statistics): Observable<Statistics | undefined>

    update(e: StatisticsDTO): Observable<Statistics | undefined>

    delete(id: string): Observable<boolean>

    uploadFile(files: string[]): Observable<string | undefined>

    generateXLS(q: Query<MonthlyDataQuery>): Observable<F | undefined>

    generatePDF(q: Query<MonthlyDataQuery>, file: FileDTO): Observable<F | undefined>

    getGraphData(q: Query<MonthlyDataQuery>): Observable<MonthlyDataDTO[]>
}

export class StatisticsService implements IStatisticsService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IStatisticsApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IStatisticsApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    add(e: StatisticsDTO): Observable<Statistics | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    getByID(id: string): Observable<Statistics | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<StatisticsQuery>): Observable<ItemList<Statistics>> {
        return this._api.getFilteredList(q)
    }

    update(e: StatisticsDTO): Observable<Statistics | undefined> {
        return this._api.update(e)
    }

    uploadFile(e: string[]): Observable<string | undefined> {
        return this._api.uploadFile(e)
    }

    getGraphData(q: Query<MonthlyDataQuery>): Observable<MonthlyDataDTO[]> {
        return this._api.getGraphData(q)
    }

    generateXLS(q: Query<MonthlyDataQuery>): Observable<F | undefined> {
        return this._api.generateXLS(q)
    }

    generatePDF(q: Query<MonthlyDataQuery>, file: FileDTO): Observable<F | undefined> {
        return this._api.generatePDF(q, file)
    }
}
