import { useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Edit as EditRequest } from '../../features/application/Edit'
import { Title } from 'components/common/Title'

export function Edit(props: RouteProps) {
    const [saveDraftAgain, setSaveDraftAgain] = useState<boolean>(false)

    const handleDraftAgain = () => {
        setSaveDraftAgain(true)
    }

    const editApplication = () => {
        return (
            <EditRequest id={props.id || ''} saveDraftAgain={saveDraftAgain} setSaveDraftAgain={setSaveDraftAgain} />
        )
    }

    return (
        <>
            <Title color={"black"} title={props.title || ''} saveDraftAgain={handleDraftAgain} />
            {editApplication()}
        </>
    )
}
