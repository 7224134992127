import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StyleProps } from '../../components/entity-page/EntityPage'

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: (props) => ({
    width: '100%',
  }),
  title: (props) => ({
    color: props.color,
    margin: "0px 0px 0px 10px",
    fontSize: 28,
  }),
  titleImage:{
    margin: "0px 0px 10px 0px",
  },
  table:{
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#e8e8ea",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "center",
    padding: "2em",
    height: "40em"
  },
  semiTable: {
    width: "80%",
  },
  semiTable2: {
    width: "40%",
    margin: "0 5rem 0 5rem"
  },
  br: {
    marginTop: "5rem"
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    border: 0,
  },
  buttonSecondary: {
    color: theme.palette.common.white,
    background: theme.palette.grey['700'],
    border: 0,

  },
  customTooltip: {
    backgroundColor: "#222",
    borderRadius: "10px",
    color: "white",
    maxWidth: "7em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: ".5em 1em",
  },
  colorSample: {
    width: "1em",
    height: "1em",
    border: "1px solid white",
    borderRadius: "5px",
    marginRight: ".3em"
  },
  customLegend: {
    height: "5em",
    paddingLeft: "2em",
    marginBottom: "5rem",
    "& .recharts-surface": {
      display: "none"
    }
  },
  plotButtons: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 1em 1em",
  }
}))
