import { Button, Grid } from '@material-ui/core'
import { useStyles } from "./Application.styles";
import { COLOR_PRIMARY } from "../../routes/color-constants";
import { useTranslation } from "react-i18next";

type ConsentProps = {
    handleModalClose: () => void
    consent: boolean
    onPrint: () => void
}

export const ModalConsent = (props: ConsentProps) => {
    const classes = useStyles({ color: COLOR_PRIMARY })
    const { t } = useTranslation()

    return (
        <>
            {!props.consent ?
                <Grid className={classes.modalSuccess} container>
                    <Grid xs={12} className={classes.titleSuccess}>
                        {t("successApplication")}
                    </Grid>
                    <div className={classes.descriptionSuccess}>
                        {t("successfulText")}
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "space-around", marginBottom: "3%", width: "100%" }}>
                        <Button className={classes.buttonModalCancel} type={"button"} onClick={props.handleModalClose}>
                            {t('skip')}
                        </Button>
                        <Button className={classes.button} type={"button"} onClick={props.onPrint}>{t('print')}</Button>
                    </div>
                </Grid>
                :
                <Grid className={classes.modalConsent} container>
                    <Grid xs={12} className={classes.title} style={{ textAlign: "center", margin: "20px 0 20px 0" }}>
                        {t("informedConsent")}
                    </Grid>
                    <Grid xs={12} style={{ color: "white", margin: "0 20px 20px 20px", fontSize: "18px" }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </Grid>
                    <Grid xs={12} style={{ display: "flex", justifyContent: "space-around", margin: "0 0 20px 0" }}>
                        <Button className={classes.button} type={"button"} onClick={() => { }}>{t('finish')}</Button>
                    </Grid>
                </Grid>
            }
        </>
    )
}