export type SampleOncologyProps = {
    _id: string | undefined
    _typeSample: string
    _sampleProvided: number
    _metastasesDescription: string
    _extractionDate: Date
    _sampleTumorCellularity: string
    _code: string
    _idOdoo: string

}

export class SampleOncology {
    private readonly _id: string | undefined
    _typeSample: string
    _sampleProvided: number
    _metastasesDescription: string
    _extractionDate: Date
    _sampleTumorCellularity: string
    _code: string
    _idOdoo: string

    constructor(p: SampleOncologyProps) {
        this._id = p._id
        this._typeSample = p._typeSample
        this._sampleProvided = p._sampleProvided
        this._metastasesDescription = p._metastasesDescription
        this._extractionDate = p._extractionDate
        this._sampleTumorCellularity = p._sampleTumorCellularity
        this._code = p._code
        this._idOdoo = p._idOdoo
    }

    get id(): string | undefined {
        return this._id
    }

    get typeSample(): string {
        return this._typeSample;
    }

    set typeSample(value: string) {
        this._typeSample = value;
    }

    get sampleProvided(): number {
        return this._sampleProvided;
    }

    set sampleProvided(value: number) {
        this._sampleProvided = value;
    }

    get metastasesDescription(): string {
        return this._metastasesDescription;
    }

    set metastasesDescription(value: string) {
        this._metastasesDescription = value;
    }

    get extractionDate(): Date {
        return this._extractionDate;
    }

    set extractionDate(value: Date) {
        this._extractionDate = value;
    }

    get sampleTumorCellularity(): string {
        return this._sampleTumorCellularity;
    }

    set sampleTumorCellularity(value: string) {
        this._sampleTumorCellularity = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get idOdoo(): string {
        return this._idOdoo;
    }

    set idOdoo(value: string) {
        this._idOdoo = value;
    }
}
