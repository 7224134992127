import { Base64 } from 'js-base64'

export const DEFAULT_LIMIT = 20

type QueryProps<Q> = {
  query?: QueryParam<Q>[] | QueryParamN<Q>[]
  pager?: Pager
  sort?: SortParam<Q>[]
}

export class Query<Q> {
  // We use Map for duplication control
  private readonly _queryParams: Map<string, QueryParam<Q> | QueryParamN<Q>> | undefined
  private readonly _sort: Map<string, SortParam<Q>> | undefined
  private readonly _pager?: Pager

  constructor(p: QueryProps<Q>) {
    if (p.query && p.query.length > 0) {
      this._queryParams = new Map<string, QueryParam<Q>>()
      p.query.map((v: QueryParam<Q> | QueryParamN<Q>) =>
        this._queryParams?.set(v.name as string, v)
      )
    }
    if (p.sort && p.sort.length > 0) {
      this._sort = new Map<string, SortParam<Q>>()
      p.sort.map((v) => this._sort?.set(v.field, v))
    }

    this._pager = p.pager
  }

  buildQuery(): string {
    const q = this._queryParams
      ? 'q=' +
        Base64.encode(
          JSON.stringify(
            Object.fromEntries(
              Array.from(this._queryParams.values()).map((qp) => [[qp.name], qp.value])
            )
          )
        ) +
        '&'
      : ''
    const s = this._sort
      ? 's=' + Base64.encode(JSON.stringify(Array.from(this._sort.values()))) + '&'
      : ''
    const p = this._pager ? `lim=${this._pager.limit}&offset=${this._pager.offset}` : ''

    return q + s + p
  }

  getParam(key: string): Value | undefined {
    return this._queryParams?.get(key)?.value
  }
}

type PropsValue = string | number | string[] | number[] | Date | BoolQueryParam
type Value = string | number | string[] | number[] | Date | BoolQueryParam

export type QueryParamN<Q> = {
  name: keyof Q | string
  value: string | number | string[] | number[] | Date | BoolQueryParam
}

export class QueryParam<Q> {
  private readonly _name: keyof Q | string
  private readonly _value: Value

  constructor(name: keyof Q | string, value: PropsValue) {
    this._name = name
    this._value = QueryParam.parseParams(value)
  }

  private static parseParams(v: PropsValue): Value {
    if (v instanceof Date) {
      return v.toString()
    }

    return v
  }

  get name(): keyof Q | string {
    return this._name
  }

  get value(): Value {
    return this._value
  }
}

export type SortParam<T> = {
  field: string
  desc?: boolean
}

export type Pager = {
  limit: number
  offset: number
}

export class BoolQueryParam {
  private readonly hasValue: boolean = true
  private readonly value: boolean

  constructor(val: boolean) {
    this.value = val
  }
}
