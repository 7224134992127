import { v4 as uuidv4 } from 'uuid'
import { Sample } from './Sample'

export interface SampleDTO {
  id: string | undefined
  type: string
  date: Date
  codeSample: string
  idOdooType: string
}

export function emptySampleDTO(): SampleDTO {
  return {
    id: uuidv4(),
    type: '',
    date: new Date(),
    codeSample: '',
    idOdooType: '',
  }
}

export function fromModel(c: Sample): SampleDTO {
  return {
    id: c.id,
    type: c.type,
    date: c.date,
    codeSample: c.codeSample,
    idOdooType: c.idOdooType,
  }
}

export function toModel(c: SampleDTO): Sample {
  return new Sample(c)
}
