import { v4 as uuidv4 } from 'uuid'
import { Address } from './Address'

export interface AddressDTO {
  id: string | undefined
  requestID: string
  street: string
  number: string
  zipCode: string
  city: string
  province: string
  date: Date
  hour: number
  phone: string
  firstName: string
  lastName: string
}

export function emptyAddressDTO(): AddressDTO {
  return {
    id: uuidv4(),
    requestID: uuidv4(),
    street: '',
    number: '',
    zipCode: '',
    city: '',
    province: '',
    date: new Date(),
    hour: 0,
    phone: '',
    firstName:  '',
    lastName:  '',
  }
}

export function fromModel(c: Address): AddressDTO {
  return {
    id: c.id,
    requestID: c.requestID,
    street: c.street,
    number: c.number,
    zipCode: c.zipCode,
    city: c.city,
    province: c.province,
    date: c.date,
    hour: c.hour,
    phone: c.phone,
    firstName: c.firstName,
    lastName: c.lastName,

  }
}

export function toModel(c: AddressDTO): Address {
  return new Address(c)
}
