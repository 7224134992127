import { Product } from "../../../product/models/Product";
import { Patient } from "../Patient";
import { PatientPGT } from "../patientForm/PatientPGT";
import { PatientMicroVE } from "../patientForm/PatientMicroVE";
import { PatientNGS } from "../patientForm/PatientNGS";
import { Genes } from "../../../genes/models/Genes";
import { Address } from "../../../address/models/Address";
import { File } from "../../../files/models/File";
import {PatientCitogeneticaArray} from "../patientForm/PatientCitogeneticaArray";
import {PatientOncology} from "../patientForm/PatientOncology";


export type PatientProductProp = {
    product: Product
    patientNulls: Patient[]
    patientPGTs: PatientPGT[]
    patientMicroVE: PatientMicroVE[]
    patientNGS: PatientNGS[]
    patientCitogenetics: PatientCitogeneticaArray[]
    patientOncology: PatientOncology[]
    genes: Genes[]
    address: Address
    userID: string
    file: File[]
    step: number
    language: string
    indications: string
}


export class PatientProduct {
    _product: Product
    _patientNulls: Patient[]
    _patientPGTs: PatientPGT[]
    _patientMicroVE: PatientMicroVE[]
    _patientNGS: PatientNGS[]
    _patientCitogenetics: PatientCitogeneticaArray[]
    _patientOncology: PatientOncology[]
    _genes: Genes[]
    _address: Address
    _userID: string
    private _file: File[]
    _step: number
    _language: string
    _indications: string

    constructor(p: PatientProductProp) {
        this._product = p.product
        this._patientNulls = p.patientNulls
        this._patientPGTs = p.patientPGTs
        this._patientMicroVE = p.patientMicroVE
        this._patientNGS = p.patientNGS
        this._patientCitogenetics = p.patientCitogenetics
        this._patientOncology = p.patientOncology
        this._genes = p.genes
        this._address = p.address
        this._userID = p.userID
        this._file = p.file
        this._step = p.step
        this._language = p.language
        this._indications = p.indications
    }

    public get product(): Product {
        return this._product
    }

    public set product(value: Product) {
        this._product = value
    }

    public get patientNulls(): Patient[] {
        return this._patientNulls
    }

    public set step(value: number) {
        this._step = value
    }

    public get step(): number {
        return this._step
    }

    public set patientNulls(value: Patient[]) {
        this._patientNulls = value
    }

    public get patientPGTs(): PatientPGT[] {
        return this._patientPGTs
    }

    public set patientPGTs(value: PatientPGT[]) {
        this._patientPGTs = value
    }

    public get patientMicroVE(): PatientMicroVE[] {
        return this._patientMicroVE
    }

    public set patientMicroVE(value: PatientMicroVE[]) {
        this._patientMicroVE = value
    }

    public get patientNGS(): PatientNGS[] {
        return this._patientNGS
    }

    public set patientNGS(value: PatientNGS[]) {
        this._patientNGS = value
    }

    public get patientCitogenetics(): PatientCitogeneticaArray[] {
        return this._patientCitogenetics
    }

    public set patientCitogenetics(value: PatientCitogeneticaArray[]) {
        this._patientCitogenetics = value
    }

    get patientOncology(): PatientOncology[] {
        return this._patientOncology;
    }

    set patientOncology(value: PatientOncology[]) {
        this._patientOncology = value;
    }

    get file(): File[] {
        return this._file;
    }

    set file(value: File[]) {
        this._file = value;
    }

    public get genes(): Genes[] {
        return this._genes
    }

    public set genes(value: Genes[]) {
        this._genes = value
    }

    public get address(): Address {
        return this._address
    }

    public set address(value: Address) {
        this._address = value
    }

    public get userID(): string {
        return this._userID
    }

    public set userID(value: string) {
        this._userID = value
    }

    public get files(): File[] {
        return this._file
    }

    public set files(value: File[]) {
        this._file = value
    }

    public get language(): string {
        return this._language
    }

    public set language(value: string) {
        this._language = value
    }


    public get indications(): string {
        return this._indications
    }

    public set indications(value: string) {
        this._indications = value
    }

}