import {RouteProps} from '../../routes/AppRouter'
import {ApplicationDetail} from '../../features/application'
import {Title} from 'components/common/Title'

export function Detail(props: RouteProps) {
    return (
        <>
            <Title color={"black"} title={props.title || ''}/>
            <ApplicationDetail id={props.id || ''}/>
        </>
    )
}
