import i18n from 'i18next'

export const getCountries = () => {
    return [
        { label: i18n.t('afghanistan'), value: 'afghanistan' },
        { label: i18n.t('albania'), value: 'albania' },
        { label: i18n.t('algeria'), value: 'algeria' },
        { label: i18n.t('andorra'), value: 'andorra' },
        { label: i18n.t('angola'), value: 'angola' },
        { label: i18n.t('antiguaAndBarbuda'), value: 'antiguaAndBarbuda' },
        { label: i18n.t('argentina'), value: 'argentina' },
        { label: i18n.t('armenia'), value: 'armenia' },
        { label: i18n.t('australia'), value: 'australia' },
        { label: i18n.t('austria'), value: 'austria' },
        { label: i18n.t('azerbaijan'), value: 'azerbaijan' },
        { label: i18n.t('bahamas'), value: 'bahamas' },
        { label: i18n.t('bahrain'), value: 'bahrain' },
        { label: i18n.t('bangladesh'), value: 'bangladesh' },
        { label: i18n.t('barbados'), value: 'barbados' },
        { label: i18n.t('belarus'), value: 'belarus' },
        { label: i18n.t('belgium'), value: 'belgium' },
        { label: i18n.t('belize'), value: 'belize' },
        { label: i18n.t('benin'), value: 'benin' },
        { label: i18n.t('bhutan'), value: 'bhutan' },
        { label: i18n.t('bolivia'), value: 'bolivia' },
        { label: i18n.t('bosniaAndHerzegovina'), value: 'bosniaAndHerzegovina' },
        { label: i18n.t('botswana'), value: 'botswana' },
        { label: i18n.t('brazil'), value: 'brazil' },
        { label: i18n.t('brunei'), value: 'brunei' },
        { label: i18n.t('bulgaria'), value: 'bulgaria' },
        { label: i18n.t('burkinaFaso'), value: 'burkinaFaso' },
        { label: i18n.t('burundi'), value: 'burundi' },
        { label: i18n.t('caboVerde'), value: 'caboVerde' },
        { label: i18n.t('cambodia'), value: 'cambodia' },
        { label: i18n.t('cameroon'), value: 'cameroon' },
        { label: i18n.t('canada'), value: 'canada' },
        { label: i18n.t('centralAfricanRepublic'), value: 'centralAfricanRepublic' },
        { label: i18n.t('chad'), value: 'chad' },
        { label: i18n.t('chile'), value: 'chile' },
        { label: i18n.t('china'), value: 'china' },
        { label: i18n.t('colombia'), value: 'colombia' },
        { label: i18n.t('comoros'), value: 'comoros' },
        { label: i18n.t('congo'), value: 'congo' },
        { label: i18n.t('costaRica'), value: 'costaRica' },
        { label: i18n.t('croatia'), value: 'croatia' },
        { label: i18n.t('cuba'), value: 'cuba' },
        { label: i18n.t('cyprus'), value: 'cyprus' },
        { label: i18n.t('czechRepublic'), value: 'czechRepublic' },
        { label: i18n.t('coteDivore'), value: 'coteDivore' },
        { label: i18n.t('denmark'), value: 'denmark' },
        { label: i18n.t('djibouti'), value: 'djibouti' },
        { label: i18n.t('dominica'), value: 'dominica' },
        { label: i18n.t('dominicanRepublic'), value: 'dominicanRepublic' },
        { label: i18n.t('drCongo'), value: 'drCongo' },
        { label: i18n.t('ecuador'), value: 'ecuador' },
        { label: i18n.t('egypt'), value: 'egypt' },
        { label: i18n.t('elSalvador'), value: 'elSalvador' },
        { label: i18n.t('equatorialGuinea'), value: 'equatorialGuinea' },
        { label: i18n.t('eritrea'), value: 'eritrea' },
        { label: i18n.t('estonia'), value: 'estonia' },
        { label: i18n.t('eswatini'), value: 'eswatini' },
        { label: i18n.t('ethiopia'), value: 'ethiopia' },
        { label: i18n.t('fiji'), value: 'fiji' },
        { label: i18n.t('finland'), value: 'finland' },
        { label: i18n.t('france'), value: 'france' },
        { label: i18n.t('gabon'), value: 'gabon' },
        { label: i18n.t('gambia'), value: 'gambia' },
        { label: i18n.t('georgia'), value: 'georgia' },
        { label: i18n.t('germany'), value: 'germany' },
        { label: i18n.t('ghana'), value: 'ghana' },
        { label: i18n.t('greece'), value: 'greece' },
        { label: i18n.t('grenada'), value: 'grenada' },
        { label: i18n.t('guatemala'), value: 'guatemala' },
        { label: i18n.t('guinea'), value: 'guinea' },
        { label: i18n.t('guineaBissau'), value: 'guineaBissau' },
        { label: i18n.t('guyana'), value: 'guyana' },
        { label: i18n.t('haiti'), value: 'haiti' },
        { label: i18n.t('holySee'), value: 'holySee' },
        { label: i18n.t('honduras'), value: 'honduras' },
        { label: i18n.t('hungary'), value: 'hungary' },
        { label: i18n.t('iceland'), value: 'iceland' },
        { label: i18n.t('india'), value: 'india' },
        { label: i18n.t('indonesia'), value: 'indonesia' },
        { label: i18n.t('iran'), value: 'iran' },
        { label: i18n.t('iraq'), value: 'iraq' },
        { label: i18n.t('ireland'), value: 'ireland' },
        { label: i18n.t('israel'), value: 'israel' },
        { label: i18n.t('italy'), value: 'italy' },
        { label: i18n.t('jamaica'), value: 'jamaica' },
        { label: i18n.t('japan'), value: 'japan' },
        { label: i18n.t('jordan'), value: 'jordan' },
        { label: i18n.t('kazakhstan'), value: 'kazakhstan' },
        { label: i18n.t('kenya'), value: 'kenya' },
        { label: i18n.t('kiribati'), value: 'kiribati' },
        { label: i18n.t('kuwait'), value: 'kuwait' },
        { label: i18n.t('kyrgyzstan'), value: 'kyrgyzstan' },
        { label: i18n.t('laos'), value: 'laos' },
        { label: i18n.t('latvia'), value: 'latvia' },
        { label: i18n.t('lebanon'), value: 'lebanon' },
        { label: i18n.t('lesotho'), value: 'lesotho' },
        { label: i18n.t('liberia'), value: 'liberia' },
        { label: i18n.t('libya'), value: 'libya' },
        { label: i18n.t('liechtenstein'), value: 'liechtenstein' },
        { label: i18n.t('lithuania'), value: 'lithuania' },
        { label: i18n.t('luxembourg'), value: 'luxembourg' },
        { label: i18n.t('madagascar'), value: 'madagascar' },
        { label: i18n.t('malawi'), value: 'malawi' },
        { label: i18n.t('malaysia'), value: 'malaysia' },
        { label: i18n.t('maldives'), value: 'maldives' },
        { label: i18n.t('mali'), value: 'mali' },
        { label: i18n.t('malta'), value: 'malta' },
        { label: i18n.t('marshallIslands'), value: 'marshallIslands' },
        { label: i18n.t('mauritania'), value: 'mauritania' },
        { label: i18n.t('mauritius'), value: 'mauritius' },
        { label: i18n.t('mexico'), value: 'mexico' },
        { label: i18n.t('micronesia'), value: 'micronesia' },
        { label: i18n.t('moldova'), value: 'moldova' },
        { label: i18n.t('monaco'), value: 'monaco' },
        { label: i18n.t('mongolia'), value: 'mongolia' },
        { label: i18n.t('montenegro'), value: 'montenegro' },
        { label: i18n.t('morocco'), value: 'morocco' },
        { label: i18n.t('mozambique'), value: 'mozambique' },
        { label: i18n.t('myanmar'), value: 'myanmar' },
        { label: i18n.t('namibia'), value: 'namibia' },
        { label: i18n.t('nauru'), value: 'nauru' },
        { label: i18n.t('nepal'), value: 'nepal' },
        { label: i18n.t('netherlands'), value: 'netherlands' },
        { label: i18n.t('newZealand'), value: 'newZealand' },
        { label: i18n.t('nicaragua'), value: 'nicaragua' },
        { label: i18n.t('niger'), value: 'niger' },
        { label: i18n.t('nigeria'), value: 'nigeria' },
        { label: i18n.t('northKorea'), value: 'northKorea' },
        { label: i18n.t('northMacedonia'), value: 'northMacedonia' },
        { label: i18n.t('norway'), value: 'norway' },
        { label: i18n.t('oman'), value: 'oman' },
        { label: i18n.t('pakistan'), value: 'pakistan' },
        { label: i18n.t('palau'), value: 'palau' },
        { label: i18n.t('panama'), value: 'panama' },
        { label: i18n.t('papuaNewGuinea'), value: 'papuaNewGuinea' },
        { label: i18n.t('paraguay'), value: 'paraguay' },
        { label: i18n.t('peru'), value: 'peru' },
        { label: i18n.t('philippines'), value: 'philippines' },
        { label: i18n.t('poland'), value: 'poland' },
        { label: i18n.t('portugal'), value: 'portugal' },
        { label: i18n.t('qatar'), value: 'qatar' },
        { label: i18n.t('romania'), value: 'romania' },
        { label: i18n.t('russia'), value: 'russia' },
        { label: i18n.t('rwanda'), value: 'rwanda' },
        { label: i18n.t('saintKittsNevis'), value: 'saintKittsNevis' },
        { label: i18n.t('saintLucia'), value: 'saintLucia' },
        { label: i18n.t('samoa'), value: 'samoa' },
        { label: i18n.t('sanMarino'), value: 'sanMarino' },
        { label: i18n.t('saoTomePrincipe'), value: 'saoTomePrincipe' },
        { label: i18n.t('saudiArabia'), value: 'saudiArabia' },
        { label: i18n.t('senegal'), value: 'senegal' },
        { label: i18n.t('serbia'), value: 'serbia' },
        { label: i18n.t('seychelles'), value: 'seychelles' },
        { label: i18n.t('sierraLeone'), value: 'sierraLeone' },
        { label: i18n.t('singapore'), value: 'singapore' },
        { label: i18n.t('slovakia'), value: 'slovakia' },
        { label: i18n.t('slovenia'), value: 'slovenia' },
        { label: i18n.t('solomonIslands'), value: 'solomonIslands' },
        { label: i18n.t('somalia'), value: 'somalia' },
        { label: i18n.t('southAfrica'), value: 'southAfrica' },
        { label: i18n.t('southKorea'), value: 'southKorea' },
        { label: i18n.t('southSudan'), value: 'southSudan' },
        { label: i18n.t('spain'), value: 'spain' },
        { label: i18n.t('sriLanka'), value: 'sriLanka' },
        { label: i18n.t('stVincentGrenadines'), value: 'stVincentGrenadines' },
        { label: i18n.t('stateOfPalestine'), value: 'stateOfPalestine' },
        { label: i18n.t('sudan'), value: 'sudan' },
        { label: i18n.t('suriname'), value: 'suriname' },
        { label: i18n.t('sweden'), value: 'sweden' },
        { label: i18n.t('switzerland'), value: 'switzerland' },
        { label: i18n.t('syria'), value: 'syria' },
        { label: i18n.t('tajikistan'), value: 'tajikistan' },
        { label: i18n.t('tanzania'), value: 'tanzania' },
        { label: i18n.t('thailand'), value: 'thailand' },
        { label: i18n.t('timorLeste'), value: 'timorLeste' },
        { label: i18n.t('togo'), value: 'togo' },
        { label: i18n.t('tonga'), value: 'tonga' },
        { label: i18n.t('trinidadAndTobago'), value: 'trinidadAndTobago' },
        { label: i18n.t('tunisia'), value: 'tunisia' },
        { label: i18n.t('turkey'), value: 'turkey' },
        { label: i18n.t('turkmenistan'), value: 'turkmenistan' },
        { label: i18n.t('tuvalu'), value: 'tuvalu' },
        { label: i18n.t('uganda'), value: 'uganda' },
        { label: i18n.t('ukraine'), value: 'ukraine' },
        { label: i18n.t('unitedArabEmirates'), value: 'unitedArabEmirates' },
        { label: i18n.t('unitedKingdom'), value: 'unitedKingdom' },
        { label: i18n.t('unitedStates'), value: 'unitedStates' },
        { label: i18n.t('uruguay'), value: 'uruguay' },
        { label: i18n.t('uzbekistan'), value: 'uzbekistan' },
        { label: i18n.t('vanuatu'), value: 'vanuatu' },
        { label: i18n.t('venezuela'), value: 'venezuela' },
        { label: i18n.t('vietnam'), value: 'vietnam' },
        { label: i18n.t('yemen'), value: 'yemen' },
        { label: i18n.t('zambia'), value: 'zambia' },
        { label: i18n.t('zimbabwe'), value: 'zimbabwe' }
    ]
} 