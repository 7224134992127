import { RouteProps } from '../../routes/AppRouter'
import { Show as ShowRequest } from '../../features/application/Show'
import { Title } from 'components/common/Title'

export function Show(props: RouteProps) {
    return (
        <>
            <Title color={"black"} title={props.title || ''} />
            <ShowRequest id={props.id || ''} />
        </>
    )
}
