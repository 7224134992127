import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import {Patient, PatientQuery} from '../models/Patient'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import {PatientDTO} from '../models/PatientDTO'
import { IPatientApi } from '../api/PatientApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { PatientMicroVE } from '../models/patientForm/PatientMicroVE'
import { PatientPGT } from '../models/patientForm/PatientPGT'
import { PatientNGS } from '../models/patientForm/PatientNGS'
import { PatientOncology } from '../models/patientForm/PatientOncology'
import { PatientCitogeneticaArray } from '../models/patientForm/PatientCitogeneticaArray'

type Props = {
  apiKey: symbol
}

export interface IPatientService extends IInit {
  getByID(id: string): Observable<Patient | undefined>

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>>

  getPatients(q: Query<PatientQuery>): Observable<ItemList<Patient>>

  add(e: Patient): Observable<Patient | undefined>

  update(e: PatientDTO): Observable<Patient | undefined>

  delete(id: string): Observable<boolean>

  getFilteredListMicroVE(q: Query<PatientQuery>): Observable<ItemList<PatientMicroVE>>

  getFilteredListPGT(q: Query<PatientQuery>): Observable<ItemList<PatientPGT>>

  getFilteredListNGS(q: Query<PatientQuery>): Observable<ItemList<PatientNGS>>

  getFilteredListOncology(q: Query<PatientQuery>): Observable<ItemList<PatientOncology>>

  getFilteredListCitogenicArray(q: Query<PatientQuery>): Observable<ItemList<PatientCitogeneticaArray>>

  getPatientRequest(q: Query<PatientQuery>): Observable<any[]>

  getRequestsByPatientID(q: Query<PatientQuery>): Observable<any[]>
}

export class PatientService implements IPatientService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IPatientApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IPatientApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: PatientDTO): Observable<Patient | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Patient | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>> {
    return this._api.getFilteredList(q)
  }

  getPatients(q: Query<PatientQuery>): Observable<ItemList<Patient>> {
    return this._api.getPatients(q)
  }

  getFilteredListMicroVE(q: Query<PatientQuery>): Observable<ItemList<PatientMicroVE>> {
    return this._api.getFilteredListMicroVE(q)
  }

  getFilteredListNGS(q: Query<PatientQuery>): Observable<ItemList<PatientNGS>> {
    return this._api.getFilteredListNGS(q)
  }

  getFilteredListPGT(q: Query<PatientQuery>): Observable<ItemList<PatientPGT>> {
    return this._api.getFilteredListPGT(q)
  }

  getFilteredListOncology(q: Query<PatientQuery>): Observable<ItemList<PatientOncology>> {
    return this._api.getFilteredListOncology(q)
  }

  getFilteredListCitogenicArray(q: Query<PatientQuery>): Observable<ItemList<PatientCitogeneticaArray>> {
    return this._api.getFilteredListCitogenicArray(q)
  }

  getPatientRequest(q: Query<PatientQuery>): Observable<any[]> {
    return this._api.getPatientRequest(q)
  }

  getRequestsByPatientID(q: Query<PatientQuery>): Observable<Request[]> {
    return this._api.getRequestsByPatientID(q)
  }

  update(e: PatientDTO): Observable<Patient | undefined> {
    return this._api.update(e)
  }
}
