import { Container } from '../../common/container/Container'

export type AddressContainerConfig = {
  moduleFullUrl: string
}

export type AddressProps = {
  parentContainer: Container
  config: AddressContainerConfig
}

export const ADDRESS_API_KEY = Symbol('ADDRESS_API_KEY')
export const ADDRESS_SERVICE_KEY = Symbol('ADDRESS_SERVICE_KEY')
export const ADDRESS_MODULE = Symbol('ADDRESS_MODULE')
