import { Container } from '../../common/container/Container'

export type GenesContainerConfig = {
  moduleFullUrl: string
}

export type GenesProps = {
  parentContainer: Container
  config: GenesContainerConfig
}

export const GENES_API_KEY = Symbol('GENES_API_KEY')
export const GENES_SERVICE_KEY = Symbol('GENES_SERVICE_KEY')
export const GENES_MODULE = Symbol('GENES_MODULE')
