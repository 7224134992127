import React, { useEffect, useState } from 'react'
import { Button, Checkbox, FormGroup, Grid, TextField, Typography } from '@material-ui/core'
import { useStyles } from './Application.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import { Product } from '../../modules/product/models/Product'

type ProductProps = {
  data: Product[]
  handleModalClose: () => void
  selectedProduct: Product[]
  setSelectedProduct: (p: Product[]) => void
}

type ProductChecked = {
  product: Product
  checked: boolean
  index: number
}
export const ModalProducts = (props: ProductProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t, i18n } = useTranslation()
  const [searcher, setSearcher] = useState<string>('')
  const [selectedHere, setSelectedHere] = useState<Product[]>(props.selectedProduct)

  const getProductChecked = () => {
    let productCheckedArr: ProductChecked[] = []
    if (props.selectedProduct.length === 0) {
      props.data.map((p, i) => {
        productCheckedArr.push({ index: i, checked: false, product: p })
      })
    } else {
      props.data.map((p, i) => {
        const selected = props.selectedProduct.some((sp) => sp.id === p.id)
        productCheckedArr.push({ index: i, checked: selected, product: p })
      })
    }
    return productCheckedArr
  }

  const [productChecked, setProductChecked] = useState<ProductChecked[]>(getProductChecked())
  const [filteredProducts, setFilteredProducts] = useState<ProductChecked[]>(productChecked)
  const [AllFilteredProducts, setAllFilteredProducts] = useState<ProductChecked[]>(
    getProductChecked()
  )

  const handleSave = () => {
    props.setSelectedProduct && props.setSelectedProduct(selectedHere)
    props.handleModalClose()
  }

  const getNameByLanguage = (p: Product) => {
    if (i18n.language == 'es') {
      return p.nameES
    }
    return p.nameEN
  }

  const handleChangeCheck = (product: ProductChecked) => {
    let aux = [...selectedHere]
    let index = aux.map((p) => p.id).indexOf(product.product.id)

    if (index != -1) {
      aux.splice(index, 1)
      setSelectedHere(aux)
    } else {
      //se añade
      aux.push(product.product)
      setSelectedHere(aux)
    }

    let checkedBefore = productChecked.filter((p) => p.checked)

    let ch = [...productChecked, ...filteredProducts]
    let indexOf = ch.map((c) => c.product.id).indexOf(product.product.id)

    if (indexOf != -1) {
      ch[indexOf].checked = !ch[indexOf].checked
    }

    setProductChecked(ch)

    let checkedAfter = ch.filter((p) => p.checked)

    if (checkedBefore.length == 0 && checkedAfter.length > 0) {
      let type = product.product.typeForm
      let filtered = props.data.filter((d) => d.typeForm == type)
      let productCheckedArr: ProductChecked[] = []

      filtered.map((p, i) => {
        productCheckedArr.push({ index: i, checked: false, product: p })
      })

      if (searcher != '') {
        setFilteredProducts(
          productCheckedArr.filter((t) =>
            getNameByLanguage(t.product).toLowerCase().includes(searcher.toLowerCase())
          )
        )
      } else {
        setFilteredProducts(productCheckedArr)
      }
      setAllFilteredProducts(productCheckedArr)
    } else if (checkedBefore.length > 0 && checkedAfter.length == 0) {
      let productCheckedArr: ProductChecked[] = []

      if (searcher != '') {
        const filtered2 = props.data.filter((p) =>
          getNameByLanguage(p).toLowerCase().includes(searcher.toLowerCase())
        )
        setFilteredProducts(
          getProductChecked().filter((pc) => filtered2.some((p) => p.id === pc.product.id))
        )
      } else {
        props.data.map((p, i) => {
          productCheckedArr.push({ index: i, checked: false, product: p })
        })
        if (searcher != '') {
          let s: string = searcher
          setFilteredProducts(
            productCheckedArr.filter((t) =>
              getNameByLanguage(t.product).toLowerCase().includes(s.toLowerCase())
            )
          )
        } else {
          setFilteredProducts(productCheckedArr)
        }
        setAllFilteredProducts(productCheckedArr)
      }
    }
  }

  const filterProducts = (searchText: string) => {
    let p =
      productChecked.filter((p) => p.checked).length == 0
        ? props.data
        : AllFilteredProducts.map((a) => a.product)

    const filtered2 = p.filter(
      (p) =>
        getNameByLanguage(p).toLowerCase().includes(searchText.toLowerCase()) ||
        p.reference.includes(searchText)
    )
    setFilteredProducts(
      getProductChecked().filter((pc) => filtered2.some((p) => p.id === pc.product.id))
    )
  }

  const handleSearch = (e: any) => {
    const searchText = e.target.value
    setSearcher(searchText)
    filterProducts(searchText)
  }

  useEffect(() => {
    setProductChecked(getProductChecked())
    filterProducts(searcher)
  }, [])

  const isChecked = (pr: Product) => {
    return selectedHere.map((p) => p.id).includes(pr.id)
  }

  return (
    <>
      <Grid className={classes.modalProduct} container>
        <Grid item xs={12} className={classes.modalBackground}>
          <Typography
            variant={'h4'}
            align="left"
            className={classes.titleModal}
            style={{ marginLeft: '2rem' }}>
            {t('productSelection')}
          </Typography>
          <div style={{ marginLeft: '2rem', fontWeight: 'bold' }}>{t('selectYourProduct')}</div>
        </Grid>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            flexGrow: 1,
            marginTop: '-6rem',
            marginRight: '30px',
          }}>
          <TextField
            id="outlined"
            label={t('search')}
            variant="outlined"
            value={searcher}
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <Grid
          item
          xs={12}
          style={{
            margin: '2rem 2rem 2rem 2rem',
            overflowY: 'scroll',
            height: '50vh',
            overflowX: 'hidden',
          }}>
          <FormGroup style={{}}>
            {filteredProducts.map((element) => {
              return (
                <Grid item xs={12} key={element.product.id}>
                  <div className={classes.row2}>
                    <Checkbox
                      checked={isChecked(element.product)}
                      onChange={() => handleChangeCheck(element)}
                      style={{ paddingTop: '1px' }}
                    />
                    <div className={classes.titleProductName}>
                      {(i18n.language == 'es' ? element.product.nameES : element.product.nameEN)}
                    </div>
                  </div>
                  <div
                    style={{
                      borderBottom: 'solid',
                      borderWidth: '1px',
                      borderColor: 'grey',
                      marginBottom: '20px',
                      paddingBottom: '20px',
                    }}>
                    <div>
                      <div className={classes.column}>
                        <Grid container>
                          <Grid item xs={7}>
                            <div className={classes.row2}>
                              <div className={classes.productType}>{t('typeProduct')}</div>
                              <div>{element.product.type}</div>
                            </div>
                          </Grid>
                          <Grid item xs={7}>
                            <div className={classes.row2}>
                              <div className={classes.productType}>{t('reference')}</div>
                              <div>{element.product.reference}</div>
                            </div>
                          </Grid>
                          {element.product.genes != '' && (
                            <Grid item xs={7}>
                              <div className={classes.row2}>
                                <div className={classes.productType}>{t('genes')}</div>
                                <div>{element.product.genes}</div>
                              </div>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </div>
                </Grid>
              )
            })}
          </FormGroup>
        </Grid>
        <Grid item xs={12} className={classes.buttonsModal}>
          <Button type={'button'} onClick={handleSave} className={classes.button}>
            OK
          </Button>
          <Button
            type={'button'}
            onClick={props.handleModalClose}
            className={classes.buttonSecondary}>
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
