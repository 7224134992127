import { v4 as uuidv4 } from 'uuid'
import { SampleType } from './SampleType'

export interface SampleTypeDTO {
  id: string | undefined
  nameES: string
  code: string
  idOdoo: string
  nameEN: string
}

export function emptySampleTypeDTO(): SampleTypeDTO {
  return {
    id: uuidv4(),
    nameES: '',
    nameEN: '',
    code: '',
    idOdoo: ''
  }
}

export function fromModel(c: SampleType): SampleTypeDTO {
  return {
    id: c.id,
    nameES: c.nameES,
    nameEN: c.nameEN,
    code: c.code,
    idOdoo: c.idOdoo
  }
}

export function toModel(c: SampleTypeDTO): SampleType {
  return new SampleType(c)
}
