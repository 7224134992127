import { Container } from '../../common/container/Container'

export type FaqContainerConfig = {
  moduleFullUrl: string
}

export type FaqProps = {
  parentContainer: Container
  config: FaqContainerConfig
}

export const FAQ_API_KEY = Symbol('FAQ_API_KEY')
export const FAQ_SERVICE_KEY = Symbol('FAQ_SERVICE_KEY')
export const FAQ_MODULE = Symbol('FAQ_MODULE')
