import {Request} from "../../request/models/Request";
import {Patient} from "../../patient/models/Patient";
import {Sample} from "../../sample/models/Sample"
import {Address} from "../../address/models/Address"
import {Product} from "../../product/models/Product"

type Product2GenesObject = {
    product: string,
    genes:   string[],
}

type Product2PatientObject = {
    product: string,
    patient: Patient[] ,
}

type Patient2SampleObject = {
    patient: string,
    sample:  Sample[],
}

export type WizardProps = {
    request: Request,
    file:    File[],
    genes:   Product2GenesObject[] ,
    newGenes:   Product2GenesObject[] ,
    patient: Product2PatientObject[] ,
    sample:  Patient2SampleObject[],
    address: Address  ,
    product: Product[],
}


export class Wizard {
    private _request: Request
    private _file:    File[]
    private _genes:   Product2GenesObject[]
    private _newGenes:   Product2GenesObject[]
    private _patient: Product2PatientObject[]
    private _sample:  Patient2SampleObject[]
    private _address: Address
    private _product: Product[]

    constructor(p: WizardProps) {
        this._request = p.request
        this._file = p.file
        this._genes = p.genes
        this._newGenes = p.newGenes
        this._patient = p.patient
        this._sample = p.sample
        this._address = p.address
        this._product = p.product
    }

    get request(): Request {
        return this._request;
    }

    set request(value: Request) {
        this._request = value;
    }

    get file(): File[] {
        return this._file;
    }

    set file(value: File[]) {
        this._file = value;
    }

    get genes(): Product2GenesObject[] {
        return this._genes;
    }

    set genes(value: Product2GenesObject[]) {
        this._genes = value;
    }

    get newGenes(): Product2GenesObject[] {
        return this._newGenes;
    }

    set newGenes(value: Product2GenesObject[]) {
        this._newGenes = value;
    }

    get patient(): Product2PatientObject[] {
        return this._patient;
    }

    set patient(value: Product2PatientObject[]) {
        this._patient = value;
    }

    get sample(): Patient2SampleObject[] {
        return this._sample;
    }

    set sample(value: Patient2SampleObject[]) {
        this._sample = value;
    }

    get address(): Address {
        return this._address;
    }

    set address(value: Address) {
        this._address = value;
    }
    get product(): Product[] {
        return this._product;
    }

    set product(value: Product[]) {
        this._product = value;
    }
}

