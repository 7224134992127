export type RequestProps = {
  id: string
  date: Date
  status: string
  idOdoo: string
  userID: string
  step: number;
  newID: string;
  language: string;
}

export class Request {
  private readonly _id: string
  private _date: Date
  private _status: string
  private _idOdoo: string
  private _userID: string
  private _step: number;
  private _newID: string;
  private _language: string;

  constructor(p: RequestProps) {
    this._id = p.id
    this._date = p.date
    this._status = p.status
    this._idOdoo = p.idOdoo
    this._userID = p.userID
    this._step = p.step
    this._newID = p.newID
    this._language = p.language
  }

  get id(): string {
    return this._id
  }

  get newID(): string {
    return this._newID
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get step(): number {
    return this._step
  }

  set step(value: number) {
    this._step = value
  }

  get status(): string {
    return this._status
  }

  set status(value: string) {
    this._status = value
  }

  get idOdoo(): string {
    return this._idOdoo
  }

  set idOdoo(value: string) {
    this._idOdoo = value
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }

  get language(): string {
    return this._language
  }

  set language(value: string) {
    this._language = value
  }
}

export interface RequestQuery {
  id: string
  ids: string[]
  date: Date
  status: string
  idOdoo: string
  languaga: string
  all: string
}

