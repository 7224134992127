export type FeedbackProps = {
    id: string | undefined
    requestCodeSample: string
    nEmbTransfer: number,
    idEmbTransfer: string,
    fullTerm: boolean
    userID: string
}

export class FeedbackData {
    private readonly _id: string | undefined
    private _requestCodeSample: string
    private _nEmbTransfer: number
    private _idEmbTransfer: string
    private _fullTerm: boolean
    private _userID: string

    constructor(p: FeedbackProps) {
        this._id = p.id
        this._requestCodeSample = p.requestCodeSample
        this._nEmbTransfer = p.nEmbTransfer
        this._idEmbTransfer = p.idEmbTransfer
        this._fullTerm = p.fullTerm
        this._userID = p.userID
    }

    get id(): string | undefined {
        return this._id
    }


    get userID(): string {
        return this._userID
    }

    set userID(value: string) {
        this._userID = value
    }

    get requestCodeSample(): string {
        return this._requestCodeSample
    }

    set requestCodeSample(value: string) {
        this._requestCodeSample = value
    }

    get nEmbTransfer(): number {
        return this._nEmbTransfer
    }

    set nEmbTransfer(value: number) {
        this._nEmbTransfer = value
    }

    get idEmbTransfer(): string {
        return this._idEmbTransfer
    }

    set idEmbTransfer(value: string) {
        this._idEmbTransfer = value
    }

    get fullTerm(): boolean {
        return this._fullTerm
    }

    set fullTerm(value: boolean) {
        this._fullTerm = value
    }
}

export interface FeedbackDataQuery {
    id: string
}


