import { Container, ContainerItem } from '../common/container/Container'
import { ProductProps } from '../modules/product/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { PRODUCT_API_KEY, PRODUCT_SERVICE_KEY, PRODUCT_MODULE } from '../modules/product'
import { ProductService } from '../modules/product/services/ProductService'
import { ProductApi } from '../modules/product/api/ProductApi'

let container: Container

function init(p: ProductProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(PRODUCT_API_KEY, new ContainerItem({ instance: new ProductApi() }))
  items.set(
    PRODUCT_SERVICE_KEY,
    new ContainerItem({
      instance: new ProductService({ apiKey: PRODUCT_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getProductContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[PRODUCT_MODULE],
      },
    })
  }

  return container
}
