import { USERS_MODULE } from '../modules/users'
import { CLINICS_MODULE } from '../modules/clinics'
import { AUTHS_MODULE } from '../modules/auth'
import { TESTS_MODULE } from '../modules/tests'
import { TEST_TYPES_MODULE } from '../modules/test-types'
import { GENE_TYPES_MODULE } from '../modules/gene-types'
import { CSVS_MODULE } from '../modules/csvs'
import { TRANSLATIONS_MODULE } from '../modules/translations'
import { FILES_MODULE } from '../modules/files'
import { ADDRESS_MODULE } from "../modules/address"
import { CLIENTS_MODULE } from "../modules/clients"
import { PRODUCT_MODULE } from "../modules/product"
import { REQUEST_MODULE } from "../modules/request"
import { SAMPLE_MODULE } from "../modules/sample"
import { FAQ_MODULE } from "../modules/faq"
import { CLIENT2REQUEST_MODULE } from "../modules/client2Requests"
import { GENES_MODULE } from "../modules/genes"
import { PATIENT_MODULE } from "../modules/patient"
import { SAMPLETYPE_MODULE } from "../modules/sampleType"
import { STATISTICS_MODULE } from "../modules/statistics"
import { FEEDBACKDATA_MODULE } from "../modules/feedbackData"

const apiURL = process.env.REACT_APP_BACKEND_URL

export const moduleURLs = {
  [AUTHS_MODULE]: `${apiURL}`,
  [USERS_MODULE]: `${apiURL}/users`,
  [CLINICS_MODULE]: `${apiURL}/clinics`,
  [TESTS_MODULE]: `${apiURL}/tests`,
  [TEST_TYPES_MODULE]: `${apiURL}/test-types`,
  [GENE_TYPES_MODULE]: `${apiURL}/gene-types`,
  [CSVS_MODULE]: `${apiURL}/csvs`,
  [TRANSLATIONS_MODULE]: `${apiURL}/translations`,
  [FILES_MODULE]: `${apiURL}/files`,
  [ADDRESS_MODULE]: `${apiURL}/address`,
  [CLIENTS_MODULE]: `${apiURL}/clients`,
  [PRODUCT_MODULE]: `${apiURL}/products`,
  [REQUEST_MODULE]: `${apiURL}/requests`,
  [SAMPLE_MODULE]: `${apiURL}/samples`,
  [FAQ_MODULE]: `${apiURL}/faqs`,
  [CLIENT2REQUEST_MODULE]: `${apiURL}/client2request`,
  [GENES_MODULE]: `${apiURL}/genes`,
  [PATIENT_MODULE]: `${apiURL}/patients`,
  [SAMPLETYPE_MODULE]: `${apiURL}/sampleType`,
  [STATISTICS_MODULE]: `${apiURL}/statistics`,
  [FEEDBACKDATA_MODULE]: `${apiURL}/feedbackData`
}
