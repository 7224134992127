import { RouteProps } from '../../routes/AppRouter'
import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select as MUSelect,
  TextField,
  Typography,
} from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid'
import { useForm } from '../../common/utils/form-generation/useForm'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { URL_CLIENTS } from '../../routes/routes-constants'
import { useEffect, useState } from 'react'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { useStyles } from './Client.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { getClientContainer } from '../../container/client-modules'
import { ClientService } from '../../modules/clients/services/ClientService'
import { CLIENTS_SERVICE_KEY } from '../../modules/clients'
import { Client, ClientOdoo } from '../../modules/clients/models/Client'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { ChangePasswordDTO } from '../../modules/auth/models/ChangePassword'
import { Lock } from '@material-ui/icons'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { AuthService } from '../../modules/auth/services/AuthService'
import { getAuthContainer } from '../../container/auth-modules'
import { clinicUserRoles, roleTypes } from '../../modules/users/enums/RoleType'
import { Permission } from '../../common/enums/Permissions'
import { useSnackbar } from 'notistack'
import Autocomplete from '@mui/material/Autocomplete'
import { getCountries } from './getCountires'
import AddBoxIcon from '@mui/icons-material/AddBox'
import CancelIcon from '@mui/icons-material/Cancel'
import { getUserContainer } from 'container/user-modules'
import { UserService } from 'modules/users/services/UserService'
import { USER_SERVICE_KEY } from 'modules/users'
import { emptyRegisterUser, UserRegister } from 'modules/users/models/User'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Query } from 'common/api/Query'
import { ClientDTO, emptyClientDTO } from 'modules/clients/models/ClientDTO'
import Select from 'react-select'

const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export type ClientFormProps = RouteProps & {
  client: Client | undefined
}

export const Form = (props: ClientFormProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { enqueueSnackbar } = useSnackbar()
  let countries = getCountries()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const roles = authService.get().permissions.find((p) => p === Permission.editAllUsers)
    ? roleTypes()
    : clinicUserRoles()
  const [passwordModal, setPasswordModal] = useState<boolean>(false)
  const [usersBefore, setUsersBefore] = useState<UserRegister[]>([])
  const [users, setUsers] = useState<UserRegister[]>([])
  const [otherClientSelected, setOtherClientSelected] = useState<boolean>(false)
  const [clients, setClients] = useState<Client[]>([])
  const [isLoadingClients, setIsLoadingClients] = useState<boolean>(true)
  const [clientsOdoo, setClientsOdoo] = useState<ClientOdoo[]>([])

  const useClass = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 0),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      plusIcon: {
        color: '#00b8e2',
        alignSelf: 'flex-end',
        cursor: 'pointer',
      },
      cancelIcon: {
        color: '#dbb4b4',
        alignSelf: 'flex-end',
        cursor: 'pointer',
      },
      loadingSpinner: {
        position: 'absolute',
        right: '40%',
        overflow: ' hidden !important',
        bottom: '30%',
        color: '#0077ff !important',
      },
    })
  )

  const formClass = useClass()

  useEffect(() => {
    clientService.getClinicsOdoo().subscribe((res) => {
      console.log(res.items)
      setClientsOdoo(res.items)
      setIsLoadingClients(false)
    })
  }, [])

  useEffect(() => {
    if (props.client && props.client.id) {
      clientService.getClinicAndUsers(props.client.id).subscribe((res) => {
        if (res) {
          if (res.clinic?.referencedClientID != null) {
            setOtherClientSelected(true)
          }
          setData(res?.clinic)
          res?.clinicUsers ? setUsers([...res?.clinicUsers]) : setUsers([])
          let uBefore = JSON.parse(JSON.stringify(res?.clinicUsers))
          uBefore
            ? setUsersBefore(JSON.parse(JSON.stringify(res?.clinicUsers)))
            : setUsersBefore([])
          setIsLoading(false)
        }
      })
    } else {
      setData(emptyClientDTO())
      setIsLoading(false)
    }
  }, [props.client])

  useEffect(() => {
    let filterID = ''
    if (props.client && props.client.id) {
      filterID = props.client.id
    }

    clientService.getFilteredList(new Query({})).subscribe((res) => {
      if (filterID) {
        setClients(res.items.filter((c) => c.id != filterID))
      } else {
        setClients(res.items)
      }
    })
  }, [props.client])

  const areEquals = (oldUser: UserRegister, newUser: UserRegister) => {
    if (
      oldUser.firstName != newUser.firstName ||
      oldUser.lastName != newUser.lastName ||
      oldUser.email != newUser.email ||
      oldUser.role != newUser.role
    ) {
      return false
    }

    if (oldUser.emails?.length != newUser.emails?.length) {
      return false
    }

    let allEquals = true
    oldUser.emails?.forEach((email, i) => {
      if (email != newUser.emails[i]) {
        allEquals = false
      }
    })

    if (!allEquals) {
      return false
    }

    return true
  }

  function compareArrays(arrayOld: UserRegister[], arrayUpdated: UserRegister[]) {
    const itemsToAdd = arrayUpdated.filter(
      (updatedItem) => !arrayOld.some((oldItem) => oldItem.id == updatedItem.id)
    )
    const itemsToDelete = arrayOld.filter(
      (oldItem) => !arrayUpdated.some((updatedItem) => oldItem.id == updatedItem.id)
    )

    return { itemsToAdd, itemsToDelete }
  }

  const { handleChange, handleSubmit, data, setData, errors } = useForm<ClientDTO>({
    validations: {
      taxIdentity: {
        required: {
          value: true,
          message: t('taxIdentityNotValidError'),
        },
      },
      taxName: {
        required: {
          value: true,
          message: t('taxNameNotValidError'),
        },
      },
      street: {
        required: {
          value: true,
          message: t('streetNotValidError'),
        },
      },
      number: {
        required: {
          value: true,
          message: t('numberNotValidError'),
        },
      },
      zipCode: {
        required: {
          value: true,
          message: t('zipCodeNotValidError'),
        },
      },
      city: {
        required: {
          value: true,
          message: t('cityNotValidError'),
        },
      },
      province: {
        required: {
          value: true,
          message: t('provinceNotValidError'),
        },
      },
      country: {
        required: {
          value: true,
          message: t('countryNotValidError'),
        },
      },
    },
    onSubmit: () => {
      if (!props.client?.id) {
        if (users.length == 0) {
          enqueueSnackbar(t('mustExistsOneUser'), { variant: 'error' })
          return
        }

        let isError = false
        users.forEach((user, index) => {
          if (!user.firstName || !user.lastName || !user.email || !user.role) {
            enqueueSnackbar(
              t('theUser') + ' ' + Number(index + 1) + ' ' + t('dontHaveAllTheFields'),
              { variant: 'error' }
            )
            isError = true
          }

          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{1,}$/
          if (!emailRegex.test(user.email)) {
            enqueueSnackbar(t('theEmail') + ' ' + user.email + ' ' + t('isnotvalid'), {
              variant: 'error',
            })
            isError = true
          }
        })

        if (isError) {
          return
        }

        data.id = uuidv4()
        setIsLoading(true)
        clientService.add(data).subscribe((res) => {
          users.forEach((u) => {
            u.clinicID = data.id || ''
            u.role = Number(u.role)
          })

          if (res == undefined) {
            enqueueSnackbar(t('errorRegistering'), { variant: 'error' })
            setIsLoading(false)
            goToClients()
            return
          }

          userService.addClinicUsers(users).subscribe((res2) => {
            if (res2 === undefined) {
              enqueueSnackbar(t('errorRegisteringUsers'), { variant: 'error' })
            }
            setIsLoading(false)
            goToClients()
          })
        })
        return
      }

      if (users.length == 0) {
        enqueueSnackbar(t('mustExistsOneUser'), { variant: 'error' })
        return
      }

      setIsLoading(true)
      clientService.update(data).subscribe(async (res) => {
        if (res == undefined) {
          enqueueSnackbar(t('errorRegistering'), { variant: 'error' })
          setIsLoading(false)
          goToClients()
          return
        }

        const comparisonResult = compareArrays(usersBefore, users)

        //Remove
        const deletePromises = comparisonResult.itemsToDelete.map(async (user) => {
          return userService.delete(user.id).toPromise()
        })

        let result1 = await Promise.all(deletePromises)

        //Add
        const addPromises = comparisonResult.itemsToAdd.map(async (user) => {
          user.clinicID = data.id || ''
          user.role = Number(user.role)
          return userService.addClinicUser(user).toPromise()
        })

        let result2 = await Promise.all(addPromises)

        //Update
        const updatePromises = users.map(async (u) => {
          const usBefore = usersBefore.filter((userBefore) => userBefore.id == u.id)
          if (usBefore.length > 0 && !areEquals(usBefore[0], u)) {
            return userService.updateClinicUser(u).toPromise()
          }
          return null
        })

        let result3 = await Promise.all(updatePromises)

        const results = [...result1, ...result2, ...result3]
        if (results.some((result) => result === undefined)) {
          enqueueSnackbar(t('errorRegisteringUsers'), { variant: 'error' })
        }
        setIsLoading(false)
        goToClients()
      })
    },
  })

  const isPasswordValid = (value: string): boolean => dataPassword.newPassword === value

  const {
    handleChange: handleChangePassword,
    handleSubmit: handleSubmitPassword,
    data: dataPassword,
    setData: setDataPassword,
    errors: errorsPassword,
  } = useForm<ChangePasswordDTO>({
    validations: {
      newPassword: {
        required: {
          value: true,
          message: t('newPasswordIsRequired'),
        },
        pattern: {
          value: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$',
          message: t('passwordRequirements'),
        },
      },
      confirmNewPassword: {
        required: {
          value: true,
          message: t('repeatNewPasswordIsRequired'),
        },
        custom: {
          isValid: isPasswordValid,
          message: t('passwordsDontMatch'),
        },
      },
    },

    onSubmit: () => {
      authService.changePassword(dataPassword).subscribe(() => setPasswordModal(false))
    },
  })

  const goToClients = () => navigate(URL_CLIENTS)

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => goToClients(),
    },
  ]

  const actionsPassword: FormAction[] = [
    {
      label: t('close'),
      handleAction: () => setPasswordModal(false),
    },
  ]

  const handleAddUser = () => {
    let auxUsers = [...users]
    auxUsers.push(emptyRegisterUser())
    setUsers(auxUsers)
  }

  const handleChangeUser = (field: string, value: any, index: number) => {
    let auxUsers = [...users]
    let user = auxUsers[index]
    //@ts-ignore
    user[field] = value
    auxUsers[index] = user
    setUsers(auxUsers)
  }

  const removeUser = (indexUser: number) => {
    let auxUsers = [...users]
    auxUsers.splice(indexUser, 1)
    setUsers(auxUsers)
  }

  const handleChangeOtherUserSelected = (otherUserID: any) => {
    setData(Object.assign({ ...data }, { referencedClientID: otherUserID }))
  }

  const handleCheckboxSelectedClient = () => {
    setOtherClientSelected(!otherClientSelected)
    setData(Object.assign({ ...data }, { referencedClientID: null }))
  }

  const getTaxName = () => {
    let filtered = clientsOdoo.filter((c) => c.value == data.idOdoo)
    console.log(filtered)
    if (filtered?.length > 0) {
      return {
        label: filtered[filtered?.length - 1].label,
        value: filtered[filtered?.length - 1].value,
      }
    } else {
      return { label: '', value: '' }
    }
  }

  return (
    <>
      {!isLoading && !isLoadingClients ? (
        <form onSubmit={handleSubmit} style={{ marginBottom: '3.5%' }}>
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={'outlined'}
                error={errors['taxIdentity'] !== undefined}
                id={'taxIdentity'}
                type={'string'}
                onChange={(event) => handleChange('taxIdentity', event.target.value)}
                value={(data && data.taxIdentity) || ''}
                label={t('taxIdentity')}
                helperText={errors['taxIdentity']}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                options={clientsOdoo}
                placeholder={t('taxName')}
                onChange={(newValue) => {
                  setData(
                    Object.assign(
                      { ...data },
                      { taxName: newValue?.label || '' },
                      { idOdoo: newValue?.value || '' }
                    )
                  )
                }}
                value={getTaxName()}
                styles={{
                  control: (baseStyles: any) => ({
                    ...baseStyles,
                    textAlign: 'left',
                    height: '56px',
                  }),
                  menu: (baseStyles: any) => ({
                    ...baseStyles,
                    zIndex: 10
                  }),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={'outlined'}
                error={errors['street'] !== undefined}
                id={'street'}
                type={'string'}
                onChange={(event) => handleChange('street', event.target.value)}
                value={(data && data.street) || ''}
                label={t('street')}
                helperText={errors['street']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={'outlined'}
                error={errors['number'] !== undefined}
                id={'number'}
                type={'string'}
                onChange={(event) => handleChange('number', event.target.value)}
                value={(data && data.number) || ''}
                label={t('number')}
                helperText={errors['number']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={'outlined'}
                error={errors['zipCode'] !== undefined}
                id={'zipCode'}
                type={'string'}
                onChange={(event) => handleChange('zipCode', event.target.value)}
                value={(data && data.zipCode) || ''}
                label={t('zipCode')}
                helperText={errors['zipCode']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={'outlined'}
                error={errors['city'] !== undefined}
                id={'city'}
                type={'string'}
                onChange={(event) => handleChange('city', event.target.value)}
                value={(data && data.city) || ''}
                label={t('city')}
                helperText={errors['city']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant={'outlined'}
                error={errors['province'] !== undefined}
                id={'province'}
                type={'string'}
                onChange={(event) => handleChange('province', event.target.value)}
                value={(data && data.province) || ''}
                label={t('province')}
                helperText={errors['province']}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                disablePortal
                style={{ width: '100%' }}
                fullWidth
                id={'country'}
                //@ts-ignore
                onChange={(_, value) => handleChange('country', value?.value)}
                options={countries}
                value={{ label: t(data?.country) || '', value: data?.country || '' }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} label={t('country')} />
                )}
              />
            </Grid>
            {authService.userCan(Permission.assignOtherClientToClient) && (
              <Grid
                item
                xs={6}
                style={{ justifyContent: 'flex-start', display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={
                    <Switch onChange={handleCheckboxSelectedClient} checked={otherClientSelected} />
                  }
                  label={t('otherClient')}
                />
                {otherClientSelected && (
                  <FormControl fullWidth variant="outlined" style={{ marginTop: '3%' }}>
                    <InputLabel id="select-client-label">{t('selectClient')}</InputLabel>
                    <MUSelect
                      labelId="inherit-label"
                      id="inherit"
                      fullWidth
                      style={{ textAlign: 'left' }}
                      value={
                        data.referencedClientID
                          ? clients.filter((c) => c.id == data.referencedClientID).length > 0 &&
                            clients.filter((c) => c.id == data.referencedClientID)[0]?.id
                          : ''
                      }
                      onChange={(event) => handleChangeOtherUserSelected(event.target.value)}
                      label={t('client')}>
                      {clients?.map((client) => (
                        <MenuItem value={client.id} key={client.id}>
                          {client.taxName}
                        </MenuItem>
                      ))}
                    </MUSelect>
                  </FormControl>
                )}
              </Grid>
            )}
          </Grid>
          <Grid container style={{ marginTop: '2.5%' }} item xs={12}>
            <FormControl style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <FormLabel style={{ color: 'black', alignSelf: 'center' }}>
                {t('notifications')}:
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                style={{ display: 'flex', flexDirection: 'row', marginLeft: '1%' }}>
                <FormControlLabel
                  value="stateChanges"
                  control={
                    <Radio
                      checked={data.cosnotifications}
                      onClick={() =>
                        setData(
                          Object.assign({ ...data }, { cosnotifications: !data.cosnotifications })
                        )
                      }
                    />
                  }
                  label={t('stateChanges')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container style={{ marginTop: '3%' }} item xs={12}>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'left' }}>
              <Typography className={classes.title} variant={'h4'} align="left">
                {t('users')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ justifyContent: 'flex-end', flexDirection: 'row', display: 'flex' }}>
              <p style={{ fontSize: '12px', alignSelf: 'center', marginRight: '0.5%' }}>
                {t('addUser')}
              </p>
              <AddBoxIcon
                className={formClass.plusIcon}
                style={{ fontSize: '34px', alignSelf: 'center' }}
                onClick={handleAddUser}
                titleAccess={t('addUser')}
              />
            </Grid>
          </Grid>
          {users?.map((user, i) => (
            <Grid
              container
              spacing={5}
              key={user.id}
              style={{ marginTop: '1%', marginBottom: '1%' }}>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'flex-end', display: 'flex', marginBottom: '-1.5%' }}>
                <CancelIcon
                  className={formClass.cancelIcon}
                  onClick={() => removeUser(i)}
                  titleAccess={t('removeUser')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  id={'firstName'}
                  type={'string'}
                  onChange={(event) => handleChangeUser('firstName', event.target.value, i)}
                  value={(user && user.firstName) || ''}
                  label={t('firstName')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  id={'lastName'}
                  type={'string'}
                  onChange={(event) => handleChangeUser('lastName', event.target.value, i)}
                  value={(user && user.lastName) || ''}
                  label={t('lastName')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  id="email"
                  type={'email'}
                  label={t('email')}
                  onChange={(event) => handleChangeUser('email', event.target.value, i)}
                  value={(user && user.email) || ''}
                  disabled={
                    props.client &&
                    props.client.id != undefined &&
                    usersBefore.filter((u) => u.id == user.id).length > 0
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="role-label">{t('role')}</InputLabel>

                  <MUSelect
                    labelId="inherit-label"
                    id="inherit"
                    fullWidth
                    style={{ textAlign: 'left' }}
                    value={(user && user.role) || ''}
                    onChange={(event) => handleChangeUser('role', Number(event.target.value), i)}
                    label={t('role')}>
                    {Object.entries(roles)?.map(([key, value]) => (
                      <MenuItem value={key} key={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </MUSelect>
                </FormControl>
              </Grid>
            </Grid>
          ))}

          <FormActions actions={actions} />

          {props.client && (
            <Box style={{ textAlign: 'start' }}>
              <Button
                style={{ width: '205px' }}
                className={classes.button}
                onClick={() => setPasswordModal(true)}
                variant={'contained'}>
                {t('changePassword')}
              </Button>
            </Box>
          )}
        </form>
      ) : (
        <LoadingSpinner className={formClass.loadingSpinner} />
      )}
      <Modal open={passwordModal}>
        <Box className={formClass.modal}>
          <form onSubmit={handleSubmitPassword}>
            <Box mt={2}>
              <FormControl
                fullWidth
                variant={'outlined'}
                error={errorsPassword['newPassword'] !== undefined}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  onChange={(event) => handleChangePassword('newPassword', event.target.value)}
                  name={'newPassword'}
                  label={t('newPassword')}
                  type={'password'}
                  id={'newPassword'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment color={'inherit'} position={'start'}>
                        <Lock color={'inherit'} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>{errorsPassword['newPassword']}</FormHelperText>
              </FormControl>
            </Box>
            <Box mt={2}>
              <FormControl
                fullWidth
                variant={'outlined'}
                error={errorsPassword['confirmNewPassword'] !== undefined}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  onChange={(event) =>
                    handleChangePassword('confirmNewPassword', event.target.value)
                  }
                  name={'confirmNewPassword'}
                  label={t('confirmNewPassword')}
                  type={'password'}
                  id={'confirmNewPassword'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment color={'inherit'} position={'start'}>
                        <Lock color={'inherit'} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>{errorsPassword['confirmNewPassword']}</FormHelperText>
              </FormControl>
            </Box>
            <FormActions actions={actionsPassword} />
          </form>
        </Box>
      </Modal>
    </>
  )
}
