import {Age} from "../enum/Age";

export const StringToAge = (name: string): Age | undefined => {
    for (const age of Object.values(Age)) {
        if (age === name) {
            return <Age> name;
        }
    }
    return undefined
}
