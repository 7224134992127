import { Accordion, AccordionDetails, AccordionSummary, Button, Typography, } from '@material-ui/core'
import { ExpandMore } from "@material-ui/icons"
import React, { useEffect, useState } from 'react'
import { COLOR_PRIMARY } from "../../routes/color-constants"
import { useStyles } from './Help.styles'
import { useTranslation } from "react-i18next"
import { getFaqContainer } from "../../container/faq-modules"
import { FaqService } from "../../modules/faq/services/FaqService"
import { FAQ_SERVICE_KEY } from "../../modules/faq"
import { Faq } from "../../modules/faq/models/Faq"
import { Query } from "../../common/api/Query";
import { navigate } from "@reach/router";
import { URL_FAQ_CREATE, URL_FAQ_EDIT } from "../../routes/routes-constants";
import { LoggedUser } from "../../modules/auth/models/LoggedUser";
import { RouteProps } from "../../routes/AppRouter";
import { getAuthContainer } from "../../container/auth-modules";
import { AuthService } from "../../modules/auth/services/AuthService";
import { AUTH_SERVICE_KEY } from "../../modules/auth";
import { Permission } from "../../common/enums/Permissions";

const faqService = getFaqContainer().get<FaqService>(FAQ_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)



export type FaqTableProps = RouteProps &
{
    loggedUser: LoggedUser
}

export const Table = (loggedUser: FaqTableProps) => {
    const classes = useStyles({ color: COLOR_PRIMARY })
    const { t } = useTranslation()
    const [items, setItems] = useState<Faq[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        faqService.getFilteredList(
            new Query({})
        ).subscribe((res) => {
            setItems(res.items)
        }
        )
    }, [isLoading])

    useEffect(() => {
        setIsLoading(false)
    }, [isLoading])

    const handleCreate = () => {
        navigate(URL_FAQ_CREATE)
    }

    const handleDelete = (e: React.MouseEvent) => {
        faqService.delete(e.currentTarget.id).subscribe((res) => {
            if (res) {
                setIsLoading(true)
            }
        })
    }

    const handleEdit = (e: React.MouseEvent) => {
        navigate(URL_FAQ_EDIT.replace(':id', `${e.currentTarget.id}`))
    }
    const admin = authService.get().permissions.find((p)=> p == "CreateClients")
    const lang = authService.get().language

    const accordeon = (element: Faq)=>{
        return (
            <Accordion key={element.id} style={{ borderStyle: "groove", marginTop: '1%', borderColor: COLOR_PRIMARY }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="a"
                    id={typeof element.id == 'string' ? element.id : ''}
                >
                    <Typography>{element.question}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ justifyContent: "space-between", display: "flex" }}>
                    <div >
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: '1%' }}>
                            <Typography align="center">
                                {element.answer}
                            </Typography>
                        </div>
                    </div>
                    <div>
                        {authService.userCan(Permission.editFaqs) && <Button
                            id={element.id}
                            style={{ marginRight: '20px', color: '#d40f0f', backgroundColor: "#f5d5d5" }}
                            onClick={(e) => handleDelete(e)}
                            variant={'contained'}>
                            {t('delete')}
                        </Button>}
                        {authService.userCan(Permission.editFaqs) && <Button
                            id={element.id}
                            className={classes.button}
                            onClick={(e) => handleEdit(e)}
                            variant={'contained'}>
                            {t('edit')}
                        </Button>}
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <>
            <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "row", marginBottom: '1%' }}>

                    <Typography className={classes.title} variant={'h4'} align="center">
                        {'FAQS'}
                    </Typography>
                </div>
                <div>
                    {authService.userCan(Permission.createFaqs) && <Button
                        style={{ float: 'right' }}
                        className={classes.button}
                        onClick={handleCreate}
                        variant={'contained'}>
                        {t('create')}
                    </Button>}
                </div>
            </div>

            <div>
                {items.map((element) =>{
                   if(!admin && lang == element.language ) {
               return(
                   accordeon(element)
                ) } else if(admin){
                       return(
                           accordeon(element)
                       )
                   }

                }
                    )}
            </div>

        </>
    )
}