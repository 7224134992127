import { RouteProps } from '../../routes/AppRouter'

export type FormProps = RouteProps & {
   id?: string
}

export const Form = () => {

  return (<>
      {/*
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <EntitySelect
            name={'name'}
            value={data && data.clinicID}
            options={clinics}
            error={errors['clinicID']}
            onChange={(value) => handleChange('clinicID', value && value.id)}
            label={t('clinic')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['sampleName'] !== undefined}
            id={'name'}
            type={'string'}
            onChange={(event) => handleChange('sampleName', event.target.value)}
            value={(data && data.sampleName) || ''}
            label={t('sampleName')}
            helperText={errors['sampleName']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['idPatient'] !== undefined}
            id={'name'}
            type={'string'}
            onChange={(event) => handleChange('idPatient', event.target.value)}
            value={(data && data.idPatient) || ''}
            label={t('idPatient')}
            helperText={errors['idPatient']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['firstName'] !== undefined}
            id={'firstName'}
            type={'string'}
            onChange={(event) => handleChange('firstName', event.target.value)}
            value={(data && data.firstName) || ''}
            label={t('firstName')}
            helperText={errors['firstName']}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant={'outlined'}
            error={errors['lastName'] !== undefined}
            id={'lastName'}
            type={'string'}
            onChange={(event) => handleChange('lastName', event.target.value)}
            value={(data && data.lastName) || ''}
            label={t('lastName')}
            helperText={errors['lastName']}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: '100%' }}
              id='dob'
              autoOk
              inputVariant={'outlined'}
              format='dd/MM/yyyy'
              value={data.dob || null}
              onChange={(newDate) => setData(Object.assign({ ...data }, { dob: newDate }))}
              required={false}
              size={'small'}
              label={t('dob')}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined' error={errors['gender'] !== undefined}>
            <InputLabel id='inherit-label'>{t('gender')}</InputLabel>
            <Select
              labelId='gender-label'
              id='gender'
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.gender) || ''}
              onChange={(event) =>
                handleChange('gender', event.target.value && +(event.target.value as string))
              }
              label={t('gender')}>
              {Object.entries(userGenders()).map(([key, value]) => (
                <MenuItem value={key}>{value}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['gender']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined' error={errors['type'] !== undefined}>
            <InputLabel id='type-label'>{t('patientType')}</InputLabel>
            <Select
              labelId='type-label'
              id='type'
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.type) || ''}
              onChange={(event) =>
                handleChange('type', event.target.value && +(event.target.value as string))
              }
              label={t('patientType')}>
              {Object.entries(patientTypes()).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {t(value)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors['type']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['height'] !== undefined}
            fullWidth
            variant={'outlined'}
            id='height'
            type={'number'}
            label={t('height')}
            onChange={(event) => handleChange('height', +event.target.value)}
            value={data && data.height}
            helperText={errors['height']}
            InputProps={{ inputProps: { min: 0 } }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={errors['geneticDiseaseHistory'] !== undefined}
            fullWidth
            id='geneticDiseaseHistory'
            label={t('geneticDiseaseHistory')}
            onChange={(event) => handleChange('geneticDiseaseHistory', event.target.value)}
            multiline
            rows={4}
            value={data && data.geneticDiseaseHistory}
            variant='outlined'
            helperText={errors['geneticDiseaseHistory']}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant='outlined' error={errors['isPublic'] !== undefined}>
            <InputLabel id='isPublic-label'>{t('isPublic')}</InputLabel>
            <Select
              labelId='isPublic-label'
              id='isPublic'
              fullWidth
              style={{ textAlign: 'left' }}
              value={(data && data.isPublic && 1) || 0}
              onChange={(event) => handleChange('isPublic', !!event.target.value)}
              label={t('isPublic')}>
              <MenuItem value={1} key={1}>
                {t('yes')}
              </MenuItem>
              <MenuItem value={0} key={0}>
                {t('no')}
              </MenuItem>
            </Select>
            <FormHelperText>{errors['isPublic']}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.ethnicity}
            options={translatedOptions(
              patientParams.get(PatientParamType.Ethnicity) || [],
              'ethnicity',
            )}
            error={errors['ethnicity']}
            onChange={(value) => handleChange('ethnicity', value && value.id)}
            label={t('ethnicity')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.skinColor}
            options={translatedOptions(
              patientParams.get(PatientParamType.SkinColor) || [],
              'skinColor',
            )}
            error={errors['skinColor']}
            onChange={(value) => handleChange('skinColor', value && value.id)}
            label={t('skinColor')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.eyeColor}
            options={translatedOptions(
              patientParams.get(PatientParamType.EyesColor) || [],
              'eyeColor',
            )}
            error={errors['eyeColor']}
            onChange={(value) => handleChange('eyeColor', value && value.id)}
            label={t('eyeColor')}
            pk={'id'}
          />
        </Grid>
        <Grid item xs={12}>
          <EntitySelect
            name={'translation'}
            value={data && data.hairColor}
            options={translatedOptions(
              patientParams.get(PatientParamType.HairColor) || [],
              'hairColor',
            )}
            error={errors['hairColor']}
            onChange={(value) => handleChange('hairColor', value && value.id)}
            label={t('hairColor')}
            pk={'id'}
          />
        </Grid>
        <Grid item container justify={'flex-start'}>
          <input
            type='file'
            id='csvFile'
            accept='.csv, .txt'
            onChange={(event) => handleFileInput(event)}
            ref={inputFile}
            style={{ display: 'none' }}
          />
          <Fab
            onClick={() => inputFile.current?.click()}
            variant='extended'
            color='primary'
            aria-label='add'>
            {(csv && (
              <>
                <CachedIcon className={classes.actionButton} />
                {(selectedFile && selectedFile.name) || t('changeCsvFile')}
              </>
            )) || (
              <>
                <AddIcon className={classes.actionButton} />
                {(selectedFile && selectedFile.name) || t('addCsvFile')}
              </>
            )}
          </Fab>
        </Grid>
      </Grid>
      <FormActions actions={actions} />
    </form>*/}
      </>
  )
}
