import { RouteProps } from '../../../routes/AppRouter'
import { Product } from '../../../modules/product/models/Product'
import { useEffect, useState } from 'react'
import { useStyles } from '../Application.styles'
import { COLOR_PRIMARY } from '../../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { getSampleTypeContainer } from '../../../container/sampleType-modules'
import { SampleTypeService } from '../../../modules/sampleType/services/SampleTypeService'
import { SAMPLETYPE_SERVICE_KEY } from '../../../modules/sampleType'
import { Query } from '../../../common/api/Query'
import { SampleType } from '../../../modules/sampleType/models/SampleType'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { PatientProduct } from 'modules/patient/models/relationship/PatientProduct'
import { PatientCitogeneticaArrayDTO } from '../../../modules/patient/models/patientForm/PatientCitogeneticaArrayDTO'
import { emptySampleCitogeneticaDTO } from 'modules/sampleType/models/sampleForm/SampleCitogeneticaDTO'
import { format, isValid } from 'date-fns'

export type SampleCitogeneticaArrayProps = RouteProps & {
  patients: PatientCitogeneticaArrayDTO[]
  patientProduct: PatientProduct
  product: Product
  handleChangePatientProducts: (pp: PatientProduct, i: number) => void
  index: number
  edit?: boolean
}

const sampleTypeService = getSampleTypeContainer().get<SampleTypeService>(SAMPLETYPE_SERVICE_KEY)

export const SampleCitogeneticaArray = (props: SampleCitogeneticaArrayProps) => {
  const [patientNumber, setPatientNumber] = useState<number>(props.product.patientNumber || 0)
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t, i18n } = useTranslation()
  const [sampleType, setSampleType] = useState<SampleType[]>([])
  const [patientProduct, setPatientProduct] = useState<PatientProduct>(props.patientProduct)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    sampleTypeService.getFilteredListDB(new Query({})).subscribe((res) => {
      setSampleType(res.items)
      setIsLoading(false)
    })
  }, [])

  const handleChangePatients = (e: any, type: string, index: number) => {
    if (props.patients) {
      let p = props.patients[index]
      let pp = props.patientProduct

      if (p && p._sample) {
        if (type == '_typeSample') {
          p._sample._typeSample = e.target.value
          p._sample.typeSample = e.target.value
        } else if (type == '_code') {
          p._sample._code = e.target.value
          p._sample.code = e.target.value
        } else if (type == 'language') {
          pp.language = e.target.value
          pp._language = e.target.value
        }

        let patients = props.patients
        patients[index] = p

        //@ts-ignore
        let newProduct = Object.assign({ ...patientProduct }, { patientCitogenetics: patients })
        //@ts-ignore
        setPatientProduct(newProduct)
        //@ts-ignore
        props.handleChangePatientProducts(newProduct, props.index)
      } else {
        let p = props.patientProduct.patientCitogenetics[index]

        if (p && p._sample) {
          if (type == '_typeSample') {
            p._sample._typeSample = e.target.value
            p._sample.typeSample = e.target.value
          } else if (type == '_code') {
            p._sample._code = e.target.value
            p._sample.code = e.target.value
          } else if (type == 'language') {
            pp.language = e.target.value
            pp._language = e.target.value
          }

          let patients = props.patients
          patients[index] = p

          //@ts-ignore
          let newProduct = Object.assign({ ...patientProduct }, { patientCitogenetics: patients })
          //@ts-ignore
          setPatientProduct(newProduct)
          //@ts-ignore
          props.handleChangePatientProducts(newProduct, props.index)
        }
      }
    }
  }

  const handleChangePatientsDate = (d: Date, index: number) => {
    if (props.patients) {
      let p = props.patients[index]
      if (!p._sample) {
        //@ts-ignore
        p._sample = emptySampleCitogeneticaDTO()
      }
      p._sample._extractionDate = d

      let patients = props.patients
      patients[index] = p

      //@ts-ignore
      let newProduct = Object.assign({ ...patientProduct }, { patientCitogenetics: patients })
      //@ts-ignore
      props.handleChangePatientProducts(newProduct, props.index)
      //@ts-ignore
      setPatientProduct(newProduct)
    }
  }

  const handleReset = (index: number) => {
    let p = props.patientProduct.patientCitogenetics[index]
    p._sample._code = ''
    p._sample.code = ''
    p._sample._typeSample = ''
    p._sample.typeSample = ''
    p._sample._extractionDate = new Date()

    let patients = props.patients
    patients[index] = p
    //@ts-ignore
    let newProduct = Object.assign({ ...patientProduct }, { patientCitogenetics: patients })
    //@ts-ignore
    props.handleChangePatientProducts(newProduct, props.index)
    //@ts-ignore
    setPatientProduct(newProduct)
  }

   const formatDate = (date: Date) =>{
    const sdate: string = format(date, "ddMMyyyy")
    const d = parseInt(sdate.slice(0,2))
    const m = parseInt(sdate.slice(2,4))-1
    const y = parseInt(sdate.slice(4,8))

    let fixedDate = new Date(Date.UTC(y, m, d))
    fixedDate.setUTCHours(fixedDate.getUTCHours() + 2)

    return fixedDate
  }


  return (
    <>
      {!isLoading ? (
        <Grid container direction="column">
          {[...Array(patientNumber)]?.map((_, index) => (
            <Grid container spacing={4} key={index} style={{ marginTop: '40px' }}>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'left',
                  fontWeight: 'bold',
                  fontSize: '18px',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}>
                  
                {t('dateAboutSample') + ' ' + (index + 1) + ' ' + t('for') + ' ' + (i18n.language ==
                'es'
                  ? props.product?.nameES
                  : props.product?.nameEN)}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="typeSampleLabel">{t('typeSample') + '*'}</InputLabel>
                  <Select
                    labelId={'typeSampleLabel'}
                    id={'type'}
                    name={'type'}
                    required={true}
                    onChange={(e) => handleChangePatients(e, '_typeSample', index)}
                    value={
                      patientProduct.patientCitogenetics?.length >= index &&
                      patientProduct.patientCitogenetics[index] &&
                      patientProduct.patientCitogenetics[index]?._sample?._typeSample
                    }>
                    {sampleType?.map((elementST, indexST) => {
                      return (
                        <MenuItem key={indexST} value={elementST.idOdoo ? elementST.idOdoo : ''}>
                          {(i18n.language == 'es' ? elementST?.nameES : elementST?.nameEN)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id={`code-${index}`}
                  label={t('codeSample')}
                  variant="filled"
                  className={classes.textField}
                  fullWidth
                  value={
                    patientProduct.patientCitogenetics?.length >= index &&
                    patientProduct.patientCitogenetics[index] &&
                    patientProduct.patientCitogenetics[index]?._sample?._code
                  }
                  onChange={(e) => handleChangePatients(e, '_code', index)}
                />
              </Grid>
              <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    style={{ width: '100%' }}
                    id={`dob-${index}`}
                    autoOk
                    inputVariant={'outlined'}
                    format="dd/MM/yyyy"
                    onChange={(date) => date  && isValid(date) && handleChangePatientsDate(date, index)}
                    value={
                      (patientProduct.patientCitogenetics?.length >= index &&
                        patientProduct.patientCitogenetics[index] &&
                        patientProduct.patientCitogenetics[index]?._sample?._extractionDate) ||
                      null
                    }
                    size={'small'}
                    label={t('doe')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <CircularProgress style={{ alignSelf: 'center' }} />
      )}
    </>
  )
}
