import { Client } from "../../clients/models/Client";
import { Request } from "../../request/models/Request";
import { Client2RequestDTO } from "./Client2RequestDTO";
import { Patient } from "../../patient/models/Patient";

export type Client2RequestProps = {
    client: Client
    request: Request
    patient: Patient
}

export class Client2Request {
    private _client: Client
    private _request: Request
    private _patient: Patient

    constructor(p: Client2RequestDTO) {
        this._client = p.client
        this._request = p.request
        this._patient = p.patient
    }

    set client(value: Client) {
        this._client = value
    }

    set request(value: Request) {
        this._request = value
    }

    get client(): Client {
        return this._client
    }

    get request(): Request {
        return this._request
    }

    set patient(value: Patient) {
        this._patient = value
    }

    get patient(): Patient {
        return this._patient
    }
}

export type Client2RequestsProps = {
    id: string
    date: Date
    paciente: string
    sample: string
    status: string
}

export interface Client2RequestQuery {
    patient: string
}


export class Client2RequestTable {
    private _id: string
    private _date: Date
    private _paciente: string
    private _sample: string
    private _status: string

    constructor(p: Client2RequestDTO) {
        this._id = p.request.id
        this._date = p.request.date
        this._paciente = p.patient.firstName + p.patient.lastName
        this._sample = "Falta este dato"
        this._status = p.request.status
    }
}