import { Container } from '../../common/container/Container'

export type PatientContainerConfig = {
  moduleFullUrl: string
}

export type PatientProps = {
  parentContainer: Container
  config: PatientContainerConfig
}

export const PATIENT_API_KEY = Symbol('PATIENT_API_KEY')
export const PATIENT_SERVICE_KEY = Symbol('PATIENT_SERVICE_KEY')
export const PATIENT_MODULE = Symbol('PATIENT_MODULE')