import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { StyleProps } from "../../../components/entity-page/EntityPage";

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    textField: {
        marginBottom: '0%',
        marginTop: '-1.5%'
    },
    generalGrid: {
        marginBottom: "70px"
    },
    titleText: {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "18px"
    },
    pInformationGrid: {
        marginTop: "40px"
    },
    text: {
        textAlign: "left",
        fontSize: "18px",
        textDecorationLine: "underline"
    },
    dobGrid: {
        marginTop: "5%"
    },
    gynecologicalConditionGrid: {
        marginTop: "2.5%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        textAlign: "start"
    },
    gynecologicalConditionText: {
        textAlign: "left",
        fontSize: "18px"
    },
    grid: {
        fontSize: "16.5px",
        justifyContent: "flex-start",
        flexDirection: "row",
        display: "flex"
    },
    grid2: {
        fontSize: "16.5px",
        justifyContent: "flex-start",
        flexDirection: "row",
        marginLeft: "-20%",
        marginTop: "-1%"
    },
    miscarriagesGrid: {
        marginTop: "-0.5%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        textAlign: "start"
    },
    endometrialBiopsyMethod: {
        display: "flex",
        flexDirection: "row",
        marginTop: "2%",
        fontSize: "18px"
    },
    endometrialBiopsyMethod2: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: "0.4%",
        marginRight: "2.5%"
    },
    endometrialBiopsyMethod3: {
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: "0.4%"
    },
    sizeTextField: {
        width: "50px",
        marginTop: "1%",
        marginRight: "0.8%",
        marginLeft: "0.8%"
    },
    sizeGrid: {
        marginTop: "2%",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        textAlign: "start"
    },
    timePicker: {
        marginTop: "-1.1%",
        marginLeft: "6%"
    },
    grid3: {
        fontSize: "16.5px",
        justifyContent: "flex-start",
        flexDirection: "row",
        display: "flex",
        marginTop: "-1.8%"
    },
    grid20: {
        display: "flex",
        flexDirection: "row",
        width: "20%"
    },
    treatmentDurationTextField: {
        width: "50px",
        marginLeft: "1%",
        marginTop: "0.8%"
    },
    desc1: {
        textAlign: "left",
        fontSize: "15px"
    },
    desc2: {
        textAlign: "left",
        fontSize: "15px",
        marginTop: "-1.3%"
    },
    top5: {
        marginTop: "5%"
    },
    padding1: {
        paddingTop: "1px"
    },
    width40: {
        width: "40px"
    },
    left4: {
        marginLeft: "4%"
    },
    width100: {
        width: '100%'
    },
    width15: {
        width: "15%"
    },
    textFieldCycle: {
        width: "50px",
        marginTop: "1%",
        marginRight: "0.8%"
    }
}));
