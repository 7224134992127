import { RouteProps } from '../../routes/AppRouter'
import { ApplicationTemplate } from '../../features/application'
import { Title } from 'components/common/Title'
import { useState } from 'react'

export function Template(props: RouteProps) {
  const [saveDraftAgain, setSaveDraftAgain] = useState<boolean>(false)

  const handleDraftAgain = () => {
      setSaveDraftAgain(true)
  }

  const editTemplate = () => {
    return (
        <ApplicationTemplate id={props.id || ''} saveDraftAgain={saveDraftAgain} setSaveDraftAgain={setSaveDraftAgain} />
    )
  }

  return (
    <>
      <Title color={'black'} title={props.title || ''} saveDraftAgain={handleDraftAgain}/>
      {editTemplate()}
    </>
  )
}
