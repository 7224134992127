import { Container } from '../../common/container/Container'

export type SampleContainerConfig = {
  moduleFullUrl: string
}

export type SampleProps = {
  parentContainer: Container
  config: SampleContainerConfig
}

export const SAMPLE_API_KEY = Symbol('SAMPLE_API_KEY')
export const SAMPLE_SERVICE_KEY = Symbol('SAMPLE_SERVICE_KEY')
export const SAMPLE_MODULE = Symbol('SAMPLE_MODULE')
