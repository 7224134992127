import React, { CSSProperties } from 'react'
import { Table, TableContainer, Button } from '@material-ui/core'
import { Head } from './Head'
import { Pagination } from './Pagination'
import { Body } from './Body'
import { Actions, Field, Pager, Search, Sort } from './types'
import { Searcher } from './Searcher'
import { useStyles } from '../../features/home/Home.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'

const DEFAULT_ROW_KEY = 'id'

export type TableProps<T, Q> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly styleHeader?: CSSProperties

  readonly actions?: Actions<T>
  readonly search?: Search<Q>
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly button?: string
  handleButton?: () => void
  readonly areRequests?: boolean
  handleCheck?: (item: any) => void
  handleCheckAll?: () => void
  requestsSendNacex?: any[]
  readonly allChecked?: boolean
  selectedStatus?: string
}

export function AppTable<T extends { [key: string]: any }, Q extends { [key: string]: any }>(
  props: TableProps<T, Q>
) {
  const classes = useStyles({ color: COLOR_PRIMARY })

  return (
    <>
      {props.search && <Searcher search={props.search} selectedStatus={props.selectedStatus}/>}
      <div
        style={{
          borderColor: '#e8e8ea',
          borderWidth: '10px',
          borderStyle: 'solid',
          borderRadius: '10px',
        }}>
        <TableContainer>
          <Table>
            <Head
              allChecked={props.allChecked}
              fields={props.fields}
              sort={props.sort}
              actions={props.actions}
              styleHeader={props.styleHeader}
              areRequests={props.areRequests}
              handleCheckAll={props.handleCheckAll}
            />
            <Body
              actions={props.actions}
              fields={props.fields}
              items={props.items}
              rowKeyField={props.rowKeyField || DEFAULT_ROW_KEY}
              areRequests={props.areRequests}
              handleCheck={props.handleCheck}
              requestsSendNacex={props.requestsSendNacex}
            />
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', flex: 'row', justifyContent: 'space-between' }}>
          {props.pager && <Pagination {...props.pager} />}
          {props.button! ? (
            <div style={{ margin: '25px 20px 0 0' }}>
              <Button
                type={'button'}
                onChange={props.handleButton ? () => props.handleButton : () => {}}
                className={classes.button}>
                {props.button}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div></div>
      </div>
    </>
  )
}
