import { useTranslation } from 'react-i18next'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { useStyles } from './Help.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { getUserContainer } from 'container/user-modules'
import { UserService } from 'modules/users/services/UserService'
import { USER_SERVICE_KEY } from 'modules/users'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'

const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const Contact = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [subject, setSubject] = useState<string>('')
  const [consult, setConsult] = useState<string>('')
  const [type, setType] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = () => {
    if (name && email && subject && consult && type) {
      userService.sendMail({
        name: name,
        email: email,
        subject: subject,
        consult: consult,
        type: type,
      })

      setTimeout(() => {
        setName('')
        setEmail('')
        setSubject('')
        setConsult('')
        setType('')
        enqueueSnackbar(t('emailCorrectlySend'), { variant: 'success' })
      }, 600)
    }
  }

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <Typography className={classes.title} variant={'h4'} align="left">
          {t('support')}
        </Typography>
      </div>
      <br />
      <form onSubmit={handleSubmit}>
        <Grid xs={12}>
          <TextField
            id="name"
            label={t('name')}
            variant="filled"
            fullWidth
            className={classes.textField}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="email"
            label={t('email')}
            variant="filled"
            fullWidth
            className={classes.textField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Grid item xs={12} style={{ marginBottom: '1%' }}>
            <FormControl variant="filled" fullWidth>
              <InputLabel id="typeSampleLabel">{t('queryType')}</InputLabel>
              <Select
                labelId={'queryType'}
                id={'type'}
                name={'type'}
                onChange={(e: any) => setType(e.target.value)}
                style={{ textAlign: 'left' }}
                value={type}>
                <MenuItem value={'cytogenomics'}>{t('cytogenomics')}</MenuItem>
                <MenuItem value={'ngs'}>{t('ngs')}</MenuItem>
                <MenuItem value={'oncology'}>{t('oncology')}</MenuItem>
                <MenuItem value={'reproduction'}>{t('reproduction')}</MenuItem>
                <MenuItem value={'others'}>{t('others')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <TextField
            id="issue"
            label={t('issue')}
            variant="filled"
            fullWidth
            className={classes.textField}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            id="yourConsult"
            label={t('yourConsult')}
            variant="filled"
            fullWidth
            multiline
            rows={5}
            className={classes.textField}
            value={consult}
            onChange={(e) => setConsult(e.target.value)}
          />
        </Grid>
        <Grid item xs={2} alignItems={'center'}>
          <Button fullWidth className={classes.button} onClick={handleSubmit} variant={'contained'}>
            {t('send')}
          </Button>
        </Grid>
      </form>
    </div>
  )
}
