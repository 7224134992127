import { Container, ContainerItem } from '../common/container/Container'
import { RequestProps } from '../modules/request/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { REQUEST_API_KEY, REQUEST_SERVICE_KEY, REQUEST_MODULE } from '../modules/request'
import { RequestService } from '../modules/request/services/RequestService'
import { RequestApi } from '../modules/request/api/RequestApi'

let container: Container

function init(p: RequestProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(REQUEST_API_KEY, new ContainerItem({ instance: new RequestApi() }))
  items.set(
    REQUEST_SERVICE_KEY,
    new ContainerItem({
      instance: new RequestService({ apiKey: REQUEST_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getRequestContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[REQUEST_MODULE],
      },
    })
  }

  return container
}
