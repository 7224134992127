import { useTranslation } from 'react-i18next'
import { RouteProps } from '../../routes/AppRouter'
import React, { useState } from 'react'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Title } from '../../components/common/Title'
import { useStyles } from '../../components/entity-page/EntityPage.styles'
import { UserService } from '../../modules/users/services/UserService'
import { USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-modules'
import { LoggedUserDTO } from 'modules/auth/models/LoggedUserDTO'
import { useForm } from '../../common/utils/form-generation/useForm'
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@material-ui/core'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { navigate } from '@reach/router'
import { LoggedUser } from '../../modules/auth/models/LoggedUser'
import { Lock } from '@material-ui/icons'
import { ChangePasswordDTO } from '../../modules/auth/models/ChangePassword'
import { optionLanguage } from '../../modules/users/enums/Language'
import { commonStyles } from '../../common/styles/Styles'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const UserProfile = (props: RouteProps) => {
  const { t } = useTranslation()
  const classes = useStyles({ color: props.color })

  const styles = commonStyles()

  const [loggedUser, setLoggedUser] = useState<LoggedUser>(authService.get())
  const [passwordModal, setPasswordModal] = useState<boolean>(false)

  const { handleChange, handleSubmit, data, setData, errors } = useForm<LoggedUserDTO>({
    validations: {
      firstName: {
        required: {
          value: true,
          message: t('firstNameIsNecessary'),
        },
      },
      lastName: {
        required: {
          value: true,
          message: t('lastNameIsNecessary'),
        },
      },
      email: {
        required: {
          value: true,
          message: t('emailIsNecessary'),
        },
      },
      phone: {
        required: {
          value: true,
          message: t('phoneIsNecessary'),
        },
      },
    },

    onSubmit: () => {
      userService.updateProfile(data).subscribe(() => authService.updateLocalProfile(data))
    },

    initialValues: loggedUser && loggedUser.toDTO(),
  })

  const isPasswordValid = (value: string): boolean => dataPassword.newPassword === value

  const {
    handleChange: handleChangePassword,
    handleSubmit: handleSubmitPassword,
    data: dataPassword,
    setData: setDataPassword,
    errors: errorsPassword,
  } = useForm<ChangePasswordDTO>({
    validations: {
      newPassword: {
        required: {
          value: true,
          message: t('newPasswordIsRequired'),
        },
        pattern: {
          value: '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$',
          message: t('passwordRequirements'),
        },
      },
      confirmNewPassword: {
        required: {
          value: true,
          message: t('repeatNewPasswordIsRequired'),
        },
        custom: {
          isValid: isPasswordValid,
          message: t('passwordsDoesntMatch'),
        },
      },
    },

    onSubmit: () => {
      dataPassword.id = authService.get().id
      authService.changePassword(dataPassword).subscribe(() => setPasswordModal(false))
    },
  })

  const actions: FormAction[] = [
    {
      label: t('back'),
      handleAction: () => navigate(-1),
    },
  ]

  const actionsPassword: FormAction[] = [
    {
      label: t('close'),
      handleAction: () => setPasswordModal(false),
    },
  ]

  return (
    <div className={classes.root}>
      <Title title={props.title || ''} color={props.color} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={errors['firstName'] !== undefined}
              fullWidth
              variant={'outlined'}
              id={'firstName'}
              type={'text'}
              label={t('firstName')}
              onChange={(event) => handleChange('firstName', event.target.value)}
              value={data && data.firstName}
              helperText={errors['firstName']}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors['lastName'] !== undefined}
              fullWidth
              variant={'outlined'}
              id="lastName"
              type={'text'}
              label={t('lastName')}
              onChange={(event) => handleChange('lastName', event.target.value)}
              value={data && data.lastName}
              helperText={errors['lastName']}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors['email'] !== undefined}
              fullWidth
              variant={'outlined'}
              id="email"
              type={'text'}
              label={t('email')}
              onChange={(event) => handleChange('email', event.target.value)}
              value={data && data.email}
              helperText={errors['email']}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors['phone'] !== undefined}
              fullWidth
              variant={'outlined'}
              id="phone"
              type={'text'}
              label={t('phone')}
              onChange={(event) => handleChange('phone', event.target.value)}
              value={data && data.phone}
              helperText={errors['phone']}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" error={errors['language'] !== undefined}>
              <InputLabel id="language-label">{t('language')}</InputLabel>
              <Select
                labelId="language-label"
                id="language"
                fullWidth
                style={{ textAlign: 'left' }}
                value={(data && data.language) || ''}
                onChange={(event) => handleChange('language', event.target.value)}
                label={t('language')}>
                {Object.keys(optionLanguage()).map((l) => (
                  <MenuItem value={l} key={l}>
                    {t(l)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors['language']}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <FormActions actions={actions} />
      </form>
      <Box style={{ textAlign: 'start' }}>
        <Button
          style={{ width: '205px' }}
          className={classes.button}
          onClick={() => setPasswordModal(true)}
          variant={'contained'}>
          {t('changePassword')}
        </Button>
      </Box>
      <Modal open={passwordModal}>
        <Box className={styles.modal}>
          <form onSubmit={handleSubmitPassword}>
            <Box mt={2}>
              <FormControl
                fullWidth
                variant={'outlined'}
                error={errorsPassword['newPassword'] !== undefined}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  onChange={(event) => handleChangePassword('newPassword', event.target.value)}
                  name={'newPassword'}
                  label={t('newPassword')}
                  type={'password'}
                  id={'newPassword'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment color={'inherit'} position={'start'}>
                        <Lock color={'inherit'} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>{errorsPassword['newPassword']}</FormHelperText>
              </FormControl>
            </Box>
            <Box mt={2}>
              <FormControl
                fullWidth
                variant={'outlined'}
                error={errorsPassword['confirmNewPassword'] !== undefined}>
                <TextField
                  fullWidth
                  variant={'outlined'}
                  onChange={(event) =>
                    handleChangePassword('confirmNewPassword', event.target.value)
                  }
                  name={'confirmNewPassword'}
                  label={t('confirmNewPassword')}
                  type={'password'}
                  id={'confirmNewPassword'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment color={'inherit'} position={'start'}>
                        <Lock color={'inherit'} />
                      </InputAdornment>
                    ),
                  }}
                />
                <FormHelperText>{errorsPassword['confirmNewPassword']}</FormHelperText>
              </FormControl>
            </Box>
            <FormActions actions={actionsPassword} />
          </form>
        </Box>
      </Modal>
    </div>
  )
}
