import { Container } from '../../common/container/Container'

export type TranslationContainerConfig = {
    moduleFullUrl: string
}

export type TranslationProps = {
    parentContainer: Container
    config: TranslationContainerConfig
}

export const TRANSLATION_API_KEY = Symbol('TRANSLATION _API_KEY')
export const TRANSLATION_SERVICE_KEY = Symbol('TRANSLATION_SERVICE_KEY')
export const TRANSLATIONS_MODULE = Symbol('TRANSLATIONS_MODULE')
