import { withStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const LoginTextField = withStyles((theme: Theme) => ({
  root: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: "rgb(237, 236, 244)",
    "& .MuiFilledInput-underline:before": {
      borderBottom: 0,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: 0,
    },
    "& .MuiInputAdornment-root": {
      color: theme.palette.grey["600"],
    },
    "& .MuiFilledInput-root": {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  },
}))(TextField);
