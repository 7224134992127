import { v4 as uuidv4 } from 'uuid'
import { SampleMicroVEBioER } from "./SampleMicroVEBioER";


export interface SampleMicroVEBioERDTO {
    _id: string | undefined
    _typeSample: string
    _endometrialBiopsyMethod: number
    _size: number
    _biopsyDate: Date
    _biopsyTime: Date
    _biopsyNumber: number
    _hrt: boolean
    _pCycle: number
    _p4DateTime: Date
    _natural: boolean
    _lhCycle: number
    _lhDateTime: Date
    _hcgCycle: number
    _hcgInjection: Date
    _question1: boolean
    _typeAntibioticTreatment: string
    _treatmentDuration: string
    _endDate: Date
    _question2: boolean
    _reportDate: Date
    _result: string
    _followedTreatment: string
    _vaginosis: boolean
    _symptoms: string
    _idOdooType: string
    _question3: boolean
    _code: string
}

export function emptySampleMicroVEBioERDTO(): SampleMicroVEBioERDTO {
    return {
        _id: uuidv4(),
        _typeSample: '',
        _biopsyDate: new Date(),
        _biopsyNumber: 0,
        _biopsyTime: new Date(),
        _endDate: new Date(),
        _endometrialBiopsyMethod: 0,
        _followedTreatment: '',
        _hrt: false,
        _hcgCycle: 0,
        _hcgInjection: new Date(),
        _idOdooType: '',
        _lhCycle: 0,
        _lhDateTime: new Date(),
        _natural: false,
        _p4DateTime: new Date(),
        _pCycle: 0,
        _question1: false,
        _question2: false,
        _question3: false,
        _reportDate: new Date(),
        _result: '',
        _size: 0,
        _symptoms: '',
        _treatmentDuration: '',
        _typeAntibioticTreatment: '',
        _vaginosis: false,
        _code: ''
    }
}

export function fromModel(c: SampleMicroVEBioER): SampleMicroVEBioERDTO {
    return {
        _id: c.id,
        _typeSample: c.typeSample,
        _endometrialBiopsyMethod: c.endometrialBiopsyMethod,
        _size: c.size,
        _biopsyDate: c.biopsyDate,
        _biopsyTime: c.biopsyTime,
        _biopsyNumber: c.biopsyNumber,
        _hrt: c.hrt,
        _pCycle: c.pCycle,
        _p4DateTime: c.p4DateTime,
        _natural: c.natural,
        _lhCycle: c.lhCycle,
        _lhDateTime: c.lhDateTime,
        _hcgCycle: c.hcgCycle,
        _hcgInjection: c.hcgInjection,
        _question1: c.question1,
        _typeAntibioticTreatment: c.typeAntibioticTreatment,
        _treatmentDuration: c.treatmentDuration,
        _endDate: c.endDate,
        _question2: c.question2,
        _reportDate: c.reportDate,
        _result: c.result,
        _followedTreatment: c.followedTreatment,
        _vaginosis: c.vaginosis,
        _symptoms: c.symptoms,
        _idOdooType: c.idOdooType,
        _question3: c.question3,
        _code: c.code,
    }
}


export function toModel(c: SampleMicroVEBioERDTO): SampleMicroVEBioER {
    return new SampleMicroVEBioER(c)
}
