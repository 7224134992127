import { useStyles } from './Application.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'
import { horarioOptions } from 'common/enums/Enums'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import {
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from '@material-ui/core'
import { useState } from 'react'
import { Address } from 'modules/address/models/Address'

type ConsentProps = {
  handleModalClose: () => void
  handleCreateRecogida: (address: Address) => void
  pickup: any
}

export const ModalAddress = (props: ConsentProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t } = useTranslation()
  const [pickUp, setPickUp] = useState<any>(props.pickup)

  const handleOnChange = (e: any) => {
    let newPickUp = pickUp
    newPickUp = Object.assign({ ...newPickUp }, { [e.target.id]: e.target.value })
    setPickUp(newPickUp)
  }

  const handleChangeDate = (d: Date) => {
    let auxDate = d
    pickUp._date = auxDate
    const copiedObject = Object.assign({}, pickUp)
    copiedObject._date = auxDate
    setPickUp(copiedObject)
  }

  const handleChangeHour = (e: string) => {
    const selectedHour = parseInt(e, 10) 
    pickUp._hour = selectedHour
    const updatedPickUp = Object.assign({}, pickUp)
    updatedPickUp._hour = selectedHour
    setPickUp(updatedPickUp)
  }

  return (
    <>
      <Grid className={classes.modalConsentPickup2} container>
        <Grid
          xs={12}
          className={classes.title}
          style={{ textAlign: 'center', margin: '20px 0 20px 0' }}>
          {t('adressIndication')}
        </Grid>
        <Grid container spacing={4} style={{ padding: 40 }}>
          <Grid item xs={6}>
            <TextField
              id="_street"
              label={t('street')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._street}
              onChange={handleOnChange}
              inputProps={{ maxLength: 35 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="_number"
              label={t('streetNumber')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._number}
              onChange={handleOnChange}
              inputProps={{ maxLength: 25 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="_zipCode"
              label={t('postCode')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._zipCode}
              onChange={handleOnChange}
              inputProps={{ maxLength: 8 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="_city"
              label={t('city')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._city}
              onChange={handleOnChange}
              inputProps={{ maxLength: 30 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="_province"
              label={t('province')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._province}
              onChange={handleOnChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="_phone"
              label={t('phone')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._phone}
              onChange={handleOnChange}
              inputProps={{ maxLength: 15 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="_firstName"
              label={t('firstName')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._firstName}
              onChange={handleOnChange}
              inputProps={{ maxLength: 15 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="_lastName"
              label={t('lastName')}
              variant="filled"
              fullWidth
              className={classes.textField}
              value={pickUp._lastName}
              onChange={handleOnChange}
              inputProps={{ maxLength: 20 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="_date"
                style={{ width: '100%' }}
                autoOk
                inputVariant={'outlined'}
                format="dd/MM/yyyy"
                value={new Date(pickUp._date)}
                required={true}
                size={'small'}
                onChange={(date) => date && handleChangeDate(new Date(date))}
                label={t('pickUpDate')}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="horario-label">{t('Horario')}</InputLabel>
              <Select
                labelId="horario-label"
                id="_horario"
                fullWidth
                style={{ textAlign: 'left' }}
                value={(pickUp && pickUp._hour) || ''}
                onChange={(e) => handleChangeHour(e.target.value as string)}
                label={t('horario')}>
                {Object.entries(horarioOptions()).map(([key, value]) => (
                  <MenuItem value={key}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined">
              <TextField
                  id="_numBultos"
                  onChange={handleOnChange}
                  label={t('numBultos')}
                  type="number"
                  value={(pickUp && pickUp._numBultos) || ''}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-around', margin: '0 0 20px 0' }}>
          <Button
            type={'button'}
            onClick={props.handleModalClose}
            className={classes.buttonSecondary}>
            {t('cancel')}
          </Button>
          <Button className={classes.button} type={'button'} onClick={()=> props.handleCreateRecogida(pickUp)}>
            {t('send')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
