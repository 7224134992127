import { OptionValue } from "./form-generation/types";

export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName];
}

export function getOption<T, K extends keyof T>(
  o: T,
  propertyName: K
): OptionValue {
  const property = getProperty(o, propertyName);
  return (property && property + "") || "";
}
