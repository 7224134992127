import React, { useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { HelpTable } from '../../features/help'
import { Title } from '../../components/common/Title'
import { useParams } from "@reach/router";
import { getAuthContainer } from "../../container/auth-modules";
import { AuthService } from "../../modules/auth/services/AuthService";
import { AUTH_SERVICE_KEY } from "../../modules/auth";

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)


export const Table = (props: RouteProps) => {
    const loggedUser = authService.get()

    return (
        <>
            <Title color={props.color} title={props.title || ''} />
            <HelpTable loggedUser={loggedUser} />

        </>
    )
}
