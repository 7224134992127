export interface ItemList<T> {
  items: T[]
  count: number
}

export function emptyList<T>(): ItemList<T> {
  return {
    items: [],
    count: 0,
  }
}
