import { Container } from "../../common/container/Container";

export type TestContainerConfig = {
    moduleFullUrl: string;
};

export type TestProps = {
    parentContainer: Container;
    config: TestContainerConfig;
};

export const TEST_API_KEY = Symbol("TEST_API_KEY");
export const TEST_SERVICE_KEY = Symbol("TEST_SERVICE_KEY");
export const TESTS_MODULE = Symbol("TESTS_MODULE");
