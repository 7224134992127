import {RouteProps} from "../../routes/AppRouter";
import { Title } from '../../components/common/Title'
import {FormFaq} from '../../features/help/Form'
import {useEffect, useState} from "react";
import {useParams} from "@reach/router";
import {getFaqContainer} from "../../container/faq-modules";
import {FaqService} from "../../modules/faq/services/FaqService";
import {FAQ_SERVICE_KEY} from "../../modules/faq";
import {Faq} from "../../modules/faq/models/Faq";



const faqService = getFaqContainer().get<FaqService>(FAQ_SERVICE_KEY)

export const Form =(props: RouteProps) => {
    const params = useParams()
    const [faq, setFaq] = useState<Faq>()

    useEffect(()=> {
        if (params.id) {
            faqService.getByID(params.id).subscribe((res) => {
                setFaq(res)
            })
        }
        return
    },[params.id])
    return (
        <>
            <Title color={props.color} title={props.title || ''} />
            <FormFaq faq={faq}/>
        </>
    )
}