import { Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_HOME } from '../../routes/color-constants'
import { RouteProps } from '../../routes/AppRouter'
import React, { useEffect, useState } from 'react'
import { Actions, Field, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import { AppTable } from '../../components/table'
import { SortParam } from '../../common/api/Query'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { Permission } from '../../common/enums/Permissions'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { getClient2RequestContainer } from "../../container/client2request-modules";
import { Client2RequestService } from "../../modules/client2Requests/services/Client2RequestService";
import { CLIENT2REQUEST_SERVICE_KEY } from "../../modules/client2Requests";
import { Client2Request, Client2RequestQuery } from "../../modules/client2Requests/models/Client2Request";
import { getRequestContainer } from 'container/request-modules'
import { RequestService } from 'modules/request/services/RequestService'
import { REQUEST_SERVICE_KEY } from 'modules/request'

type DetailProps = {
    id?: string
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const client2RequestService = getClient2RequestContainer().get<Client2RequestService>(CLIENT2REQUEST_SERVICE_KEY)
const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)

export const ClientApplication = (props: DetailProps) => {
    return (
        <Container>
            <PatientTests {...props} />
        </Container>
    )
}

export const PatientTests = (props: RouteProps) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [pager, setPager] = useState<Pager>()
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const [items, setItems] = useState<Client2Request[]>([])
    const [allItems, setAllItems] = useState<Client2Request[]>([])
    const [sort, setSort] = useState<SortParam<Client2Request>>({
        field: 'createdAt',
        desc: true,
    })
    const [searcher, setSearcher] = useState<SearchValue<Client2RequestQuery>[]>([
        {
            name: 'patient',
            label: t('search') + ' ' + t('byPatient'),
        },
    ])

    useEffect(() => {
        client2RequestService.getByClientID(props.id || '').subscribe((res) => {
            setAllItems(res.items.sort(function (a, b) { return new Date(a.request.date).getTime() - new Date(b.request.date).getTime() }))
        })
    }, [])

    useEffect(() => {
        if (!isLoading) {
            return
        }
        client2RequestService.getByClientID(props.id || '').subscribe((res) => {
            setIsLoading(false)
            setItems(res.items.splice(page * itemsPerPage, (page * itemsPerPage + itemsPerPage)))
            setCount(res.count)
        })

    }, [isLoading])

    useEffect(() => {
        setIsLoading(true)

        if (itemsPerPage > count) {
            setPage(0)
        }

        setPager({
            page,
            count,
            handleChangePage: handlePaginationChange,
            rowsPerPage: itemsPerPage,
            handleChangeRowsPerPage,
        })
    }, [page, count, itemsPerPage])

    const handlePaginationChange = (event: unknown, value: number) => setPage(value)

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number.isNaN(event.target.value)) {
            setItemsPerPage(10)
            return
        }
        setItemsPerPage(Number.parseInt(event.target.value))
    }

    const fields: Field<any>[] = [
        {
            sortable: false,
            label: "ID",
            name: "request",
            renderFunc: (field, item) => item.request.id,
        },
        {
            sortable: true,
            label: t('date'),
            //@ts-ignore
            name: "date",
            renderFunc: (field, item) => new Date(item.request.date).toLocaleString().substring(0, new Date(item.request.date).toLocaleString().length - 3) + ' h',
        },
        {
            sortable: false,
            label: t("codeSample"),
            name: "request",
            renderFunc: (field, item) => t('leftData')
        },
        {
            sortable: true,
            label: t("state"),
            //@ts-ignore
            name: "state",
            renderFunc: (field, item) => t(item.request.status)
        },
        {
            searchable: true,
            sortable: false,
            label: t('bioarrayCode'),
            //@ts-ignore
            name: 'bioarrayCode',
            renderFunc: (field, item) => item.request.bioarrayCode,
        },
    ]

    const downloadPDF = (r: Client2Request) => {
        return true
    }

    const showDownloadPDF = (r: Client2Request) => {
        return true
    }

    const deleteTest = (r: Client2Request) => {
        requestService.delete(r.request.id).subscribe(()=> {
            setIsLoading(true)
        })
    }

    const showDeleteButton = () => !authService.userCan(Permission.deletePatientTests)

    const actions: Actions<Client2Request> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: downloadPDF,
                hidden: showDownloadPDF,
                icon: downloadIcon,
                label: t('Download'),
            },
            {
                handler: deleteTest,
                hidden: showDeleteButton,
                icon: deleteIcon,
                label: t('Delete'),
            },
        ],
    }

    const sortable: Sort<Client2Request> = {
        name: sort.field,
        direction: sort.desc ? 'desc' : 'asc',
        handleSort: (field) => {

            if (String(field) == 'date') {
                setItems(items.sort(function (a, b) {
                    if (sort.desc) {
                        return new Date(a.request.date).getTime() - new Date(b.request.date).getTime()
                    } else {
                        return new Date(b.request.date).getTime() - new Date(a.request.date).getTime()
                    }
                }))
            } else if (String(field) == 'patient') {
                setItems(items.sort(function (a, b) {
                    if (a.patient.firstName < b.patient.firstName) {
                        if (sort.desc) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                    if (a.patient.firstName > b.patient.firstName) {
                        if (sort.desc) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    return 0;
                }))
            } else if (String(field) == 'state') {
                setItems(items.sort(function (a, b) {
                    if (t(a.request.status) < t(b.request.status)) {
                        if (sort.desc) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                    if (t(a.request.status) > t(b.request.status)) {
                        if (sort.desc) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                    return 0;
                }))
            }

            setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
        },
    }

    const search: Search<Client2RequestQuery> = {
        searchValues: searcher,
        handleSearch: (svs: SearchValue<Client2RequestQuery>[]) => {
            const result: SearchValue<Client2RequestQuery>[] = []
            svs.forEach((s) => {
                if (s.type === 'date' && s.value) {
                    result.push(Object.assign({ ...s }, { value: new Date(s.value as string).toISOString() }))
                } else {
                    result.push(s)
                }
            })

            if (result[0].value == "") {
                setItems(allItems)
            } else {
                setItems(allItems.filter((i) => (i.patient.firstName + ' ' + i.patient.lastName).toUpperCase().includes(result[0].value?.toUpperCase() || '')))
            }
            setSearcher(result)
        },
    }

    return (
        <>
        {!isLoading && (
            <AppTable
                styleHeader={{ color: COLOR_HOME }}
                actions={actions}
                fields={fields}
                items={items}
                rowKeyField={'request'}
                pager={pager}
                sort={sortable}
                search={search}
            />
        )}
        </>
    )
}
