import { Container } from '../../common/container/Container'

export type RequestContainerConfig = {
  moduleFullUrl: string
}

export type RequestProps = {
  parentContainer: Container
  config: RequestContainerConfig
}

export const REQUEST_API_KEY = Symbol('REQUEST_API_KEY')
export const REQUEST_SERVICE_KEY = Symbol('REQUEST_SERVICE_KEY')
export const REQUEST_MODULE = Symbol('REQUEST_MODULE')
