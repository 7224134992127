import { v4 as uuidv4 } from 'uuid'
import { Clinic } from './Clinic'

export interface ClinicDTO {
  id: string | undefined
  idClinic: string | undefined
  name: string
  address: string
  cp: string
  locality: string
  cif: string
  odooNumber: string | undefined
  email: string
  phone: string
  testType: string
  patients: string[]
  entryDate: Date
  disabled: boolean
}

export function emptyClinicDTO(): ClinicDTO {
  return {
    id: uuidv4(),
    idClinic: '',
    name: '',
    address: '',
    cp: '',
    locality: '',
    cif: '',
    odooNumber: '',
    email: '',
    phone: '',
    testType: '',
    patients: [],
    entryDate: new Date(),
    disabled: false
  }
}

export function fromModel(c: Clinic): ClinicDTO {
  return {
    id: c.id,
    idClinic: c.idClinic,
    name: c.name,
    address: c.address,
    cp: c.cp,
    locality: c.locality,
    cif: c.cif,
    odooNumber: c.odooNumber,
    email: c.email,
    phone: c.phone,
    testType: c.testType,
    patients: c.patients,
    entryDate: c.entryDate,
    disabled: c.disabled
  }
}

export function toModel(c: ClinicDTO): Clinic {
  return new Clinic(c)
}
