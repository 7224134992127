import i18n from '../../../i18n/i18n'

export enum Language {
  Spanish = 'es',
  English = 'en',
}

export const optionLanguage = (): Record<Language, string> => ({
  [Language.Spanish]: i18n.t(Language.Spanish),
  [Language.English]: i18n.t(Language.English),
})
