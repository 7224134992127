import { MenuItem } from './types'
import {
  URL_CLIENTS,
  URL_HELP,
  URL_APPLICATIONS,
  URL_HOME,
  URL_PATIENTS,
  URL_RESULTS
} from '../../routes/routes-constants'
import {
  TITLE_CLIENTS,
  TITLE_HELP,
  TITLE_APPLICATIONS,
  TITLE_HOME,
  TITLE_RESULTS,
  TITLE_PATIENTS,
} from '../../routes/title-constants'
import { COLOR_MENU_PRIMARY } from '../../routes/color-constants'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'
import home from '../../assets/Iconos_muestras/inicio-inact.svg'
import client from '../../assets/Iconos_muestras/clientes-inac.svg'
import solicitud from '../../assets/Iconos_muestras/solicitudes-inac.svg'
import ayuda from '../../assets/Iconos_muestras/ayuda-inac.svg'
import results from '../../assets/Iconos_muestras/results.svg'

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export const sideBarMenuItems = (): MenuItem[] => {
  return [
    {
      name: TITLE_HOME,
      path: URL_HOME,
      image: home,
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewClinics),
    },
    {
      name: TITLE_CLIENTS,
      path: URL_CLIENTS,
      image: client,
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewClients),
    },
    {
      name: TITLE_APPLICATIONS,
      path: URL_APPLICATIONS,
      image: solicitud,
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.createRequest),
    },
    {
      name: TITLE_PATIENTS,
      path: URL_PATIENTS,
      image: client,
      color: COLOR_MENU_PRIMARY,
      userAccess: true,
    },
    {
      name: TITLE_RESULTS,
      path: URL_RESULTS,
      image: results,
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.seeResults),
    },
    {
      name: TITLE_HELP,
      path: URL_HELP,
      image: ayuda,
      color: COLOR_MENU_PRIMARY,
      userAccess: authService.userCan(Permission.viewFaqs),
    },
  ]
}
