export type SampleCitogeneticaProps = {
    _id: string | undefined
    _typeSample: string
    _extractionDate: Date
    _code: string
    _idOdoo: string

}

export class SampleCitogenetica {
    private readonly _id: string | undefined
    _typeSample: string
    _extractionDate: Date
    _code: string
    _idOdoo: string

    constructor(p: SampleCitogeneticaProps) {
        this._id = p._id
        this._typeSample = p._typeSample
        this._extractionDate = p._extractionDate
        this._code = p._code
        this._idOdoo = p._idOdoo
    }

    get id(): string | undefined {
        return this._id
    }

    get typeSample(): string  {
        return this._typeSample
    }

    set typeSample(value: string ) {
        this._typeSample = value
    }

    get extractionDate(): Date {
        return this._extractionDate
    }

    set extractionDate(value: Date) {
        this._extractionDate = value
    }

    get code(): string  {
        return this._code
    }

    set code(value: string ) {
        this._code = value
    }

    get idOdoo(): string  {
        return this._idOdoo
    }

    set idOdoo(value: string ) {
        this._idOdoo = value
    }

}
