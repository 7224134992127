import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useStyles } from './Application.styles'
import { useEffect, useState } from 'react'
import { getClient2RequestContainer } from "../../container/client2request-modules";
import { Client2RequestService } from "../../modules/client2Requests/services/Client2RequestService";
import { CLIENT2REQUEST_SERVICE_KEY } from "../../modules/client2Requests";
import { PatientProduct } from "../../modules/patient/models/relationship/PatientProduct";

type DetailProps = {
    id?: string
}

const client2RequestService = getClient2RequestContainer().get<Client2RequestService>(CLIENT2REQUEST_SERVICE_KEY)

export const Detail = (props: DetailProps) => {
    const classes = useStyles({ color: COLOR_PRIMARY })
    const { t } = useTranslation()
    const handleSubmit = () => {
    }
    const [patientProducts, setPatientProducts] = useState<PatientProduct[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        client2RequestService.getWizardByID(props.id || "").subscribe((res) => {
            res && setPatientProducts(res)
            setIsLoading(false)
        })
    }, [isLoading])

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "left" }}>

                <Typography className={classes.title} variant={'h4'} align="left">
                    {"ID " + t("Application")}
                </Typography>
            </div>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            id="product"
                            label={t('product')}
                            variant="standard"
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="name"
                            label={t('name')}
                            variant="standard"
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="lastName"
                            label={t('lastName')}
                            variant="standard"
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="phohe"
                            label={t('phone')}
                            variant="standard"
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="email"
                            label={t('email')}
                            variant="standard"
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="clinicHistory"
                            label={t("clinicHistory")}
                            variant="standard"
                            fullWidth
                            multiline
                            rows={4}
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="address"
                            label={t("address")}
                            variant="standard"
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="pickUpTime"
                            label={t("pickUpTime")}
                            variant="standard"
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="documentation"
                            label={t("documentation")}
                            variant="standard"
                            fullWidth
                            multiline
                            rows={4}
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={3} alignItems={"flex-start"}>
                        <Button type={"button"} className={classes.buttonSecondary}>
                            {t("selectMorFiles")}
                        </Button>
                    </Grid>
                </Grid>
            </form>

        </div>
    )
}