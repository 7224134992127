export const svgToPng = (svg: Node, width: number, height: number) => {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, width, height);

            let xml = new XMLSerializer().serializeToString(svg);
            let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
            let img = new Image(width, height);

            img.onload = () => {
                ctx && ctx.drawImage(img, 0, 0);
                let imageData = canvas.toDataURL('image/png', 1.0);
                resolve(imageData)
            }

            img.onerror = (e) => {
                console.log("error: ", e)
                reject()
            };
            img.src = dataUrl;
        }
    });
};