import { Container, ContainerItem } from '../common/container/Container'
import { HTTP_CLIENT_KEY, HTTPClient } from '../common/api/HTTPClient'
import { StatusService } from '../common/status/StatusService'

export const STATUS_SERVICE_KEY = Symbol('STATUS_SERVICE_KEY')
let container: Container

function init() {
  const items = new Map<symbol, ContainerItem>()

  items.set(STATUS_SERVICE_KEY, new ContainerItem({ instance: new StatusService() }))
  items.set(HTTP_CLIENT_KEY, new ContainerItem({ instance: new HTTPClient() }))

  container = new Container(items, undefined, {})
}

export function getAppContainer(): Container {
  if (!container) {
    init()
  }
  return container
}
