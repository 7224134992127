import { Container } from '../../common/container/Container'

export type SampleTypeContainerConfig = {
  moduleFullUrl: string
}

export type SampleTypeProps = {
  parentContainer: Container
  config: SampleTypeContainerConfig
}

export const SAMPLETYPE_API_KEY = Symbol('SAMPLETYPE_API_KEY')
export const SAMPLETYPE_SERVICE_KEY = Symbol('SAMPLETYPE_SERVICE_KEY')
export const SAMPLETYPE_MODULE = Symbol('SAMPLETYPE_MODULE')
