import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@material-ui/core'
import { useRef, useState } from 'react'
import { useStyles } from "../../features/application/Application.styles";
import { COLOR_PRIMARY } from 'routes/color-constants'
import { File } from '../../modules/files/models/File'
import { LoadingSpinner } from "../../components/loading-spinner/LoadingSpinner";
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { useSnackbar } from 'notistack'
import { getStatisticsContainer } from 'container/statistics-modules'
import { StatisticsService } from 'modules/statistics/services/StatisticsService'
import { STATISTICS_SERVICE_KEY } from 'modules/statistics'

const statisticsService = getStatisticsContainer().get<StatisticsService>(STATISTICS_SERVICE_KEY)

export const Statistics = () => {
    const { t } = useTranslation()
    const [file, setFile] = useState<File[]>([])
    const classes = useStyles({ color: COLOR_PRIMARY })
    const inputFile = useRef<HTMLInputElement | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar()

    const handleDelete = (i: number) => {
        let arr = new Array()
        file.map((element, index) => {
            if (i != index) {
                arr.push(element)
            }
        })
        setFile(arr)
    }

    const getBase64 = (file: any) => {
        return new Promise(resolve => {
            let baseURL = ""
            let reader = new FileReader()
            // Convert the file to base64 text
            reader.readAsDataURL(file)
            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result as string;
                resolve(baseURL);
            }
        })
    }

    const handleFile = () => {
        inputFile.current?.click()
    }

    const handleSave = (e: any) => {
        let filesData: string[] = []

        Array.from(e.target.files).forEach(async (file: any, i: number) => {
            let data: any = await getBase64(file)
            filesData.push(data.substring(data.indexOf(",") + 1, data.length))

            if (i == e.target.files.length - 1) {
                statisticsService.uploadFile(filesData).subscribe((res) => {
                    if (res) {
                        enqueueSnackbar(res, { variant: "warning" })
                    } else {
                        enqueueSnackbar(t("correctlyUpdatedFile"), { variant: "success" })
                    }
                })
            }
        })
    }

    return (
        <>
            {!isLoading ? (
                <Grid container spacing={4} style={{ marginTop: '1%' }}>
                    <Grid item xs={8} style={{ textAlign: "left" }}>
                        <div style={{ margin: "0 0 0 10px " }}>{t('addStatisticsReport')}</div>
                        {file.map((elementF: File, indexF: number) => {
                            return (
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1.5%" }} key={elementF.id}>
                                    <div key={indexF.toString()} className={classes.titleProduct}>{elementF.name}</div>
                                    <Button className={classes.button} type={"button"}
                                        onClick={() => handleDelete(indexF)}>{t("delete")}</Button>
                                </div>

                            )
                        })}
                        <div style={{ marginTop: "5%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <input type='file' id='file' multiple={true} ref={inputFile} onChange={handleSave}
                                accept={".xls,.xlsx,.ods"}
                                style={{ display: 'none' }} />
                            <Button className={classes.buttonSecondary} onClick={handleFile} type={"button"}>
                                {t('selectFile')}
                            </Button>
                            {file.length > 0 && (
                                <Button className={classes.button} type={"button"}>
                                    {t('send')}
                                </Button>)}
                        </div>
                    </Grid>
                </Grid>) :
                (<LoadingSpinner className={style.loadingSpinner} />)
            }
        </>
    )
}