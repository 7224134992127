import { useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { useStyles } from './Application.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import { Product } from '../../modules/product/models/Product'

type GeneProps = {
  data?: Product[]
  product: Product
  handleModalClose: () => void
  handleModalSave: (genes: string[]) => void
}

export const ModalGenes = (props: GeneProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t } = useTranslation()
  //@ts-ignore
  const initialGenes = props?.product?._genes?.split(',')
  //@ts-ignore
  const [genes, setGenes] = useState<any[]>(props?.product?._genes?.split(','))
  const [isAllGenes, setIsAllGenes] = useState<boolean>()

  const handleGen = (element: string) => {
    setIsAllGenes(false)
    if (genes.includes(element)) {
      let auxGenes = [...genes]
      let index = auxGenes.indexOf(element)
      auxGenes.splice(index, 1)
      setGenes(auxGenes)
    } else {
      let auxGenes = [...genes]
      auxGenes.push(element)
      setGenes(auxGenes)
    }
  }

  const handelSave = () => {
    props.handleModalSave(genes)
  }

  const handleAll = () => {
    if (genes.length > 0) {
      setIsAllGenes(false)
      setGenes([])
    } else {
      setGenes(initialGenes)
    }
  }

  const button = (element: any, index: number) => {
    return (
      <Button
        type={'button'}
        onClick={() => {
          handleGen(element)
        }}
        className={classes.buttonModal}
        style={
          !genes.includes(element) ? { backgroundColor: 'grey' } : { backgroundColor: '#00b8e2' }
        }>
        {element}
      </Button>
    )
  }

  return (
    <>
      <Grid className={classes.modal} container>
        <Grid item xs={12} className={classes.modalBackground}>
          <Typography
            variant={'h4'}
            align="left"
            className={classes.titleModal}
            style={{ marginLeft: '2rem' }}>
            {/**@ts-ignore */}
            {props.product._name}
          </Typography>
          <div style={{ marginLeft: '2rem', fontWeight: 'bold' }}>{t('availableGen')}</div>
        </Grid>
        <Grid item xs={12} style={{ margin: '2rem 2rem 2rem 2rem' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className={classes.titleNamePrimary}
              style={{ fontSize: 20, marginBottom: '20px', alignSelf: 'center' }}>
              {t('select1')}
            </div>
            <Button
              type={'button'}
              onClick={handleAll}
              className={genes.length > 0 ? classes.buttonSecondary : classes.button}
              style={{ marginBottom: 20, marginLeft: '5%', alignSelf: 'center' }}>
              {genes.length > 0 ? t('deleteSelection') : t('selectAll')}
            </Button>
          </div>
          <div
            className={classes.bodyModal}
            style={{ height: '45vh', backgroundColor: '#e3e3e3', overflowY: 'scroll' }}>
            {initialGenes?.map((element, index) => {
              return (
                <Grid item xs={2} key={element}>
                  {button(element, index)}
                </Grid>
              )
            })}
          </div>
        </Grid>
        <Grid item xs={12} className={classes.buttonsModal}>
          <Button type={'button'} onClick={handelSave} className={classes.button}>
            OK
          </Button>
          <Button
            type={'button'}
            onClick={props.handleModalClose}
            className={classes.buttonSecondary}>
            {t('cancel')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
