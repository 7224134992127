import { useState, useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { NewApp } from '../../features/application'
import { Title } from 'components/common/Title'

export function NewApplication(props: RouteProps) {
    const [saveDraft, setSaveDraft] = useState<boolean>(false)

    const handleDraft = () => {
        setSaveDraft(true)
    }

    const newApplication = () => {
        return (
            <NewApp id={props.id || ''} saveDraft={saveDraft} setSaveDraft={setSaveDraft} />
        )
    }

    useEffect(() => {
        newApplication()
    }, [saveDraft])

    return (
        <>
            <Title color={"black"} title={props.title || ''} saveDraft={handleDraft} />
            {newApplication()}
        </>
    )
}