import { useState, useEffect, useRef } from 'react'
import {
    Box,
    createStyles,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { FeedbackDataDTO, emptyFeedbackDataDTO } from "../../modules/feedbackData/models/FeedbackDataDTO";
import { getAuthContainer } from 'container/auth-modules'
import { AuthService } from 'modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from 'modules/auth'
import { Query } from 'common/api/Query'
import { Button } from '@material-ui/core'
import { useStyles } from "../../features/application/Application.styles";
import { COLOR_PRIMARY } from 'routes/color-constants'
import { getFeedbackDataContainer } from 'container/feedbackdata-module';
import { FeedbackDataService } from 'modules/feedbackData/services/FeedbackDataService';
import { FEEDBACKDATA_SERVICE_KEY } from 'modules/feedbackData';
import {getClient2RequestContainer} from "../../container/client2request-modules";
import {Client2RequestService} from "../../modules/client2Requests/services/Client2RequestService";
import {CLIENT2REQUEST_SERVICE_KEY} from "../../modules/client2Requests";

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const feedbackService = getFeedbackDataContainer().get<FeedbackDataService>(FEEDBACKDATA_SERVICE_KEY)
const cliet2RequestService = getClient2RequestContainer().get<Client2RequestService>(CLIENT2REQUEST_SERVICE_KEY)

export const Feedback = () => {
    const { t } = useTranslation()
    const [statisticsData, setStatisticsData] = useState<FeedbackDataDTO[]>([])
    const classes = useStyles({ color: COLOR_PRIMARY })
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [previouslyAdded, setPreviouslyAdded] = useState<FeedbackDataDTO[]>([])
    let itemsRef = useRef(null)
    const [codeSamples, setCodeSamples] = useState<any[]>([])

    useEffect(() => {
        if (!isLoading) {
            return;
        }

        cliet2RequestService.getCodeSamples(authService.get()?.id).subscribe((res) => {
            if (res && res.items) {
                setCodeSamples(res.items);
            } else {
                console.error("res o res.items es undefined");
            }
        });

        feedbackService.getFilteredList(new Query({
            query: [
                {
                    name: 'userID',
                    value: authService.get()?.id
                },
            ],
        })).subscribe((res) => {
            setPreviouslyAdded(res.items)
            //@ts-ignore
            itemsRef.current?.scrollIntoView({ behavior: "auto" })

            setIsLoading(false)
        })
    }, [isLoading])



    const useClass = makeStyles((theme: Theme) =>
        createStyles({
            modal: {
                position: 'absolute',
                width: 600,
                backgroundColor: theme.palette.background.paper,
                borderRadius: '4px',
                boxShadow: theme.shadows[5],
                padding: theme.spacing(2, 4, 0),
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            },
            boxPlus: {
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
            },
            plusIcon: {
                color: "#00b8e2",
                alignSelf: "flex-end",
                cursor: "pointer"
            },
            statisticsRow: {
                display: "flex",
                alignItems: "center",
                gap: "1em",
            },
            boxMinus: {
                color: "#00b8e2",
                cursor: "pointer"
            },
            borderStyle: {
                borderColor: "#e8e8ea",
                borderWidth: "10px",
                borderStyle: "solid",
                borderRadius: "10px",
                padding: "1.5em",
                maxHeight: "850px",
                overflowY: "scroll"
            }
        }),
    )

    const formClass = useClass()

    const handleAddNewStatistics = () => {
        let auxSt = [...statisticsData]
        auxSt.push(emptyFeedbackDataDTO(authService.get()?.id || ""))
        setStatisticsData(auxSt)
    }

    const handleRemoveStatisticsRow = (rowIndex: number) => {
        let auxSt = [...statisticsData]
        auxSt = auxSt.filter((_, index) => index !== rowIndex)
        setStatisticsData(auxSt)
    }

    const handleChangeStatistics = (name: string, value: any, index: number) => {
        let auxSt = [...statisticsData]

        switch (name) {
            case 'requestCodeSample':
                auxSt[index].requestCodeSample = value
                break
            case 'nEmbTransfer':
                auxSt[index].nEmbTransfer = value
                break;
            case 'idEmbTransfer':
                auxSt[index].idEmbTransfer = value
                break;
            case 'fullTerm':
                auxSt[index].fullTerm = value == t("yes") ? true : false
                break;
        }

        setStatisticsData(auxSt)
    }

    const handleSend = () => {
        feedbackService.add(statisticsData.filter((st) => st.requestCodeSample != "")).subscribe((res) => {
            setIsLoading(true)
            setStatisticsData([])
        })
    }

    const handleDelete = (i: number) => {
        feedbackService.delete(previouslyAdded[i].id || "").subscribe((res) => {
            setIsLoading(true)
        })
    }

    useEffect(() => {
        if (statisticsData.length > 0) {
            let el = document.getElementById("scrollDiv")
            el?.scrollTo(0, el?.offsetHeight)
        }
    }, [statisticsData])

    return (
        <div className={formClass.borderStyle} id="scrollDiv">
            {previouslyAdded.map((item, i) => (
                <div ref={itemsRef} className={formClass.statisticsRow} style={{ marginTop: i === 0 ? "1%" : "5%", marginBottom: i === (previouslyAdded.length - 1) ? "3%" : "-1.5%" }}>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id='inherit-label'>{t('codeSample')}</InputLabel>
                                <Select
                                    labelId='requestCodeSample'
                                    id='requestCodeSample'
                                    name={'requestCodeSample'}
                                    fullWidth
                                    style={{ textAlign: 'left' }}
                                    disabled={true}
                                    value={item.requestCodeSample}
                                    onChange={(event) => handleChangeStatistics("requestCodeSample", event.target.value, i)}
                                    label={t('requestID')}>
                                    {codeSamples.map((codeSample) => (
                                            <MenuItem value={codeSample}>{codeSample}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                name={'nEmbTransfer'}
                                id={'nEmbTransfer'}
                                type={'number'}
                                onChange={(event) => handleChangeStatistics(event.target.name, Number(event.target.value), i)}
                                value={item.nEmbTransfer}
                                label={t('nEmbryosTransfer')}
                                InputProps={{ inputProps: { min: 0 }, readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                name={'idEmbTransfer'}
                                id={'idEmbTransfer'}
                                type={'string'}
                                onChange={(event) => handleChangeStatistics(event.target.name, event.target.value, i)}
                                value={item.idEmbTransfer}
                                label={t('idEmbryosTransfer')}
                                InputProps={{ readOnly: true, }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id='inherit-label'>{t('fullTerm')}</InputLabel>
                                <Select
                                    labelId='fullTerm'
                                    id='fullTerm'
                                    name={'fullTerm'}
                                    fullWidth
                                    style={{ textAlign: 'left' }}
                                    value={item.fullTerm ? t("yes") : t("no")}
                                    onChange={(event) => handleChangeStatistics("fullTerm", event.target.value, i)}
                                    disabled={true}
                                    label={t('fullTerm')}>
                                    <MenuItem value={t("yes")}>{t("yes")}</MenuItem>
                                    <MenuItem value={t("no")}>{t("no")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <IndeterminateCheckBoxIcon className={formClass.boxMinus} style={{ fontSize: "37px" }} onClick={() => handleDelete(i)} />
                </div>
            ))}
            {statisticsData.map((item, i) => (
                <div className={formClass.statisticsRow} style={{ marginTop: i === 0 ? "1%" : "5%", marginBottom: i === (statisticsData.length - 1) ? "2%" : "-1.5%" }}>
                    <Grid container spacing={5}>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id='inherit-label'>{t('codeSample')}</InputLabel>
                                <Select
                                    labelId='requestCodeSample'
                                    id='requestCodeSample'
                                    name={'requestCodeSample'}
                                    fullWidth
                                    style={{ textAlign: 'left' }}
                                    value={item.requestCodeSample}
                                    onChange={(event) => handleChangeStatistics("requestCodeSample", event.target.value, i)}
                                    label={t('requestID')}>
                                    {codeSamples.map((codeSample) => (
                                        <MenuItem value={codeSample}>{codeSample}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                name={'nEmbTransfer'}
                                id={'nEmbTransfer'}
                                type={'number'}
                                onChange={(event) => handleChangeStatistics(event.target.name, Number(event.target.value), i)}
                                value={item.nEmbTransfer}
                                label={t('nEmbryosTransfer')}
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                variant={'outlined'}
                                name={'idEmbTransfer'}
                                id={'idEmbTransfer'}
                                type={'string'}
                                onChange={(event) => handleChangeStatistics(event.target.name, event.target.value, i)}
                                value={item.idEmbTransfer}
                                label={t('idEmbryosTransfer')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id='inherit-label'>{t('fullTerm')}</InputLabel>
                                <Select
                                    labelId='fullTerm'
                                    id='fullTerm'
                                    name={'fullTerm'}
                                    fullWidth
                                    style={{ textAlign: 'left' }}
                                    value={item.fullTerm ? t("yes") : t("no")}
                                    onChange={(event) => handleChangeStatistics("fullTerm", event.target.value, i)}
                                    label={t('fullTerm')}>
                                    <MenuItem value={t("yes")}>{t("yes")}</MenuItem>
                                    <MenuItem value={t("no")}>{t("no")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <IndeterminateCheckBoxIcon className={formClass.boxMinus} style={{ fontSize: "37px" }} onClick={() => handleRemoveStatisticsRow(i)} />
                </div>
            ))}
            <Box className={formClass.boxPlus}>
                {
                    statisticsData.length === 0 &&
                    <Box style={{ marginRight: "1em" }}>{t('addNewFeedback')}</Box>
                }
                <AddBoxIcon className={formClass.plusIcon} style={{ fontSize: "37px" }} onClick={handleAddNewStatistics} />
            </Box>
            <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "5%" }}>
                <Button className={classes.button} onClick={handleSend} type={"button"}>
                    {t('send')}
                </Button>
            </Box>
        </div>
    )
}