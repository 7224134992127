import { Container, ContainerItem } from '../common/container/Container'
import { FeedbackDataProps } from '../modules/feedbackData/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { FEEDBACKDATA_MODULE, FEEDBACKDATA_SERVICE_KEY, FEEDBACKDATA_API_KEY } from '../modules/feedbackData'
import { FeedbackDataService } from '../modules/feedbackData/services/FeedbackDataService'
import { FeedbackDataApi } from '../modules/feedbackData/api/FeedbackDataApi'

let container: Container

function init(p: FeedbackDataProps) {
    const items = new Map<symbol, ContainerItem>()

    items.set(FEEDBACKDATA_API_KEY, new ContainerItem({ instance: new FeedbackDataApi() }))
    items.set(
        FEEDBACKDATA_SERVICE_KEY,
        new ContainerItem({
            instance: new FeedbackDataService({ apiKey: FEEDBACKDATA_API_KEY }),
        })
    )

    container = new Container(items, p.parentContainer, p.config)
}

export function getFeedbackDataContainer(): Container {
    if (!container) {
        init({
            parentContainer: getAppContainer(),
            config: {
                moduleFullUrl: moduleURLs[FEEDBACKDATA_MODULE],
            },
        })
    }

    return container
}
