import { v4 as uuidv4 } from 'uuid'
import { Faq } from './Faq'

export interface FaqDTO {
  id: string
  name: string
  creatorID: string
  question: string
  answer: string
  language: string
}

export function emptyFaqDTO(): FaqDTO {
  return {
    id: uuidv4(),
    name: '',
    creatorID: '',
    question: '',
    answer: '',
    language: ''
  }
}

export function fromModel(c: Faq): FaqDTO {
  return {
    id: c.id,
    name: c.name,
    creatorID: c.creatorID,
    question: c.question,
    answer: c.answer,
    language: c.language
  }
}

export function toModel(c: FaqDTO): Faq {
  return new Faq(c)
}
