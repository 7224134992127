import { v4 as uuidv4 } from 'uuid'
import {Client, ClientRegister} from './Client'

export interface ClientDTO {
  id: string | undefined
  taxName: string
  taxIdentity: string
  street: string
  number: string
  zipCode: string
  city: string
  province: string
  country: string
  idOdoo: string
  referencedClientID: string | null
  cosnotifications: boolean
}

export interface ClientRegisterDTO {
  id: string
  taxName: string
  taxIdentity: string
  street: string
  number: string
  zipCode: string
  city: string
  province: string
  country: string
  password: string
  validatePassword: string
  referencedClientID: string | null
  cosnotifications: boolean
}

export function emptyClientDTO(): ClientDTO {
  return {
    id: uuidv4(),
    taxName: '',
    taxIdentity: '',
    street: '',
    number: '',
    zipCode: '',
    city: '',
    province: '',
    country: '',
    idOdoo: '',
    referencedClientID: null,
    cosnotifications: true
  }
}

export function fromModel(c: Client): ClientDTO {
  return {
    id: c.id,
    taxName: c.taxName,
    taxIdentity: c.taxIdentity,
    street: c.street,
    number: c.number,
    zipCode: c.zipCode,
    city: c.city,
    province: c.province,
    country: c.country,
    idOdoo: c.idOdoo,
    referencedClientID: c.referencedClientID,
    cosnotifications: c.cosnotifications
  }
}

export function fromModelCR(c: ClientRegister): ClientRegisterDTO {
  return {
    id: c.id,
    taxName: c.taxName,
    taxIdentity: c.taxIdentity,
    street: c.street,
    number: c.number,
    zipCode: c.zipCode,
    city: c.city,
    province: c.province,
    country: c.country,
    password: c.password,
    validatePassword: c.password,
    referencedClientID: c.referencedClientID,
    cosnotifications: c.cosnotifications,
  }
}

export function toModel(c: ClientDTO): Client {
  return new Client(c)
}


export function toModelCR(cr : ClientRegisterDTO): ClientRegister {
  return new ClientRegister(cr)
}