import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { ClientTable, HomeTable } from '../../features/home'
import { Statistic } from "../../features/home/Statistic";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../features/home/Home.styles";
import { COLOR_PRIMARY } from "../../routes/color-constants";
import { RequestInact } from '../../assets/Iconos_muestras/solicitudes-inac'
import { ClientsInac } from '../../assets/Iconos_muestras/clientes-inac'
import {getAuthContainer} from "../../container/auth-modules";
import {AuthService} from "../../modules/auth/services/AuthService";
import {AUTH_SERVICE_KEY} from "../../modules/auth";

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
export const Table = (props: RouteProps) => {


    const { t } = useTranslation()

    const classes = useStyles({ color: COLOR_PRIMARY })
    const value = authService.get().permissions.find((p)=> p == "CreateClients")

    return (
        <>
            <Title color={"black"} title={props.title || ''} />
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '1%', width: "100%"}}>
                    <div style={{ display: 'flex', marginBottom: '2%' }}>
                        {/*<img className={classes.titleImage} src={()=>RequestInact}/>*/}
                        <RequestInact />
                        <Typography className={classes.title} variant={'h4'} align="left">
                            {t('request')}
                        </Typography>
                    </div>
                    <HomeTable />
                </div>
                {value ?
                    <div>
                        <div style={{display: 'flex'}}>
                            {/*<img className={classes.titleImage} src={()=>RequestInact}/>*/}
                            <ClientsInac style={{marginBottom: '2%'}}/>
                            <Typography className={classes.title} variant={'h4'} align="left">
                                {t('clients')}
                            </Typography>
                        </div>
                        <ClientTable/>
                    </div>
                    :
                    <></>
                }
            </div>
            <Statistic />
        </>
    )
}
