import React, { useState, useEffect } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { RequestsTable } from '../../features/requests'
import { Title } from '../../components/common/Title'
import { getPatientContainer } from 'container/patient-modules'
import { PatientService } from 'modules/patient/services/PatientService'
import { PATIENT_SERVICE_KEY } from 'modules/patient'
import { Query, QueryParam } from 'common/api/Query'

const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export const Table = (props: RouteProps) => {
  const [patient, setPatient] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const getPatient = async () => {
    let patients = await patientService
      .getFilteredList(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (patients && patients?.items?.length > 0) {
      setPatient(patients?.items[0])
      setIsLoading(false)
    }

    let citogenicPatients = await patientService
      .getFilteredListCitogenicArray(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (citogenicPatients && citogenicPatients?.items?.length > 0) {
      setPatient(citogenicPatients?.items[0])
      setIsLoading(false)
    }

    let microvePatients = await patientService
      .getFilteredListMicroVE(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (microvePatients && microvePatients?.items?.length > 0) {
      setPatient(microvePatients?.items[0])
      setIsLoading(false)
    }

    let ngsPatients = await patientService
      .getFilteredListNGS(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (ngsPatients && ngsPatients?.items?.length > 0) {
      setPatient(ngsPatients?.items[0])
      setIsLoading(false)
    }

    let oncologyPatients = await patientService
      .getFilteredListOncology(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (oncologyPatients && oncologyPatients?.items?.length > 0) {
      setPatient(oncologyPatients?.items[0])
      setIsLoading(false)
    }

    let pgtPatients = await patientService
      .getFilteredListPGT(
        new Query({
          query: [new QueryParam('id', props.id || '')],
        })
      )
      .toPromise()

    if (pgtPatients && pgtPatients?.items?.length > 0) {
      setPatient(pgtPatients?.items[0])
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPatient()
  }, [])

  return (
    <>
      {!isLoading && (
        <>
          <Title
            color={'black'}
            title={props.title + ' ' + patient?.firstName + ' ' + patient?.lastName}
          />
          <RequestsTable id={props.id || ''} />
        </>
      )}
    </>
  )
}
