import {getFaqContainer} from "../../container/faq-modules";
import {FaqService} from "../../modules/faq/services/FaqService";
import {FAQ_SERVICE_KEY} from "../../modules/faq";
import {useTranslation} from "react-i18next";
import {FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {useForm} from "../../common/utils/form-generation/useForm";
import {FaqDTO} from "../../modules/faq/models/FaqDTO";
import {getUserContainer} from "../../container/user-modules";
import {UserService} from "../../modules/users/services/UserService";
import {USER_SERVICE_KEY} from "../../modules/users";
import {getAuthContainer} from "../../container/auth-modules";
import {AuthService} from "../../modules/auth/services/AuthService";
import {AUTH_SERVICE_KEY} from "../../modules/auth";
import {useEffect, useState} from "react";
import {User} from "../../modules/users/models/User";
import {optionLanguage} from "../../modules/users/enums/Language";
import {FormAction, FormActions} from "../../common/utils/form-generation";
import {URL_HELP} from "../../routes/routes-constants";
import {navigate} from "@reach/router";
import {v4 as uuidv4} from 'uuid'
import {RouteProps} from "../../routes/AppRouter";
import {Faq} from "../../modules/faq/models/Faq";


const faqService = getFaqContainer().get<FaqService>(FAQ_SERVICE_KEY)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export type FaqFormProps = RouteProps &
    {
        faq: Faq | undefined
    }
export const FormFaq = (props: FaqFormProps) => {

    const {t} = useTranslation()
    const [user, setUser] = useState<User>()
    const loggedUser = authService.get()

    useEffect(() => {
        userService.getByID(loggedUser.id).subscribe((res) => {
            setUser(res)
        })
    }, [])

    useEffect(() => {
        props.faq && setData(props.faq.toDTO())
    }, [props.faq])

    const actions: FormAction[] = [
        {
            label: t('back'),
            handleAction: () => navigate(URL_HELP),
        },
    ]

    const {handleChange, handleSubmit, data, setData, errors} = useForm<FaqDTO>({
        validations: {
            name: {
                required: {
                    value: true,
                    message: t('nameNotValidError'),
                },
            },
            question: {
                required: {
                    value: true,
                    message: t('questionNotValidError'),
                },
            },
            answer: {
                required: {
                    value: true,
                    message: t('answerNotValidError'),
                },
            },
            language: {
                required: {
                    value: true,
                    message: t('languageNotValidError'),
                },
            },
        },

        onSubmit: () => {
            if (data && data.id) {
                faqService.update(data).subscribe(async () => {
                    navigate(URL_HELP)
                })
                return
            }
            faqService.add({...data, id: uuidv4(), creatorID: loggedUser.id}).subscribe((res) => {
                navigate(URL_HELP)
            })
        },
        initialValues: props.faq && props.faq.toDTO(),
    })

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant='outlined' error={errors['language'] !== undefined}>
                            <InputLabel id='language-label'>{t('language')}</InputLabel>
                            <Select
                                labelId='language-label'
                                id='language'
                                fullWidth
                                style={{textAlign: 'left'}}
                                value={(data && data.language) || ""}
                                onChange={(event) => handleChange('language', event.target.value)}
                                label={t('language')}>
                                {Object.keys(optionLanguage()).map((l) => (
                                    <MenuItem value={l} key={l}>
                                        {t(l)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{errors['language']}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            error={errors['name'] !== undefined}
                            id={'name'}
                            type={'string'}
                            onChange={(event) => handleChange('name', event.target.value)}
                            value={data && data.name || ""}
                            label={t('name')}
                            helperText={errors['name']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            error={errors['question'] !== undefined}
                            id={'question'}
                            type={'string'}
                            onChange={(event) => handleChange('question', event.target.value)}
                            value={(data && data.question) || ""}
                            label={t('question')}
                            helperText={errors['question']}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            error={errors['answer'] !== undefined}
                            fullWidth
                            type={'string'}
                            id='answer'
                            label={t('answer')}
                            onChange={(event) => handleChange('answer', event.target.value)}
                            multiline
                            rows={4}
                            value={(data && data.answer) || ""}
                            variant='outlined'
                            helperText={errors['answer']}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <FormActions actions={actions}/>
            </form>
        </>
    )
}