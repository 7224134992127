import {Error} from "./ErrorModel";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React from "react";
import {TFunction} from "react-i18next";


export const ErrorSnackBar = ({ t, err, open, handleClose }: {t :TFunction ,err: Error, open: boolean, handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void }) => {
    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={10000}
                onClose={handleClose}
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: 'auto',
                }}>
                <Alert
                    onClose={handleClose}
                    severity={'error'}
                    style={{
                        marginLeft: 'initial',
                    }}>
                    {err ? t('code') + ' ' + t(err.code) + '. ' + t(err.error)
                    + ' ' + t(err.message) : ''}
                </Alert>
            </Snackbar>
        </div>
    )
}