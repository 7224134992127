import {Container, ContainerItem} from "../common/container/Container";
import {ClientProps, CLIENTS_API_KEY, CLIENTS_MODULE, CLIENTS_SERVICE_KEY} from "../modules/clients/container";
import {ClientApi} from "../modules/clients/api/ClientApi";
import {ClientService} from "../modules/clients/services/ClientService";
import {getAppContainer} from "./app";
import {moduleURLs} from "../config/api";
import {CLIENT2REQUEST_API_KEY, CLIENT2REQUEST_MODULE, CLIENT2REQUEST_SERVICE_KEY} from "../modules/client2Requests";
import {Client2RequestApi} from "../modules/client2Requests/api/Client2RequestApi";
import {Client2RequestService} from "../modules/client2Requests/services/Client2RequestService";


let container: Container

function init(p: ClientProps) {
    const items = new Map<symbol, ContainerItem>()

    items.set(CLIENT2REQUEST_API_KEY, new ContainerItem({ instance: new Client2RequestApi() }))
    items.set(
        CLIENT2REQUEST_SERVICE_KEY,
        new ContainerItem({
            instance: new Client2RequestService({ apiKey: CLIENT2REQUEST_API_KEY }),
        })
    )

    container = new Container(items, p.parentContainer, p.config)
}

export function getClient2RequestContainer(): Container {
    if (!container) {
        init({
            parentContainer: getAppContainer(),
            config: {
                moduleFullUrl: moduleURLs[CLIENT2REQUEST_MODULE],
            },
        })
    }

    return container
}
