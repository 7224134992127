import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import {Patient, PatientQuery} from '../models/Patient'
import { PatientContainerConfig } from '../container'
import {PatientDTO, toModel} from '../models/PatientDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { PatientMicroVE } from '../models/patientForm/PatientMicroVE'
import { PatientMicroVEDTO } from '../models/patientForm/PatientMicroVEDTO'
import { PatientPGT } from '../models/patientForm/PatientPGT'
import { PatientPGTDTO } from '../models/patientForm/PatientPGTDTO'
import { PatientNGS } from '../models/patientForm/PatientNGS'
import { PatientNGSDTO } from '../models/patientForm/PatientNGSDTO'
import { PatientOncology } from '../models/patientForm/PatientOncology'
import { PatientOncologyDTO } from '../models/patientForm/PatientOncologyDTO'
import { PatientCitogeneticaArray } from '../models/patientForm/PatientCitogeneticaArray'
import { PatientCitogeneticaArrayDTO } from '../models/patientForm/PatientCitogeneticaArrayDTO'

export interface IPatientApi extends IInit {
  getByID(id: string): Observable<Patient | undefined>

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>>

  getPatients(q: Query<PatientQuery>): Observable<ItemList<Patient>>

  getFilteredListMicroVE(q: Query<PatientQuery>): Observable<ItemList<PatientMicroVE>>

  getFilteredListPGT(q: Query<PatientQuery>): Observable<ItemList<PatientPGT>>

  getFilteredListNGS(q: Query<PatientQuery>): Observable<ItemList<PatientNGS>>

  getFilteredListOncology(q: Query<PatientQuery>): Observable<ItemList<PatientOncology>>

  getFilteredListCitogenicArray(q: Query<PatientQuery>): Observable<ItemList<PatientCitogeneticaArray>>

  getPatientRequest(q: Query<PatientQuery>): Observable<any[]>

  add(e: PatientDTO): Observable<Patient | undefined>

  update(e: PatientDTO): Observable<Patient | undefined>

  delete(id: string): Observable<boolean>

  getRequestsByPatientID(q: Query<PatientQuery>): Observable<Request[]>
}

export class PatientApi implements IPatientApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as PatientContainerConfig).moduleFullUrl
  }

  add(e: PatientDTO): Observable<Patient | undefined> {
    return this._httpClient.post<Patient>({ url: this._url, body: e }).pipe(
      map<PatientDTO, Patient>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Patient | undefined> {
    return this._httpClient.get<Patient>({ url: `${this._url}/${id}` }).pipe(
      map<PatientDTO, Patient>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<PatientQuery>): Observable<ItemList<Patient>> {
    return this._httpClient.get<ItemList<Patient>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<PatientDTO>, ItemList<Patient>>((dto) => {
        const itemList = emptyList<any>()
        itemList.count = dto.count
        itemList.items = dto.items
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Patient>())
      })
    )
  }

  getPatients(q: Query<PatientQuery>): Observable<ItemList<Patient>> {
    return this._httpClient.get<ItemList<Patient>>({ url: prepareURL(this._url+"/patients/patients", q) }).pipe(
      map<ItemList<PatientDTO>, ItemList<Patient>>((dto) => {
        const itemList = emptyList<any>()
        itemList.count = dto.count
        itemList.items = dto.items
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Patient>())
      })
    )
  }

  getFilteredListPGT(q: Query<PatientQuery>): Observable<ItemList<PatientPGT>> {
    return this._httpClient.get<ItemList<PatientPGT>>({ url: prepareURL(this._url+'/patients/pgt', q) }).pipe(
      map<ItemList<PatientPGTDTO>, ItemList<PatientPGT>>((dto) => {
        const itemList = emptyList<any>()
        itemList.count = dto.count
        itemList.items = dto.items
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<PatientPGT>())
      })
    )
  }

  getFilteredListMicroVE(q: Query<PatientQuery>): Observable<ItemList<PatientMicroVE>> {
    return this._httpClient.get<ItemList<PatientMicroVE>>({ url: prepareURL(this._url+'/patients/microvebioer', q) }).pipe(
      map<ItemList<PatientMicroVEDTO>, ItemList<PatientMicroVE>>((dto) => {
        const itemList = emptyList<any>()
        itemList.count = dto.count
        itemList.items = dto.items
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<PatientMicroVE>())
      })
    )
  }

  getFilteredListNGS(q: Query<PatientQuery>): Observable<ItemList<PatientNGS>> {
    return this._httpClient.get<ItemList<PatientNGS>>({ url: prepareURL(this._url+'/patients/ngs', q) }).pipe(
      map<ItemList<PatientNGSDTO>, ItemList<PatientNGS>>((dto) => {
        const itemList = emptyList<any>()
        itemList.count = dto.count
        itemList.items = dto.items
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<PatientNGS>())
      })
    )
  }

  getFilteredListOncology(q: Query<PatientQuery>): Observable<ItemList<PatientOncology>> {
    return this._httpClient.get<ItemList<PatientOncology>>({ url: prepareURL(this._url+'/patients/oncology', q) }).pipe(
      map<ItemList<PatientOncologyDTO>, ItemList<PatientOncology>>((dto) => {
        const itemList = emptyList<any>()
        itemList.count = dto.count
        itemList.items = dto.items
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<PatientOncology>())
      })
    )
  }

  getFilteredListCitogenicArray(q: Query<PatientQuery>): Observable<ItemList<PatientCitogeneticaArray>> {
    return this._httpClient.get<ItemList<PatientCitogeneticaArray>>({ url: prepareURL(this._url+'/patients/citogenic', q) }).pipe(
      map<ItemList<PatientCitogeneticaArrayDTO>, ItemList<PatientCitogeneticaArray>>((dto) => {
        const itemList = emptyList<any>()
        itemList.count = dto.count
        itemList.items = dto.items
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<PatientCitogeneticaArray>())
      })
    )
  }

  getPatientRequest(q: Query<PatientQuery>): Observable<any[]> {
    return this._httpClient.get<any[]>({ url: prepareURL(this._url+'/patients/patientrequest', q) }).pipe(
      map<any[], any[]>((dto) => {
        return dto
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  getRequestsByPatientID(q: Query<PatientQuery>): Observable<Request[]> {
    return this._httpClient.get<any[]>({ url: prepareURL(this._url+'/patients/requestsbypatient', q) }).pipe(
      map<any[], any[]>((dto) => {
        return dto
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of([])
      })
    )
  }

  update(e: PatientDTO): Observable<Patient | undefined> {
    return this._httpClient.put<Patient>({ url: this._url, body: e }).pipe(
      map<PatientDTO, Patient>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

}
