export type AddressProps = {
    id: string | undefined
    requestID: string
    street: string
    number: string
    zipCode: string
    city: string
    province: string
    date: Date
    hour: number
    phone: string
    firstName: string
    lastName: string
}


export class Address {

    private readonly _id: string | undefined
    _requestID: string
    _street: string
    _number: string
    _zipCode: string
    _city: string
    _province: string
    _date: Date
    _hour: number
    _phone: string
    _firstName: string
    _lastName: string

    constructor(p: AddressProps) {
        this._id = p.id
        this._requestID = p.requestID
        this._street = p.street
        this._number = p.number
        this._zipCode = p.zipCode
        this._city = p.city
        this._province = p.province
        this._date = p.date
        this._hour = p.hour
        this._phone = p.phone
        this._firstName = p.firstName
        this._lastName = p.lastName

    }

    get id(): string | undefined {
        return this._id
    }

    get requestID(): string {
        return this._requestID
    }

    set requestID(value: string) {
        this._requestID = value
    }

    get street(): string {
        return this._street
    }

    set street(value: string) {
        this._street = value
    }

    get number(): string {
        return this._number
    }

    set number(value: string) {
        this._number = value
    }

    get zipCode(): string {
        return this._zipCode
    }

    set zipCode(value: string) {
        this._zipCode = value
    }

    get city(): string {
        return this._city
    }

    set city(value: string) {
        this._city = value
    }

    get province(): string {
        return this._province
    }

    set province(value: string) {
        this._province = value
    }

    get date(): Date {
        return this._date
    }

    set date(value: Date) {
        this._date = value
    }

    get hour(): number {
        return this._hour;
    }

    set hour(value: number) {
        this._hour = value;
    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }
}

export interface AddressQuery {
    id: string
    ids: string[]
    date: Date
    requestID: string
}


