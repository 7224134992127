import {Container, IInit} from '../../../common/container/Container'
import {Observable} from 'rxjs'
import {Client, ClientOdoo, ClientQuery, ClientRegister} from '../models/Client'
import {ItemList} from '../../../common/models/ItemList'
import {Query} from '../../../common/api/Query'
import {ClientDTO, ClientRegisterDTO} from '../models/ClientDTO'
import {IClientApi} from '../api/ClientApi'
import {IStatusService} from '../../../common/status/StatusService'
import {STATUS_SERVICE_KEY} from '../../../container/app'

type Props = {
    apiKey: symbol
}

export interface IClientService extends IInit {
    getByID(id: string): Observable<Client | undefined>

    getFilteredList(q: Query<ClientQuery>): Observable<ItemList<Client>>

    add(e: Client): Observable<Client | undefined>

    update(e: ClientDTO): Observable<Client | undefined>

    delete(id: string): Observable<boolean>

    addAndRegister(cr: ClientRegisterDTO): Observable<ClientRegister | undefined>

    getClinicAndUsers(clinicID: string): Observable<any | undefined>

    getClinicsOdoo(): Observable<ItemList<ClientOdoo>>
}

export class ClientService implements IClientService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IClientApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IClientApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    add(e: ClientDTO): Observable<Client | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    getByID(id: string): Observable<Client | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<ClientQuery>): Observable<ItemList<Client>> {
        return this._api.getFilteredList(q)
    }

    update(e: ClientDTO): Observable<Client | undefined> {
        return this._api.update(e)
    }

    addAndRegister(cr: ClientRegisterDTO): Observable<ClientRegister | undefined> {
        return this._api.addAndRegister(cr)
    }

    getClinicAndUsers(id: string): Observable<any | undefined> {
        return this._api.getClinicAndUsers(id)
    }

    getClinicsOdoo(): Observable<ItemList<ClientOdoo>> {
        return this._api.getClinicsOdoo()
      }
}
