import {
  Box,
  CircularProgress,
  createMuiTheme,
  LinearProgress,
  MuiThemeProvider,
} from '@material-ui/core'
import React from 'react'

export type LoadingSpinnerProps = {
  className?: string
  progress?: number
}

const customThemeSpinner = createMuiTheme({
  palette: {
    primary: {
      main: '#02a6dc ',
      contrastText: '#ffffff !important', // --color-dark-blue
    },
  },
})

// TODO Convert this loader into modal
export function LoadingSpinner(props: LoadingSpinnerProps): JSX.Element {
  return (
    <MuiThemeProvider theme={customThemeSpinner}>
      {/**@ts-ignore */}
      <Box className={props.className} overflow="auto" flexGrow="1" key={'box6'}>
        <CircularProgress disableShrink size="7rem" />
      </Box>
    </MuiThemeProvider>
  )
}

export function LoadingFile(props: LoadingSpinnerProps): JSX.Element {
  return (
    <>
      {/**@ts-ignore */}
      < Box className={props.className} flexGrow="2" key={'box6'} >
        <LinearProgress value={props.progress} />
      </Box >
    </>
  )
}
