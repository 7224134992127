import { v4 as uuidv4 } from 'uuid'
import { Product } from './Product'

export interface ProductDTO {
  id: string | undefined
  nameES: string
  nameEN: string
  type: string
  reference: string
  price: number
  patientNumber: number
  samplePerPatient: boolean
  genes: string
  idOdoo: string
  typeForm: string
  typeConsent: number
}

export function emptyProductDTO(): ProductDTO {
  return {
    id: uuidv4(),
    nameES: '',
    type: '',
    reference: '',
    price: 0,
    patientNumber: 0,
    samplePerPatient: false,
    genes: '',
    idOdoo: '',
    typeForm: '',
    typeConsent: 1000,
    nameEN: ''
  }
}

export function fromModel(c: Product): ProductDTO {
  return {
    id: c.id,
    nameES: c.nameES,
    type: c.type,
    reference: c.reference,
    price: c.price,
    patientNumber: c.patientNumber,
    samplePerPatient: c.samplePerPatient,
    genes: c.genes,
    idOdoo: c.idOdoo,
    typeForm: c.typeForm,
    typeConsent: c.typeConsent,
    nameEN: c.nameEN,
  }
}

export function toModel(c: ProductDTO): Product {
  return new Product(c)
}
