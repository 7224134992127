import { Box, Button, CircularProgress, Grid, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useStyles } from './Application.styles'
import React, { useEffect, useState } from 'react'
import { Query, QueryParam } from '../../common/api/Query'
import { ModalGenes } from './ModalGenes'
import { getProductContainer } from '../../container/product-modules'
import { ProductService } from '../../modules/product/services/ProductService'
import { PRODUCT_SERVICE_KEY } from '../../modules/product'
import { Product } from '../../modules/product/models/Product'
import { ModalProducts } from './ModalProduct'
import { Genes, GenesQuery } from '../../modules/genes/models/Genes'
import {
  emptyProductDTO,
  fromModel,
  ProductDTO,
  toModel,
} from '../../modules/product/models/ProductDTO'
import { downloadFile } from '../../common/files/file'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { ModalAddGenes } from './ModalAddGenes'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { PatientProduct } from '../../modules/patient/models/relationship/PatientProduct'
import { emptyAddressDTO, toModel as toModelAddress } from '../../modules/address/models/AddressDTO'
import { getGenesContainer } from 'container/genes-modules'
import { GenesService } from 'modules/genes/services/GenesService'
import { GENES_SERVICE_KEY } from 'modules/genes'
import { ModalGenesEdit } from './ModalGenesEdit'
import { FormControl } from '@material-ui/core'
import { AUTH_SERVICE_KEY } from 'modules/auth'
import { AuthService } from 'modules/auth/services/AuthService'
import { getAuthContainer } from 'container/auth-modules'
import { Permission } from 'common/enums/Permissions'
import { getClientContainer } from 'container/client-modules'
import { ClientService } from 'modules/clients/services/ClientService'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { Client } from 'modules/clients/models/Client'
import { getUserContainer } from 'container/user-modules'
import { UserService } from 'modules/users/services/UserService'
import { USER_SERVICE_KEY } from 'modules/users'
import { ModalLanguages } from './ModalLanguages'
import { LanguageConsent } from 'modules/files/models/File'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@material-ui/core'
import { forkJoin } from 'rxjs'
import Select from "react-select"

type FirstStepProps = {
  increaseStep: () => void
  patientProducts: PatientProduct[]
  setPatientProducts: React.Dispatch<React.SetStateAction<PatientProduct[]>> | undefined
  selectedProducts?: Product[]
  type?: string
  edit?: boolean
  requestID?: string
  handleChangeUserDoingRequest?: (iduser: string, idclient: string) => void
  selectedClient?: string
}

const productService = getProductContainer().get<ProductService>(PRODUCT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const genesService = getGenesContainer().get<GenesService>(GENES_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)
const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const ProductAplication = (props: FirstStepProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t , i18n } = useTranslation()
  const [modalGenes, setModalGenes] = useState<boolean>(false)
  const [modalProduct, setModalProduct] = useState<boolean>(false)
  const [currentProduct, setCurrentProduct] = useState<Product>(toModel(emptyProductDTO()))
  const [selectedProduct, setSelectedProduct] = useState<Product[]>(props.selectedProducts || [])
  const [items, setItems] = useState<Product[]>([])
  const [modalAddGenes, setModalAddGenes] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [clients, setClients] = useState<Client[]>()
  const [clients2, setClients2] = useState<any[]>([])
  const [selectedClient, setSelectedClient] = useState<Client>()
  const [modalLanguageOpened, setModalLanguageOpened] = useState<boolean>(false)
  const loggedUser = authService.get()
  const [languagesConsents, setLanguagesConsents] = useState<LanguageConsent[]>([])
  const [languagesLoading, setLanguagesLoading] = useState<boolean>(false)
  const [productsLoading, setProductsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (selectedProduct?.length == 0 || !productsLoading) return

    const updateObservables = selectedProduct.map((pr) =>
      productService.updateFromOdoo(fromModel(pr))
    )

    forkJoin(updateObservables).subscribe(() => {
      productService
        .getFilteredListFromDatabase(
          new Query({
            query: [
              new QueryParam(
                'idsOdoo',
                selectedProduct.map((s) => s.idOdoo)
              ),
            ],
          })
        )
        .subscribe((res) => {
          setSelectedProduct(res?.items)
          setProductsLoading(false)
        })
    })
  }, [selectedProduct])

  useEffect(() => {
    if (!modalLanguageOpened) return
    setLanguagesLoading(true)
    fileService
      .getLanguageConsents(
        new Query({
          query: [new QueryParam('typeConsent', Number(currentProduct?.typeConsent))],
        })
      )
      .subscribe((res) => {
        res && setLanguagesConsents(res.items)
        setLanguagesLoading(false)
      })
  }, [modalLanguageOpened])

  useEffect(() => {
    if (authService.userCan(Permission.createRequestToOtherClient)) {
      clientService.getFilteredList(new Query({})).subscribe((res) => {
        let filtered = res.items.filter((c) => c.id != loggedUser.clinicID)
        setSelectedClient(filtered.filter((i) => i.id == props.selectedClient)[0])

        let auxClients: any[] = []
        filtered.forEach((f) => {
          auxClients.push({
            label: f.taxName + ' - ' + f.taxIdentity,
            value: f.id,
          })
        })

        auxClients.push({
          label: t('notSelect'),
          value: '',
        })

        setClients2(auxClients)
        setClients(filtered)
      })
    }
  }, [])

  useEffect(() => {
    productService.getFilteredListUpdated(new Query({})).subscribe((res) => {
      setItems(res.items)
      setIsLoading(false)
    })
  }, [isLoading])

  const handleOpenModalGenes = (product: Product) => {
    if (props.requestID) {
      let aux = product
      aux.genes = product.genes
      let pps = props.patientProducts.filter((p) => p._product.id === product.id)

      if (pps.length > 0) {
        let pp = pps[0]
        if (pp._genes) {
          aux.genes = pp._genes.map((g) => g._name).join(',')
        } else {
          aux.genes = pp._product.genes
        }
      }

      setCurrentProduct(aux)
      setModalGenes(true)
    } else {
      setCurrentProduct(product)
      setModalGenes(true)
    }
  }

  const handleCloseModalGenes = () => {
    setModalGenes(false)
  }

  const handleSaveModalGenes = async (genes: string[]) => {
    let auxProduct = currentProduct
    auxProduct.genes = genes.join(',')

    let newGenes = await genesService
      .getFilteredList(
        new Query({
          query: [new QueryParam<GenesQuery>('names', genes)],
        })
      )
      .toPromise()

    props.setPatientProducts &&
      addNewGenesToPatientProduct(
        currentProduct,
        newGenes?.items || [],
        props.patientProducts,
        props.setPatientProducts
      )

    setModalGenes(false)
  }

  const handleOpenModalProduct = () => {
    setModalProduct(true)
  }

  const handleOpenModalAddGenes = (product: any) => {
    if (props.requestID) {
      let aux = product
      aux.genes = product.genes
      let pps = props.patientProducts.filter((p) => p._product.id === product.id)

      if (pps.length > 0) {
        let pp = pps[0]
        if (pp._genes) {
          aux.genes = pp._genes.map((g) => g._name).join(',')
        } else {
          aux.genes = pp._product.genes
        }
      }

      setCurrentProduct(aux)
      setModalAddGenes(true)
    } else {
      setCurrentProduct(product)
      setModalAddGenes(true)
    }
  }

  const handleCloseModalProduct = () => {
    setModalProduct(false)
  }

  useEffect(() => {
    createPatientProduct(selectedProduct)
  }, [selectedProduct])

  const createPatientProduct = (arrProduct: Product[]) => {
    let auxPP: PatientProduct[] = []
    arrProduct.forEach((p) => {
      let aux: PatientProduct = new PatientProduct({
        product: p,
        patientNGS: props.patientProducts.length > 0 ? props.patientProducts[0].patientNGS : [],
        patientPGTs: props.patientProducts.length > 0 ? props.patientProducts[0].patientPGTs : [],
        patientMicroVE:
          props.patientProducts.length > 0 ? props.patientProducts[0].patientMicroVE : [],
        patientNulls: props.patientProducts.length > 0 ? props.patientProducts[0].patientNulls : [],
        patientOncology:
          props.patientProducts.length > 0 ? props.patientProducts[0].patientOncology : [],
        patientCitogenetics:
          props.patientProducts.length > 0 ? props.patientProducts[0].patientCitogenetics : [],
        genes: props.patientProducts[0]?.genes,
        address: toModelAddress(emptyAddressDTO()),
        file: [],
        userID: props.patientProducts.length > 0 ? props.patientProducts[0].userID : '',
        step: 0,
        language: '',
        indications: '',
      })
      auxPP.push(aux)
    })

    props.setPatientProducts && props.setPatientProducts(auxPP)
  }

  const downloadConsent = (lan: string) => {
    let arrProductsDTO: ProductDTO[] = []

    selectedProduct.forEach((p) => {
      let pr = fromModel(p)
      arrProductsDTO.push(pr)
    })

    fileService.downloadConsent(arrProductsDTO, lan).subscribe((res) => {
      res.items.forEach((f) => {
        downloadFile(f.name, f.mimeType, f.base64)
      })
    })
  }

  const handleSaveModalAddGenes = async (product: Product, genes: Genes[]) => {
    setModalAddGenes(false)

    let auxProduct = product
    auxProduct.genes = genes.map((g) => g._name).join(',')

    let newGenes = await genesService
      .getFilteredList(
        new Query({
          query: [
            new QueryParam<GenesQuery>(
              'names',
              genes.map((g) => g._name)
            ),
          ],
        })
      )
      .toPromise()

    props.setPatientProducts &&
      addNewGenesToPatientProduct(
        product,
        newGenes?.items || [],
        props.patientProducts,
        props.setPatientProducts
      )
    setCurrentProduct(auxProduct)
  }

  const addNewGenesToPatientProduct = async (
    product: Product,
    newGenes: Genes[],
    patientProducts: PatientProduct[],
    setPatientProducts: (patientProducts: PatientProduct[]) => void
  ) => {
    const index = patientProducts.findIndex((patientProduct) => patientProduct.product === product)
    if (index === -1) return

    let auxProduct = product

    if (auxProduct?.genes) {
      auxProduct.genes = newGenes.map((i) => i.name).toString()
    }
    setCurrentProduct(auxProduct)

    let updatedProduct = patientProducts[index]
    updatedProduct._genes = newGenes

    const updatedProducts: PatientProduct[] = [...patientProducts]
    updatedProducts[index] = updatedProduct
    setPatientProducts(updatedProducts)
  }

  const checkProduct = () => {
    return selectedProduct.length == 0
  }

  const changeSelectedPatient = async (id: any) => {
    let cs = clients?.filter((c) => c.id == id)

    if (cs && cs?.length > 0) {
      setSelectedClient(cs[0])
      let res = await userService
        .getFilteredList(
          new Query({
            query: [new QueryParam('clinicID', cs[0].id || '')],
          })
        )
        .toPromise()

      if (res && res?.items.length > 0) {
        props.handleChangeUserDoingRequest &&
          props.handleChangeUserDoingRequest(res.items[0].id, cs[0].id || '')
      }
    } else {
      setSelectedClient(undefined)
      props.handleChangeUserDoingRequest && props.handleChangeUserDoingRequest('', '')
    }
  }

  return (
    <>
      {props.type != 'view' && (
        <>
          {!isLoading ? (
            <Grid item xs={12}>
              <div style={{ textAlign: 'left' }}>{t('selectYourProduct')}</div>
              <Grid
                container
                spacing={2}
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={4}>
                  <Button
                    className={classes.button}
                    style={{ marginTop: '10px', alignSelf: 'flex-start', display: 'flex' }}
                    type={'button'}
                    onClick={handleOpenModalProduct}>
                    {t('select')}
                  </Button>
                </Grid>
              </Grid>

              <br />
              <div
                style={selectedProduct.length == 0 ? { display: 'none' } : { textAlign: 'left' }}>
                {t('selectedProducts')}
              </div>

              {authService.userCan(Permission.createRequestToOtherClient) && props.edit && (
                <Grid item xs={10} style={{ marginTop: '2.2%', marginBottom: '2.2%' }}>
                  <FormControl variant="outlined" fullWidth>
                  <p style={{textAlign: 'left'}}>{t('selectClient')}</p>
                  <Select
                    options={clients2 || []}
                    onChange={(value) => {
                      value && changeSelectedPatient(value.value)
                    }}
                    value={{
                      label: selectedClient ? (selectedClient?.taxName + ' - ' + selectedClient?.taxIdentity) : '',
                      value: selectedClient?.id || '',
                    }} 
                    styles={{
                      control: (baseStyles: any) => ({
                        ...baseStyles,
                        textAlign: 'left',
                        height: '56px',
                      }),
                      menu: (baseStyles: any) => ({
                        ...baseStyles,
                        zIndex: 10
                      }),
                    }}
                  />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          ) : (
            <LoadingSpinner className={style.loadingSpinner} />
          )}
        </>
      )}
      <Grid container>
        {selectedProduct.length != 0 &&
          selectedProduct.map((element) => {
            return (
              <>
                <Grid item xs={12} key={element?.idOdoo ? element?.idOdoo : ''}>
                  <div className={classes.productGrid}>
                    <div className={classes.titleProduct}>
                    {(i18n.language == "es" ? element?.nameES : element?.nameEN)}
                      </div>
                    <div className={classes.column}>
                      <Grid container>
                        <Grid item xs={7}>
                          <div className={classes.row2}>
                            <div className={classes.productType}>{t('typeProduct')}</div>
                            <div>{element?.type}</div>
                          </div>
                        </Grid>
                        <Grid item xs={7}>
                          <div className={classes.row2}>
                            <div className={classes.productType}>{t('reference')}</div>
                            <div>{element?.reference}</div>
                          </div>
                        </Grid>
                      </Grid>
                      <div style={{ marginBottom: '15px' }}>
                        <Button
                          className={classes.buttonSecondary}
                          style={{ backgroundColor: 'grey', flex: 1 }}
                          type={'button'}
                          onClick={() => {
                            setCurrentProduct(element)
                            setModalLanguageOpened(true)
                          }}>
                          {t('downloadConsent')}
                        </Button>
                      </div>
                      <div style={{ margin: '-7px 15px 0 0' }}>
                        {element?.genes != '' && element?.genes.includes(',') && (
                          <div style={{ textAlign: 'right', padding: '0  0 0 0', width: '150px' }}>
                            <Button
                              className={classes.button}
                              type={'button'}
                              onClick={() => handleOpenModalGenes(element)}>
                              {t('seeGene')}
                            </Button>
                          </div>
                        )}
                        {element?.genes != '' && element?.genes.includes(',') && (
                          <div
                            style={{
                              textAlign: 'right',
                              margin: '20px 0px 25px 0px',
                              padding: '0  0 0 0',
                              width: '150px',
                            }}>
                            {props.edit != false && (
                              <Button
                                className={classes.buttonSecondary}
                                style={{ padding: 5 }}
                                type={'button'}
                                onClick={() => handleOpenModalAddGenes(element)}>
                                {t('addGen')}
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Grid>
              </>
            )
          })}
        <Grid item xs={12}>
          <Box mt={3}>
            <Button
              onClick={props.increaseStep}
              className={classes.button}
              disabled={checkProduct()}>
              {t('continue')}
            </Button>
          </Box>
        </Grid>
      </Grid>

      {props.requestID ? (
        <Modal open={modalGenes} onClose={handleCloseModalGenes}>
          <ModalGenesEdit
            data={items}
            product={currentProduct}
            handleModalClose={handleCloseModalGenes}
            handleModalSave={handleSaveModalGenes}
          />
        </Modal>
      ) : (
        <Modal open={modalGenes} onClose={handleCloseModalGenes}>
          <ModalGenes
            data={items}
            product={currentProduct}
            handleModalClose={handleCloseModalGenes}
            handleModalSave={handleSaveModalGenes}
          />
        </Modal>
      )}

      <Modal open={modalProduct} onClose={handleCloseModalProduct}>
        <ModalProducts
          data={items}
          handleModalClose={handleCloseModalProduct}
          selectedProduct={selectedProduct}
          setSelectedProduct={(p: Product[]) => {
            setProductsLoading(true)
            setSelectedProduct(p)}}
        />
      </Modal>
      <Modal
        open={modalAddGenes}
        onClose={() => setModalAddGenes(false)}
        style={{ marginTop: '-4.5%' }}>
        <ModalAddGenes
          data={props.patientProducts}
          product={currentProduct}
          handleModalClose={() => setModalAddGenes(false)}
          handleModalSave={handleSaveModalAddGenes}
        />
      </Modal>
      {modalLanguageOpened && (
        <>
          {!languagesLoading ? (
            <Modal
              open={modalLanguageOpened}
              onClose={() => setModalLanguageOpened(!modalLanguageOpened)}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ModalLanguages
                handleModalClose={() => setModalLanguageOpened(!modalLanguageOpened)}
                handleChangeLanguage={(lan) => {
                  downloadConsent(lan)
                  setModalLanguageOpened(!modalLanguageOpened)
                }}
                languages={languagesConsents?.map((l) => l.language)}
              />
            </Modal>
          ) : (
            <Modal open={languagesLoading}>
              <div
                style={{
                  position: 'absolute',
                  fontSize: '35px',
                  top: '50%',
                  left: '50%',
                  padding: '30px',
                  borderRadius: '10%',
                  display: 'flex',
                  justifyContent: 'center',
                  backgroundColor: 'white',
                }}>
                <CircularProgress style={{ alignSelf: 'center' }} />
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  )
}
