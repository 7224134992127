import { Container, IInit } from 'common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { USER_SERVICE_KEY } from '../container'
import { IUserApi } from '../api/UserApi'
import { Observable } from 'rxjs'
import { User, UserDTO, UserRegister } from '../models/User'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { LoggedUserDTO } from 'modules/auth/models/LoggedUserDTO'
import { LoggedUser } from '../../auth/models/LoggedUser'

export interface IUserService extends IInit {
  getByID(id: string): Observable<User | undefined>
  getFilteredList(q: Query<User>): Observable<ItemList<User>>
  add(e: UserDTO): Observable<User | undefined>
  update(e: UserDTO): Observable<User | undefined>
  delete(id: string): Observable<boolean>
  updateProfile(u: LoggedUserDTO): Observable<LoggedUser | undefined>
  sendMail(e: any): void
  getUserEmails(userID: string): Observable<any[]>
  addUserEmails(userID: string, emails: string[]): Observable<User | undefined>
  updateUserEmails(userID: string, emails: string[]): Observable<User | undefined>
  addClinicUsers(users: UserRegister[]): Observable<UserRegister[] | undefined>
  addClinicUser(user: UserRegister): Observable<UserRegister | undefined>
  updateClinicUser(user: UserRegister): Observable<UserRegister | undefined>
}

type Props = {
  apiKey: symbol
}

export class UserService implements IUserService {
  private readonly _apiKey: symbol
  private _api!: IUserApi
  private _container!: Container
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(USER_SERVICE_KEY)
  }

  getByID(id: string): Observable<User | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<User>): Observable<ItemList<User>> {
    return this._api.getFilteredList(q).pipe()
  }

  add(e: UserDTO): Observable<User | undefined> {
    return this._api.add(e)
  }

  addUserEmails(userID: string, emails: string[]): Observable<User | undefined> {
    return this._api.addUserEmails(userID, emails)
  }

  addClinicUsers(users: UserRegister[]): Observable<UserRegister[] | undefined> {
    return this._api.addClinicUsers(users)
  }

  addClinicUser(user: UserRegister): Observable<UserRegister | undefined> {
    return this._api.addClinicUser(user)
  }

  updateClinicUser(user: UserRegister): Observable<UserRegister | undefined> {
    return this._api.updateClinicUser(user)
  }

  updateUserEmails(userID: string, emails: string[]): Observable<User | undefined> {
    return this._api.updateUserEmails(userID, emails)
  }

  getUserEmails(userID: string): Observable<any[]> {
    return this._api.getUserEmails(userID)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  update(e: UserDTO): Observable<User | undefined> {
    return this._api.update(e)
  }

  updateProfile(e: LoggedUserDTO): Observable<LoggedUser | undefined> {
    return this._api.updateProfile(e)
  }

  sendMail(e: any): void {
    this._api.sendMail(e)
  }
}
