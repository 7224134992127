import { Container, ContainerItem } from '../common/container/Container'
import { UserProps } from '../modules/users/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { AUTH_API_KEY, AUTH_SERVICE_KEY, AUTHS_MODULE } from '../modules/auth'
import { AuthApi } from '../modules/auth/api/AuthApi'
import { AuthService } from '../modules/auth/services/AuthService'

let container: Container

function init(p: UserProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(AUTH_API_KEY, new ContainerItem({ instance: new AuthApi() }))
  items.set(
    AUTH_SERVICE_KEY,
    new ContainerItem({
      instance: new AuthService({ apiKey: AUTH_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getAuthContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[AUTHS_MODULE],
      },
    })
  }

  return container
}
