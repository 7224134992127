export const URL_LOGIN_2: string = '/'
export const URL_LOGIN: string = '/login'
export const URL_REGISTER: string = '/register'
export const URL_RECOVER_PASSWORD: string = '/recover-password'
export const URL_CHANGE_PASSWORD: string = '/change-password/:token'
export const URL_PARAMETERS: string = '/settings/parameters'
export const URL_PARAMETER: string = '/settings/parameters/:id'
export const URL_TRANSLATIONS: string = '/settings/translation'
export const URL_TRANSLATION: string = '/settings/translation/:id'
export const URL_TEST_TYPE: string = '/settings/testtypes/:id'
export const URL_TESTS: string = '/patients/tests'
export const URL_TEST: string = '/patients/:patientId/tests/:id'
export const URL_GENE_TYPES: string = '/settings/genetypes'
export const URL_GENE_TYPE: string = '/settings/genetypes/:id'
export const URL_USERS: string = '/settings/users'
export const URL_USER: string = '/settings/users/:id'
export const URL_PROFILE_USER: string = '/profile'

export const URL_REPORTS: string = '/reports'
export const URL_REPORTS_DETAILS: string = '/reports/:type'
export const URL_RECEPTIVENESS: string = '/receptiveness/'
export const URL_RECEPTIVENESS_TABLE: string = '/receptiveness/table'
export const URL_SCRIPT: string = '/script'

export const URL_CLIENTS: string = '/clients'
export const URL_NEWCLIENT: string = '/clients/new'
export const URL_CLIENTS_ID: string = '/clients/:id'

export const URL_APPLICATIONS: string = '/applications'
export const URL_PATIENTS: string = '/patients'
export const URL_PATIENT_REQUESTS: string = '/patients/requests/:id'
export const URL_APPLICATION: string = '/applications/:id'
export const URL_APPLICATION_SHOW: string = '/applications/:id/show'
export const URL_APPLICATION_EDIT: string = '/applications/:id/edit'
export const URL_APPLICATION_TEMPLATE: string = '/applications/:id/template'
export const URL_APPLICATION_TEMPLATE_NEW: string = '/applications/:id/new'
export const URL_APPLICATION_SHOW2: string = '/applications/:id/show2'
export const URL_APPLICATION_CLIENT_SHOW: string = '/applications/client/:id'
export const URL_APPLICATION_NEW: string = '/applications/new'
export const URL_HOME: string = '/home'

export const URL_HELP: string = '/help'
export const URL_CONTACT: string = '/help/contact'
export const URL_FAQ_CREATE: string = '/help/create'
export const URL_FAQ_EDIT: string = '/help/edit/:id'
export const URL_RESULTS: string = '/results'
