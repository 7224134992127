import {SampleNGS} from "../../../sampleType/models/sampleForm/SampleNGS";

export type PatientNGSProps = {
    _id: string | undefined;
    _firstName: string;
    _lastName: string;
    _dob: Date;
    _nhc: string;
    _nss: string
    _province: string
    _zipCode: string
    _phone: string;
    _email: string;
    _indication: string
    _clinicHistory: string
    _sample: SampleNGS
    _sex: string

};
export class  PatientNGS {
     _id: string | undefined;
     _firstName: string;
     _lastName: string;
     _dob: Date;
     _nhc: string;
     _nss: string
     _province: string
     _zipCode: string
     _phone: string;
     _email: string;
     _indication: string
     _clinicHistory: string
     _sample: SampleNGS
     _sex: string

    constructor(p: PatientNGSProps) {
        this._id = p._id;
        this._firstName = p._firstName;
        this._lastName = p._lastName;
        this._dob = p._dob;
        this._nhc = p._nhc;
        this._nss = p._nss;
        this._phone = p._phone;
        this._email = p._email;
        this._province = p._province;
        this._zipCode = p._zipCode
        this._indication = p._indication
        this._clinicHistory = p._clinicHistory
        this._sample = p._sample
        this._sex = p._sex
    }

    get id(): string | undefined {
        return this._id;
    }

    set id(value: string | undefined) {
        this._id = value;
    }

    get sex(): string {
        return this._sex;
    }

    set sex(value: string) {
        this._sex = value;
    }

    get firstName(): string {
        return this._firstName;
    }

    set firstName(value: string) {
        this._firstName = value;
    }

    get lastName(): string {
        return this._lastName;
    }

    set lastName(value: string) {
        this._lastName = value;
    }

    get dob(): Date {
        return this._dob;
    }

    set dob(value: Date) {
        this._dob = value;
    }

    get nhc(): string {
        return this._nhc;
    }

    set nhc(value: string) {
        this._nhc = value;
    }

    get nss(): string {
        return this._nss;
    }

    set nss(value: string) {
        this._nss = value;
    }

    get phone(): string {
        return this._phone;
    }

    set phone(value: string) {
        this._phone = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get province(): string {
        return this._province;
    }

    set province(value: string) {
        this._province = value;
    }

    get zipCode(): string {
        return this._zipCode;
    }

    set zipCode(value: string) {
        this._zipCode = value;
    }

    get indication(): string {
        return this._indication;
    }

    set indication(value: string) {
        this._indication = value;
    }

    get clinicHistory(): string {
        return this._clinicHistory;
    }

    set clinicHistory(value: string) {
        this._clinicHistory = value;
    }

    get sample(): SampleNGS {
        return this._sample;
    }

    set sample(value: SampleNGS) {
        this._sample = value;
    }
}
