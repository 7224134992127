import {v4 as uuidv4} from 'uuid'
import {SampleOncology} from "../../../sampleType/models/sampleForm/SampleOncology";
import {PatientOncology} from "./PatientOncology";
import {emptySampleOncologyDTO} from "../../../sampleType/models/sampleForm/SampleOncologyDTO";

export interface PatientOncologyDTO {
    _id: string | undefined;
    _firstName: string;
    _lastName: string;
    _dni: string
    _dob: Date;
    _sex: string
    _street: string
    _city: string
    _zipCode: string
    _country: string
    _phone: string;
    _diagnosis: string
    _stage: string
    _indication: string
    _clinicHistory: string
    _sample: SampleOncology
}

export function emptyPatientOncologyDTO(): PatientOncologyDTO {
    return {
        _id: uuidv4(),
        _firstName: '',
        _lastName: '',
        _dni: '',
        _dob: new Date(),
        _city: '',
        _country: '',
        _diagnosis: '',
        _sex: "0",
        _stage: '',
        _sample: new SampleOncology(emptySampleOncologyDTO()),
        _clinicHistory: '',
        _phone: '',
        _street: '',
        _zipCode: '',
        _indication: ''
    }
}

export function fromPatientOncology(c: PatientOncology): PatientOncologyDTO {
    return {
        _id: c.id,
        _firstName: c.firstName,
        _lastName: c.lastName,
        _dni: c.dni,
        _dob: c.dob,
        _sex: c.sex,
        _street: c.street,
        _stage: c.stage,
        _diagnosis: c.diagnosis,
        _country: c.country,
        _city: c.city,
        _zipCode: c.zipCode,
        _phone: c.phone,
        _indication: c.indication,
        _clinicHistory: c.clinicHistory,
        _sample: c.sample
    }
}

export function toModelPatientOncology(c: PatientOncologyDTO): PatientOncology {
    return new PatientOncology(c)
}

export function toModelPatientOncologyList(c: PatientOncologyDTO[]): PatientOncology[] {
    let list: PatientOncology[] = []
    c?.forEach((p) => {
        list.push(new PatientOncology(p))
    })

    return list
}