import React, {CSSProperties, useState, useEffect} from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { Actions, Field, Sort } from './types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { getClientContainer } from 'container/client-modules'
import { ClientService } from 'modules/clients/services/ClientService'
import { CLIENTS_SERVICE_KEY } from 'modules/clients'
import { Client, ClientQuery } from 'modules/clients/models/Client'
import { getAuthContainer } from 'container/auth-modules'
import { AuthService } from 'modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from 'modules/auth'
import { Query, QueryParam } from 'common/api/Query'

type HeadProps<T> = {
  readonly fields: Field<T>[]
  readonly sort?: Sort<T>
  readonly actions?: Actions<T>
  readonly styleHeader?: CSSProperties
  readonly areRequests?: boolean
  readonly handleCheckAll?: () => void
  readonly allChecked?: boolean
}

const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export function Head<T extends { [key: string]: any }>(props: HeadProps<T>) {
  const handleSort = (name: keyof T) => () => props.sort?.handleSort && props.sort.handleSort(name)
  const { t } = useTranslation()
  const [client, setClient] = useState<Client>()
  const loggedUser = authService.get()

  useEffect(() => {
    if (loggedUser) {
      clientService
        .getFilteredList(
          new Query({
            query: [new QueryParam<ClientQuery>('ID', loggedUser.clinicID)],
          })
        )
        .subscribe((res) => {
          if (res && res.items.length > 0) {
            setClient(res.items[0])
          }
        })
    }
  }, [loggedUser])

  const useStyles = makeStyles((_) => ({
    head: {
      fontWeight: 'bold',
      fontSize: '18px',
      backgroundColor: '#FFFFFF',
    },
  }))

  const classes = useStyles()

  const headCells = Object.values(props.fields).map((f) => (
    <TableCell style={props.styleHeader} className={classes.head} key={f.name as string}>
      {f.sortable ? (
        <TableSortLabel
          active={f.name === props.sort?.name}
          direction={props.sort?.direction || 'asc'}
          onClick={handleSort(f.name)}>
          {f.label}
        </TableSortLabel>
      ) : (
        f.label
      )}
    </TableCell>
  ))

  return (
    <TableHead>
      <TableRow>
        {props.areRequests && client?.country == 'spain' && (
          <TableCell style={{flex: 1, display: "flex", flexDirection: "row"}}>
            <input
              type="checkbox"
              checked={props.allChecked}
              onChange={() => props.handleCheckAll && props.handleCheckAll()}
          />
          <p style={{marginLeft: "10px", fontWeight: "normal", fontSize: "13px", lineHeight: "15px"}}>{t("selectAll")}</p>
          </TableCell>
        )}
        {headCells}
        {props.actions && props.actions.actionsColumn && (
          <TableCell style={props.styleHeader} className={classes.head}>{props.actions.actionsColumn}</TableCell>
        )}
      </TableRow>
    </TableHead>
  )
}
