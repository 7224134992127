export type SampleProps = {
  id: string | undefined
  type: string
  date: Date
  codeSample: string
  idOdooType: string
}

export class Sample {
  readonly _id: string | undefined
  _type: string
  _date: Date
  _codeSample: string
  _idOdooType: string

  constructor(p: SampleProps) {
    this._id = p.id
    this._type = p.type
    this._date = p.date
    this._codeSample = p.codeSample
    this._idOdooType = p.idOdooType
  }

  get id(): string | undefined {
    return this._id
  }

  get type(): string {
    return this._type
  }

  set type(value: string) {
    this._type = value
  }

  get date(): Date {
    return this._date
  }

  set date(value: Date) {
    this._date = value
  }

  get codeSample(): string {
    return this._codeSample
  }

  set codeSample(value: string) {
    this._codeSample = value
  }

  get idOdooType(): string {
    return this._idOdooType
  }

  set idOdooType(value: string) {
    this._idOdooType = value
  }

}

export interface SampleQuery {
  id: string
  ids: string[]
  date: Date
  codeSample: string
  type: string
}
