import {Container} from "../../common/container/Container";


export type Client2RequestContainerConfig = {
    moduleFullUrl: string
}

export type Client2RequestProps = {
    parentContainer: Container
    config: Client2RequestContainerConfig
}

export const CLIENT2REQUEST_API_KEY = Symbol('CLIENT2REQUEST_API_KEY')
export const CLIENT2REQUEST_SERVICE_KEY = Symbol('CLIENT2REQUEST_SERVICE_KEY')
export const CLIENT2REQUEST_MODULE = Symbol('CLIENT2REQUEST_MODULE')