export const TITLE_DASHBOARD: string = 'Bioarray'

export const TITLE_GENE_TYPES: string = 'geneTypes'
export const TITLE_USERS: string = 'users'
export const TITLE_PROFILE_USER: string = 'profile'

export const TITLE_REPORTS: string = 'reports'
export const TITLE_PARAMETERS: string = 'parameters'
export const TITLE_TRANSLATIONS: string = 'translations'
export const TITLE_NEWCLIENT: string = 'newClients'
export const TITLE_TESTS: string = 'tests'
export const TITLE_RECEPTIVENESS: string = 'receptiveness'
export const TITLE_SCRIPT: string = 'Script'

export const TITLE_HOMES: string = 'Home'
export const TITLE_HOME: string = 'Home'
export const TITLE_CLIENTS: string = 'clients'
export const TITLE_APPLICATION: string = 'request'
export const TITLE_APPLICATIONS: string = 'requests'
export const TITLE_PATIENTS: string = 'patients'
export const TITLE_PATIENT_REQUESTS: string = 'patientRequests'
export const TITLE_APPLICATIONSMANAGE: string = 'ApplicationsManage'
export const TITLE_APPLICACIONSDETAIL: string = 'ApplicationsDetail'
export const TITLE_APPLICACIONSDETAIL2: string = 'ApplicationsDetail2'
export const TITLE_APPLICAIONSNEW: string = 'ApplicationsNew'
export const TITLE_HELP: string = 'Help'
export const TITLE_CONTACT: string = 'contact'
export const TITLE_FAQS_CREATE: string = 'createFaq'
export const TITLE_RESULTS: string = 'results'
export const TITLE_STATISTICS: string = 'statistics'
