import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { Statistics, StatisticsQuery } from '../models/Statistics'
import { StatisticsContainerConfig } from '../container'
import { StatisticsDTO, toModel } from '../models/StatisticsDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { MonthlyDataQuery } from "../models/MonthlyData";
import { emptyMonthlyDTO, modifyPathologyValue, MonthlyDataDTO } from "../models/MonthlyDataDTO";
import { PathologyFromIndex } from "../utils/Pathology";
import { MonthFromIndex } from "../utils/Month";
import { Pathology } from "../enum/Pathology";
import { File as F, FileDTO } from "../../../modules/files/models/File"

export interface IStatisticsApi extends IInit {
    getByID(id: string): Observable<Statistics | undefined>

    getFilteredList(q: Query<StatisticsQuery>): Observable<ItemList<Statistics>>

    add(e: StatisticsDTO): Observable<Statistics | undefined>

    update(e: StatisticsDTO): Observable<Statistics | undefined>

    delete(id: string): Observable<boolean>

    uploadFile(file: string[]): Observable<string | undefined>

    getGraphData(q: Query<MonthlyDataQuery>): Observable<MonthlyDataDTO[]>

    generateXLS(q: Query<MonthlyDataQuery>): Observable<F | undefined>

    generatePDF(q: Query<MonthlyDataQuery>, file: FileDTO): Observable<F | undefined>
}

export class StatisticsApi implements IStatisticsApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as StatisticsContainerConfig).moduleFullUrl
    }

    add(e: StatisticsDTO): Observable<Statistics | undefined> {
        return this._httpClient.post<Statistics>({ url: this._url, body: e }).pipe(
            map<StatisticsDTO, Statistics>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<Statistics | undefined> {
        return this._httpClient.get<Statistics>({ url: `${this._url}/${id}` }).pipe(
            map<StatisticsDTO, Statistics>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getFilteredList(q: Query<StatisticsQuery>): Observable<ItemList<Statistics>> {
        return this._httpClient.get<ItemList<Statistics>>({ url: prepareURL(this._url, q) }).pipe(
            map<ItemList<StatisticsDTO>, ItemList<Statistics>>((dto) => {
                const itemList = emptyList<Statistics>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<Statistics>())
            })
        )
    }

    update(e: StatisticsDTO): Observable<Statistics | undefined> {
        return this._httpClient.put<Statistics>({ url: this._url, body: e }).pipe(
            map<StatisticsDTO, Statistics>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    uploadFile(e: string[]): Observable<string | undefined> {
        return this._httpClient.post({ url: this._url + "/uploadFile", body: e }).pipe(
            map((d: any) => {
                return d
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getGraphData(q: Query<MonthlyDataQuery>): Observable<MonthlyDataDTO[]> {
        return this._httpClient.get<any>({ url: prepareURL(this._url + "/statistics/graphData", q) }).pipe(
            map((dto) => {
                let pathologyIndex = q.getParam("type")
                if (pathologyIndex === undefined) {
                    throw new Error("Unexpected pathology value")
                }
                const pathology = PathologyFromIndex(pathologyIndex as number)
                if (!pathology) {
                    throw new Error("Unexpected pathology value")
                }

                let totalData: MonthlyDataDTO[];
                if (pathology === Pathology.AllPathologies) {
                    totalData = dto.map((data: { percentage: number, type: number }[], month: number) => {
                        let monthlyData: MonthlyDataDTO = emptyMonthlyDTO()
                        monthlyData.month = MonthFromIndex(month)
                        data.forEach(item => {
                            monthlyData = modifyPathologyValue(monthlyData, PathologyFromIndex(item.type), item.percentage)
                        })
                        return monthlyData
                    })
                } else {
                    totalData = dto.map((item: { percentage: number, type: number }, month: number) => {
                        let monthlyData: MonthlyDataDTO = emptyMonthlyDTO()
                        monthlyData.month = MonthFromIndex(month)
                        monthlyData = modifyPathologyValue(monthlyData, PathologyFromIndex(item.type), item.percentage)
                        return monthlyData
                    })
                }
                return totalData
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of([])
            })
        )
    }

    generateXLS(q: Query<MonthlyDataQuery>): Observable<F | undefined> {
        return this._httpClient.get<F>({ url: prepareURL(this._url + "/statistics/generateXLS", q) }).pipe(
            map((d) => {
                return d
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    generatePDF(q: Query<MonthlyDataQuery>, file: FileDTO): Observable<F | undefined> {
        return this._httpClient.post<F>({ url: prepareURL(this._url + "/statistics/generatePDF", q), body: file }).pipe(
            map((d) => {
                return d
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }
}
