import {
  Button,
  Modal,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useStyles } from './Application.styles'
import React, { useState } from 'react'
import { PatientProduct } from '../../modules/patient/models/relationship/PatientProduct'
import { FormType } from '../../common/enums/Enums'
import { SampleNull, SampleMicroVE } from './samplePatients'
import { navigate } from '@reach/router'
import { URL_APPLICATIONS } from '../../routes/routes-constants'
import { SampleNGS } from './samplePatients/sampleNGS'
import { SamplePGT } from './samplePatients/samplePGT'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { SampleCitogeneticaArray } from './samplePatients/sampleCitogeneticaArray'
import { SampleOncology } from './samplePatients/sampleOncology'
import { Request } from '../../modules/request/models/Request'
import {
  PatientProductDTO,
  emptyPatientProductDTO,
} from 'modules/patient/models/relationship/PatientProductDTO'
import { getAuthContainer } from 'container/auth-modules'
import { AuthService } from 'modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from 'modules/auth'
import { Address } from 'modules/address/models/Address'
import { emptyAddressDTO, toModel } from 'modules/address/models/AddressDTO'
import { getClient2RequestContainer } from 'container/client2request-modules'
import { CLIENT2REQUEST_SERVICE_KEY } from 'modules/client2Requests'
import { Client2RequestService } from 'modules/client2Requests/services/Client2RequestService'
import { ModalConsent } from './ModalConsent'
import { FILE_SERVICE_KEY } from 'modules/files'
import { FileService } from 'modules/files/services/FileService'
import { getFileContainer } from 'container/file-module'
import { downloadFile } from '../../common/files/file'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import { Error } from '../../common/error/ErrorModel'
import { ErrorSnackBar } from '../../common/error/ErrorSnackBar'
import { getSampleTypeContainer } from 'container/sampleType-modules'
import { SampleTypeService } from 'modules/sampleType/services/SampleTypeService'
import { SAMPLETYPE_SERVICE_KEY } from 'modules/sampleType'
import { Query, QueryParam } from 'common/api/Query'
import { useSnackbar } from 'notistack'

type newApplicationProps = {
  id?: string
  increaseStep: () => void
  decreseStep: () => void
  patientProducts: PatientProduct[]
  setPatientProducts: React.Dispatch<React.SetStateAction<PatientProduct[]>> | undefined
  isReturned?: boolean
  edit?: boolean
  request?: Request | undefined
  type?: string
  pickUp?: Address
  files?: any[]
  userDoingTheRequest: string
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const clientRequestService = getClient2RequestContainer().get<Client2RequestService>(
  CLIENT2REQUEST_SERVICE_KEY
)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const sampleTypeService = getSampleTypeContainer().get<SampleTypeService>(SAMPLETYPE_SERVICE_KEY)

export const SampleAplication = (props: newApplicationProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t } = useTranslation()
  const [disableButton, setDisableButton] = useState<boolean>(false)
  const loggedUser = authService.get()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pickUp, setPickUp] = useState<Address>(
    props.pickUp != undefined ? props.pickUp : toModel(emptyAddressDTO())
  )
  const [requests, setRequests] = useState<Request[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [consent, setConsent] = useState<boolean>(false)
  const [snackBar, setSnackBar] = useState<Error>()
  const [open, setOpen] = useState<boolean>(false)
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    (props.patientProducts?.length > 0 && props.patientProducts[0].language) || ''
  )
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handleChangePatientProducts = (pp: PatientProduct, index: number) => {
    let aux = [...props.patientProducts]
    aux[index] = pp
    props.setPatientProducts && props.setPatientProducts(aux)
  }

  const handleChangeLanguage = (language: string) => {
    let aux = [...props.patientProducts]
    aux[0].language = language
    aux[0]._language = language
    setSelectedLanguage(language)
    props.setPatientProducts && props.setPatientProducts(aux)
  }

  const switchSamplesByProduct = () => {
    return (
      <>
        {props.patientProducts?.map((p, i) => {
          if (p._product) {
            if (props.isReturned) {
              switch (Number(p._product.typeForm)) {
                case FormType.TypeFormMicroVEBioER:
                  return (
                    <SampleMicroVE
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p.patientMicroVE}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormPGT:
                  return (
                    <SamplePGT
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p.patientPGTs}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormNGS:
                  return (
                    <SampleNGS
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p.patientNGS}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormCitogeneticaArray:
                  return (
                    <SampleCitogeneticaArray
                      patients={p.patientCitogenetics}
                      patientProduct={p}
                      product={p._product}
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormOncology:
                  return (
                    <SampleOncology
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p.patientOncology}
                      edit={props.edit}
                    />
                  )
                default:
                  return (
                    <SampleNull
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p.patientNulls}
                      edit={props.edit}
                    />
                  )
              }
            } else {
              switch (Number(p._product.typeForm)) {
                case FormType.TypeFormMicroVEBioER:
                  return (
                    <SampleMicroVE
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p._patientMicroVE}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormPGT:
                  return (
                    <SamplePGT
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p._patientPGTs}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormNGS:
                  return (
                    <SampleNGS
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p._patientNGS}
                      edit={props.edit}
                    />
                  )

                case FormType.TypeFormCitogeneticaArray:
                  return (
                    <SampleCitogeneticaArray
                      patients={p._patientCitogenetics}
                      patientProduct={p}
                      product={p._product}
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormOncology:
                  return (
                    <SampleOncology
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p._patientOncology}
                      edit={props.edit}
                    />
                  )
                default:
                  return (
                    <SampleNull
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p._product}
                      patientProduct={p}
                      patients={p._patientNulls}
                      edit={props.edit}
                    />
                  )
              }
            }
          } else {
            if (p.product) {
              switch (Number(p.product.typeForm)) {
                case FormType.TypeFormMicroVEBioER:
                  return (
                    <SampleMicroVE
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p.product}
                      patientProduct={p}
                      patients={p.patientMicroVE}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormPGT:
                  return (
                    <SamplePGT
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p.product}
                      patientProduct={p}
                      patients={p.patientPGTs}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormNGS:
                  return (
                    <SampleNGS
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p.product}
                      patientProduct={p}
                      patients={p.patientNGS}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormCitogeneticaArray:
                  return (
                    <SampleCitogeneticaArray
                      patients={p.patientCitogenetics}
                      patientProduct={p}
                      product={p.product}
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormOncology:
                  return (
                    <SampleOncology
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p.product}
                      patientProduct={p}
                      patients={p.patientOncology}
                      edit={props.edit}
                    />
                  )
                default:
                  return (
                    <SampleNull
                      handleChangePatientProducts={handleChangePatientProducts}
                      index={i}
                      product={p.product}
                      patientProduct={p}
                      patients={p.patientNulls}
                      edit={props.edit}
                    />
                  )
              }
            }
          }
        })}
      </>
    )
  }

  const handleFinish = () => {
    let completed = true
    props.patientProducts.forEach((p) => {
      if (p._product) {
        switch (Number(p._product.typeForm)) {
          case FormType.TypeFormMicroVEBioER:
            p._patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (patient._sample._question1) {
                  if (!patient._sample._endDate) {
                    patient._sample._endDate = new Date()
                  }
                  if (
                    !patient._sample._typeAntibioticTreatment ||
                    !patient._sample._treatmentDuration ||
                    !patient._sample._endDate
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('typeAntibioticTreatment') +
                        ', ' +
                        t('treatmentDuration') +
                        ', ' +
                        t('endDate'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }

                if (i == 0) {
                  if (
                    !patient._sample._typeSample
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheField') +
                        t('typeSample'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }

                if (patient._sample._question2) {
                  if (!patient._sample._reportDate) {
                    patient._sample._reportDate = new Date()
                  }
                  if (
                    !patient._sample._reportDate ||
                    patient._sample._result == "" ||
                    patient._sample._followedTreatment == ""
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('reportDate') +
                        ', ' +
                        t('result') +
                        ', ' +
                        t('followedTreatment'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          
            case FormType.TypeFormPGT: 
            p._patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;

            case FormType.TypeFormOncology: 
            p._patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;
            case FormType.TypeFormCitogeneticaArray: 
            p._patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;
            case FormType.TypeFormNGS: 
            p._patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;
            case FormType.TypeFormNull: 
            p._patientNulls.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._type
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;
          default:
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
        }
      } else if (p.product) {
        switch (Number(p.product.typeForm)) {
          case FormType.TypeFormMicroVEBioER:
            p.patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }

                if (patient._sample._question1) {
                  if (
                    !patient._sample._typeAntibioticTreatment ||
                    !patient._sample._treatmentDuration ||
                    !patient._sample._endDate
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('typeAntibioticTreatment') +
                        ', ' +
                        t('treatmentDuration') +
                        ', ' +
                        t('endDate'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }

                if (patient._sample._question2) {
                  if (
                    !patient._sample._reportDate ||
                    !patient._sample._result ||
                    !patient._sample._followedTreatment
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('reportDate') +
                        ', ' +
                        t('result') +
                        ', ' +
                        t('followedTreatment'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }
              }
            })

            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

            case FormType.TypeFormPGT: 
            p.patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;

            case FormType.TypeFormOncology: 
            p.patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;
            
            case FormType.TypeFormCitogeneticaArray: 
            p.patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })

            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;

            case FormType.TypeFormNGS: 
            p.patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._typeSample
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })

            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;
            case FormType.TypeFormNull: 
            p.patientNulls.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._sample._type
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheField') +
                      t('typeSample'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })

            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break;
          default:
            if (!props.patientProducts[0].language && !props.patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
        }
      }
    })

    completed && sendNewRequest()
  }

  const sendNewRequest = async () => {
    let arrToSend: PatientProductDTO[] = []
    setIsLoading(true)

    if (props.type === 'template' || !props.request) {
      let pdto = emptyPatientProductDTO()
      pdto.userID = props.userDoingTheRequest
      pdto.product = props.patientProducts[0]._product
      pdto.patientNulls = props.patientProducts[0].patientNulls
      pdto.patientPGTs = props.patientProducts[0].patientPGTs
      pdto.patientNGS = props.patientProducts[0].patientNGS
      pdto.patientMicroVE = props.patientProducts[0].patientMicroVE
      pdto.patientOncology = props.patientProducts[0].patientOncology
      pdto.patientCitogenetics = props.patientProducts[0].patientCitogenetics
      pdto.genes = props.patientProducts[0]._genes || props.patientProducts[0].genes
      pdto.indications = props.patientProducts[0]._indications
      pdto.address = pickUp
      //@ts-ignore
      pdto.file = props.files
      pdto.language = props.patientProducts[0].language || props.patientProducts[0]._language

      let types: string[] = []

      props.patientProducts.forEach((pp, i) => {
        if (i > 0) {
          pp.patientNulls?.forEach((p) => {
            types.push(p._sample?._type)
          })

          pp.patientPGTs?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientNGS?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientMicroVE?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientOncology?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientCitogenetics?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })
        }
      })

      //@ts-ignore
      let uniqueArray = [...new Set(types)]

      if ((uniqueArray?.length > 0) && (!pdto.indications)) {
        let objTypes = await sampleTypeService
          .getFilteredListDB(
            new Query({
              query: [new QueryParam('idsOdoo', uniqueArray)],
            })
          )
          .toPromise()

        let indication = 'También llega muestra '

        objTypes?.items?.forEach((t, i) => {
          if (i > 0) {
            indication += ', '
          }
          indication += t.nameES
        })

        pdto.indications = indication
      }
      arrToSend.push(pdto)

      for (let i=1; i < props.patientProducts?.length; i++) {
        let pdto = emptyPatientProductDTO()
        pdto.userID = props.userDoingTheRequest
        pdto.product = props.patientProducts[i]._product
        pdto.patientNulls = props.patientProducts[i].patientNulls
        pdto.patientPGTs = props.patientProducts[i].patientPGTs
        pdto.patientNGS = props.patientProducts[i].patientNGS
        pdto.patientMicroVE = props.patientProducts[i].patientMicroVE
        pdto.patientOncology = props.patientProducts[i].patientOncology
        pdto.patientCitogenetics = props.patientProducts[i].patientCitogenetics
        pdto.genes = props.patientProducts[i]._genes || props.patientProducts[i].genes
        pdto.indications = props.patientProducts[i]._indications
        pdto.address = pickUp
        //@ts-ignore
        pdto.file = props.files

        arrToSend.push(pdto)
      }

      clientRequestService.sendPatientProduct(arrToSend).subscribe((res) => {
        //@ts-ignore
        res && setRequests(res)
        setIsLoading(false)
        setOpenModal(true)
      })
    } else {
      setRequests([props.request])

      let pdto = emptyPatientProductDTO()
      pdto.userID = loggedUser.id
      pdto.product = props.patientProducts[0].product
      pdto.patientNulls = props.patientProducts[0].patientNulls
      pdto.patientPGTs = props.patientProducts[0].patientPGTs
      pdto.patientNGS = props.patientProducts[0].patientNGS
      pdto.patientMicroVE = props.patientProducts[0].patientMicroVE
      pdto.patientOncology = props.patientProducts[0].patientOncology
      pdto.patientCitogenetics = props.patientProducts[0].patientCitogenetics
      pdto.genes = props.patientProducts[0]._genes || props.patientProducts[0].genes
      pdto.indications = props.patientProducts[0].indications
      pdto.address = pickUp
      //@ts-ignore
      pdto.file = props.files
      pdto.language = props.patientProducts[0].language || props.patientProducts[0]._language

      let types: string[] = []

      props.patientProducts.forEach((pp, i) => {
        if (i > 0) {
          pp.patientNulls?.forEach((p) => {
            types.push(p._sample?._type)
          })

          pp.patientPGTs?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientNGS?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientMicroVE?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientOncology?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })

          pp.patientCitogenetics?.forEach((p) => {
            types.push(p._sample?._typeSample)
          })
        }
      })

      //@ts-ignore
      let uniqueArray = [...new Set(types)]

      if ((uniqueArray?.length > 0) && (!pdto.indications)){
        let objTypes = await sampleTypeService
          .getFilteredListDB(
            new Query({
              query: [new QueryParam('idsOdoo', uniqueArray)],
            })
          )
          .toPromise()

        let indication = 'También llega muestra '

        objTypes?.items?.forEach((t, i) => {
          if (i > 0) {
            indication += ', '
          }
          indication += t.nameES
        })

        pdto.indications = indication
      }
      arrToSend.push(pdto)

      for (let i=1; i < props.patientProducts?.length; i++) {
        let pdto = emptyPatientProductDTO()
        pdto.userID = props.userDoingTheRequest
        pdto.product = props.patientProducts[i]._product
        pdto.patientNulls = props.patientProducts[i].patientNulls
        pdto.patientPGTs = props.patientProducts[i].patientPGTs
        pdto.patientNGS = props.patientProducts[i].patientNGS
        pdto.patientMicroVE = props.patientProducts[i].patientMicroVE
        pdto.patientOncology = props.patientProducts[i].patientOncology
        pdto.patientCitogenetics = props.patientProducts[i].patientCitogenetics
        pdto.genes = props.patientProducts[i]._genes || props.patientProducts[i].genes
        pdto.indications = props.patientProducts[i]._indications
        pdto.address = pickUp
        //@ts-ignore
        pdto.file = props.files

        arrToSend.push(pdto)
      }

      clientRequestService.updateWizard(props.request.id, arrToSend).subscribe(() => {
        setIsLoading(false)
        setOpenModal(true)
      })
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)

    navigate(URL_APPLICATIONS)
  }

  const handlePrint = () => {
    if (requests.length == 0) {
      setOpenModal(false)
      navigate(URL_APPLICATIONS)
    }

    let request = requests[0]
    fileService.downloadReport(request.id).subscribe((res) => {
      res && downloadFile(res.name, res.mimeType, res.base64)
      setOpenModal(false)
      navigate(URL_APPLICATIONS)
    })
  }

  const getLanguagesByProduct = () => {
    if (props.patientProducts[0]._product) {
      switch (Number(props.patientProducts[0]._product.typeForm)) {
        case FormType.TypeFormCitogeneticaArray:
          let arr: any[] = []
          arr.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr.push({
            label: t('english'),
            value: 'english',
          })
          return arr
        case FormType.TypeFormMicroVEBioER:
          let arr2: any[] = []
          arr2.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr2.push({
            label: t('english'),
            value: 'english',
          })
          return arr2
        case FormType.TypeFormNGS:
          let arr3: any[] = []
          arr3.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr3.push({
            label: t('english'),
            value: 'english',
          })
          return arr3
        case FormType.TypeFormNull:
          let arr6: any[] = []
          arr6.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr6.push({
            label: t('english'),
            value: 'english',
          })
          return arr6
        case FormType.TypeFormOncology:
          let arr4: any[] = []
          arr4.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr4.push({
            label: t('english'),
            value: 'english',
          })
          return arr4
        case FormType.TypeFormPGT:
          let arr5: any[] = []
          arr5.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr5.push({
            label: t('english'),
            value: 'english',
          })
          arr5.push({
            label: t('french'),
            value: 'french',
          })
          arr5.push({
            label: t('italian'),
            value: 'italian',
          })
          return arr5
        default:
          let arr7: any[] = []
          arr7.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr7.push({
            label: t('english'),
            value: 'english',
          })
          return arr7
      }
    } else if (props.patientProducts[0].product) {
      switch (Number(props.patientProducts[0].product.typeForm)) {
        case FormType.TypeFormCitogeneticaArray:
          let arr: any[] = []
          arr.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr.push({
            label: t('english'),
            value: 'english',
          })
          return arr
        case FormType.TypeFormMicroVEBioER:
          let arr2: any[] = []
          arr2.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr2.push({
            label: t('english'),
            value: 'english',
          })
          return arr2
        case FormType.TypeFormNGS:
          let arr3: any[] = []
          arr3.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr3.push({
            label: t('english'),
            value: 'english',
          })
          return arr3
        case FormType.TypeFormNull:
          let arr6: any[] = []
          arr6.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr6.push({
            label: t('english'),
            value: 'english',
          })
          return arr6
        case FormType.TypeFormOncology:
          let arr4: any[] = []
          arr4.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr4.push({
            label: t('english'),
            value: 'english',
          })
          return arr4
        case FormType.TypeFormPGT:
          let arr5: any[] = []
          arr5.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr5.push({
            label: t('english'),
            value: 'english',
          })
          arr5.push({
            label: t('french'),
            value: 'french',
          })
          arr5.push({
            label: t('italian'),
            value: 'italian',
          })
          return arr5
        default:
          let arr7: any[] = []
          arr7.push({
            label: t('spanish'),
            value: 'spanish',
          })
          arr7.push({
            label: t('english'),
            value: 'english',
          })
          return arr7
      }
    }
  }

  return (
    <>
      {!isLoading ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <Typography className={classes.title} variant={'h4'} align="left">
              {t('samples')}
            </Typography>
          </div>
          <div style={{ marginBottom: '2%' }}>
            {switchSamplesByProduct()}

            <div style={{ height: '1wpx', backgroundColor: 'grey', marginTop: '4%' }}></div>
            <Grid container spacing={1} className={classes.pInformationGrid}>
              <Grid item xs={12} className={classes.titleText}>
                {t('reportLanguage')}
              </Grid>
            </Grid>
            <Grid item xs={6} spacing={2} style={{ marginTop: '2%', marginBottom: '4%' }}>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="languageLabel">{t('language') + '*'}</InputLabel>
                <Select
                  labelId={'languageLabel'}
                  id={'language'}
                  disabled={props.edit == false}
                  name={'language'}
                  onChange={(e) => handleChangeLanguage(e.target.value as string)}
                  required
                  value={selectedLanguage}>
                  {getLanguagesByProduct()?.map((lan) => (
                    <MenuItem key={'languageS'} value={lan?.value}>
                      {lan?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <div className={classes.buttonBoxPickUp}>
              <Button
                variant="contained"
                startIcon={<ArrowBack />}
                onClick={props.decreseStep}
                className={classes.button}>
                {t('back2')}
              </Button>

              {props.request?.status !== 'Sent' && props.type != 'view' && !disableButton ? (
                <Button className={classes.buttonSecondary} type={'button'} onClick={handleFinish}>
                  {t('finish')}
                </Button>
              ) : (
                <>
                  {props.type == 'template' && (
                    <Button
                      className={classes.buttonSecondary}
                      type={'button'}
                      onClick={handleFinish}>
                      {t('finish')}
                    </Button>
                  )}
                </>
              )}
            </div>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ModalConsent
                handleModalClose={handleCloseModal}
                consent={consent}
                onPrint={handlePrint}
              />
            </Modal>
          </div>
        </>
      ) : (
        <LoadingSpinner className={classes.loadingSpinner} />
      )}
      {snackBar && ErrorSnackBar({ t: t, err: snackBar, open: open, handleClose: handleClose })}
    </>
  )
}
