import { ClientRegisterDTO } from "./ClientDTO";

export type ClientOdoo = {
  label: string
  value: string
}

export type ClientProps = {
  id: string | undefined
  taxName: string
  taxIdentity: string
  street: string
  number: string
  zipCode: string
  city: string
  province: string
  country: string
  idOdoo: string
  referencedClientID: string | null
  cosnotifications: boolean
}

export type ClientRegisterProps = {
  id: string
  taxName: string
  taxIdentity: string
  street: string
  number: string
  zipCode: string
  city: string
  province: string
  country: string
  password: string
  validatePassword: string
  referencedClientID: string | null
  cosnotifications: boolean
}

export class ClientRegister {
  private _id: string
  private _taxName: string
  private _taxIdentity: string
  private _street: string
  private _number: string
  private _zipCode: string
  private _city: string
  private _province: string
  private _country: string
  private _password: string
  private _validatePassword: string
  private _referencedClientID: string | null
  private _cosnotifications: boolean

  constructor(p: ClientRegisterProps) {
    this._id = p.id
    this._taxName = p.taxName
    this._taxIdentity = p.taxIdentity
    this._street = p.street
    this._number = p.number
    this._zipCode = p.zipCode
    this._city = p.city
    this._province = p.province
    this._country = p.country
    this._password = p.password
    this._validatePassword = p.validatePassword
    this._referencedClientID = p.referencedClientID
    this._cosnotifications = p.cosnotifications
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get cosnotifications(): boolean {
    return this._cosnotifications
  }

  set cosnotifications(value: boolean) {
    this._cosnotifications = value
  }

  get taxName(): string {
    return this._taxName
  }

  set referencedClientID(value: string | null) {
    this._referencedClientID = value
  }

  get referencedClientID(): string | null {
    return this._referencedClientID
  }

  set taxName(value: string) {
    this._taxName = value
  }

  get taxIdentity(): string {
    return this._taxIdentity
  }

  set taxIdentity(value: string) {
    this._taxIdentity = value
  }

  get street(): string {
    return this._street
  }

  set street(value: string) {
    this._street = value
  }

  get number(): string {
    return this._number
  }

  set number(value: string) {
    this._number = value
  }

  get zipCode(): string {
    return this._zipCode
  }

  set zipCode(value: string) {
    this._zipCode = value
  }

  get city(): string {
    return this._city
  }

  set city(value: string) {
    this._city = value
  }

  get province(): string {
    return this._province
  }

  set province(value: string) {
    this._province = value
  }

  get country(): string {
    return this._country
  }

  set country(value: string) {
    this._country = value
  }

  get password(): string {
    return this._password
  }

  set password(value: string) {
    this._password = value
  }

  get validatePassword(): string {
    return this._validatePassword
  }

  set validatePassword(value: string) {
    this._validatePassword = value
  }

  toDTO(): ClientRegisterDTO {
    return {
      id: this.id,
      taxName: this.taxName,
      taxIdentity: this.taxIdentity,
      street: this.street,
      number: this.number,
      zipCode: this.zipCode,
      city: this.city,
      province: this.province,
      country: this.country,
      password: this.password,
      validatePassword: this.validatePassword,
      referencedClientID: this.referencedClientID,
      cosnotifications: this.cosnotifications,
    }
  }
}

export class Client {
  private _id: string | undefined
  private _taxName: string
  private _taxIdentity: string
  private _street: string
  private _number: string
  private _zipCode: string
  private _city: string
  private _province: string
  private _country: string
  private _idOdoo: string
  private _referencedClientID: string | null
  private _cosnotifications: boolean

  constructor(p: ClientProps) {
    this._id = p.id
    this._taxName = p.taxName
    this._taxIdentity = p.taxIdentity
    this._street = p.street
    this._number = p.number
    this._zipCode = p.zipCode
    this._city = p.city
    this._province = p.province
    this._country = p.country
    this._idOdoo = p.idOdoo
    this._referencedClientID = p.referencedClientID
    this._cosnotifications = p.cosnotifications
  }

  get id(): string | undefined {
    return this._id
  }

  set id(value: string | undefined) {
    this._id = value
  }

  get cosnotifications(): boolean {
    return this._cosnotifications
  }

  set cosnotifications(value: boolean) {
    this._cosnotifications = value
  }

  get referencedClientID(): string | null {
    return this._referencedClientID
  }

  set referencedClientID(value: string | null) {
    this._referencedClientID = value
  }

  get taxName(): string {
    return this._taxName
  }

  set taxName(value: string) {
    this._taxName = value
  }

  get taxIdentity(): string {
    return this._taxIdentity
  }

  set taxIdentity(value: string) {
    this._taxIdentity = value
  }

  get street(): string {
    return this._street
  }

  set street(value: string) {
    this._street = value
  }

  get number(): string {
    return this._number
  }

  set number(value: string) {
    this._number = value
  }

  get zipCode(): string {
    return this._zipCode
  }

  set zipCode(value: string) {
    this._zipCode = value
  }

  get city(): string {
    return this._city
  }

  set city(value: string) {
    this._city = value
  }

  get province(): string {
    return this._province
  }

  set province(value: string) {
    this._province = value
  }

  get country(): string {
    return this._country
  }

  set country(value: string) {
    this._country = value
  }

  get idOdoo(): string {
    return this._idOdoo
  }

  set idOdoo(value: string) {
    this._idOdoo = value
  }
}

export interface ClientQuery {
  id: string
  ids: string[]
  all: string;
  taxName: string;
}

