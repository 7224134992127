import { Avatar, Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { LoggedUser } from '../../../modules/auth/models/LoggedUser'
import { AUTH_SERVICE_KEY } from '../../../modules/auth'
import { getAuthContainer } from '../../../container/auth-modules'
import { URL_PROFILE_USER } from '../../../routes/routes-constants'
import { navigate } from '@reach/router'
import { AuthService } from '../../../modules/auth/services/AuthService'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.grey['700'],
      fontWeight: 700,
    },
    subtitle: {
      color: theme.palette.grey['600'],
      fontWeight: 400,
      fontSize: '0.7rem',
    },
    exitIcon: {
      color: theme.palette.grey['700'],
    },
    avatar: {
      cursor: 'pointer',
    },
  })
)

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const Profile = () => {
  const classes = useStyles()

  const [user, setUser] = useState<LoggedUser>(authService.get())

  const handleGoProfile = () => navigate(URL_PROFILE_USER)

  useEffect(() => {
    authService.getObservable().subscribe((u) => {
      setUser(u)
    })
  }, [])

  const logout = () => authService.logout2()

  return (
    <Grid container spacing={2} className={classes.avatar}>
      <Grid item xs zeroMinWidth onClick={handleGoProfile}>
        <Typography align="right" className={classes.title}>
          {user.firstName}
        </Typography>
        <Typography align="right" className={classes.subtitle}>
          {user.lastName}
        </Typography>
      </Grid>
      <Grid item>
        <Avatar>
          <ExitToAppIcon className={classes.exitIcon} onClick={logout} />
        </Avatar>
      </Grid>
    </Grid>
  )
}
