import { Container, ContainerItem } from '../common/container/Container'
import { GenesProps } from '../modules/genes/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { GENES_API_KEY, GENES_SERVICE_KEY, GENES_MODULE } from '../modules/genes'
import { GenesService } from '../modules/genes/services/GenesService'
import { GenesApi } from '../modules/genes/api/GenesApi'

let container: Container

function init(p: GenesProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(GENES_API_KEY, new ContainerItem({ instance: new GenesApi() }))
  items.set(
    GENES_SERVICE_KEY,
    new ContainerItem({
      instance: new GenesService({ apiKey: GENES_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getGenesContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[GENES_MODULE],
      },
    })
  }

  return container
}
