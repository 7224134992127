import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StyleProps } from '../../components/entity-page/EntityPage'

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  addGenTo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  availableGen: {
    marginLeft: '2rem',
    fontWeight: 'bold',
  },
  selectedGenes: {
    margin: '0.5rem 2rem 2rem 2rem',
  },
  title: (props) => ({
    color: props.color,
    margin: '0px 0px 0px 10px',
    fontSize: 28,
  }),
  titleSuccess: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 26,
    color: '#00b8e2',
    marginTop: '25px',
    marginLeft: '30px',
    marginRight: '30px',
    marginBottom: '15px',
  },
  descriptionSuccess: {
    color: 'white',
    fontSize: '18px',
    marginBottom: '20px',
    width: '75%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  box: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  fullName: {
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  textField: {
    marginBottom: '0%',
    marginTop: '-1.5%',
  },
  divTextField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemBox: {
    padding: theme.spacing(2),
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    border: 0,
  },
  buttonSecondary: {
    color: theme.palette.common.white,
    background: theme.palette.grey['700'],
    border: 0,
  },
  buttonModalCancel: {
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    border: 0,
  },
  buttonModalCancelLanguage: {
    color: 'black',
    background: '#b5b5b5',
    border: 0,
  },
  bottomGrid: {
    display: 'flex',
    minHeight: '15vh',
  },
  container: {
    marginBottom: 40,
  },
  titleNumberPrimary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: '5px',
    borderColor: theme.palette.primary.main,
    borderRadius: '40px',
    width: '40px',
    height: '40px',
    fontSize: 24,
  },
  titleNumberSecondary: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey['700'],
    borderStyle: 'solid',
    borderWidth: '5px',
    borderColor: theme.palette.grey['700'],
    borderRadius: '40px',
    width: '40px',
    height: '40px',
    fontSize: 24,
  },
  gridSelect: {
    margin: '2rem 2rem 2rem 2rem',
  },
  titleNumberDisabled: {
    color: theme.palette.common.white,
    backgroundColor: '#e3e3e3',
    borderStyle: 'solid',
    borderWidth: '5px',
    borderColor: '#e3e3e3',
    borderRadius: '40px',
    width: '40px',
    height: '40px',
    fontSize: 24,
  },
  titleNamePrimary: {
    color: theme.palette.primary.main,
    fontSize: 26,
    marginLeft: '10px',
    marginBottom: 0,
  },
  titleNameSecondary: {
    color: theme.palette.grey['700'],
    fontSize: 26,
    marginLeft: '10px',
    marginBottom: 0,
  },
  titleNameDisabled: {
    color: '#e3e3e3',
    fontSize: 26,
    marginLeft: '10px',
    marginBottom: 0,
  },
  breadCrumble1: {
    display: 'flex',
    flex: 'row',
    justifyContent: 'space-between',
    marginBottom: '3rem',
  },
  breadCrumble2: {
    display: 'flex',
    fles: 'row',
    marginRight: '5px',
    marginLeft: '5px',
  },
  spacePrimary: {
    width: '20%',
    height: '2px',
    marginTop: '1.1rem',
    backgroundColor: theme.palette.grey['700'],
  },
  spaceSecondary: {
    width: '20%',
    height: '2px',
    marginTop: '1.1rem',
    backgroundColor: '#e3e3e3',
  },
  footer: {
    margin: '5rem 0 3rem 0',
  },
  titleProduct: {
    color: theme.palette.primary.main,
    fontSize: 20,
    margin: '0 0 0 10px',
    paddingTop: '10px',
  },
  productGrid: {
    backgroundColor: '#e3e3e3',
    borderRadius: '15px',
    textAlign: 'left',
    marginTop: '10px',
  },
  column: {
    display: 'flex',
    flex: 'column',
  },
  row1: {
    display: 'flex',
    flex: 'row',
    flexGrow: 1,
  },
  row2: {
    display: 'flex',
    flex: '100%',
  },
  productType: {
    fontWeight: 'bold',
    margin: '0 5px 10px 10px',
  },
  modal: {
    backgroundColor: 'white',
    margin: '6% 20% 30% 20%',
    height: '65hv',
    width: '60%',
    display: 'flex',
    borderRadius: '20px',
    justifyContent: 'space-between',
  },
  modalGenes: {
    backgroundColor: 'white',
    width: '60%',
    display: 'flex',
    borderRadius: '20px',
    justifyContent: 'space-between',
  },
  modalProduct: {
    backgroundColor: 'white',
    margin: '6% 20% 0 20%',
    height: '65hv',
    width: '60%',
    borderRadius: '20px',
  },
  modalConsent: {
    backgroundColor: 'black',
    margin: '10% 20% 0 20%',
    height: '70hv',
    width: '60%',
    borderRadius: '20px',
  },
  modalConsentPickup: {
    backgroundColor: '#f8f8f8',
    margin: '10% 20% 0 20%',
    height: '70hv',
    width: '60%',
    borderRadius: '20px',
  },
  modalConsentPickup2: {
    backgroundColor: '#f8f8f8',
    height: '70hv',
    width: '60%',
    borderRadius: '20px',
  },
  modalSuccess: {
    backgroundColor: 'black',
    width: '20%',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  modalLanguage: {
    backgroundColor: '#f8f8f8',
    width: '23%',
    borderRadius: '20px',
    justifyContent: 'center',
  },
  modalBackground: {
    backgroundColor: '#e3e3e3',
    height: '10rem',
    borderRadius: '20px 20px 0 0',
  },
  titleModal: (props) => ({
    color: props.color,
    margin: '4rem 5rem 5px 2px',
    fontSize: 28,
  }),
  titleModal2: (props) => ({
    color: props.color,
    margin: '4rem 0.7rem 5px 2rem',
    fontSize: 28,
  }),
  buttonsModal: {
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'space-around',
    height: '3rem',
  },
  bodyModal: {
    //backgroundColor: "#e3e3e3",
    backgroundColor: 'red',
    borderRadius: '20px',
    width: '100%',
    height: '65hv',
    display: 'flex',
    flex: 'row',
    padding: '2rem 1rem 2rem 1rem',
    flexWrap: 'wrap',
    overflowY: 'hidden',
  },
  bodyModalAggGen: {
    backgroundColor: '#e3e3e3',
    borderRadius: '20px',
    width: '100%',
    maxHeight: 360,
    display: 'flex',
    flex: 'row',
    padding: '2rem 1rem 2rem 1rem',
    flexWrap: 'wrap',
    overflowY: 'scroll',
  },
  buttonModal: {
    color: theme.palette.common.white,
    border: 0,
    width: '90%',
    margin: '10px 10px 10px 10px',
  },
  titleProductName: (props) => ({
    color: props.color,
    margin: '0px 0px 0px 10px',
    fontSize: '20px',
  }),
  buttonBox: {
    width: '58%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '3%',
  },
  buttonBox2: {
    flexDirection: 'row',
    display: 'flex',
  },
  buttonBoxPickUp: {
    flexDirection: 'row',
    display: 'flex',
    width: '50%',
    justifyContent: 'space-between',
    marginTop: '2%',
  },
  loadingSpinner: {
    position: 'absolute',
    right: '40%',
    overflow: 'hidden !important',
    bottom: '55%',
    color: '#0077ff !important',
  },
  gynecologicalConditionGrid: {
    marginTop: '2.5%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    textAlign: 'start',
  },
  gynecologicalConditionText: {
    textAlign: 'left',
    fontSize: '18px',
    marginLeft: '1.1%',
  },
  grid: {
    fontSize: '16.5px',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    display: 'flex',
    marginLeft: '1.5%',
  },
  padding1: {
    paddingTop: '1px',
  },
  left4: {
    marginLeft: '4%',
  },
  grid2: {
    fontSize: '16.5px',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginLeft: '-20%',
    marginTop: '-0.5%',
    textAlign: 'left',
  },
  width40: {
    width: '40px',
  },
  pInformationGrid: {
    marginTop: '40px',
  },
  titleText: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '18px',
  },
}))
