import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import SideBarMenuItem from './SideBarMenuItem'
import { sideBarMenuItems } from './sidebar-content'
import { Query, QueryParam } from '../../common/api/Query'
import { ClinicQuery } from '../../modules/clinics/models/Clinic'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'
import logo from '../../assets/Logo/logo-menu.svg'
import logoButtom from '../../assets/Backgrounds/imagen-fondo-pie-menu.svg'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export const SideBarMenu: React.FC = () => {
  const classes = useStyles()

  useEffect(() => {
    if (authService.get().permissions.includes(Permission.viewReceptiveness)) {
      return
    }
    const query: QueryParam<ClinicQuery>[] = []
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
    }
    clinicService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: authService.get()?.clinics?.length },
          query,
          sort: [{ field: 'name' }],
        })
      )
      .subscribe()
  }, [])

  return (
    <>
      <img className={classes.img} src={logo} />
      <List
        component="nav"
        className={classes.sideBarMenu}
        disablePadding>
        {sideBarMenuItems().map(
          (item, index) => item.userAccess && <SideBarMenuItem {...item} key={index} />
        )}
      </List>
      <div
        className={classes.divImgLogoButton} >
        <img src={logoButtom} className={classes.imgLogoButton} />
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sideBarMenu: {
      width: '100%',
      marginTop: '5.5%'
    },
    img: {
      padding: '15px' 
    },
    imgLogoButton: {
      margin: 0, 
      padding: 0,
      width: '100%'
    },
    divImgLogoButton: {
      padding: '5px',
      margin: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
    }
  })
)

export default SideBarMenu
