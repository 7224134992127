import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { FeedbackData, FeedbackDataQuery } from '../models/FeedbackData'
import { FeedbackDataContainerConfig } from '../container'
import { FeedbackDataDTO, toModel } from '../models/FeedbackDataDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'

export interface IFeedbackDataApi extends IInit {
    getByID(id: string): Observable<FeedbackData | undefined>

    getFilteredList(q: Query<FeedbackDataQuery>): Observable<ItemList<FeedbackData>>

    add(e: FeedbackDataDTO[]): Observable<FeedbackData | undefined>

    update(e: FeedbackDataDTO): Observable<FeedbackData | undefined>

    delete(id: string): Observable<boolean>
}

export class FeedbackDataApi implements IFeedbackDataApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as FeedbackDataContainerConfig).moduleFullUrl
    }

    add(e: FeedbackDataDTO[]): Observable<FeedbackData | undefined> {
        return this._httpClient.post<FeedbackData>({ url: this._url, body: e }).pipe(
            map<FeedbackDataDTO, FeedbackData>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(false)
            })
        )
    }

    getByID(id: string): Observable<FeedbackData | undefined> {
        return this._httpClient.get<FeedbackData>({ url: `${this._url}/${id}` }).pipe(
            map<FeedbackDataDTO, FeedbackData>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getFilteredList(q: Query<FeedbackDataQuery>): Observable<ItemList<FeedbackData>> {
        return this._httpClient.get<ItemList<FeedbackData>>({ url: prepareURL(this._url, q) }).pipe(
            map<ItemList<FeedbackDataDTO>, ItemList<FeedbackData>>((dto) => {
                const itemList = emptyList<FeedbackData>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<FeedbackData>())
            })
        )
    }

    update(e: FeedbackDataDTO): Observable<FeedbackData | undefined> {
        return this._httpClient.put<FeedbackData>({ url: this._url, body: e }).pipe(
            map<FeedbackDataDTO, FeedbackData>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }
}
