import { Container } from '../../common/container/Container'

export type ClientContainerConfig = {
  moduleFullUrl: string
}

export type ClientProps = {
  parentContainer: Container
  config: ClientContainerConfig
}

export const CLIENTS_API_KEY = Symbol('CLIENTS_API_KEY')
export const CLIENTS_SERVICE_KEY = Symbol('CLIENTS_SERVICE_KEY')
export const CLIENTS_MODULE = Symbol('CLIENTS_MODULE')