import { Container, ContainerItem } from '../common/container/Container'
import { FaqProps } from '../modules/faq/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { FAQ_API_KEY, FAQ_SERVICE_KEY, FAQ_MODULE } from '../modules/faq'
import { FaqService } from '../modules/faq/services/FaqService'
import { FaqApi } from '../modules/faq/api/FaqApi'

let container: Container

function init(p: FaqProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(FAQ_API_KEY, new ContainerItem({ instance: new FaqApi() }))
  items.set(
    FAQ_SERVICE_KEY,
    new ContainerItem({
      instance: new FaqService({ apiKey: FAQ_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getFaqContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[FAQ_MODULE],
      },
    })
  }

  return container
}
