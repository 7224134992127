import { RouteProps } from '../../routes/AppRouter'
import { ApplicationForm } from '../../features/application'
import { Title } from '../../components/common/Title'
import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()

  const title = params && params.id === 'create' ? t('newPatient') : t('Edit')

  return (
    <>
      <Title color={props.color} title={title || ''} />
      <ApplicationForm />
    </>
  )
}
