import { v4 as uuidv4 } from 'uuid'

import {SampleCitogenetica} from "../../../sampleType/models/sampleForm/SampleCitogenetica";
import {emptySampleCitogeneticaDTO} from "../../../sampleType/models/sampleForm/SampleCitogeneticaDTO";
import {PatientCitogeneticaArray} from "./PatientCitogeneticaArray";

export interface PatientCitogeneticaArrayDTO {
    _id: string | undefined;
    _firstName: string;
    _lastName: string;
    _dob: Date;
    _nhc: string;
    _sex: string
    _indication: string
    _previousGeneticStudies: string
    _familyBackground: string
    _fetalSex: string
    _eggDonationPregnancy: boolean
    _weeksGestation: number
    _sample: SampleCitogenetica
}

export function emptyPatientCitogeneticaArrayDTO(): PatientCitogeneticaArrayDTO {
    return {
        _id: uuidv4(),
        _firstName: '',
        _lastName: '',
        _dob: new Date(),
        _nhc: '',
        _eggDonationPregnancy: false,
        _familyBackground: '',
        _fetalSex: "0",
        _sex: "0",
        _previousGeneticStudies: '',
        _weeksGestation: 0,
        _indication: '',
        _sample: new SampleCitogenetica(emptySampleCitogeneticaDTO())
    }
}

export function fromPatientNGSModel(c: PatientCitogeneticaArray): PatientCitogeneticaArrayDTO {
    return {
        _id: c.id,
        _firstName: c.firstName,
        _lastName: c.lastName,
        _dob: c.dob,
        _nhc: c.nhc,
        _sex: c.sex,
        _indication: c.indication,
        _previousGeneticStudies: c.previousGeneticStudies,
        _familyBackground: c.familyBackground,
        _fetalSex: c.fetalSex,
        _eggDonationPregnancy: c.eggDonationPregnancy,
        _weeksGestation: c.weeksGestation,
        _sample: c.sample
    }
}

export function toModelPatientCitogeneticaArray(c: PatientCitogeneticaArrayDTO): PatientCitogeneticaArray {
    return new PatientCitogeneticaArray(c)
}

export function toModelPatientCitogeneticaArrayList(c: PatientCitogeneticaArrayDTO[]): PatientCitogeneticaArray[] {
    let list: PatientCitogeneticaArray[] = []
    c?.forEach((p) => {
        list.push(new PatientCitogeneticaArray(p))
    })

    return list
}