import { Box, Button, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useStyles } from './Application.styles'
import React from 'react'
import { PatientProduct } from '../../modules/patient/models/relationship/PatientProduct'
import { FormType } from '../../common/enums/Enums'
import { CitoArray, MicroVE, NGS, Null, Oncology, PGT } from './formPatients'
import { URL_APPLICATIONS } from '../../routes/routes-constants'
import { navigate } from '@reach/router'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { useSnackbar } from 'notistack'
import { emptySampleMicroVEBioERDTO } from 'modules/sampleType/models/sampleForm/SampleMicroVEBioERDTO'
import { emptySamplePGTDTO } from 'modules/sampleType/models/sampleForm/SamplePGTDTO'
import { emptySampleNGSDTO } from 'modules/sampleType/models/sampleForm/SampleNGSDTO'
import { emptySampleDTO } from 'modules/sample/models/SampleDTO'
import { emptySampleOncologyDTO } from 'modules/sampleType/models/sampleForm/SampleOncologyDTO'
import { emptySampleCitogeneticaDTO } from 'modules/sampleType/models/sampleForm/SampleCitogeneticaDTO'

type SecondStepProps = {
  increaseStep: () => void
  decreseStep: () => void
  patientProducts: PatientProduct[]
  setPatientProducts: React.Dispatch<React.SetStateAction<PatientProduct[]>> | undefined
  type?: string
  isReturned?: boolean
  edit?: boolean
  clientIDDoingTheRequest: string
}

export const PatientAplication = (props: SecondStepProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  
  const handleChangePatients = (productIndex: number, patients: any[], type: FormType) => {
    let aux = [...props.patientProducts]
    switch (type) {
      case FormType.TypeFormMicroVEBioER:
        let tosave = aux[productIndex].patientMicroVE
        aux[productIndex].patientMicroVE = patients
        aux[productIndex].patientMicroVE.forEach((_, i)=> {
          //@ts-ignore
          aux[productIndex].patientMicroVE[i]._sample = tosave?.length >= i ? (tosave[i]?._sample || emptySampleMicroVEBioERDTO())  : emptySampleMicroVEBioERDTO()
        })
        props.setPatientProducts && props.setPatientProducts(aux)
        break
      case FormType.TypeFormPGT:
        let tosave2 = aux[productIndex].patientPGTs
        aux[productIndex].patientPGTs = patients
        aux[productIndex].patientPGTs.forEach((_, i)=> {
          //@ts-ignore
          aux[productIndex].patientPGTs[i]._sample = tosave2?.length >= i ? (tosave2[i]?._sample || emptySamplePGTDTO()) : emptySamplePGTDTO()
        })
        props.setPatientProducts && props.setPatientProducts(aux)
        break
      case FormType.TypeFormNGS:
        let tosave3 = aux[productIndex].patientNGS
        aux[productIndex].patientNGS = patients
        aux[productIndex].patientNGS.forEach((_, i)=> {
          //@ts-ignore
          aux[productIndex].patientNGS[i]._sample = tosave3?.length >= i ? (tosave3[i]?._sample || emptySampleNGSDTO()) : emptySampleNGSDTO()
        })
        props.setPatientProducts && props.setPatientProducts(aux)
        break
      case FormType.TypeFormNull:
        let tosave4 =  aux[productIndex].patientNulls
        aux[productIndex].patientNulls = patients
        aux[productIndex].patientNulls.forEach((_, i)=> {
          //@ts-ignore
          aux[productIndex].patientNulls[i]._sample = tosave4?.length >= i ? (tosave4[i]?._sample || emptySampleDTO()) : emptySampleDTO()
        })
        props.setPatientProducts && props.setPatientProducts(aux)
        break
      case FormType.TypeFormOncology:
        let tosave5 = aux[productIndex].patientOncology
        aux[productIndex].patientOncology = patients
        aux[productIndex].patientOncology.forEach((_, i)=> {
          //@ts-ignore
          aux[productIndex].patientOncology[i]._sample = tosave5?.length >= i ? (tosave5[i]?._sample || emptySampleOncologyDTO()) : emptySampleOncologyDTO() 
        })
        props.setPatientProducts && props.setPatientProducts(aux)
        break
      case FormType.TypeFormCitogeneticaArray:
        let tosave6 = aux[productIndex].patientCitogenetics
        aux[productIndex].patientCitogenetics = patients
        aux[productIndex].patientCitogenetics.forEach((_, i)=> {
          //@ts-ignore
          aux[productIndex].patientCitogenetics[i]._sample = tosave6?.length >= i ? (tosave6[i]?._sample || emptySampleCitogeneticaDTO()) : emptySampleCitogeneticaDTO()  
        })
        props.setPatientProducts && props.setPatientProducts(aux)
        break
    }
  }

  const switchSelectedProduct = () => {
    return (
      <>
        {props.patientProducts.map((p, index) => {
          if (p._product) {
            if (props.isReturned) {
              switch (Number(p._product.typeForm)) {
                case FormType.TypeFormCitogeneticaArray:
                  return (
                    <CitoArray
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientCitogenetics}
                      type={'view'}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                    />
                  )
                case FormType.TypeFormPGT:
                  return (
                    <PGT
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientPGTs}
                      type={'view'}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormOncology:
                  return (
                    <Oncology
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientOncology}
                      type={'view'}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                    />
                  )
                case FormType.TypeFormMicroVEBioER:
                  return (
                    <MicroVE
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientMicroVE}
                      type={'view'}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormNGS:
                  return (
                    <NGS
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientNGS}
                      type={'view'}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                default:
                  return (
                    <Null
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientNulls}
                      type={'view'}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
              }
            } else {
              switch (Number(p._product.typeForm)) {
                case FormType.TypeFormCitogeneticaArray:
                  return (
                    <CitoArray
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientCitogenetics}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                    />
                  )
                case FormType.TypeFormPGT:
                  return (
                    <PGT
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientPGTs}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormOncology:
                  return (
                    <Oncology
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientOncology}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                    />
                  )
                case FormType.TypeFormMicroVEBioER:
                  return (
                    <MicroVE
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientMicroVE}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormNGS:
                  return (
                    <NGS
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientNGS}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                default:
                  return (
                    <Null
                      key={p._product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p._product}
                      patients={props.patientProducts[index].patientNulls}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
              }
            }
          } else {
            if (p.product) {
              switch (Number(p.product.typeForm)) {
                case FormType.TypeFormCitogeneticaArray:
                  return (
                    <CitoArray
                      key={p.product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p.product}
                      patients={props.patientProducts[index].patientCitogenetics}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                    />
                  )
                case FormType.TypeFormPGT:
                  return (
                    <PGT
                      key={p.product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p.product}
                      patients={props.patientProducts[index].patientPGTs}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormOncology:
                  return (
                    <Oncology
                      key={p.product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p.product}
                      patients={props.patientProducts[index].patientOncology}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                    />
                  )
                case FormType.TypeFormMicroVEBioER:
                  return (
                    <MicroVE
                      key={p.product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p.product}
                      patients={props.patientProducts[index].patientMicroVE}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                case FormType.TypeFormNGS:
                  return (
                    <NGS
                      key={p.product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p.product}
                      patients={props.patientProducts[index].patientNGS}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
                default:
                  return (
                    <Null
                      key={p.product.id}
                      clientIDDoingTheRequest={props.clientIDDoingTheRequest}
                      product={p.product}
                      patients={props.patientProducts[index].patientNulls}
                      type={props.type}
                      productIndex={index}
                      handleChangePatients={handleChangePatients}
                      edit={props.edit}
                    />
                  )
              }
            }
          }
        })}
      </>
    )
  }

  const goToHome = () => {
    navigate(URL_APPLICATIONS)
  }

  const handleIncreaseStep = () => {
    let completed = true
    props.patientProducts.forEach((p) => {
      if (p._product) {
        switch (Number(p._product.typeForm)) {
          case FormType.TypeFormCitogeneticaArray:
            p._patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._firstName ||
                  !patient._lastName ||
                  !patient._nhc ||
                  patient._sex == '0' ||
                  !patient._dob
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('sex') +
                      ', ' +
                      t('dob') +
                      ', ' +
                      t('nhc'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          case FormType.TypeFormPGT:
            p._patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._firstName || !patient._lastName || !patient._nhcOrDni) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheFields') + t('firstName') + ', ' + t('nhc'), {
                    variant: 'error',
                  })
                }
              }
            })
            break
          case FormType.TypeFormOncology:
            p._patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._firstName ||
                  !patient._lastName ||
                  patient._sex == '0' ||
                  !patient._dob ||
                  !patient._diagnosis
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('sex') +
                      ', ' +
                      t('dob') +
                      ', ' +
                      t('diagnosis'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          case FormType.TypeFormMicroVEBioER:
            p._patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._firstName || !patient._lastName || !patient._nhc) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('nhc'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          case FormType.TypeFormNGS:
            p._patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._firstName ||
                  !patient._lastName ||
                  !patient._nhc ||
                  patient._sex == '0' ||
                  !patient._dob
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('sex') +
                      ', ' +
                      t('dob') +
                      ', ' +
                      t('nhc'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          default:
            break
        }
      } else if (p.product) {
        switch (Number(p.product.typeForm)) {
          case FormType.TypeFormCitogeneticaArray:
            p.patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._firstName ||
                  !patient._lastName ||
                  !patient._nhc ||
                  patient._sex == '0' ||
                  !patient._dob
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('sex') +
                      ', ' +
                      t('dob') +
                      ', ' +
                      t('nhc'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          case FormType.TypeFormPGT:
            p.patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._firstName || !patient._lastName || !patient._nhcOrDni) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheFields') + t('firstName') + ', ' + t('nhc'), {
                    variant: 'error',
                  })
                }
              }
            })
            break
          case FormType.TypeFormOncology:
            p.patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._firstName ||
                  !patient._lastName ||
                  patient._sex == '0' ||
                  !patient._dob ||
                  !patient._diagnosis
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('sex') +
                      ', ' +
                      t('dob') +
                      ', ' +
                      t('diagnosis'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          case FormType.TypeFormMicroVEBioER:
            p.patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._firstName || !patient._lastName || !patient._nhc) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('nhc'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          case FormType.TypeFormNGS:
            p.patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (
                  !patient._firstName ||
                  !patient._lastName ||
                  !patient._nhc ||
                  patient._sex == '0' ||
                  !patient._dob
                ) {
                  completed = false
                  enqueueSnackbar(
                    t('mustCompleteTheFields') +
                      t('firstName') +
                      ', ' +
                      t('lastName') +
                      ', ' +
                      t('sex') +
                      ', ' +
                      t('dob') +
                      ', ' +
                      t('nhc'),
                    {
                      variant: 'error',
                    }
                  )
                }
              }
            })
            break
          default:
            break
        }
      }
    })

    completed && props.increaseStep()
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <Typography className={classes.title} variant={'h4'} align="left">
          {t('patientInfo')}
        </Typography>
      </div>
      <div>
        {switchSelectedProduct()}
        <Grid item xs={12} style={{ marginTop: '2.5%' }}>
          <Button
            variant="contained"
            startIcon={<ArrowBack />}
            onClick={props.decreseStep}
            className={classes.button}
            style={{ display: 'flex', alignSelf: 'flex-start' }}>
            {t('back2')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <Button
              onClick={goToHome}
              className={classes.buttonSecondary}
              style={{ marginRight: '2.5%' }}>
              {t('cancel')}
            </Button>
            <Button onClick={handleIncreaseStep} className={classes.button}>
              {t('continue')}
            </Button>
          </Box>
        </Grid>
      </div>
    </>
  )
}
