import { v4 as uuidv4 } from 'uuid'
import { Patient } from './Patient'
import { emptySampleDTO } from "../../sample/models/SampleDTO";
import { Sample } from "../../sample/models/Sample";

export interface PatientDTO {
  _id: string | undefined
  _firstName: string
  _lastName: string
  _creationDate: Date
  _geneticDiseaseHistory: string
  _sample: Sample
  _indication: string
}

export function emptyPatientDTO(): PatientDTO {
  return {
    _id: uuidv4(),
    _firstName: '',
    _lastName: '',
    _creationDate: new Date(),
    _geneticDiseaseHistory: '',
    _sample: new Sample(emptySampleDTO()),
    _indication: ''
  }
}

export function fromModel(c: Patient): PatientDTO {
  return {
    _id: c.id,
    _firstName: c.firstName,
    _lastName: c.lastName,
    _creationDate: c.creationDate,
    _geneticDiseaseHistory: c.geneticDiseaseHistory,
    _sample: c.sample,
    _indication: c.indication
  }
}

export function toModel(c: PatientDTO): Patient {
  return new Patient(c)
}

export function toModelList(c: PatientDTO[]): Patient[] {
  let list: Patient[] = []
  c?.forEach((p) => {
    list.push(new Patient(p))
  })

  return list
}