import { Box, Button, Grid } from '@material-ui/core'
import { useStyles } from './Application.styles'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

type ConsentProps = {
  handleModalClose: () => void
  handleChangeLanguage: (lan: string) => void
  languages: string[]
}

export const ModalLanguages = (props: ConsentProps) => {
  const classes = useStyles({ color: COLOR_PRIMARY })
  const { t } = useTranslation()
  const [languageSelected, setLanguageSelected] = useState<string>('')
  const [languages, setLanguages] = useState<any[]>([])

  useEffect(()=> {
    let languagesAux: any[] = []

    props.languages.forEach((lan)=> {
      languagesAux.push({
        label: t(lan), value: lan
      })
    })
    setLanguages(languagesAux)
  }, [])

  return (
    <>
      <Grid className={classes.modalLanguage} container>
        <Grid xs={12} className={classes.titleSuccess}>
          {t('chooseLanguageConsent')}
        </Grid>
        {languages.map((lan: any) => (
          <Box
            onClick={() => setLanguageSelected(lan.value)}
            style={{
              cursor: 'pointer',
              backgroundColor: lan.value == languageSelected ? '#00b8e2' : '#d6d6d6',
              width: '80%',
              marginTop: '2%',
              justifyContent: 'center',
              display: 'flex',
              borderRadius: '15px',
              height: '40px',
            }}>
            <p style={{ fontSize: '15px', alignSelf: 'center' }}>{t(lan.label)}</p>
          </Box>
        ))}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            marginBottom: '3%',
            width: '100%',
            marginTop: '7%',
          }}>
          <Button
            className={classes.buttonModalCancelLanguage}
            type={'button'}
            onClick={props.handleModalClose}>
            {t('cancel')}
          </Button>
          <Button
            className={classes.button}
            type={'button'}
            onClick={() => props.handleChangeLanguage(languageSelected)}>
            {t('downloadConsent2')}
          </Button>
        </div>
      </Grid>
    </>
  )
}
