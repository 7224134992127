import { RouteProps } from '../../routes/AppRouter'
import { ApplicationTemplateNew } from '../../features/application'
import { Title } from 'components/common/Title'
import { useState } from 'react'

export function TemplateNew(props: RouteProps) {
  const [saveDraftAgain, setSaveDraftAgain] = useState<boolean>(false)

  const handleDraftAgain = () => {
      setSaveDraftAgain(true)
  }

  const editTemplateNew = () => {
    return (
      <ApplicationTemplateNew id={props.id || ''} saveDraftAgain={saveDraftAgain} setSaveDraftAgain={setSaveDraftAgain}/>
    )
  }

  return (
    <>
      <Title color={'black'} title={props.title || ''} saveDraftAgain={handleDraftAgain} />
      {editTemplateNew()}
    </>
  )
}
