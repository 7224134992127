import React, { useState } from 'react'
import { Search, SearchValue } from './types'
import { Box, TextField, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type SearchProps<Q> = {
  readonly search: Search<Q>
  selectedStatus?: string
}

export function Searcher<Q extends { [key: string]: any }>(props: SearchProps<Q>) {
  const { t } = useTranslation()
  const [values, setValues] = useState<SearchValue<Q>[]>(props.search.searchValues)
  const [selectedStatus, setSelectedStatus] = useState<string>(props.selectedStatus || '')
  const handleSubmit = () => props.search.handleSearch([...values])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    values.find((sv) => e.target.name == sv.name)!.value = e.target.value
    setValues([...values])
    handleSubmit()
  }

  const changeSelectedStatus =(va: any) =>{
    setSelectedStatus(va)
    values.find((sv) => "status" == sv.name)!.value = va
    setValues([...values])
    handleSubmit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box marginBottom="5px">
        <Box display="flex" flexWrap="wrap">
          {values.map((v) => (
            <>
              <Box mr={2} mb={2} width={v.width} key={v.name as string}>
                {
                  (v.name === 'status')?
                  (
                    <FormControl variant="outlined" fullWidth size="small" >
                    <InputLabel id="statusLable" style={{ width: '100%' }}>{t('state')} </InputLabel>
                    <Select
                      labelId={'statusLable'}
                      id={'status'}
                      name={'state'}
                      style={(selectedStatus === '')?{ width: '200%' }: {width: '100%'}}
                      onChange={e=> changeSelectedStatus(e.target.value)}
                      value={selectedStatus}
                    >
                        <MenuItem value={''}>{t('state')}</MenuItem>
                        <MenuItem value={'Cancelled'}>{t('Cancelled')}</MenuItem>
                        <MenuItem value={'notAvailable'}>{t('notAvailable')}</MenuItem>
                        <MenuItem value={'Completed'}>{t('Completed')}</MenuItem>
                        <MenuItem value={'Eraser'}>{t('Eraser')}</MenuItem>
                        <MenuItem value={'InPreparation'}>{t('InPreparation')}</MenuItem>
                        <MenuItem value={'Sent'}>{t('Sent')}</MenuItem>
                    </Select>
                    </FormControl>
                  ):(
                    <TextField
                    name={v.name as string}
                    label={v.label}
                    onChange={handleChange}
                    type={v.type || 'text'}
                    value={v.value || ''}
                    variant="outlined"
                    size="small"
                  />
                  )
                }
              
              </Box>
            </>
          ))}
        </Box>
      </Box>
    </form>
  )
}