import {Container, ContainerItem} from '../common/container/Container'
import {USER_API_KEY, USER_SERVICE_KEY, UserProps, USERS_MODULE} from '../modules/users/container'
import {getAppContainer} from './app'
import {moduleURLs} from '../config/api'
import {UserApi} from '../modules/users/api/UserApi'
import {UserService} from '../modules/users/services/UserService'

let container: Container

function init(p: UserProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(USER_API_KEY, new ContainerItem({ instance: new UserApi() }))
  items.set(
    USER_SERVICE_KEY,
    new ContainerItem({
      instance: new UserService({ apiKey: USER_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getUserContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[USERS_MODULE],
      },
    })
  }

  return container
}
