export type SampleTypeProps = {
  id: string | undefined
  nameES: string
  code: string
  idOdoo: string
  nameEN: string
}

export class SampleType {
  private readonly _id: string | undefined
  private _nameES: string
  private _nameEN: string
  private _code: string
  private _idOdoo: string

  constructor(p: SampleTypeProps) {
    this._id = p.id
    this._nameES = p.nameES
    this._nameEN = p.nameEN
    this._code = p.code
    this._idOdoo = p.idOdoo
  }

  get id(): string | undefined {
    return this._id
  }

  get nameES(): string  {
    return this._nameES
  }

  set nameES(value: string ) {
    this._nameES = value
  }

  get nameEN(): string  {
    return this._nameEN
  }

  set nameEN(value: string ) {
    this._nameEN = value
  }

  get code(): string  {
    return this._code
  }

  set code(value: string ) {
    this._code = value
  }

  get idOdoo(): string  {
    return this._idOdoo
  }

  set idOdoo(value: string ) {
    this._idOdoo = value
  }

}

export interface SampleTypeQuery {
  id: string
  ids: string[]
  name: string
  idOdoo: string
}
