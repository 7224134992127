import {File, FileDTO, LanguageConsent} from '../models/File'
import {Observable} from 'rxjs'
import {Query} from '../../../common/api/Query'
import {ItemList} from '../../../common/models/ItemList'
import {Container, IInit} from '../../../common/container/Container'
import {IStatusService} from '../../../common/status/StatusService'
import {IFileApi} from '../api/FileApi'
import {STATUS_SERVICE_KEY} from '../../../container/app'
import {ProductDTO} from "../../product/models/ProductDTO";

type Props = {
    apiKey: symbol
}

export interface IFileService extends IInit {
    getByID(id: string): Observable<File | undefined>

    getFilteredList(q: Query<File>): Observable<ItemList<File>>

    add(e: FileDTO): Observable<File | undefined>

    update(e: FileDTO): Observable<File | undefined>

    delete(id: string): Observable<boolean>

    downloadConsent(p: ProductDTO[], lan: string): Observable<ItemList<File>>

    downloadReport(id: string): Observable<File | undefined>

    getLanguageConsents(q: Query<LanguageConsent>): Observable<ItemList<LanguageConsent>>

    deleteResultFile(id: string): Observable<boolean>
}

export class FileService implements IFileService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IFileApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IFileApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    getByID(id: string): Observable<File | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<File>): Observable<ItemList<File>> {
        return this._api.getFilteredList(q).pipe()
    }

    add(e: FileDTO): Observable<File | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    deleteResultFile(id: string): Observable<boolean> {
        return this._api.deleteResultFile(id)
    }

    update(e: FileDTO): Observable<File | undefined> {
        return this._api.update(e)
    }

    downloadConsent(p: ProductDTO[], lan: string): Observable<ItemList<File>> {
        return this._api.downloadConsent(p, lan)
    }

    downloadReport(id: string): Observable<File | undefined>{
        return this._api.downloadReport(id)
    }

    getLanguageConsents(q: Query<LanguageConsent>): Observable<ItemList<LanguageConsent>> {
        return this._api.getLanguageConsents(q).pipe()
    }
}
