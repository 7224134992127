import { Container } from '../../common/container/Container'

export type StatisticsContainerConfig = {
    moduleFullUrl: string
}

export type StatisticsProps = {
    parentContainer: Container
    config: StatisticsContainerConfig
}

export const STATISTICS_API_KEY = Symbol('STATISTICS_API_KEY')
export const STATISTICS_SERVICE_KEY = Symbol('STATISTICS_SERVICE_KEY')
export const STATISTICS_MODULE = Symbol('STATISTICS_MODULE')
