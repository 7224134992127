import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-modules'
import { UserService } from '../../modules/users/services/UserService'
import { USER_SERVICE_KEY } from '../../modules/users'
import { User } from '../../modules/users/models/User'
import { UserForm } from '../../features/users'

const userService = getUserContainer().get<UserService>(USER_SERVICE_KEY)

export const Form = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const [user, setUser] = useState<User>()
  const title = params && params.id === 'create' ? t('newUser') : t('Edit')

  useEffect(() => {
    if (params.id === 'create') {
      return
    }
    userService.getByID(params.id).subscribe((value) => setUser(value))
  }, [params.id])

  return (
    <>
      <Title title={title} color={props.color} />
      <UserForm user={user} />
    </>
  )
}
