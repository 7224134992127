import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { SampleType, SampleTypeQuery } from '../models/SampleType'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { SampleTypeDTO } from '../models/SampleTypeDTO'
import { ISampleTypeApi } from '../api/SampleTypeApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface ISampleTypeService extends IInit {
  getByID(id: string): Observable<SampleType | undefined>

  getFilteredList(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>>

  getFilteredListDB(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>>
}

export class SampleTypeService implements ISampleTypeService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ISampleTypeApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ISampleTypeApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }


  getByID(id: string): Observable<SampleType | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>> {
    return this._api.getFilteredList(q)
  }

  getFilteredListDB(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>> {
    return this._api.getFilteredListDB(q)
  }
}
