import { Container } from '../../common/container/Container'

export type UserContainerConfig = {
  moduleFullUrl: string
}

export type UserProps = {
  parentContainer: Container
  config: UserContainerConfig
}

export const USER_API_KEY = Symbol('USER_API_KEY')
export const REGISTER_USER_SERVICE_KEY = Symbol('REGISTER_USER_SERVICE_KEY')
export const FORGOT_PASSWORD_SERVICE_KEY = Symbol('FORGOT_PASSWORD_SERVICE_KEY')
export const CHANGE_PASSWORD_SERVICE_KEY = Symbol('CHANGE_PASSWORD_SERVICE_KEY')
export const USER_SERVICE_KEY = Symbol('USER_SERVICE_KEY')
export const USERS_MODULE = Symbol('USERS_MODULE')
