import { createMuiTheme } from "@material-ui/core/styles";
import { COLOR_PRIMARY } from "./routes/color-constants";
const theme = createMuiTheme({
  palette: {
    primary: {
      dark: "#00b8e2",
      main: "#00b8e2",
    },
    grey: {
      A100: "#001d2f",
    },
  },
  typography: {
    h3: {
      fontWeight: 600,
    },
  },
});
export default theme;
