import { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { ClientApp } from '../../features/application'
import { Title } from 'components/common/Title'
import { getClientContainer } from "../../container/client-modules";
import { ClientService } from "../../modules/clients/services/ClientService";
import { CLIENTS_SERVICE_KEY } from "../../modules/clients";
import { Client } from "../../modules/clients/models/Client";
import { useTranslation } from "react-i18next";

const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export function ClientApplication(props: RouteProps) {
    const { t } = useTranslation()
    const [client, setClient] = useState<Client>()

    useEffect(() => {
        if (props.id) {
            clientService.getByID(props.id).subscribe((res) => {
                if (res) setClient(res)
            })
        }
    }, [props])

    const replaceTitle = (title: string | undefined) => {
        return title && title.replace(t('clients') + ' / ' + t('requests'),
            client?.taxName.toString() + ' / ' + t('requests'))
    }

    return (
        <>
            <Title color={"black"} title={replaceTitle(props.title) || ''} />
            <ClientApp id={props.id || ''} />
        </>
    )
}
