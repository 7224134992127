import { useRef, useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { useStyles } from "./Application.styles";
import { COLOR_PRIMARY } from "../../routes/color-constants";
import { useTranslation } from "react-i18next";
import { File } from '../../modules/files/models/File'
import { getAuthContainer } from "../../container/auth-modules";
import { AuthService } from "../../modules/auth/services/AuthService";
import { AUTH_SERVICE_KEY } from "../../modules/auth";
import { v4 as uuidv4 } from 'uuid'

type newApplication = {
    data: any[]
    handleFile: (file: any[]) => void
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const FileApplication = (props: newApplication) => {
    const classes = useStyles({ color: COLOR_PRIMARY })
    const { t } = useTranslation()
    const [file, setFile] = useState<any[]>(props.data || [])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const inputFile = useRef<HTMLInputElement | null>(null)

    const handleDelete = (i: number) => {
        let arr = new Array()
        file?.map((element, index) => {
            if (i != index) {
                arr.push(element)
            }
        })
        setFile(arr)
        props.handleFile(arr)
    }

    const getBase64 = (file: any) => {
        return new Promise(resolve => {
            let baseURL = ""
            let reader = new FileReader()
            // Convert the file to base64 text
            reader.readAsDataURL(file)
            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result as string;
                resolve(baseURL);
            }
        })
    }

    const handleFile = () => {
        inputFile.current?.click()
    }

    const handleSave = (e: any) => {        
        let fi = e.target.files[0]
        getBase64(fi)
            .then(result => {
                if (fi) {
                    if (typeof result == "string") {
                        const extention = fi.name.split(".")
                        let f: any = {
                            id: uuidv4(),
                            name: fi.name,
                            date: new Date(),
                            size: fi.size,
                            mimeType: fi.type,
                            extension: extention[extention.length - 1],
                            ownerID: authService.get().id,
                            base64: result.substring(result.indexOf(',') + 1),
                            typeConsent: 0
                        }
                        let arrFile = file
                        arrFile.push(f)
                        setFile(arrFile)
                        props.handleFile(arrFile)
                        setIsLoading(!isLoading)
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <Grid container spacing={4} style={{ marginTop: '1%' }}>
            <Grid item xs={8} style={{ textAlign: "left" }}>
                <div style={{ margin: "0 0 0 10px " }}>{t('addDocumentation')}</div>
                {file?.map((elementF: File, indexF: number) => {
                    return (
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1.2%" }}>
                            <div key={indexF.toString()} className={classes.titleProduct}>{elementF.name}</div>
                            <Button className={classes.button} type={"button"}
                                onClick={() => handleDelete(indexF)}>{t("delete")}</Button>
                        </div>

                    )
                })}
                <div style={{ marginTop: "20px" }}>
                    <input type='file' id='file' ref={inputFile} onChange={handleSave} style={{ display: 'none' }} />
                    <Button className={classes.buttonSecondary} onClick={handleFile} type={"button"}>
                        {t('selectFile')}
                    </Button>
                </div>
            </Grid>
        </Grid>
    )
}