import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Profile } from './profile/Profile'

export const TopBar = () => {
  const classes = useStyles()
  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems="center" spacing={3} justify="space-between">
            <Grid item></Grid>
            <Grid item>
              <Profile />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  logo: {
    padding: '10px',
    maxHeight: '65px',
  },
  appBar: {
    background: '#cecece',
    color: 'Gray≤',
    width: '100%',
    height: '4.5rem',
  },
  icon: {
    padding: '10px',
  },
  title: {
    margin: 'logo',
  },
}))
