import { useState, useEffect, useRef, ChangeEvent } from 'react'
import { Button, Grid, Typography, TextField } from '@material-ui/core'
import { useStyles } from "./Application.styles";
import { COLOR_PRIMARY } from "../../routes/color-constants";
import { useTranslation } from "react-i18next";
import { getGenesContainer } from "../../container/genes-modules";
import { GenesService } from "../../modules/genes/services/GenesService";
import { GENES_SERVICE_KEY } from "../../modules/genes"
import { Product } from "../../modules/product/models/Product";
import { Query, QueryParam, QueryParamN } from 'common/api/Query';
import { SearchValue } from 'components/table/types';
import { Genes, GenesQuery } from 'modules/genes/models/Genes';
import { v4 as uuidv4 } from 'uuid'
import { PatientProduct } from "../../modules/patient/models/relationship/PatientProduct";

type GeneProps = {
    data: PatientProduct[]
    product: Product
    handleModalClose: () => void
    handleModalSave: (product: Product, genes: Genes[]) => void
}

const genesService = getGenesContainer().get<GenesService>(GENES_SERVICE_KEY)

const searcherQuery = (
    svs: SearchValue<GenesQuery>[],
): QueryParam<GenesQuery>[] | QueryParamN<GenesQuery>[] =>
    svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export const ModalAddGenes = (props: GeneProps) => {
    const classes = useStyles({ color: COLOR_PRIMARY })
    const { t, i18n } = useTranslation()
    const [isLoading, setIsloading] = useState<boolean>(true)
    const [isLoadingFilter, setIsloadingFilter] = useState<boolean>(true)
    const [currentItems, setCurrentItems] = useState<any[]>([])
    const [genesSelected, setGenesSelected] = useState<Genes[]>([])
    const offset = useRef(0)
    const offsetFiltered = useRef(0)
    const [text, setText] = useState<string>('')
    const textRef = useRef('')
    const [lastText, setLastText] = useState<string>('')

    const getGenes = async () => {
        let res = await genesService.getFilteredList(new Query({
            query: [new QueryParam<GenesQuery>('names', props.product?.genes.split("\n").join("").split(","))],
        })).toPromise()
        if (res) {
            setGenesSelected(res.items)
        }
    }

    useEffect(() => {
        getGenes()
    }, [])

    useEffect(() => {
        let s: SearchValue<GenesQuery>[] = [{
            name: 'name',
            label: '',
            value: text
        }]

        if (text) {
            if (text == lastText) {
                offsetFiltered.current = offsetFiltered.current + 100
            } else {
                offsetFiltered.current = 0
            }

            genesService.getFilteredList(new Query({
                pager: { offset: offsetFiltered.current, limit: offsetFiltered.current + 100 },
                query: [...searcherQuery(s)],
            })).subscribe((res) => {

                if (text == lastText) {
                    setCurrentItems(currentItems.concat(res.items))
                }
                else {
                    setCurrentItems(res.items)
                }
            })
        }
        setLastText(text)

    }, [text, isLoadingFilter])

    useEffect(() => {
        if (!isLoading) {
            return;
        }

        genesService.getFilteredList(new Query({
            pager: { offset: offset.current, limit: offset.current + 100 },
        })).subscribe((res) => {
            setCurrentItems(currentItems.concat(res.items))
            setIsloading(false)
            offset.current = offset.current + 100
        })
    }, [isLoading])

    const onScroll = () => {
        let element = document.getElementById('scroll')
        if (element) {
            if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 2) {
                if (textRef.current == "") {
                    setIsloading(true)
                } else {
                    setIsloadingFilter(!isLoadingFilter)
                }
            }
        }
    }

    useEffect(() => {
        if (!isLoading) {
            let elem = document.getElementById("scroll")

            if (elem) {
                elem.addEventListener('scroll', onScroll, { passive: true });
            }
        }
    }, [isLoading])

    const handleSelect = (element: any, index: number) => {
        let index2 = genesSelected.map((g) => g.id).indexOf(element.id)
        if (index2 != -1) {
            let aux = [...genesSelected]
            aux.splice(index2, 1)
            setGenesSelected(aux)
        } else {
            setGenesSelected([...genesSelected, element]);
        }
    }

    const button = (element: any, index: number) => {
        return (
            <Button type={"button"} onClick={() => handleSelect(element, index)}
                className={classes.buttonModal}
                style={!genesSelected.map((g) => g.name).includes(element.name) ? { backgroundColor: "grey" } : { backgroundColor: "#00b8e2" }}>
                {element.name}
            </Button>
        )
    }

    const handleSearch = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setText(e.target.value)
        textRef.current = e.target.value

        if (e.target.value == "") {
            offset.current = 0
            textRef.current = ""
            setCurrentItems([])
            setIsloading(true)
        }
    }

    const handleCancel = () => {
        setGenesSelected([])
        props.handleModalClose()
    }

   
    return (
        <Grid className={classes.modal} container>
            <Grid item xs={12} className={classes.modalBackground}>
                <div className={classes.addGenTo}>
                    <Typography variant={'h4'} align="left" className={classes.titleModal2}>
                        {t('addGenTo')}
                    </Typography>
                    <Typography variant={'h4'} align="left" className={classes.titleModal}>
                        {(i18n.language === "es" ? props.product.nameES : props.product.nameEN)}
                    </Typography>
                </div>
                <div className={classes.availableGen}>
                    {t('availableGen')}
                </div>
            </Grid>
            <Grid item xs={12} className={classes.gridSelect}>
                <div className={classes.divTextField}>
                    <div className={classes.titleNamePrimary} style={{ fontSize: 20, marginBottom: "20px" }}>{t('select1')}</div>
                    <TextField
                        id="outlined"
                        label={t("search")}
                        variant="outlined"
                        value={text}
                        onChange={(e) => handleSearch(e)}
                        className={classes.textField}
                    /></div>
                <div className={classes.selectedGenes}>{t("selectedGenes")}:{" "}
                {genesSelected.map(gen =>(genesSelected[genesSelected.length-1]!== gen)? (gen.name+", ") : gen.name)}
                </div>
                <Grid item xs={12} className={classes.bodyModalAggGen} id="scroll">
                 {  
                    currentItems.map((element: any, index: any) => {
                        return(
                            <Grid item xs={2} key={element.id + uuidv4()} >
                                {button(element, index)}
                            </Grid>)
                    })}
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonsModal}>
                <Button type={"button"} onClick={() => props.handleModalSave(props.product, genesSelected)} className={classes.button}>
                    {t("save")}
                </Button>
                <Button type={"button"} onClick={handleCancel} className={classes.buttonSecondary}>
                    {t('cancel')}
                </Button>
            </Grid>
        </Grid>
    )
}
