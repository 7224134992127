import { Button, Divider, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { COLOR_PRIMARY } from '../../routes/color-constants'
import { navigate } from '@reach/router'
import {
  URL_APPLICATION_NEW,
  URL_APPLICATIONS,
  URL_CONTACT,
  URL_HELP,
  URL_NEWCLIENT,
} from '../../routes/routes-constants'
import home from '../../assets/Iconos_muestras/inicio-inact.svg'
import client from '../../assets/Iconos_muestras/clientes-inac.svg'
import solicitud from '../../assets/Iconos_muestras/solicitudes-inac.svg'
import ayuda from '../../assets/Iconos_muestras/ayuda-inac.svg'
import estadisticas from '../../assets/Iconos_muestras/estadisticas.svg'

export interface TitleProps {
  title: string
  color?: string
  saveDraft?: () => void
  saveDraftAgain?: () => void
}

export const Title = (props: TitleProps) => {
  const classes = useStyles(props)
  const { t } = useTranslation()

  const imageSwitch = () => {
    let value
    switch (props.title) {
      case t('Home'):
        value = home
        break
      case t('Help'):
        value = ayuda
        break
      case t('createFaq'):
        value = ayuda
        break
      case t('clients'):
        value = client
        break
      case t('ApplicationsNew'):
        value = solicitud
        break
      case t('clients') + ' / ' + t('Applications'):
        value = solicitud
        break
      case t('contact'):
        value = ayuda
        break
      case t('ApplicationsDetail'):
        value = solicitud
        break
      case t('ApplicationsDetail2'):
        value = solicitud
        break
      case t('ApplicationsManage'):
        value = solicitud
        break
      case t('statistics'):
        value = estadisticas
        break
      default:
        value = solicitud
        break
    }
    return value
  }

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerImage}>
          <img src={imageSwitch()} alt={props.title} />
          <Typography className={classes.title} variant={'h3'} align="left">
            {props.title}
          </Typography>
        </div>

        {props.title == t('Home') ? (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={() => {
              navigate(URL_APPLICATION_NEW)
            }}
            style={{ float: 'right' }}>
            {t('newRequest').toUpperCase()}
          </Button>
        ) : props.title == t('ApplicationsNew') ? (
          <>
            <div style={{ width: '50%' }}>
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  if (props.saveDraft != undefined) {
                    props.saveDraft()
                  }
                }}
                style={{ float: 'right' }}>
                {t('saveDraft').toUpperCase()}
              </Button>
              <Button
                className={classes.button}
                variant={'contained'}
                onClick={() => {
                  navigate(URL_APPLICATIONS)
                }}
                style={{ marginLeft: '50%' }}>
                {t('cancel').toUpperCase()}
              </Button>
            </div>
          </>
        ) : props.title == t('ApplicationsDetail') ? (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={() => {
              props.saveDraftAgain && props.saveDraftAgain()
            }}
            style={{ float: 'right' }}>
            {t('Edit').toUpperCase()}
          </Button>
        ) : props.title == t('ApplicationsManage') ? (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={() => {
              navigate(URL_APPLICATION_NEW)
            }}
            style={{ float: 'right' }}>
            {t('newRequest').toUpperCase()}
          </Button>
        ) : props.title == t('Help') ? (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={() => {
              navigate(URL_CONTACT)
            }}
            style={{ float: 'right' }}>
            {t('contact').toUpperCase()}
          </Button>
        ) : props.title == t('contact') ? (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={() => {
              navigate(URL_HELP)
            }}
            style={{ float: 'right' }}>
            {t('backToHelp').toUpperCase()}
          </Button>
        ) : props.title == t('clients') ? (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={() => {
              navigate(URL_NEWCLIENT)
            }}
            style={{ float: 'right' }}>
            {t('newClients').toUpperCase()}
          </Button>
        ) : (
          <></>
        )}
      </div>
      <Divider className={classes.divider} />
    </>
  )
}

const useStyles = makeStyles<Theme, TitleProps>((theme: Theme) => ({
  title: (props) => ({
    color: props.color,
    fontSize: 38,
    paddingBottom: theme.spacing(0),
    marginLeft: '10px',
  }),
  divider: {
    marginBottom: theme.spacing(4),
  },
  button: { backgroundColor: COLOR_PRIMARY, color: 'white' },
  header: { display: 'flex', justifyContent: 'space-between', marginBottom: '10px' },
  headerImage: { display: 'flex', justifyContent: 'left' },
}))
