import { RouteComponentProps, Router } from '@reach/router'
import { AuthService } from 'modules/auth/services/AuthService'
import React, { useEffect, useState } from 'react'
import {
  URL_APPLICATION,
  URL_APPLICATION_CLIENT_SHOW,
  URL_APPLICATION_NEW,
  URL_APPLICATION_SHOW,
  URL_APPLICATION_EDIT,
  URL_APPLICATION_SHOW2,
  URL_APPLICATIONS,
  URL_CHANGE_PASSWORD,
  URL_CLIENTS,
  URL_CLIENTS_ID,
  URL_CONTACT,
  URL_FAQ_CREATE,
  URL_FAQ_EDIT,
  URL_HELP,
  URL_HOME,
  URL_NEWCLIENT,
  URL_PROFILE_USER,
  URL_RECOVER_PASSWORD,
  URL_REGISTER,
  URL_USER,
  URL_USERS,
  URL_RESULTS,
  URL_PATIENTS,
  URL_PATIENT_REQUESTS,
  URL_APPLICATION_TEMPLATE,
  URL_APPLICATION_TEMPLATE_NEW,
} from './routes-constants'
import {
  TITLE_APPLICACIONSDETAIL,
  TITLE_APPLICAIONSNEW,
  TITLE_APPLICATION,
  TITLE_APPLICATIONSMANAGE,
  TITLE_CLIENTS,
  TITLE_CONTACT,
  TITLE_FAQS_CREATE,
  TITLE_HELP,
  TITLE_HOME,
  TITLE_NEWCLIENT,
  TITLE_PROFILE_USER,
  TITLE_USERS,
  TITLE_RESULTS,
  TITLE_APPLICACIONSDETAIL2,
  TITLE_PATIENTS,
  TITLE_PATIENT_REQUESTS,
} from './title-constants'
import {
  COLOR_APPLICATIONS,
  COLOR_CLIENTS,
  COLOR_HOME,
  COLOR_MATCHING,
  COLOR_PRIMARY,
} from './color-constants'
import { Register } from '../pages/login/Register'
import { LoggedUser } from '../modules/auth/models/LoggedUser'
import { getAuthContainer } from '../container/auth-modules'
import { AUTH_SERVICE_KEY } from '../modules/auth'
import { RecoverPassword } from '../pages/login/RecoverPassword'
import { ChangePassword } from '../pages/login/ChangePassword'
import { ClientForm, ClientTable } from '../pages/clients'
import {
  ApplicationDetail,
  ApplicationForm,
  ApplicationTable,
  ClientApp,
  NewApp,
  ApplicationEdit,
  ApplicationShow,
  ApplicationTemplate,
} from '../pages/application'
import { UserProfile } from '../pages/users/UserProfile'
import { UserForm, UsersTable } from '../pages/users'
import { HomeTable } from '../pages/home'
import { FormFaq, HelpContact, HelpTable } from '../pages/help'
import { Permission } from '../common/enums/Permissions'
import { useTranslation } from 'react-i18next'
import { Results } from '../pages/results'
import { PatientsTable } from 'pages/patients'
import { RequestsTable } from 'pages/requests'
import { ApplicationTemplateNew } from 'pages/application'

export type RouteProps = {
  title?: string
  subtitle?: string
  color?: string
  id?: string
} & RouteComponentProps

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const AppRouter: React.FC = () => {
  const { t } = useTranslation()
  const [user, setUser] = useState<LoggedUser>(authService.get())

  useEffect(() => {
    authService.getObservable().subscribe((u: LoggedUser) => setUser(u))
  }, [])

  const publicRoutes = (
    <>
      <Register path={URL_REGISTER} />
      <RecoverPassword path={URL_RECOVER_PASSWORD} />
      <ChangePassword path={URL_CHANGE_PASSWORD} />
    </>
  )

  const generalRoutes = (
    <>
      <ApplicationTemplateNew
        path={URL_APPLICATION_TEMPLATE_NEW}
        color={COLOR_APPLICATIONS}
        title={t('request')}
      />
      <ApplicationTemplate
        path={URL_APPLICATION_TEMPLATE}
        color={COLOR_APPLICATIONS}
        title={t('request')}
      />
      <RequestsTable
        path={URL_PATIENT_REQUESTS}
        color={COLOR_APPLICATIONS}
        title={t(TITLE_PATIENT_REQUESTS)}
      />
      <PatientsTable path={URL_PATIENTS} color={COLOR_APPLICATIONS} title={t(TITLE_PATIENTS)} />
      {authService.userCan(Permission.editTestTypes) && (
        <HomeTable
          path={URL_HOME}
          color={COLOR_HOME}
          title={user.language === 'es' ? t(TITLE_HOME) : 'Home'}
        />
      )}
      {authService.userCan(Permission.viewUsers) && (
        <UsersTable
          path={URL_USERS}
          color={COLOR_HOME}
          title={user.language === 'es' ? t(TITLE_USERS) : 'Users'}
        />
      )}
      {authService.userCan(Permission.editUsers) && (
        <UserForm
          path={URL_USER}
          color={COLOR_HOME}
          title={user.language === 'es' ? t(TITLE_USERS) : ' Users'}
        />
      )}
      {authService.userCan(Permission.viewClients) && (
        <ClientTable
          path={URL_CLIENTS}
          color={COLOR_CLIENTS}
          title={user.language === 'es' ? t(TITLE_CLIENTS) : 'Clients'}
        />
      )}
      {authService.userCan(Permission.editClinics) && (
        <ClientForm
          path={URL_NEWCLIENT}
          color={COLOR_CLIENTS}
          title={user.language === 'es' ? t(TITLE_NEWCLIENT) : 'New client'}
        />
      )}
      {authService.userCan(Permission.editClinics) && (
        <ClientForm
          path={URL_CLIENTS_ID}
          color={COLOR_CLIENTS}
          title={user.language === 'es' ? t(TITLE_NEWCLIENT) : 'New client'}
        />
      )}

      {authService.userCan(Permission.viewPatients) && (
        <ApplicationTable
          path={URL_APPLICATIONS}
          color={COLOR_APPLICATIONS}
          title={user.language === 'es' ? t(TITLE_APPLICATIONSMANAGE) : 'Requests'}
        />
      )}
      {authService.userCan(Permission.editPatients) && (
        <ApplicationForm
          path={URL_APPLICATION}
          color={COLOR_APPLICATIONS}
          title={user.language === 'es' ? t(TITLE_APPLICATION) : 'Request'}
        />
      )}
      {authService.userCan(Permission.viewPatients) && (
        <ApplicationDetail
          path={URL_APPLICATION_SHOW}
          color={COLOR_APPLICATIONS}
          title={user.language === 'es' ? t(TITLE_APPLICACIONSDETAIL) : 'Request /Details'}
        />
      )}
      {authService.userCan(Permission.viewPatients) && (
        <ApplicationEdit
          path={URL_APPLICATION_EDIT}
          color={COLOR_APPLICATIONS}
          title={user.language === 'es' ? t(TITLE_APPLICACIONSDETAIL) : 'Request /Details'}
        />
      )}
      {authService.userCan(Permission.viewPatients) && (
        <ApplicationShow
          path={URL_APPLICATION_SHOW2}
          color={COLOR_APPLICATIONS}
          title={user.language === 'es' ? t(TITLE_APPLICACIONSDETAIL2) : 'Request /Details'}
        />
      )}
      {authService.userCan(Permission.viewPatients) && (
        <ClientApp
          path={URL_APPLICATION_CLIENT_SHOW}
          color={COLOR_APPLICATIONS}
          title={t('clients') + ' / ' + t('requests')}
        />
      )}
      {authService.userCan(Permission.viewPatients) && (
        <NewApp
          path={URL_APPLICATION_NEW}
          color={COLOR_APPLICATIONS}
          title={user.language === 'es' ? t(TITLE_APPLICAIONSNEW) : 'New Request'}
        />
      )}

      {authService.userCan(Permission.viewFaqs) && (
        <HelpTable
          path={URL_HELP}
          color={COLOR_MATCHING}
          title={user.language === 'es' ? t(TITLE_HELP) : 'Help'}
        />
      )}

      {authService.userCan(Permission.viewFaqs) && (
        <HelpContact
          path={URL_CONTACT}
          color={COLOR_MATCHING}
          title={user.language === 'es' ? t(TITLE_CONTACT) : 'Contact'}
        />
      )}

      {authService.userCan(Permission.editFaqs || Permission.createFaqs) && (
        <FormFaq
          path={URL_FAQ_CREATE}
          color={COLOR_MATCHING}
          title={user.language === 'es' ? t(TITLE_FAQS_CREATE) : 'Create a FAQ'}
        />
      )}
      {authService.userCan(Permission.editFaqs || Permission.createFaqs) && (
        <FormFaq
          path={URL_FAQ_EDIT}
          color={COLOR_MATCHING}
          title={user.language === 'es' ? t(TITLE_FAQS_CREATE) : 'Create a FAQ'}
        />
      )}

      {authService.userCan(Permission.seeResults) && (
        <Results path={URL_RESULTS} color={COLOR_MATCHING} title={t(TITLE_RESULTS)} />
      )}

      <UserProfile
        path={URL_PROFILE_USER}
        color={COLOR_PRIMARY}
        title={user.language === 'es' ? t(TITLE_PROFILE_USER) : 'Profile'}
      />
    </>
  )

  return <Router component={'div'}>{user.id ? generalRoutes : publicRoutes}</Router>
}
