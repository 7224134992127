export type SampleMicroVEBioERProps = {
    _id: string | undefined
    _typeSample: string
    _endometrialBiopsyMethod: number
    _size: number
    _biopsyDate: Date
    _biopsyTime: Date
    _biopsyNumber: number
    _hrt: boolean
    _pCycle: number
    _p4DateTime: Date
    _natural: boolean
    _lhCycle: number
    _lhDateTime: Date
    _hcgCycle: number
    _hcgInjection: Date
    _question1: boolean
    _typeAntibioticTreatment: string
    _treatmentDuration: string
    _endDate: Date
    _question2: boolean
    _question3: boolean
    _reportDate: Date
    _result: string
    _followedTreatment: string
    _vaginosis: boolean
    _symptoms: string
    _idOdooType: string
    _code: string
}
export class SampleMicroVEBioER {
    _id: string | undefined;
    _typeSample: string;
    _endometrialBiopsyMethod: number;
    _size: number;
    _biopsyDate: Date;
    _biopsyTime: Date;
    _biopsyNumber: number;
    _hrt: boolean;
    _pCycle: number;
    _p4DateTime: Date;
    _natural: boolean;
    _lhCycle: number;
    _lhDateTime: Date;
    _hcgCycle: number;
    _hcgInjection: Date;
    _question1: boolean;
    _typeAntibioticTreatment: string;
    _treatmentDuration: string;
    _endDate: Date;
    _question2: boolean;
    _question3: boolean;
    _reportDate: Date;
    _result: string;
    _followedTreatment: string;
    _vaginosis: boolean;
    _symptoms: string;
    _idOdooType: string;
    _code: string

    constructor(p: SampleMicroVEBioERProps) {
        this._id = p._id;
        this._typeSample = p._typeSample;
        this._endometrialBiopsyMethod = p._endometrialBiopsyMethod;
        this._size = p._size;
        this._biopsyDate = p._biopsyDate;
        this._biopsyTime = p._biopsyTime;
        this._biopsyNumber = p._biopsyNumber;
        this._hrt = p._hrt;
        this._pCycle = p._pCycle;
        this._p4DateTime = p._p4DateTime;
        this._natural = p._natural;
        this._lhCycle = p._lhCycle;
        this._lhDateTime = p._lhDateTime;
        this._hcgCycle = p._hcgCycle;
        this._hcgInjection = p._hcgInjection;
        this._question1 = p._question1;
        this._typeAntibioticTreatment = p._typeAntibioticTreatment;
        this._treatmentDuration = p._treatmentDuration;
        this._endDate = p._endDate;
        this._question2 = p._question2;
        this._reportDate = p._reportDate;
        this._result = p._result;
        this._followedTreatment = p._followedTreatment;
        this._vaginosis = p._vaginosis;
        this._symptoms = p._symptoms;
        this._idOdooType = p._idOdooType;
        this._question3 = p._question3;
        this._code = p._code
    }

    get id(): string | undefined {
        return this._id;
    }

    get typeSample(): string {
        return this._typeSample;
    }

    get question3(): boolean {
        return this._question3;
    }

    set question3(value: boolean) {
        this._question3 = value;
    }

    set typeSample(value: string) {
        this._typeSample = value;
    }

    get endometrialBiopsyMethod(): number {
        return this._endometrialBiopsyMethod;
    }

    set endometrialBiopsyMethod(value: number) {
        this._endometrialBiopsyMethod = value;
    }

    get biopsyTime(): Date {
        return this._biopsyTime;
    }

    set biopsyTime(value: Date) {
        this._biopsyTime = value;
    }

    get biopsyNumber(): number {
        return this._biopsyNumber;
    }

    set biopsyNumber(value: number) {
        this._biopsyNumber = value;
    }

    get hrt(): boolean {
        return this._hrt;
    }

    set hrt(value: boolean) {
        this._hrt = value;
    }

    get pCycle(): number {
        return this._pCycle;
    }

    set pCycle(value: number) {
        this._pCycle = value;
    }

    get p4DateTime(): Date {
        return this._p4DateTime;
    }

    set p4DateTime(value: Date) {
        this._p4DateTime = value;
    }

    get natural(): boolean {
        return this._natural;
    }

    set natural(value: boolean) {
        this._natural = value;
    }

    get lhCycle(): number {
        return this._lhCycle;
    }

    set lhCycle(value: number) {
        this._lhCycle = value;
    }

    get lhDateTime(): Date {
        return this._lhDateTime;
    }

    set lhDateTime(value: Date) {
        this._lhDateTime = value;
    }

    get hcgCycle(): number {
        return this._hcgCycle;
    }

    set hcgCycle(value: number) {
        this._hcgCycle = value;
    }

    get hcgInjection(): Date {
        return this._hcgInjection;
    }

    set hcgInjection(value: Date) {
        this._hcgInjection = value;
    }

    get question1(): boolean {
        return this._question1;
    }

    set question1(value: boolean) {
        this._question1 = value;
    }

    get typeAntibioticTreatment(): string {
        return this._typeAntibioticTreatment;
    }

    set typeAntibioticTreatment(value: string) {
        this._typeAntibioticTreatment = value;
    }

    get treatmentDuration(): string {
        return this._treatmentDuration;
    }

    set treatmentDuration(value: string) {
        this._treatmentDuration = value;
    }

    get endDate(): Date {
        return this._endDate;
    }

    set endDate(value: Date) {
        this._endDate = value;
    }

    get question2(): boolean {
        return this._question2;
    }

    set question2(value: boolean) {
        this._question2 = value;
    }

    get reportDate(): Date {
        return this._reportDate;
    }

    set reportDate(value: Date) {
        this._reportDate = value;
    }

    get result(): string {
        return this._result;
    }

    set result(value: string) {
        this._result = value;
    }

    get followedTreatment(): string {
        return this._followedTreatment;
    }

    set followedTreatment(value: string) {
        this._followedTreatment = value;
    }

    get vaginosis(): boolean {
        return this._vaginosis;
    }

    set vaginosis(value: boolean) {
        this._vaginosis = value;
    }

    get symptoms(): string {
        return this._symptoms;
    }

    set symptoms(value: string) {
        this._symptoms = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get idOdooType(): string {
        return this._idOdooType;
    }

    set idOdooType(value: string) {
        this._idOdooType = value;
    }

    get size(): number {
        return this._size;
    }

    set size(value: number) {
        this._size = value;
    }

    get biopsyDate(): Date {
        return this._biopsyDate;
    }

    set biopsyDate(value: Date) {
        this._biopsyDate = value
    }
}
