import { Container } from '../../common/container/Container'

export type ProductContainerConfig = {
  moduleFullUrl: string
}

export type ProductProps = {
  parentContainer: Container
  config: ProductContainerConfig
}

export const PRODUCT_API_KEY = Symbol('PRODUCT_API_KEY')
export const PRODUCT_SERVICE_KEY = Symbol('PRODUCT_SERVICE_KEY')
export const PRODUCT_MODULE = Symbol('PRODUCT_MODULE')
