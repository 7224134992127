import { COLOR_APPLICATIONS } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager, Sort } from '../../components/table/types'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import { useTranslation } from 'react-i18next'
import { BoolQueryParam, Query, QueryParam, SortParam } from '../../common/api/Query'
import { navigate } from '@reach/router'
import { URL_PATIENT_REQUESTS } from '../../routes/routes-constants'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Client2Request } from '../../modules/client2Requests/models/Client2Request'
import { getPatientContainer } from 'container/patient-modules'
import { PatientService } from 'modules/patient/services/PatientService'
import { PATIENT_SERVICE_KEY } from 'modules/patient'
import { Box, TextField } from '@material-ui/core'
import { Permission } from 'common/enums/Permissions'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)

export const Table = () => {
  const { t } = useTranslation()
  const loggedUser = authService.get()
  const [pager, setPager] = useState<Pager>()
  const [items, setItems] = useState<any[]>([])
  const [sort, setSort] = useState<SortParam<Client2Request>>({
    field: 'firstName',
    desc: true,
  })
  const [allItems, setAllItems] = useState<any[]>([])
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    if (searchValue) {
      let filtered = allItems.filter(
        (i) =>
          (
            i.firstName.toUpperCase() +
            '-' +
            i.id.substring(0, i.id.indexOf('-')).toUpperCase()
          ).includes(searchValue.toUpperCase()) ||
          (i.firstName + ' ' + i.lastName).toUpperCase().includes(searchValue.toUpperCase())
      )
      setItems(filtered)
      setCount(filtered?.length)
      setPage(0)
    } else {
      setItems(allItems)
      setPage(0)
      setCount(allItems?.length)
    }
  }, [searchValue])

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  useEffect(() => {
    if (searchValue) {
      let filtered = allItems.filter(
        (i) =>
          i.id?.toUpperCase().includes(searchValue.toUpperCase()) ||
          (i.firstName + ' ' + i.lastName).toUpperCase().includes(searchValue.toUpperCase())
      )
      let start = (pager?.rowsPerPage || 0) * (pager?.page || 0)
      let end = start + (pager?.rowsPerPage || 0)
      setItems(filtered.slice(start, end))
    } else {
      if (pager && allItems?.length > 0) {
        let start = (pager.rowsPerPage || 0) * pager.page
        let end = start + (pager.rowsPerPage || 0)
        setItems(allItems.slice(start, end))
      }
    }
  }, [pager, allItems])

  const getPatients = async () => {
    let queries: QueryParam<any>[] = []

    if (!authService.userCan(Permission.seeAllPatients)) {
      queries.push(new QueryParam('clientID', loggedUser.clinicID || ''))
    }

    queries.push(new QueryParam('show', new BoolQueryParam(true)))

    let q = new Query({query: queries})

    let patients = await patientService.getFilteredList(q).toPromise()

    let citogenicPatientsItemList = await patientService
      .getFilteredListCitogenicArray(q)
      .toPromise()

    let citogenicPatients: any[] = citogenicPatientsItemList?.items || []

    citogenicPatients.forEach((i) => {
      i.priority = true
    })

    let microvePatients = await patientService.getFilteredListMicroVE(q).toPromise()

    let ngsPatients = await patientService.getFilteredListNGS(q).toPromise()

    let oncologyPatients = await patientService.getFilteredListOncology(q).toPromise()

    let pgtPatients = await patientService.getFilteredListPGT(q).toPromise()

    const resultArray = [
      ...(patients?.items || []),
      ...citogenicPatients,
      ...(microvePatients?.items || []),
      ...(ngsPatients?.items || []),
      ...(oncologyPatients?.items || []),
      ...(pgtPatients?.items || []),
    ]
    let personasFiltradas: any[] = filtrarPersonas(resultArray)
    setCount(personasFiltradas?.length)
    setAllItems(personasFiltradas)
  }

  useEffect(() => {
    getPatients()
  }, [])

  function filtrarPersonas(personas: any[]): any[] {
    const personasFiltradas: { [key: string]: any } = {}

    personas.forEach((persona) => {
      if (!personasFiltradas[persona.firstName + persona.lastName]) {
        personasFiltradas[persona.firstName + persona.lastName] = persona
      }
    })

    return Object.values(personasFiltradas)
  }

  const fields: Field<any>[] = [
    {
      searchable: true,
      label: t('ID'),
      name: 'id',
      sortable: false,
      renderFunc: (field, item) =>
        item.firstName + '-' + item.id.substring(0, item.id.indexOf('-')),
    },
    {
      searchable: true,
      label: t('firstNameAndLastName'),
      name: 'patientName',
      sortable: false,
      renderFunc: (field, item) => item.firstName + ' ' + item.lastName,
    },
  ]

  const viewRequests = (c2r: any) => navigate(URL_PATIENT_REQUESTS.replace(':id', `${c2r.id}`))

  const actions: Actions<any> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewRequests,
        icon: seeIcon,
        label: t('Show'),
      },
    ],
  }

  const sortable: Sort<Client2Request> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {},
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  return (
    <div style={{marginBottom: "2%"}}>
      <Box marginBottom="5px">
        {/**@ts-ignore */}
        <Box display="flex" flexWrap="wrap">
          <>
            {/**@ts-ignore */}
            <Box mr={2} mb={2}>
              <TextField
                name=""
                label={t('search')}
                onChange={(e) => setSearchValue(e.target.value)}
                type={'text'}
                value={searchValue}
                variant="outlined"
                size="small"
              />
            </Box>
          </>
        </Box>
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_APPLICATIONS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'request'}
        pager={pager}
        sort={sortable}
      />
    </div>
  )
}
