import { v4 as uuidv4 } from 'uuid'
import { FeedbackData } from './FeedbackData'

export interface FeedbackDataDTO {
    id: string | undefined
    requestCodeSample: string
    nEmbTransfer: number,
    idEmbTransfer: string,
    fullTerm: boolean
    userID: string
}

export function emptyFeedbackDataDTO(userID: string): FeedbackDataDTO {
    return {
        id: uuidv4(),
        requestCodeSample: '',
        nEmbTransfer: 0,
        idEmbTransfer: '',
        fullTerm: false,
        userID: userID
    }
}

export function fromModel(c: FeedbackData): FeedbackDataDTO {
    return {
        id: c.id,
        requestCodeSample: c.requestCodeSample,
        nEmbTransfer: c.nEmbTransfer,
        idEmbTransfer: c.idEmbTransfer,
        fullTerm: c.fullTerm,
        userID: c.userID
    }
}

export function toModel(c: FeedbackDataDTO): FeedbackData {
    return new FeedbackData(c)
}
