import { COLOR_HOME } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import { ChangeEvent, useEffect, useState } from 'react'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, SortParam } from '../../common/api/Query'
import { navigate } from '@reach/router'
import {URL_APPLICATION_EDIT} from '../../routes/routes-constants'
import { getRequestContainer } from "../../container/request-modules"
import { REQUEST_SERVICE_KEY } from "../../modules/request"
import { Request, RequestQuery } from '../../modules/request/models/Request'
import { RequestService } from '../../modules/request/services/RequestService'

const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)

export const Table = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [pager, setPager] = useState<Pager>()
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const [items, setItems] = useState<Request[]>([])
    const [sort, setSort] = useState<SortParam<Request>>({
        field: 'date',
        desc: true,
    })
    const [searcher, setSearcher] = useState<SearchValue<Request>[]>([
    ])

    useEffect(() => {
        if (!isLoading) {
            return
        }

        requestService.getFilteredListByRole(new Query({
            pager: { offset: page * itemsPerPage, limit: itemsPerPage },
            sort: [{ field: sort.field, desc: sort.desc }],
            query: [new QueryParam<RequestQuery>('status', "Eraser")],
        })).subscribe((res) => {
            setIsLoading(false)
            setItems(res.items)
            setCount(res.count)
        })
    }, [isLoading, searcher])

    useEffect(() => {
        setIsLoading(true)
        setPager({
            page,
            count,
            handleChangePage: handlePaginationChange,
            rowsPerPage: itemsPerPage,
            handleChangeRowsPerPage,
        })
    }, [page, count, itemsPerPage])

    const handlePaginationChange = (event: unknown, value: number) => setPage(value)

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        if (Number.isNaN(event.target.value)) {
            setItemsPerPage(10)
            return
        }
        setItemsPerPage(Number.parseInt(event.target.value))
    }

    const fields: Field<Request>[] = [
        {
            searchable: true,
            sortable: true,
            label: 'ID',
            name: 'id'

        },
        {
            searchable: true,
            sortable: true,
            label: t('date'),
            name: 'date',
            renderFunc: (f, i) => new Date(i.date).getDate() + "/" + (new Date(i.date).getMonth() + 1) + "/" + new Date(i.date).getFullYear(),
        },
        {
            searchable: true,
            sortable: true,
            label: t('state'),
            name: 'status',
            renderFunc: (f, i) => t(i.status),
        },
    ]

    const editRequest = (request: Request) =>
        navigate(URL_APPLICATION_EDIT.replace(':id', `${request.id}`))

    const hideEdit = (request: Request): boolean => request.status !== "Eraser"

    const actions: Actions<Request> = {
        actionsColumn: t('Actions'),
        items: [
            {
                handler: editRequest,
                hidden: hideEdit,
                icon: editIcon,
                label: t('Edit'),
            },
        ],
    }

    const search: Search<Request> = {
        searchValues: searcher,
        handleSearch: (svs: SearchValue<Request>[]) => {
            const result: SearchValue<Request>[] = []
            svs.forEach((s) => {
                if (s.type === "date" && s.value) {
                    result.push(Object.assign({ ...s }, { value: new Date(s.value as string).toISOString() }))
                } else {
                    result.push(s)
                }
            })
            setSearcher(result)
            setIsLoading(true)
        },
    }

    const sortable: Sort<Request> = {
        name: sort.field,
        direction: sort.desc ? 'desc' : 'asc',
        handleSort: (field) => {
            setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
            setIsLoading(true)
        },
    }


    return (
        <>
            <AppTable
                styleHeader={{ color: COLOR_HOME }}
                actions={actions}
                fields={fields}
                items={items}
                rowKeyField={'id'}
                pager={pager}
                search={search}
                sort={sortable}
            />
        </>
    )
}