import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { COLOR_PRIMARY } from '../../routes/color-constants'

export const commonStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      borderRadius: '4px',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 0),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    toggle: {
      width: 50,
      '& .Mui-checked': {
        color: COLOR_PRIMARY,
      },
      '& .Mui-checked:hover': {
        backgroundColor: 'rgba(204, 206, 207, 0.2)',
      },
      '& .Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#4e7d9c',
      },
      '& .MuiSwitch-track': {
        backgroundColor: '#cccecf',
        color: '#FFAA54',
      },
    }
  }),
)