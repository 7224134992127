import { v4 as uuidv4 } from 'uuid'
import { Statistics } from './Statistics'

export interface StatisticsDTO {
    id: string
    requestID: string
    nEmbEuploides: number
    nEmbTrisonomy: number
    nEmbMonosomy: number
    nEmbDups: number
    nEmbDels: number
    nEmbMosaicism: number
    nEmbCaothics: number
    nEmbNoAmp: number
    nEmbNoInf: number
}

export function emptyStatisticsDTO(): StatisticsDTO {
    return {
        id: uuidv4(),
        requestID: '',
        nEmbEuploides: 0,
        nEmbTrisonomy: 0,
        nEmbMonosomy: 0,
        nEmbDups: 0,
        nEmbDels: 0,
        nEmbMosaicism: 0,
        nEmbCaothics: 0,
        nEmbNoAmp: 0,
        nEmbNoInf: 0,
    }
}

export function fromModel(c: Statistics): StatisticsDTO {
    return {
        id: c.id,
        requestID: c.requestID,
        nEmbEuploides: c.nEmbEuploides,
        nEmbTrisonomy: c.nEmbTrisonomy,
        nEmbMonosomy: c.nEmbMonosomy,
        nEmbDups: c.nEmbDups,
        nEmbDels: c.nEmbDels,
        nEmbMosaicism: c.nEmbMosaicism,
        nEmbCaothics: c.nEmbCaothics,
        nEmbNoAmp: c.nEmbNoAmp,
        nEmbNoInf: c.nEmbNoInf,
    }
}

export function toModel(c: StatisticsDTO): Statistics {
    return new Statistics(c)
}
