import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Clinic, ClinicQuery } from '../models/Clinic'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { ClinicDTO } from '../models/ClinicDTO'
import { IClinicApi } from '../api/ClinicApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Client } from 'modules/clients/models/Client'

type Props = {
  apiKey: symbol
}

export interface IClinicService extends IInit {
  getByID(id: string): Observable<Clinic | undefined>

  getFilteredList(q: Query<ClinicQuery>): Observable<ItemList<Clinic>>

  add(e: Clinic): Observable<Clinic | undefined>

  update(e: ClinicDTO): Observable<Clinic | undefined>

  delete(id: string): Observable<boolean>
}

export class ClinicService implements IClinicService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IClinicApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IClinicApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ClinicDTO): Observable<Clinic | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Clinic | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<ClinicQuery>): Observable<ItemList<Clinic>> {
    return this._api.getFilteredList(q)
  }

  update(e: ClinicDTO): Observable<Clinic | undefined> {
    return this._api.update(e)
  }
}
