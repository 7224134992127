import { FormEvent, useState } from 'react'

interface Validation {
  required?: {
    value: boolean
    message: string
  }
  pattern?: {
    value: string
    message: string
  }
  custom?: {
    isValid: (value: string) => boolean
    message: string
  }
}

type ErrorRecord<T> = Record<keyof T, string>

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>

export const useForm = <T extends Partial<Record<keyof T, any>> = {}>(options?: {
  validations?: Validations<T>
  initialValues?: Partial<T>
  onSubmit?: () => void
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T)
  const [errors, setErrors] = useState<ErrorRecord<T>>({} as ErrorRecord<T>)

  const handleChange = (key: keyof T, value: any) => {
    setData({ ...data, [key]: value })
  }

  const isFormValid = () => {
    return Object.keys(errors).length === 0
  }

  const checkForm = (): boolean => {
    const validations = options?.validations
    if (validations) {
      let valid = true
      const newErrors = {} as ErrorRecord<T>
      for (const key in validations) {
        const value = data[key]
        const validation = validations[key]

        if (
          validation?.required?.value &&
          (!value ||
            (Array.isArray(value) && (value as Array<T[Extract<keyof T, string>]>).length == 0))
        ) {
          valid = false
          newErrors[key] = validation?.required?.message
        }

        const pattern = validation?.pattern
        if (pattern?.value && !RegExp(pattern.value).test(value)) {
          valid = false
          newErrors[key] = pattern.message
        }

        const custom = validation?.custom
        if (custom?.isValid && !custom.isValid(value)) {
          valid = false
          newErrors[key] = custom.message
        }
      }

      if (!valid) {
        setErrors(newErrors)
        return false
      }
    }

    setErrors({} as ErrorRecord<T>)
    return true
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const isValid = checkForm()

    if (isValid && options?.onSubmit) {
      options.onSubmit()
    }
  }

  return {
    data,
    setData,
    handleChange,
    handleSubmit,
    checkForm,
    isFormValid,
    errors,
  }
}
