import {Sample} from "../../sample/models/Sample";

export type PatientProps = {
  _id: string | undefined
  _firstName: string
  _lastName: string
  _creationDate: Date
  _geneticDiseaseHistory: string
  _sample: Sample
  _indication: string
}


export class Patient {
  _id: string | undefined
  _firstName: string
  _lastName: string
  _creationDate: Date
  _geneticDiseaseHistory: string
  _sample: Sample
  _indication: string

  constructor(p: PatientProps) {
    this._id = p._id
    this._firstName = p._firstName
    this._lastName = p._lastName
    this._creationDate = p._creationDate
    this._geneticDiseaseHistory = p._geneticDiseaseHistory
    this._sample = p._sample
    this._indication = p._indication
  } 

  get id(): string | undefined {
    return this._id
  }

  get firstName(): string  {
    return this._firstName
  }

  set firstName(value: string ) {
    this._firstName = value
  }

  get lastName(): string {
    return this._lastName
  }

  set lastName(value: string) {
    this._lastName = value
  }

  get creationDate(): Date {
    return this._creationDate
  }

  set creationDate(value: Date) {
    this._creationDate = value
  }

  get geneticDiseaseHistory(): string {
    return this._geneticDiseaseHistory
  }

  set geneticDiseaseHistory(value: string) {
    this._geneticDiseaseHistory = value
  }

  get sample(): Sample {
    return this._sample
  }

  set sample(value: Sample) {
    this._sample = value
  }

  get indication(): string  {
    return this._indication
  }

  set indication(value: string ) {
    this._indication = value
  }
}

export interface PatientQuery {
  id: string
  ids: string[]
  firstName: string
  lastName: string
}

