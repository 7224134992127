import {Request} from "../../request/models/Request";
import {Patient} from "../../patient/models/Patient";
import {Sample} from "../../sample/models/Sample"
import {Address} from "../../address/models/Address"
import {Product} from "../../product/models/Product"
import {emptyRequestDTO, toModel as toRModel} from "../../request/models/RequestDTO";
import {emptyAddressDTO, toModel as toAModel} from "../../address/models/AddressDTO";
import {Wizard} from "./Wizard"

type Product2GenesObject = {
    product: string,
    genes: string[],
}

type Product2PatientObject = {
    product: string,
    patient: Patient[],
}

type Patient2SampleObject = {
    patient: string,
    sample: Sample[],
}

export interface WizardDTO {
    request: Request
    file: File[]
    genes: Product2GenesObject[]
    newGenes: Product2GenesObject[]
    patient: Product2PatientObject[]
    sample: Patient2SampleObject[]
    address: Address
    product: Product[]
}


export function emptyWizardDTO(): WizardDTO {
    return {
        request: toRModel(emptyRequestDTO()),
        file: [],
        genes: [{product: "", genes: []}],
        newGenes: [{product: "", genes: []}],
        patient: [{product: "", patient: []}],
        sample: [{patient: "", sample: []}],
        address: toAModel(emptyAddressDTO()),
        product: []
    }
}

export function fromModel(c: Wizard): WizardDTO {
    return {
        request: c.request,
        file: c.file,
        genes: c.genes,
        newGenes: c.newGenes,
        patient: c.patient,
        sample: c.sample,
        address: c.address,
        product: c.product
    }
}

export function toModel(c: WizardDTO): Wizard {
    return new Wizard(c)
}

