import { navigate, RouteComponentProps } from '@reach/router'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { IAuthService } from 'modules/auth/services/AuthService'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Box, Card, CardContent, InputAdornment } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import { Lock } from '@material-ui/icons'
import { useForm } from '../../common/utils/form-generation/useForm'
import { LoginButton } from './components/Button'
import { LoginTextField } from './components/TextField'
import { URL_HOME, URL_RECOVER_PASSWORD, URL_REGISTER } from '../../routes/routes-constants'
import { useStyles } from './Login.styles'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { getAuthContainer } from '../../container/auth-modules'
import { Error } from '../../common/error/ErrorModel'
import { ErrorSnackBar } from '../../common/error/ErrorSnackBar'

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

interface FormData {
  email: string
  password: string
}

function LoginForm() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const [snackBar, setSnackBar] = useState<Error>()
  const [open, setOpen] = useState<boolean>(false)

  const error = (res: Error) => {
    setSnackBar(res)
    setOpen(true)
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const { handleSubmit, handleChange, data, errors } = useForm<FormData>({
    validations: {
      email: {
        pattern: {
          value: '^[^\\s@]+@[^\\s@]+$',
          message: t('emailNotValidError'),
        },
      },
    },

    onSubmit: () => {
      authService.login({ login: data.email, password: data.password }).subscribe((res) => {
        if ('message' in res) {
          error(res)
        } else {
          if (res) {
            i18n.changeLanguage(res.language)
          }
          navigate(URL_HOME).then()
        }
      })
    },
  })

  const handleForgotPassword = () => navigate(URL_RECOVER_PASSWORD)

  return (
    <Container className={classes.containerForm}>
      <Box className={classes.imgBox}>
        <img className={classes.logoGlobal} src={'/logoGlobal.png'} />
      </Box>
      <form className={classes.form} autoComplete={'off'} onSubmit={handleSubmit}>
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          id={'email'}
          onChange={(event) => handleChange('email', event.target.value)}
          placeholder={t('email')}
          name={'email'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <PersonIcon color={'inherit'} />
              </InputAdornment>
            ),
          }}
          autoComplete={'off'}
        />
        {errors.email && <p className={'error'}>{errors.email}</p>}
        <LoginTextField
          variant={'filled'}
          margin={'normal'}
          required
          fullWidth
          onChange={(event) => handleChange('password', event.target.value)}
          name={'password'}
          placeholder={t('password')}
          type={'password'}
          id={'password'}
          InputProps={{
            startAdornment: (
              <InputAdornment color={'inherit'} position={'start'}>
                <Lock color={'inherit'} />
              </InputAdornment>
            ),
          }}
        />
        {errors.password && <p className={'error'}>{errors.password}</p>}
        <Grid container alignItems={'center'} justify={'space-between'}>
          <Grid item xs={12}>
            <LoginButton
              type={'submit'}
              fullWidth
              variant={'contained'}
              color={'primary'}
              className={classes.loginButton}>
              {t('login')}
            </LoginButton>
          </Grid>
          <Grid item xs={6}>
            <Typography onClick={() => handleForgotPassword()} color={'inherit'} align={'left'}>
              {t('forgetYourPassword')}
            </Typography>
          </Grid>
        </Grid>
      </form>
      {snackBar && ErrorSnackBar({ t: t, err: snackBar, open: open, handleClose: handleClose })}
    </Container>
  )
}

export const Login = (props: RouteComponentProps) => {
  const classes = useStyles()

  return (
    <Container className={classes.container}>
      <Grid container className={classes.root} spacing={0} alignItems={'center'} justify={'center'}>
        <img className={classes.logo} src={'/bioarray-login-logo.svg'} />
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid alignItems={'stretch'} container spacing={0}>
                <Grid item xs={12}>
                  <Grid item xs={12} className={classes.innerGrid}>
                    <LoginForm />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
      </Grid>
    </Container>
  )
}
