import {EnumValueFromIndex, EnumValueToIndex} from "./EnumUtils";
import {Pathology} from "../enum/Pathology";

export const StringToPathology = (name: string): Pathology | undefined => {
    for (const pathologyName of Object.values(Pathology)) {
        if (pathologyName === name) {
            return <Pathology> name;
        }
    }
    return undefined
}

export const PathologyFromIndex = (index: number): Pathology => {
    return EnumValueFromIndex(Pathology, index)
}

export const PathologyToIndex = (pathology: Pathology): number => {
    return EnumValueToIndex(Pathology, pathology)
}
