import {v4 as uuidv4} from 'uuid'
import {SampleNGS} from "./SampleNGS";


export interface SampleNGSDTO {
    _id: string | undefined
    _typeSample: string
    _extractionDate: Date
    _codeSample: string
    _idOdoo: string

}

export function emptySampleNGSDTO(): SampleNGSDTO {
    return {
        _id: uuidv4(),
        _typeSample: '',
        _extractionDate: new Date(),
        _codeSample: '',
        _idOdoo: ''
    }
}

export function fromModel(c: SampleNGS): SampleNGSDTO {
    return {
        _id: c.id,
        _typeSample: c.typeSample,
        _extractionDate: c.extractionDate,
        _codeSample: c.codeSample,
        _idOdoo: c.idOdoo
    }
}

export function toModel(c: SampleNGSDTO): SampleNGS {
    return new SampleNGS(c)
}
