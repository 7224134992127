import { Container } from '../../common/container/Container'

export type FeedbackDataContainerConfig = {
    moduleFullUrl: string
}

export type FeedbackDataProps = {
    parentContainer: Container
    config: FeedbackDataContainerConfig
}

export const FEEDBACKDATA_API_KEY = Symbol('FEEDBACKDATA_API_KEY')
export const FEEDBACKDATA_SERVICE_KEY = Symbol('FEEDBACKDATA_SERVICE_KEY')
export const FEEDBACKDATA_MODULE = Symbol('FEEDBACKDATA_MODULE')
