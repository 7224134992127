import { Container } from '../../common/container/Container'

export type ClinicContainerConfig = {
  moduleFullUrl: string
}

export type ClinicProps = {
  parentContainer: Container
  config: ClinicContainerConfig
}

export const CLINIC_API_KEY = Symbol('CLINIC _API_KEY')
export const CLINIC_SERVICE_KEY = Symbol('CLINIC_SERVICE_KEY')
export const CLINICS_MODULE = Symbol('CLINICS_MODULE')
