import {
  Results as ResultsForm,
  Statistics as StatisticsForm,
  Feedback as FeedbackForm,
} from '../../features/results'
import { Title } from '../../components/common/Title'
import { useTranslation } from 'react-i18next'
import { TITLE_RESULTS } from 'routes/title-constants'
import { Permission } from '../../common/enums/Permissions'
import { AuthService } from '../../modules/auth/services/AuthService'
import { getAuthContainer } from '../../container/auth-modules'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import Grid from '@material-ui/core/Grid'
import { AppTable } from 'components/table'
import { Actions, Sort, Search, SearchValue, Field, Pager } from 'components/table/types'
import React, { useState, useEffect } from 'react'
import { Query, QueryParam, QueryParamN, SortParam } from 'common/api/Query'
import { getFileContainer } from 'container/file-module'
import { FileService } from 'modules/files/services/FileService'
import { FILE_SERVICE_KEY } from 'modules/files'
import { File, FileQuery } from 'modules/files/models/File'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { CircularProgress } from '@material-ui/core'
import { RouteProps } from 'routes/AppRouter'

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<FileQuery>[]
): QueryParam<FileQuery>[] | QueryParamN<FileQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

const BorderElement = (element: JSX.Element): JSX.Element => {
  return (
    <div
      style={{
        borderColor: '#e8e8ea',
        borderWidth: '10px',
        borderStyle: 'solid',
        borderRadius: '10px',
        padding: '1.5em',
      }}>
      {element}
    </div>
  )
}

export const Results = (props: RouteProps) => {
  const { t } = useTranslation()
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [sort, setSort] = useState<SortParam<File>>({
    field: 'date',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<FileQuery>[]>([
    {
      name: 'name',
      label: t('search') + ' ' + t('byName'),
      value: '',
    },
  ])
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(true)
  const [resultFilesToUpload, setResultFilesToUpload] = useState<File[]>([])
  const [fileResults, setFileResults] = useState<File[]>([])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setPage(0)
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handleDeleteFile = (f: File) => {
    fileService.deleteResultFile(f._id).subscribe(() => {
      setIsLoadingTable(true)
    })
  }

  const actions: Actions<File> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handleDeleteFile,
        icon: deleteIcon,
        label: t('Delete'),
      },
    ],
  }

  const search: Search<FileQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<FileQuery>[]) => {
      setSearcher(svs)
    },
  }

  useEffect(() => {
    fileService
      .getFilteredList(
        new Query({
          query: [new QueryParam('type', 'result'), ...searcherQuery(searcher)],
          sort: [{ field: sort.field, desc: sort.desc }],
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
        })
      )
      .subscribe((res) => {
        setFileResults(res.items)
        if (count != res.count) {
          setPage(0)
        }
        setCount(res.count)
      })
  }, [searcher, page, itemsPerPage])

  useEffect(() => {
    if (!isLoadingTable) return

    fileService
      .getFilteredList(
        new Query({
          query: [new QueryParam('type', 'result'), ...searcherQuery(searcher)],
          sort: [{ field: sort.field, desc: sort.desc }],
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
        })
      )
      .subscribe((res) => {
        setFileResults(res.items)
        setCount(res.count)
        setIsLoadingTable(false)
      })
  }, [isLoadingTable, sort])

  const sortable: Sort<File> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoadingTable(true)
    },
  }

  const fields: Field<File>[] = [
    {
      sortable: true,
      label: t('name'),
      name: 'name',
    },
    {
      sortable: true,
      label: t('date'),
      name: 'date',
      renderFunc: (field, item) => new Date(item._date).toLocaleDateString(),
    },
  ]

  const ClientLayout = () => {
    return (
      <>
        <Title title={t('statistics')} color={'black'} />
        <FeedbackForm />
      </>
    )
  }

  return (
    <>
      {authService.userCanEvery(
        Permission.addResults,
        Permission.addStatistics,
        Permission.deleteResults
      ) && (
        <>
          <Title color={'black'} title={t(TITLE_RESULTS)} />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              {BorderElement(
                <ResultsForm
                  handleChangeIsLoading={setIsLoadingTable}
                  files={resultFilesToUpload}
                  setFiles={setResultFilesToUpload}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {BorderElement(<StatisticsForm />)}
            </Grid>
            <Grid item xs={12} sm={12}>
              {!isLoadingTable ? (
                <AppTable
                  actions={actions}
                  fields={fields}
                  items={fileResults}
                  rowKeyField={'id'}
                  pager={pager}
                  sort={sortable}
                  search={search}
                />
              ) : (
                <CircularProgress disableShrink size="7rem" />
              )}
            </Grid>
          </Grid>
        </>
      )}
      {authService.userCan(Permission.sendFeedback) && <ClientLayout />}
    </>
  )
}
