import React from 'react'
import {createStyles, makeStyles} from '@material-ui/core/styles'

import List from '@material-ui/core/List'

import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'

import SideBarMenuItemComponent from './SideBarMenuItemComponent'
import {SideMenuItemProps} from './types'
import {Avatar, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {COLOR_MENU_PRIMARY} from '../../routes/color-constants'
import {getAuthContainer} from "../../container/auth-modules";
import {IAuthService} from "../../modules/auth/services/AuthService";
import {AUTH_SERVICE_KEY} from "../../modules/auth";


const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)


const SideBarMenuItem: React.FC<SideMenuItemProps> = (props) => {
    const {name, path, color, Icon, image, items = [], active, userAccess} = props
    const classes = useStyles()
    const {t} = useTranslation()
    const isExpandable = items && items.length > 0
    const isInnerItem = !isExpandable && !image
    const [open, setOpen] = React.useState(false)
    const loggedUser = authService.get()

    const handleClick = (): void => setOpen(!open)

    const MenuItemRoot = (
        <SideBarMenuItemComponent
            className={isInnerItem ? classes.menuInnerItem : classes.menuItem}
            path={path}
            color={color || COLOR_MENU_PRIMARY}
            onClick={handleClick}>
            {!!Icon && (
                <ListItemIcon className={classes.menuItemIcon}>
                    <Icon/>
                </ListItemIcon>
            )}
            {image && <Avatar variant={'square'} src={image}/>}
            <ListItemText
                className={isInnerItem ? classes.menuInnerItemList : ''}
                primary={
                    <Typography className={isInnerItem ? classes.menuInnerItemText : ''} variant={'h6'}>
                        {loggedUser.language === 'es' ?  t(name) : name.charAt(0).toUpperCase() + name.slice(1)}
                    </Typography>
                }
                inset={!Icon}
            />
        </SideBarMenuItemComponent>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider/>
            <List component="div" disablePadding>
                {items.filter((i) => i.userAccess).map((item, index) => (
                    <SideBarMenuItem {...item} key={index} color={color}/>
                ))}
            </List>
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    )
}

const useStyles = makeStyles((theme) =>
    createStyles({
        menuItem: {
            width: '100%',
            paddingLeft: '25px',
            paddingTop: '20px',
            paddingBottom: '20px',
            textDecoration: 'none',
            '& .MuiListItemText-inset': {
                paddingLeft: '20px',
            },
        },
        menuInnerItem: {
            width: '100%',
            paddingTop: '0px',
            paddingBottom: '0px',
            fontWeight: 10,
            textDecoration: 'none',
        },
        menuItemIcon: {
            color: 'black',
        },
        menuInnerItemList: {
            paddingLeft: '70px',
        },
        menuInnerItemText: {
            fontWeight: 10,
        },
    })
)

export default SideBarMenuItem
