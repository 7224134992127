import { Grid, Step, StepLabel, Stepper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ProductAplication } from "./productApplcation";
import { PatientProduct } from "../../modules/patient/models/relationship/PatientProduct";
import { PatientAplication } from "./patientApplication";
import { SampleAplication } from "./sampleApplication";
import { Address } from "../../modules/address/models/Address";
import { emptyAddressDTO, toModel } from "../../modules/address/models/AddressDTO";
import { getClient2RequestContainer } from 'container/client2request-modules';
import { Client2RequestService } from 'modules/client2Requests/services/Client2RequestService';
import { CLIENT2REQUEST_SERVICE_KEY } from 'modules/client2Requests';
import { getRequestContainer } from "../../container/request-modules";
import { RequestService } from "../../modules/request/services/RequestService";
import { REQUEST_SERVICE_KEY } from "../../modules/request";
import { Request } from "../../modules/request/models/Request";
import { LoadingSpinner } from "../../components/loading-spinner/LoadingSpinner";
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { getAuthContainer } from 'container/auth-modules';
import { AuthService } from 'modules/auth/services/AuthService';
import { AUTH_SERVICE_KEY } from 'modules/auth';

type DetailProps = {
    id?: string
}

const steps = ['product', 'form', 'samples']
const client2RequestService = getClient2RequestContainer().get<Client2RequestService>(CLIENT2REQUEST_SERVICE_KEY)
const requestService = getRequestContainer().get<RequestService>(REQUEST_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

export const Show = (props: DetailProps) => {
    const { t } = useTranslation()
    const [currentStep, setCurrentStep] = useState<number>(0)
    const increaseStep = () => setCurrentStep(currentStep + 1)
    const decreseStep = () => setCurrentStep(currentStep - 1)
    const [patientProducts, setPatientProducts] = useState<PatientProduct[]>([])
    const [address, setAddress] = useState<Address>(toModel(emptyAddressDTO()))
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [request, setRequest] = useState<Request>();
    const loggedUser = authService.get()

    useEffect(() => {
        if (isLoading) {
            client2RequestService.getWizardByID(props.id || "").subscribe((res: any) => {
                if (res) {
                    setAddress(res[0].address)
                    setPatientProducts(res)
                }
                setIsLoading(false)
            })
        }
    }, [isLoading])


    useEffect(() => {
        if (props.id) {
            requestService.getByID(props.id || "").subscribe((res) => {
                if (res) {
                    setCurrentStep(res.step)
                    setRequest(res as Request)
                }
            })
        }
    }, [props])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stepper activeStep={currentStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{t(label)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Grid>
            {!isLoading ? (
                <>
                    {currentStep === 0 && (
                        <ProductAplication
                            increaseStep={increaseStep}
                            patientProducts={patientProducts}
                            setPatientProducts={setPatientProducts}
                            selectedProducts={patientProducts.length > 0 ? [patientProducts[0].product] : []}
                            type={"view"}
                            edit={false}
                        />
                    )}

                    {currentStep === 1 && (
                        <>
                            <PatientAplication
                                decreseStep={decreseStep}
                                increaseStep={increaseStep}
                                patientProducts={patientProducts}
                                setPatientProducts={setPatientProducts}
                                type={"view"}
                                edit={false}
                                clientIDDoingTheRequest={loggedUser.clinicID}
                            />
                        </>
                    )}

                    {
                        currentStep === 2 && (
                            <>
                                <SampleAplication
                                    decreseStep={decreseStep}
                                    increaseStep={increaseStep}
                                    patientProducts={patientProducts}
                                    setPatientProducts={setPatientProducts}
                                    edit={false}
                                    userDoingTheRequest={loggedUser.id}
                                    type={"view"}
                                />
                            </>
                        )
                    }
                </>) :
                (<LoadingSpinner className={style.loadingSpinner} />)
            }
        </Grid>
    )
}

