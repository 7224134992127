import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { StyleProps } from './EntityPage'

export const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  title: (props) => ({
    color: props.color,
  }),
  formContainer: {
    marginTop: theme.spacing(5),
  },
  headerGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  button: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    border: 0,
  },
  actionButton: {
    marginRight: theme.spacing(2),
  },
}))
