import { v4 as uuidv4 } from 'uuid'
import { Request } from './Request'

export interface RequestDTO {
  id: string
  date: Date
  status: string
  idOdoo: string
  userID: string
  step: number;
  newID: string
  language: string
}

export function emptyRequestDTO(): RequestDTO {
  return {
    id: uuidv4(),
    date: new Date(),
    status: '',
    idOdoo: '',
    userID: '',
    step: 0,
    newID: '',
    language: '',
  }
}

export function fromModel(c: Request): RequestDTO {
  return {
    id: c.id,
    date: c.date,
    status: c.status,
    idOdoo: c.idOdoo,
    userID: c.userID,
    step: c.step,
    newID: c.newID,
    language: c.language
  }
}

export function toModel(c: RequestDTO): Request {
  return new Request(c)
}
