import {EnumValueFromIndex, EnumValueToIndex} from "./EnumUtils";
import {Month} from "../enum/Month";

export const MonthFromIndex = (index: number): Month => {
    return EnumValueFromIndex(Month, index)
}

export const MonthToIndex = (month: Month): number => {
    return EnumValueToIndex(Month, month)
}
