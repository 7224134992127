import { Grid, Step, StepLabel, Stepper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ProductAplication } from './productApplcation'
import { PatientProduct } from '../../modules/patient/models/relationship/PatientProduct'
import { PatientAplication } from './patientApplication'
import { FileApplication } from './fileApplication'
import { File } from '../../modules/files/models/File'
import { SampleAplication } from './sampleApplication'
import { getClient2RequestContainer } from 'container/client2request-modules'
import { Client2RequestService } from 'modules/client2Requests/services/Client2RequestService'
import { CLIENT2REQUEST_SERVICE_KEY } from 'modules/client2Requests'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import style from '../../components/loading-spinner/LoadingSpinner.module.css'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import {
  emptyPatientProductDTO,
  PatientProductDTO,
} from 'modules/patient/models/relationship/PatientProductDTO'
import { navigate } from '@reach/router'
import { URL_APPLICATIONS } from 'routes/routes-constants'
import { getSampleTypeContainer } from 'container/sampleType-modules'
import { SampleTypeService } from 'modules/sampleType/services/SampleTypeService'
import { SAMPLETYPE_SERVICE_KEY } from 'modules/sampleType'
import { Query, QueryParam } from 'common/api/Query'
import { useSnackbar } from 'notistack'
import { FormType } from 'common/enums/Enums'

type DetailProps = {
  id?: string
  saveDraftAgain?: boolean
  setSaveDraftAgain: (b: boolean) => void
}

const steps = ['product', 'form', 'samples']
const client2RequestService = getClient2RequestContainer().get<Client2RequestService>(
  CLIENT2REQUEST_SERVICE_KEY
)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const clientRequestService = getClient2RequestContainer().get<Client2RequestService>(
  CLIENT2REQUEST_SERVICE_KEY
)
const sampleTypeService = getSampleTypeContainer().get<SampleTypeService>(SAMPLETYPE_SERVICE_KEY)

export const TemplateNew = (props: DetailProps) => {
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const increaseStep = () => setCurrentStep(currentStep + 1)
  const decreseStep = () => setCurrentStep(currentStep - 1)
  const [patientProducts, setPatientProducts] = useState<PatientProduct[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const loggedUser = authService.get()
  const { enqueueSnackbar } = useSnackbar()
  const [beforeRequest, setBeforeRequest] = useState<PatientProduct[]>([])

  useEffect(() => {
    if (patientProducts?.length == 0) return
    setIsLoading(true)

    if (beforeRequest?.length > 0) {
      switch (Number(beforeRequest[0].product.typeForm)) {
        case FormType.TypeFormCitogeneticaArray:
          let aux = patientProducts
          aux[0].patientCitogenetics = beforeRequest[0].patientCitogenetics
          setPatientProducts(aux)
          break
        case FormType.TypeFormMicroVEBioER:
          let aux2 = patientProducts
          aux2[0].patientMicroVE = beforeRequest[0].patientMicroVE
          setPatientProducts(aux2)
          break
        case FormType.TypeFormNGS:
          let aux3 = patientProducts
          aux3[0].patientNGS = beforeRequest[0].patientNGS
          setPatientProducts(aux3)
          break
        case FormType.TypeFormNull:
          let aux4 = patientProducts
          aux4[0].patientNulls = beforeRequest[0].patientNulls
          setPatientProducts(aux4)
          break
        case FormType.TypeFormOncology:
          let aux5 = patientProducts
          aux5[0].patientOncology = beforeRequest[0].patientOncology
          setPatientProducts(aux5)
          break
        case FormType.TypeFormPGT:
          let aux6 = patientProducts
          aux6[0].patientPGTs = beforeRequest[0].patientPGTs
          setPatientProducts(aux6)
          break
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }, [patientProducts])

  useEffect(() => {
    client2RequestService.getWizardByID(props.id || '').subscribe((res: any) => {
        setBeforeRequest(res)
    })
  }, [])

  const checkRequirements = () => {
    let completed = true

    patientProducts.forEach((p) => {
      if (p._product) {
        switch (Number(p._product.typeForm)) {
          case FormType.TypeFormMicroVEBioER:
            p._patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (patient._sample?._question1) {
                  if (!patient._sample?._endDate) {
                    patient._sample._endDate = new Date()
                  }
                  if (
                    !patient._sample?._typeAntibioticTreatment ||
                    !patient._sample?._treatmentDuration ||
                    !patient._sample?._endDate
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('typeAntibioticTreatment') +
                        ', ' +
                        t('treatmentDuration') +
                        ', ' +
                        t('endDate'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }

                if (i == 0) {
                  if (!patient._sample?._typeSample) {
                    completed = false
                    enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                      variant: 'error',
                    })
                  }
                }

                if (patient._sample?._question2) {
                  if (!patient._sample?._reportDate) {
                    patient._sample._reportDate = new Date()
                  }
                  if (
                    !patient._sample?._reportDate ||
                    patient._sample?._result == '' ||
                    patient._sample?._followedTreatment == ''
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('reportDate') +
                        ', ' +
                        t('result') +
                        ', ' +
                        t('followedTreatment'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormPGT:
            p._patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormOncology:
            p._patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormCitogeneticaArray:
            p._patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormNGS:
            p._patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormNull:
            p._patientNulls.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._type) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          default:
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
        }
      } else if (p.product) {
        switch (Number(p.product.typeForm)) {
          case FormType.TypeFormMicroVEBioER:
            p.patientMicroVE.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }

                if (patient._sample?._question1) {
                  if (
                    !patient._sample?._typeAntibioticTreatment ||
                    !patient._sample?._treatmentDuration ||
                    !patient._sample?._endDate
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('typeAntibioticTreatment') +
                        ', ' +
                        t('treatmentDuration') +
                        ', ' +
                        t('endDate'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }

                if (patient._sample?._question2) {
                  if (
                    !patient._sample?._reportDate ||
                    !patient._sample?._result ||
                    !patient._sample?._followedTreatment
                  ) {
                    completed = false
                    enqueueSnackbar(
                      t('mustCompleteTheFields') +
                        t('reportDate') +
                        ', ' +
                        t('result') +
                        ', ' +
                        t('followedTreatment'),
                      {
                        variant: 'error',
                      }
                    )
                  }
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormPGT:
            p.patientPGTs.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormOncology:
            p.patientOncology.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormCitogeneticaArray:
            p.patientCitogenetics.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break

          case FormType.TypeFormNGS:
            p.patientNGS.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._typeSample) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          case FormType.TypeFormNull:
            p.patientNulls.forEach((patient, i) => {
              if (i == 0) {
                if (!patient._sample?._type) {
                  completed = false
                  enqueueSnackbar(t('mustCompleteTheField') + t('typeSample'), {
                    variant: 'error',
                  })
                }
              }
            })

            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
          default:
            if (!patientProducts[0].language && !patientProducts[0]._language) {
              completed = false
              enqueueSnackbar(t('mustCompleteTheField') + t('language'), {
                variant: 'error',
              })
            }
            break
        }
      }
    })

    !completed && props.setSaveDraftAgain(false)
    completed && handleSaveDraftAgain()
  }

  useEffect(() => {
    if (props.saveDraftAgain) {
      checkRequirements()
    }
  }, [props.saveDraftAgain])

  const handleSaveDraftAgain = async () => {
    setIsLoading(true)
    let arrToSend: PatientProductDTO[] = []

    let pdto = emptyPatientProductDTO()
    pdto.userID = loggedUser.id
    pdto.product = patientProducts[0]._product
    pdto.patientNulls = patientProducts[0].patientNulls
    pdto.patientPGTs = patientProducts[0].patientPGTs
    pdto.patientNGS = patientProducts[0].patientNGS
    pdto.patientMicroVE = patientProducts[0].patientMicroVE
    pdto.patientOncology = patientProducts[0].patientOncology
    pdto.patientCitogenetics = patientProducts[0].patientCitogenetics
    pdto.genes = patientProducts[0]._genes
    pdto.address = patientProducts[0]._address
    //@ts-ignore
    pdto.file = files
    pdto.step = currentStep
    pdto.language = patientProducts[0].language || patientProducts[0]._language

    let types: string[] = []

    patientProducts.forEach((pp, i) => {
      if (i > 0) {
        pp.patientNulls?.forEach((p) => {
          types.push(p._sample?._type)
        })

        pp.patientPGTs?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientNGS?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientMicroVE?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientOncology?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })

        pp.patientCitogenetics?.forEach((p) => {
          types.push(p._sample?._typeSample)
        })
      }
    })

    //@ts-ignore
    let uniqueArray = [...new Set(types)]

    if (uniqueArray?.length > 0) {
      let objTypes = await sampleTypeService
        .getFilteredListDB(
          new Query({
            query: [new QueryParam('idsOdoo', uniqueArray)],
          })
        )
        .toPromise()

      let indication = 'También llega muestra '

      objTypes?.items?.forEach((t, i) => {
        if (i > 0) {
          indication += ', '
        }
        indication += t.nameES
      })

      pdto.indications = indication
    }
    arrToSend.push(pdto)

    for (let i = 1; i < patientProducts?.length; i++) {
      let pdto = emptyPatientProductDTO()
      pdto.userID = loggedUser.id
      pdto.product = patientProducts[i]._product
      pdto.patientNulls = patientProducts[i].patientNulls
      pdto.patientPGTs = patientProducts[i].patientPGTs
      pdto.patientNGS = patientProducts[i].patientNGS
      pdto.patientMicroVE = patientProducts[i].patientMicroVE
      pdto.patientOncology = patientProducts[i].patientOncology
      pdto.patientCitogenetics = patientProducts[i].patientCitogenetics
      pdto.genes = patientProducts[i]._genes
      pdto.address = patientProducts[i]._address
      //@ts-ignore
      pdto.file = files
      pdto.step = currentStep
      arrToSend.push(pdto)
    }

    clientRequestService.sendPatientProductDraftAgain(arrToSend, props.id || '').subscribe(() => {
      setIsLoading(false)
      navigate(URL_APPLICATIONS)
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stepper activeStep={currentStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{t(label)}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      {!isLoading ? (
        <>
          {currentStep === 0 && (
            <ProductAplication
              increaseStep={increaseStep}
              patientProducts={patientProducts}
              setPatientProducts={setPatientProducts}
              selectedProducts={patientProducts.length > 0 ? [patientProducts[0].product] : []}
              requestID={props.id}
            />
          )}
          {currentStep === 1 && (
            <>
              <PatientAplication
                decreseStep={decreseStep}
                increaseStep={increaseStep}
                patientProducts={patientProducts}
                setPatientProducts={setPatientProducts}
                type={'template'}
                edit={true}
                clientIDDoingTheRequest={loggedUser.clinicID}
              />
              <FileApplication data={files} handleFile={setFiles} />
            </>
          )}

          {currentStep === 2 && (
            <>
              <SampleAplication
                decreseStep={decreseStep}
                increaseStep={increaseStep}
                patientProducts={patientProducts}
                setPatientProducts={setPatientProducts}
                userDoingTheRequest={loggedUser.id}
              />
            </>
          )}
        </>
      ) : (
        <LoadingSpinner className={style.loadingSpinner} />
      )}
    </Grid>
  )
}
