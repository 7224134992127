import { Patient } from '../Patient'
import { PatientProduct } from "./PatientProduct";
import { Product } from "../../../product/models/Product";
import { PatientPGT } from "../patientForm/PatientPGT";
import { PatientMicroVE } from "../patientForm/PatientMicroVE";
import { PatientNGS } from "../patientForm/PatientNGS";
import { Genes } from "../../../genes/models/Genes";
import { Address } from "../../../address/models/Address";
import { emptyProductDTO, toModel as toModelProduct } from "../../../product/models/ProductDTO";
import { emptyAddressDTO, toModel as toModelAddress } from "../../../address/models/AddressDTO";
import { File } from "../../../files/models/File";
import {PatientCitogeneticaArray} from "../patientForm/PatientCitogeneticaArray";
import {PatientOncology} from "../patientForm/PatientOncology";

export interface PatientProductDTO {
    product: Product
    patientNulls: Patient[]
    patientPGTs: PatientPGT[]
    patientMicroVE: PatientMicroVE[]
    patientNGS: PatientNGS[]
    patientCitogenetics: PatientCitogeneticaArray[]
    patientOncology: PatientOncology[]
    genes: Genes[]
    address: Address
    userID: string
    step: number
    file: File[]
    language: string
    indications: string
}

export function emptyPatientProductDTO(): PatientProductDTO {
    return {
        product: toModelProduct(emptyProductDTO()),
        patientNulls: [],
        patientPGTs: [],
        patientMicroVE: [],
        patientNGS: [],
        patientCitogenetics: [],
        patientOncology: [],
        genes: [],
        address: toModelAddress(emptyAddressDTO()),
        userID: '',
        file: [],
        step: 0,
        language: '',
        indications: ''
    }
}

export function fromModel(c: PatientProduct): PatientProductDTO {
    return {
        product: c.product,
        address: c.address,
        genes: c.genes,
        patientMicroVE: c.patientMicroVE,
        patientNGS: c.patientNGS,
        patientNulls: c.patientNulls,
        patientPGTs: c.patientPGTs,
        patientCitogenetics: c.patientCitogenetics,
        patientOncology: c.patientOncology,
        userID: c.userID,
        file: c.files,
        step: c.step,
        language: c.language,
        indications: c.indications
    }
}

export function toModel(c: PatientProductDTO): PatientProduct {
    return new PatientProduct(c)
}

