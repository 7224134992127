import { v4 as uuidv4 } from 'uuid'
import { PatientPGT } from './PatientPGT'
import { SampleNGS } from "../../../sampleType/models/sampleForm/SampleNGS";
import { emptySampleNGSDTO } from "../../../sampleType/models/sampleForm/SampleNGSDTO";

export interface PatientPGTDTO {
    _id: string | undefined;
    _firstName: string;
    _lastName: string;
    _dob: Date;
    _nhcOrDni: string;
    _karyotype: boolean
    _indication: string
    _clinicHistory: string
    _sample: SampleNGS

}

export function emptyPatientPGTDTO(): PatientPGTDTO {
    return {
        _id: uuidv4(),
        _firstName: '',
        _lastName: '',
        _dob: new Date(),
        _nhcOrDni: '',
        _karyotype: false,
        _indication: '',
        _clinicHistory: '',
        _sample: new SampleNGS(emptySampleNGSDTO())
    }
}

export function fromPatientPGTModel(c: PatientPGT): PatientPGTDTO {
    return {
        _id: c.id,
        _firstName: c.firstName,
        _lastName: c.lastName,
        _dob: c.dob,
        _nhcOrDni: c.nhcOrDni,
        _karyotype: c.karyotype,
        _indication: c.indication,
        _clinicHistory: c.clinicHistory,
        _sample: c.sample
    }
}

export function toModelPatientPGT(c: PatientPGTDTO): PatientPGT {
    return new PatientPGT(c)
}

export function toModelPatientPGTList(c: PatientPGTDTO[]): PatientPGT[] {
    let list: PatientPGT[] = []
    c?.forEach((p) => {
        list.push(new PatientPGT(p))
    })

    return list
}
