import { Container, ContainerItem } from '../common/container/Container'
import { SampleTypeProps } from '../modules/sampleType/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { SAMPLETYPE_API_KEY, SAMPLETYPE_SERVICE_KEY, SAMPLETYPE_MODULE } from '../modules/sampleType'
import { SampleTypeService } from '../modules/sampleType/services/SampleTypeService'
import { SampleTypeApi } from '../modules/sampleType/api/SampleTypeApi'

let container: Container

function init(p: SampleTypeProps) {
  const items = new Map<symbol, ContainerItem>()

  items.set(SAMPLETYPE_API_KEY, new ContainerItem({ instance: new SampleTypeApi() }))
  items.set(
    SAMPLETYPE_SERVICE_KEY,
    new ContainerItem({
      instance: new SampleTypeService({ apiKey: SAMPLETYPE_API_KEY }),
    })
  )

  container = new Container(items, p.parentContainer, p.config)
}

export function getSampleTypeContainer(): Container {
  if (!container) {
    init({
      parentContainer: getAppContainer(),
      config: {
        moduleFullUrl: moduleURLs[SAMPLETYPE_MODULE],
      },
    })
  }

  return container
}
