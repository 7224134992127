import React from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { HelpContact } from '../../features/help'

import {Title} from "../../components/common/Title";

export const Contact = (props: RouteProps) => {
  return (
    <>
        <Title color={"black"} title={props.title || ''} />
        <HelpContact />
    </>
  )
}
