import {Client} from "../../clients/models/Client";
import {Request} from "../../request/models/Request";
import {Client2Request} from "./Client2Request";
import {Patient} from "../../patient/models/Patient";


export interface Client2RequestDTO {
    client: Client
    request: Request
    patient: Patient
}


export function toModel(c :Client2RequestDTO): Client2Request {
    return new Client2Request(c)
}