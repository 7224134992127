import {SampleCitogenetica} from "./SampleCitogenetica";
import { v4 as uuidv4 } from 'uuid'
import {SampleNGS} from "./SampleNGS";
import {SamplePGT} from "./SamplePGT";


export interface SamplePGTDTO {
    id: string | undefined
    typeSample: string
    extractionDate: Date
    code: string
    idOdoo: string

}

export function emptySamplePGTDTO(): SamplePGTDTO {
    return {
        id: uuidv4(),
        typeSample: '',
        extractionDate: new Date(),
        code: '',
        idOdoo: ''
    }
}

export function fromModel(c: SamplePGT): SamplePGTDTO {
    return {
        id: c.id,
        typeSample: c.typeSample,
        extractionDate: c.extractionDate,
        code: c.code,
        idOdoo: c.idOdoo
    }
}

export function toModel(c: SamplePGTDTO): SamplePGT {
    return new SamplePGT(c)
}
