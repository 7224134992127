import { v4 as uuidv4 } from 'uuid'
import { PatientMicroVE } from './PatientMicroVE'
import { SampleMicroVEBioER } from "../../../sampleType/models/sampleForm/SampleMicroVEBioER";
import { emptySampleMicroVEBioERDTO } from "../../../sampleType/models/sampleForm/SampleMicroVEBioERDTO";

export interface PatientMicroVEDTO {
    _id: string | undefined;
    _firstName: string;
    _lastName: string;
    _age: number;
    _dob: Date;
    _phone: string;
    _email: string;
    _ivfFailure: number;
    _abortions: number;
    _sample: SampleMicroVEBioER
    _nhc: string;
    _indication: string;
}

export function emptyPatientMicroVEDTO(): PatientMicroVEDTO {
    return {
        _id: uuidv4(),
        _firstName: '',
        _lastName: '',
        _age: 0,
        _dob: new Date(),
        _phone: '',
        _email: '',
        _ivfFailure: 0,
        _abortions: 0,
        _sample: new SampleMicroVEBioER(emptySampleMicroVEBioERDTO()),
        _nhc: '',
        _indication: ''
    }
}

export function fromPatientMicroVEModel(c: PatientMicroVE): PatientMicroVEDTO {
    return {
        _id: c.id,
        _firstName: c.firstName,
        _lastName: c.lastName,
        _age: c.age,
        _dob: c.dob,
        _phone: c.phone,
        _email: c.email,
        _ivfFailure: c.ivfFailure,
        _abortions: c.abortions,
        _sample: c.sample,
        _nhc: c.nhc,
        _indication: c.indication,
    }
}

export function toPatientMicroVeModel(c: PatientMicroVEDTO): PatientMicroVE {
    return new PatientMicroVE(c)
}

export function toPatientMicroVeModelList(c: PatientMicroVEDTO[]): PatientMicroVE[] {
    let list: PatientMicroVE[] = []
    c?.forEach((p) => {
        list.push(new PatientMicroVE(p))
    })

    return list
}