import {SampleCitogenetica} from "./SampleCitogenetica";
import { v4 as uuidv4 } from 'uuid'


export interface SampleCitogeneticaDTO {
    _id: string | undefined
    _typeSample: string
    _extractionDate: Date
    _code: string
    _idOdoo: string

}

export function emptySampleCitogeneticaDTO(): SampleCitogeneticaDTO {
    return {
        _id: uuidv4(),
        _typeSample: '',
        _extractionDate: new Date(),
        _code: '',
        _idOdoo: ''
    }
}

export function fromModel(c: SampleCitogenetica): SampleCitogeneticaDTO {
    return {
        _id: c.id,
        _typeSample: c.typeSample,
        _extractionDate: c.extractionDate,
        _code: c.code,
        _idOdoo: c.idOdoo
    }
}

export function toModel(c: SampleCitogeneticaDTO): SampleCitogenetica {
    return new SampleCitogenetica(c)
}
