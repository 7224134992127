import {RouteProps} from '../../routes/AppRouter'
import {ClientForm} from '../../features/clients'
import {Title} from '../../components/common/Title'
import {useTranslation} from 'react-i18next'
import {useParams} from '@reach/router'
import {getClientContainer} from "../../container/client-modules";
import {ClientService} from "../../modules/clients/services/ClientService";
import {CLIENTS_SERVICE_KEY} from "../../modules/clients";
import {useEffect, useState} from "react";
import {Client} from "../../modules/clients/models/Client";

const clientService = getClientContainer().get<ClientService>(CLIENTS_SERVICE_KEY)

export const Form = (props: RouteProps) => {
    const {t} = useTranslation()
    const params = useParams()
    const [client, setClient] = useState<Client>()
    const title = params && params.id ? t('Edit') : t(`new`) 

    useEffect(()=>{
        if (params.id === 'create'){
            return
        }
        clientService.getByID(params.id).subscribe((res)=>{
            setClient(res)
        })
    },[params.id])

    return (
        <>
            <Title color={"black"} title={title}/>
            <ClientForm client={client}/>
        </>
    )
}
