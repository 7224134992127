import {v4 as uuidv4} from 'uuid'
import {SampleOncology} from "./SampleOncology";


export interface SampleOncologyDTO {
    _id: string | undefined
    _typeSample: string
    _sampleProvided: number
    _metastasesDescription: string
    _extractionDate: Date
    _sampleTumorCellularity: string
    _code: string
    _idOdoo: string
}

export function emptySampleOncologyDTO(): SampleOncologyDTO {
    return {
        _id: uuidv4(),
        _typeSample: '',
        _metastasesDescription: '',
        _sampleProvided: 0,
        _sampleTumorCellularity: '',
        _extractionDate: new Date(),
        _code: '',
        _idOdoo: ''
    }
}

export function fromModel(c: SampleOncology): SampleOncologyDTO {
    return {
        _id: c.id,
        _typeSample: c.typeSample,
        _sampleTumorCellularity: c.sampleTumorCellularity,
        _sampleProvided: c.sampleProvided,
        _metastasesDescription: c.metastasesDescription,
        _extractionDate: c.extractionDate,
        _code: c.code,
        _idOdoo: c.idOdoo
    }
}

export function toModel(c: SampleOncologyDTO): SampleOncology {
    return new SampleOncology(c)
}
