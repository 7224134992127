import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Request, RequestQuery } from '../models/Request'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { RequestDTO } from '../models/RequestDTO'
import { IRequestApi } from '../api/RequestApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { File, FileDTO } from 'modules/files/models/File'
import { Address } from 'modules/address/models/Address'

type Props = {
  apiKey: symbol
}

export interface IRequestService extends IInit {
  getByID(id: string): Observable<Request | undefined>

  getFilteredList(q: Query<RequestQuery>): Observable<ItemList<Request>>

  add(e: Request): Observable<Request | undefined>

  update(e: RequestDTO): Observable<Request | undefined>

  delete(id: string): Observable<boolean>

  getFilteredListByRole(q: Query<RequestQuery>): Observable<ItemList<Request>>

  add(e: Request): Observable<Request | undefined>

  sendResultFiles(e: FileDTO[], requestID: string): Observable<Request | undefined>

  downloadResults(id: string): Observable<File | undefined>

  downloadAttached(id: string): Observable<File | undefined>

  createPickupMultiple(address: Address, requestsIDs: string[]): Observable<Request | undefined>
}

export class RequestService implements IRequestService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IRequestApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IRequestApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: RequestDTO): Observable<Request | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Request | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<RequestQuery>): Observable<ItemList<Request>> {
    return this._api.getFilteredList(q)
  }

  update(e: RequestDTO): Observable<Request | undefined> {
    return this._api.update(e)
  }

  getFilteredListByRole(q: Query<RequestQuery>): Observable<ItemList<Request>> {
    return this._api.getFilteredListByRole(q)
  }

  sendResultFiles(e: FileDTO[]): Observable<Request | undefined> {
    return this._api.sendResultFiles(e)
  }

  downloadResults(id: string): Observable<File | undefined> {
    return this._api.downloadResults(id)
  }

  downloadAttached(id: string): Observable<File | undefined> {
    return this._api.downloadAttached(id)
  }

  createPickupMultiple(address: Address, requestsIDs: string[]): Observable<Request | undefined> {
    return this._api.createPickupMultiple(address, requestsIDs)
  }
}
