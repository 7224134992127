export type ClinicProps = {
  id?: string
  idClinic?: string
  name: string
  address: string
  cp: string
  locality: string
  cif: string
  odooNumber?: string
  email: string
  phone: string
  testType: string
  patients: string[]
  entryDate: Date
  disabled: boolean
}

export class Clinic {
  private readonly _id: string | undefined
  private _idClinic: string | undefined
  private _name: string
  private _address: string
  private _cp: string
  private _locality: string
  private _cif: string
  private _odooNumber: string | undefined
  private _email: string
  private _phone: string
  private _testType: string
  private _patients: string[]
  private readonly _entryDate: Date
  private _disabled: boolean


  constructor(p: ClinicProps) {
    this._id = p.id
    this._idClinic = p.idClinic
    this._name = p.name
    this._address = p.address
    this._cp = p.cp
    this._locality = p.locality
    this._cif = p.cif
    this._odooNumber = p.odooNumber
    this._email = p.email
    this._phone = p.phone
    this._testType = p.testType
    this._patients = p.patients
    this._entryDate = new Date()
    this._disabled = p.disabled
  }

  get id(): string | undefined {
    return this._id
  }

  get idClinic(): string | undefined {
    return this._idClinic
  }

  set idClinic(value: string | undefined) {
    this._idClinic = value
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get address(): string {
    return this._address
  }

  set address(value: string) {
    this._address = value
  }

  get cp(): string {
    return this._cp
  }

  set cp(value: string) {
    this._cp = value
  }

  get locality(): string {
    return this._locality
  }

  set locality(value: string) {
    this._locality = value
  }

  get cif(): string {
    return this._cif
  }

  set cif(value: string) {
    this._cif = value
  }

  get odooNumber(): string | undefined {
    return this._odooNumber
  }

  set odooNumber(value: string | undefined) {
    this._odooNumber = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }

  get phone(): string {
    return this._phone
  }

  set phone(value: string) {
    this._phone = value
  }

  get testType(): string {
    return this._testType
  }

  set testType(value: string) {
    this._testType = value
  }

  get patients(): string[] {
    return this._patients
  }

  set patients(value: string[]) {
    this._patients = value
  }

  get entryDate() : Date {
    return this._entryDate
  }

  get disabled() : boolean{
    return this._disabled
  }

  set disabled(value :boolean) {
    this._disabled = value
  }

}

export interface ClinicQuery {
  ids: string
  patient: string
  name: string
  cif: string
}
