export enum Pathology {
    EuploidyRate = "euploidyRate",
    TrisomyRate = "trisomyRate",
    MonosomyRate = "monosomyRate",
    DuplicationRate = "duplicationRate",
    DeletionRate = "deletionRate",
    MosaicismRate = "mosaicismRate",
    ChaoticRate = "chaoticRate",
    NonAplifiedRate = "nonAmplifiedRate",
    NonInformativeRate = "nonInformativeRate",
    AllPathologies = "allPathologies",
}