import { Container, ContainerItem } from '../common/container/Container'
import { StatisticsProps } from '../modules/statistics/container'
import { getAppContainer } from './app'
import { moduleURLs } from '../config/api'
import { STATISTICS_API_KEY, STATISTICS_SERVICE_KEY, STATISTICS_MODULE } from '../modules/statistics'
import { StatisticsService } from '../modules/statistics/services/StatisticsService'
import { StatisticsApi } from '../modules/statistics/api/StatisticsApi'

let container: Container

function init(p: StatisticsProps) {
    const items = new Map<symbol, ContainerItem>()

    items.set(STATISTICS_API_KEY, new ContainerItem({ instance: new StatisticsApi() }))
    items.set(
        STATISTICS_SERVICE_KEY,
        new ContainerItem({
            instance: new StatisticsService({ apiKey: STATISTICS_API_KEY }),
        })
    )

    container = new Container(items, p.parentContainer, p.config)
}

export function getStatisticsContainer(): Container {
    if (!container) {
        init({
            parentContainer: getAppContainer(),
            config: {
                moduleFullUrl: moduleURLs[STATISTICS_MODULE],
            },
        })
    }

    return container
}
