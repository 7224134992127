import {FaqDTO} from "./FaqDTO";

export type FaqProps = {
  id: string
  name: string
  creatorID: string
  question: string
  answer: string
  language: string
}



export class Faq {
  _id: string
  _name: string
  _creatorID: string
  _question: string
  _answer: string
  _language: string



  constructor(p: FaqProps) {
    this._id = p.id
    this._name = p.name
    this._creatorID = p.creatorID
    this._question = p.question
    this._answer = p.answer
    this._language = p.language

  }

  get id(): string {
    return this._id
  }

  get name(): string  {
    return this._name
  }

  set name(value: string ) {
    this._name = value
  }

  get creatorID(): string {
    return this._creatorID
  }

  set creatorID(value: string) {
    this._creatorID = value
  }

  get question(): string {
    return this._question
  }

  set question(value: string) {
    this._question = value
  }

  get answer(): string {
    return this._answer
  }

  set answer(value: string) {
    this._answer = value
  }

  get language(): string {
    return this._language
  }

  set language(value: string) {
    this._language = value
  }

  toDTO(): FaqDTO {
    return {
      id: this.id,
      name: this.name,
      creatorID: this.creatorID,
      question: this.question,
      answer: this.answer,
      language: this.language
    }
  }

}

export interface FaqQuery {
  id: string
  ids: string[]
  creatorID: string
  name: string
  language: string
}


export function toModel(f: FaqDTO): Faq {
  return new Faq(f)
}