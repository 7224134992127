import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { Product, ProductQuery } from '../models/Product'
import { ProductContainerConfig } from '../container'
import { ProductDTO, toModel } from '../models/ProductDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'

export interface IProductApi extends IInit {
  getByID(id: string): Observable<Product | undefined>

  getFilteredList(q: Query<ProductQuery>): Observable<ItemList<Product>>

  getFilteredListFromDatabase(q: Query<ProductQuery>): Observable<ItemList<Product>>

  getFilteredListUpdated(q: Query<ProductQuery>): Observable<ItemList<Product>>

  add(e: ProductDTO): Observable<Product | undefined>

  update(e: ProductDTO): Observable<Product | undefined>

  updateFromOdoo(e: ProductDTO): Observable<Product | undefined>

  delete(id: string): Observable<boolean>
}

export class ProductApi implements IProductApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ProductContainerConfig).moduleFullUrl
  }

  add(e: ProductDTO): Observable<Product | undefined> {
    return this._httpClient.post<Product>({ url: this._url, body: e }).pipe(
      map<ProductDTO, Product>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Product | undefined> {
    return this._httpClient.get<Product>({ url: `${this._url}/${id}` }).pipe(
      map<ProductDTO, Product>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<ProductQuery>): Observable<ItemList<Product>> {
    return this._httpClient.get<ItemList<Product>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<ProductDTO>, ItemList<Product>>((dto) => {
        const itemList = emptyList<Product>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Product>())
      })
    )
  }

  getFilteredListFromDatabase(q: Query<ProductQuery>): Observable<ItemList<Product>> {
    return this._httpClient.get<ItemList<Product>>({ url: prepareURL(this._url+"/list/fromdatabase", q) }).pipe(
      map<ItemList<ProductDTO>, ItemList<Product>>((dto) => {
        const itemList = emptyList<Product>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Product>())
      })
    )
  }

  getFilteredListUpdated(q: Query<ProductQuery>): Observable<ItemList<Product>> {
    return this._httpClient.get<ItemList<Product>>({ url: prepareURL(this._url+"/list/notindatabase", q) }).pipe(
      map<ItemList<ProductDTO>, ItemList<Product>>((dto) => {
        const itemList = emptyList<Product>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Product>())
      })
    )
  }

  update(e: ProductDTO): Observable<Product | undefined> {
    return this._httpClient.put<Product>({ url: this._url, body: e }).pipe(
      map<ProductDTO, Product>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateFromOdoo(e: ProductDTO): Observable<Product | undefined> {
    return this._httpClient.put<Product>({ url: this._url+"/updateFromOdoo", body: e }).pipe(
      map<ProductDTO, Product>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }
}
