import { Container } from '../../common/container/Container'

export type GeneTypeContainerConfig = {
    moduleFullUrl: string
}

export type GeneTypeProps = {
    parentContainer: Container
    config: GeneTypeContainerConfig
}

export const GENE_TYPE_API_KEY = Symbol('GENE_TYPE_API_KEY')
export const GENE_TYPE_SERVICE_KEY = Symbol('GENE_TYPE_SERVICE_KEY')
export const GENE_TYPES_MODULE = Symbol('GENE_TYPES_MODULE')
