export interface RequestViewDTO {
    date: Date
    status: string
    name: string
    taxName: string
    codeSample: string
    trackingNumber: string
    idOdoo: string
    userID: string
    requestID : string
    street: string
    number: string
    city: string
    zipCode: string
    province: string
    country: string
    taxIdentity: string
    newID: string
    patientID: string
    trackingState: string
    hasResult: boolean
    

}

export class RequestView {
  private _date: Date
    private _status: string
    private _name: string
    private _taxName: string
    private _codeSample: string
    private _trackingNumber: string
    private _idOdoo: string
    private _userID: string
    private _requestID: string
    private _street: string
    private _number: string
    private _city: string
    private _zipCode: string
    private _province: string
    private _country: string
    private _taxIdentity: string
    private _newID: string
    private _patientID: string
    private _trackingState: string
    private _hasResult: boolean
    
    constructor(p: RequestViewDTO) {
        this._date = p.date
        this._status = p.status
        this._name = p.name
        this._taxName = p.taxName
        this._codeSample = p.codeSample
        this._trackingNumber = p.trackingNumber
        this._idOdoo = p.idOdoo
        this._userID = p.userID
        this._requestID = p.requestID
        this._street = p.street
        this._number = p.number
        this._city = p.city
        this._zipCode = p.zipCode
        this._province = p.province
        this._country = p.country
        this._taxIdentity = p.taxIdentity
        this._newID = p.newID
        this._patientID = p.patientID
        this._trackingState = p.trackingState
        this._hasResult = p.hasResult

    }

    get date(): Date {
        return this._date
    }
    get status(): string {
        return this._status;
    }

    set status(value: string) {
        this._status = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get taxName(): string {
        return this._taxName;
    }

    set taxName(value: string) {
        this._taxName = value;
    }

    get codeSample(): string {
        return this._codeSample;
    }

    set codeSample(value: string) {
        this._codeSample = value;
    }

    get trackingNumber(): string {
        return this._trackingNumber;
    }

    set trackingNumber(value: string) {
        this._trackingNumber = value;
    }

    get idOdoo(): string {
        return this._idOdoo;
    }

    set idOdoo(value: string) {
        this._idOdoo = value;
    }

    get userID(): string {
        return this._userID;
    }

    set userID(value: string) {
        this._userID = value;
    }

    get requestID(): string {
        return this._requestID;
    }

    set requestID(value: string) {
        this._requestID = value;
    }

    get street(): string {
        return this._street;
    }

    set street(value: string) {
        this._street = value;
    }

    get number(): string {
        return this._number;
    }

    set number(value: string) {
        this._number = value;
    }

    get city(): string {
        return this._city;
    }

    set city(value: string) {
        this._city = value;
    }

    get zipCode(): string {
        return this._zipCode;
    }

    set zipCode(value: string) {
        this._zipCode = value;
    }

    get province(): string {
        return this._province;
    }

    set province(value: string) {
        this._province = value;
    }

    get country(): string {
        return this._country;
    }

    set country(value: string) {
        this._country = value;
    }

    get taxIdentity(): string {
        return this._taxIdentity;
    }

    set taxIdentity(value: string) {
        this._taxIdentity = value;
    }

    get newID(): string {
        return this._newID;
    }

    set newID(value: string) {
        this._newID = value;
    }

    get patientID(): string {
        return this._patientID;
    }

    set patientID(value: string) {
        this._patientID = value;
    }

    get trackingState(): string {
        return this._trackingState;
    }

    set trackingState(value: string) {
        this._trackingState = value;
    }

    get hasResult(): boolean {
        return this._hasResult;
    }

    set hasResult(value: boolean) {
        this._hasResult = value;
    }

}

//to Model
export function toModel(c: RequestViewDTO): RequestView {
    return new RequestView(c)
}
