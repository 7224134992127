import { v4 as uuidv4 } from 'uuid'

export interface FileDTO {
  id: string
  name: string
  date: Date
  size: number
  mimeType: string
  extension: string
  ownerID: string
  base64: string
  isReport: boolean
}

export interface LanguageConsent {
  id: string
  typeConsent: string
  language: string
}

export class File {
    _id: string
    _name: string
    _date: Date
    _size: number
    _mimeType: string
    _extension: string
    _ownerID: string
    _base64: string
   _isReport: boolean

  constructor(p: FileDTO) {
    this._id = p.id
    this._name = p.name
    this._date = p.date
    this._size = p.size
    this._mimeType = p.mimeType
    this._extension = p.extension
    this._ownerID = p.ownerID
    this._base64 = p.base64
    this._isReport = p.isReport
  }

  get id(): string {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get date(): Date {
    return this._date
  }

  get size(): number {
    return this._size
  }

  get mimeType(): string {
    return this._mimeType
  }

  get extension(): string {
    return this._extension
  }

  get ownerID(): string {
    return this._ownerID
  }

  get base64(): string {
    return this._base64
  }

  set name(value: string) {
    this._name = value
  }

  set date(value: Date){
    this._date = value
  }

  set size(value: number) {
    this._size = value
  }

  set mimeType(value: string) {
    this._mimeType = value
  }

  set extension(value: string) {
    this._extension = value
  }

  set ownerID(value: string) {
    this._ownerID = value
  }

  set base64(value: string) {
    this._base64 = value
  }

  set isReport(value: boolean) {
    this._isReport = value
  }

  get isReport(): boolean {
    return this._isReport
  }
}

export function fromModel(c: File): FileDTO {
  return {
    id: c.id,
    name: c.name,
    date: c.date,
    size: c.size,
    mimeType: c.mimeType,
    extension: c.extension,
    ownerID: c.ownerID,
    base64: c.base64,
    isReport: c.isReport
  }
}

export function toModel(d: FileDTO): File {
  return new File(d)
}

export function emptyFileDTO(): FileDTO {
  return {
    id: uuidv4(),
    name: '',
    date: new Date(),
    size: 0,
    mimeType: '',
    extension: '',
    ownerID: '',
    base64: '',
    isReport: false,
  }
}


export interface FileQuery {
  id: string
  date: Date
  ownerID: string
  isReport: boolean
  name: string
}