import {Container, IInit} from '../../../common/container/Container'
import {AuthDTO} from '../models/AuthDTO'
import {Observable, of} from 'rxjs'
import {LoggedUserDTO} from '../models/LoggedUserDTO'
import {IStatusService} from '../../../common/status/StatusService'
import {HTTP_CLIENT_KEY, IHTTPClient} from '../../../common/api/HTTPClient'
import {STATUS_SERVICE_KEY} from '../../../container/app'
import {catchError, map} from 'rxjs/operators'
import {AuthContainerConfig} from '../container'
import {RegisterDTO} from '../../users/models/RegisterDTO'
import {ChangePasswordDTO} from '../models/ChangePassword'
import {Error} from '../../../common/error/ErrorModel'

export interface IAuthApi extends IInit {
    login(a: AuthDTO): Observable<LoggedUserDTO | Error>

    logout(): Observable<boolean>

    register(a: RegisterDTO): Observable<boolean>

    changePassword(a: ChangePasswordDTO): Observable<boolean>

    changePasswordWithToken(a: ChangePasswordDTO, token: string): Observable<boolean>

    recoverPassword(a: { email: string }): Observable<boolean>

    update(): Observable<LoggedUserDTO | undefined>

    switchState(id: string): Observable<boolean>
}

export class AuthApi implements IAuthApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as AuthContainerConfig).moduleFullUrl
    }

    login(a: AuthDTO): Observable<LoggedUserDTO | Error> {
        return this._httpClient.post<LoggedUserDTO | Error>({url: this._url + '/login', body: a}).pipe(
            map((r) => {
                if ('message' in r) {
                    const code = r.code || 500;
                    const error = r.error || 'Internal Server Error';
                    const message = r.message;
                    throw { code, error, message };
                }
                return r;
            }),
            catchError((err) => {
                const error = {code: (err as Error).code, error: (err as Error).error, message: err.message};
                this._statusService.sendStatus({variant: 'error', error: err});
                return of(error);
            })
        )
    }

    logout(): Observable<boolean> {
        return this._httpClient.post<boolean>({url: this._url + '/logout'}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    register(a
                 :
                 RegisterDTO
    ):
        Observable<boolean> {
        return this._httpClient.post<boolean>({url: this._url + '/register', body: a}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    changePassword(a
                       :
                       ChangePasswordDTO
    ):
        Observable<boolean> {
        return this._httpClient.post<boolean>({url: this._url + '/change-password', body: a}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    changePasswordWithToken(a
                                :
                                ChangePasswordDTO, token
                                :
                                string
    ):
        Observable<boolean> {
        return this._httpClient
            .post<boolean>({url: this._url + '/change-password/' + token, body: a})
            .pipe(
                catchError((err) => {
                    this._statusService.sendStatus({variant: 'error', error: err})
                    return of(false)
                })
            )
    }

    recoverPassword(a
                        :
                        {
                            email: string
                        }
    ):
        Observable<boolean> {
        return this._httpClient.post<boolean>({url: this._url + '/recover-password', body: a}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    update()
        :
        Observable<LoggedUserDTO | undefined> {
        return this._httpClient.get<LoggedUserDTO>({url: this._url + '/update'}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(undefined)
            })
        )
    }

    switchState(id
                    :
                    string
    ):
        Observable<boolean> {
        return this._httpClient.put<boolean>({url: this._url + '/update-status/' + id}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }
}
