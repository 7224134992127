import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { SampleType, SampleTypeQuery } from '../models/SampleType'
import { SampleTypeContainerConfig } from '../container'
import { SampleTypeDTO, toModel } from '../models/SampleTypeDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'

export interface ISampleTypeApi extends IInit {
  getByID(id: string): Observable<SampleType | undefined>

  getFilteredList(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>>

  getFilteredListDB(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>>
}

export class SampleTypeApi implements ISampleTypeApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as SampleTypeContainerConfig).moduleFullUrl
  }

  getByID(id: string): Observable<SampleType | undefined> {
    return this._httpClient.get<SampleType>({ url: `${this._url}/${id}` }).pipe(
      map<SampleTypeDTO, SampleType>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>> {
    return this._httpClient.get<ItemList<SampleType>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<SampleTypeDTO>, ItemList<SampleType>>((dto) => {
        const itemList = emptyList<SampleType>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<SampleType>())
      })
    )
  }

  getFilteredListDB(q: Query<SampleTypeQuery>): Observable<ItemList<SampleType>> {
    return this._httpClient.get<ItemList<SampleType>>({ url: prepareURL(this._url +"/list/db", q) }).pipe(
      map<ItemList<SampleTypeDTO>, ItemList<SampleType>>((dto) => {
        const itemList = emptyList<SampleType>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<SampleType>())
      })
    )
  }
}
