import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Genes, GenesQuery } from '../models/Genes'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { GenesDTO } from '../models/GenesDTO'
import { IGenesApi } from '../api/GenesApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IGenesService extends IInit {
  getByID(id: string): Observable<Genes | undefined>

  getFilteredList(q: Query<GenesQuery>): Observable<ItemList<Genes>>

  add(e: Genes): Observable<Genes | undefined>

  update(e: GenesDTO): Observable<Genes | undefined>

  delete(id: string): Observable<boolean>
}

export class GenesService implements IGenesService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IGenesApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IGenesApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: GenesDTO): Observable<Genes | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Genes | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<GenesQuery>): Observable<ItemList<Genes>> {
    return this._api.getFilteredList(q)
  }

  update(e: GenesDTO): Observable<Genes | undefined> {
    return this._api.update(e)
  }
}
