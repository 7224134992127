import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Observable, of } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Query } from '../../../common/api/Query'
import { Request, RequestQuery } from '../models/Request'
import { RequestContainerConfig } from '../container'
import { RequestDTO, toModel } from '../models/RequestDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { catchError, map } from 'rxjs/operators'
import { File, FileDTO } from 'modules/files/models/File'
import { Address } from 'modules/address/models/Address'

export interface IRequestApi extends IInit {
  getByID(id: string): Observable<Request | undefined>

  getFilteredList(q: Query<RequestQuery>): Observable<ItemList<Request>>

  add(e: RequestDTO): Observable<Request | undefined>

  update(e: RequestDTO): Observable<Request | undefined>

  delete(id: string): Observable<boolean>

  getFilteredListByRole(q: Query<RequestQuery>): Observable<ItemList<Request>>

  sendResultFiles(e: FileDTO[]): Observable<Request | undefined>

  downloadResults(id: string): Observable<File | undefined>

  downloadAttached(id: string): Observable<File | undefined>

  createPickupMultiple(address: Address, requestsIDs: string[]): Observable<Request | undefined>
}

export class RequestApi implements IRequestApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as RequestContainerConfig).moduleFullUrl
  }

  add(e: RequestDTO): Observable<Request | undefined> {
    return this._httpClient.post<Request>({ url: this._url, body: e }).pipe(
      map<RequestDTO, Request>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: this._url + '/' + id }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Request | undefined> {
    return this._httpClient.get<Request>({ url: `${this._url}/${id}` }).pipe(
      map<RequestDTO, Request>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<RequestQuery>): Observable<ItemList<Request>> {
    return this._httpClient.get<ItemList<Request>>({ url: prepareURL(this._url, q) }).pipe(
      map<ItemList<RequestDTO>, ItemList<Request>>((dto) => {
        const itemList = emptyList<Request>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<Request>())
      })
    )
  }

  update(e: RequestDTO): Observable<Request | undefined> {
    return this._httpClient.put<Request>({ url: this._url, body: e }).pipe(
      map<RequestDTO, Request>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredListByRole(q: Query<RequestQuery>): Observable<ItemList<Request>> {
    return this._httpClient
      .get<ItemList<Request>>({ url: prepareURL(this._url + '/paginatedList/byRole', q) })
      .pipe(
        map<ItemList<RequestDTO>, ItemList<Request>>((dto) => {
          const itemList = emptyList<Request>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Request>())
        })
      )
  }

  sendResultFiles(e: FileDTO[]): Observable<Request | undefined> {
    return this._httpClient
      .post<Request>({ url: this._url + '/resultFiles', body: { files: e } })
      .pipe(
        map<RequestDTO, Request>((d) => toModel(d)),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  downloadResults(id: string): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: `${this._url}/downloadResults/${id}` })
  }

  downloadAttached(id: string): Observable<File | undefined> {
    return this._httpClient.get<File>({ url: `${this._url}/downloadAttached/${id}` })
  }

  createPickupMultiple(address: Address, requestsIDs: string[]): Observable<Request | undefined> {
    return this._httpClient
      .post<Request>({
        url: this._url + '/multiplePickup',
        body: {
          requestsIDs: requestsIDs,
          address: address,
        },
      })
      .pipe(
        map<RequestDTO, Request>((d) => {
          return toModel(d)}),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
