export const COLOR_PRIMARY = '#00b8e2'
export const COLOR_HOME = COLOR_PRIMARY
export const COLOR_CLIENTS = COLOR_PRIMARY
export const COLOR_APPLICATIONS = '#00b8e2'
export const COLOR_REPORTS = COLOR_PRIMARY
export const COLOR_RESULTS = COLOR_PRIMARY
export const COLOR_MATCHING = 'rgba(106, 106, 106, 1)'
export const COLOR_MENU_PRIMARY = '#00b8e2'
export const COLOR_EUPLOIDY_RATE = '#003f5c'
export const COLOR_TRISOMY_RATE = '#2f4b7c'
export const COLOR_MONOSOMY_RATE = '#665191'
export const COLOR_DUPLICATION_RATE = '#a05195'
export const COLOR_DELETION_RATE = '#d45087'
export const COLOR_MOSAICISM_RATE = '#f95d6a'
export const COLOR_CHAOTIC_RATE = '#ff7c43'
export const COLOR_NON_AMPLIFIED_RATE = '#ffa600'
export const COLOR_NON_INFORMATIVE_RATE = '#ffcfbd'

