import { Container } from '../../common/container/Container'

export type CsvContainerConfig = {
    moduleFullUrl: string
}

export type CsvProps = {
    parentContainer: Container
    config: CsvContainerConfig
}

export const CSV_API_KEY = Symbol('CSV_API_KEY')
export const CSV_SERVICE_KEY = Symbol('CSV_SERVICE_KEY')
export const CSVS_MODULE = Symbol('CSVS_MODULE')
