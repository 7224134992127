import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { Client2Request} from "../models/Client2Request";
import { RequestView} from "../models/RequestsView";

import { Wizard } from "../models/Wizard"
import { WizardDTO } from "../models/WizardDTO";
import { Client2RequestDTO } from "../models/Client2RequestDTO";
import { IClient2RequestApi } from "../api/Client2RequestApi";
import { ItemList } from "../../../common/models/ItemList";
import { PatientProduct } from "../../patient/models/relationship/PatientProduct";
import { PatientProductDTO } from "../../patient/models/relationship/PatientProductDTO";
import { GenesDTO } from 'modules/genes/models/GenesDTO';

type Props = {
    apiKey: symbol
}

export interface IClient2RequestService extends IInit {
    getByID(id: string): Observable<Client2Request | undefined>

    add(e: Client2Request): Observable<Client2Request | undefined>

    update(e: Client2RequestDTO): Observable<Client2Request | undefined>

    delete(id: string): Observable<boolean>

    getByUserID(id: string): Observable<ItemList<Client2Request>>

    byUserIDPost(id: string, offset: number, limit: number, search: string, sortField: string, sortDirection: boolean | undefined, status: string): Observable<ItemList<Client2Request>>

    byUserIDPostAll(id: string, search: string, status: string): Observable<ItemList<Client2Request>>

    getByClientID(id: string): Observable<ItemList<Client2Request>>

    saveWizard(e: Wizard): Observable<Wizard | undefined>

    saveWizardDraft(e: Wizard): Observable<Wizard | undefined>

    sendPatientProduct(e: PatientProductDTO[]): Observable<Request[] | undefined>

    sendPatientProductDraft(e: PatientProductDTO[]): Observable<PatientProductDTO | undefined>

    getWizardByID(id: string): Observable<PatientProduct[] | undefined>

    updateWizard(id: string, pp: PatientProductDTO[]): Observable<PatientProductDTO | undefined>

    createTemplate(r: Request, pp: PatientProduct): void

    sendPatientProductDraftAgain(e: PatientProductDTO[], id: string): Observable<PatientProductDTO | undefined>

    getGenesByRequestAndProduct(requestID: string, productID: string):  Observable<GenesDTO[] | undefined>

    getCodeSamples(id: string): Observable<ItemList<string | undefined>>

    getRequestsView(search: string, userID: string , sortField: string, sortDirection: boolean,  status: string, offset: number, limit: number): Observable<ItemList<RequestView>>
}

export class Client2RequestService implements IClient2RequestService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: IClient2RequestApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<IClient2RequestApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    add(e: Client2RequestDTO): Observable<Client2Request | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    getByID(id: string): Observable<Client2Request | undefined> {
        return this._api.getByID(id)
    }

    update(e: Client2RequestDTO): Observable<Client2Request | undefined> {
        return this._api.update(e)
    }

    getByUserID(id: string): Observable<ItemList<Client2Request>> {
        return this._api.getByUserID(id)
    }

    byUserIDPost(id: string, offset: number, limit: number, search: string, sortField: string, sortDirection: boolean | undefined, status: string): Observable<ItemList<Client2Request>> {
        return this._api.byUserIDPost(id, offset, limit, search, sortField, sortDirection, status)
    }

    byUserIDPostAll(id: string, search: string, status: string): Observable<ItemList<Client2Request>> {
        return this._api.byUserIDPostAll(id, search, status)
    }

    getByClientID(id: string): Observable<ItemList<Client2Request>> {
        return this._api.getByClientID(id)
    }

    saveWizard(e: WizardDTO): Observable<Wizard | undefined> {
        return this._api.saveWizard(e)
    }

    saveWizardDraft(e: WizardDTO): Observable<Wizard | undefined> {
        return this._api.saveWizardDraft(e)
    }

    sendPatientProduct(e: PatientProductDTO[]): Observable<Request[] | undefined> {
        return this._api.sendPatientProduct(e)
    }

    sendPatientProductDraft(e: PatientProductDTO[]): Observable<PatientProductDTO | undefined> {
        return this._api.sendPatientProductDraft(e)
    }

    sendPatientProductDraftAgain(e: PatientProductDTO[], id: string): Observable<PatientProductDTO | undefined> {
        return this._api.sendPatientProductDraftAgain(e, id)
    }

    getWizardByID(id: string): Observable<PatientProduct[] | undefined> {
        return this._api.getWizardByID(id)
    }

    updateWizard(id: string, pp: PatientProductDTO[]): Observable<PatientProductDTO | undefined> {
        return this._api.updateWizard(id, pp)
    }

    createTemplate(r: Request, pp: PatientProduct): void {
        return this._api.createTemplate(r, pp)
    }

    getGenesByRequestAndProduct(requestID: string, productID: string): Observable<GenesDTO[] | undefined> {
        return this._api.getGenesByRequestAndProduct(requestID, productID)
    }
    getCodeSamples(id: string): Observable<ItemList<string | undefined>> {
        return this._api.getCodeSamples(id)
    }
    getRequestsView(search: string, userID: string , sortField: string, sortDirection: boolean, status: string, offset: number, limit: number): Observable<ItemList<RequestView>> {
        return this._api.getRequestsView(search, userID, sortField, sortDirection, status, offset, limit)
    }
}
