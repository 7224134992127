import i18n from "i18next";
import {Option, OptionValue} from "../utils/form-generation/types";

export enum Tests {
    CompletePanel = "851fea73-9913-4701-837a-2af9cdbec05b"
}

export enum FormType {
    TypeFormMicroVEBioER,
    TypeFormPGT,
    TypeFormOncology,
    TypeFormCitogeneticaArray,
    TypeFormNGS,
    TypeFormNull
}

export enum SampleType {
    TypeSampleMicroVEBioER,
    TypeSample,
    TypeSampleOncology,
    TypeSampleCitogeneticaArray,
    TypeSampleNGS,
    TypeSampleNull
}

export enum ConsentType {
    TypeConsentTrisomias,
    TypeConsentArrayCGH,
    TypeConsentPanelPortadores,
    TypeConsentBiopsiaLiquida,
    TypeConsentCitogenetica,
    TypeConsentExoma,
    TypeConsentGenoma,
    TypeConsentMicroVEBiopsiaEndometrial,
    TypeConsentReceptividadEndometrial,
    TypeConsentSecuenciacion,
    TypeConsentPGT,
    TypeConsentBioER,
    TypeConsentNull
}

export enum GenderType {
    Male = 1,
    Female,
}

export const userGenders = (): Record<GenderType, string> => ({
    [GenderType.Male]: i18n.t('Male'),
    [GenderType.Female]: i18n.t('Female'),
})

export const optionUserGenders = (): Option<OptionValue>[] => [
    { value: GenderType.Male, label: 'Male' },
    { value: GenderType.Female, label: 'Female' },
]

export enum HorarioType {
    H9to11 = 1,
    H10to12 = 2,
    H11to13 = 3,
    H12to14 = 4,
    H16to18 = 5,
    H18to20 = 6,
}

export const horarioOptions = (): Record<HorarioType, string> => ({
    [HorarioType.H9to11]: '9:00 - 11:00',
    [HorarioType.H10to12]: '10:00 - 12:00',
    [HorarioType.H11to13]: '11:00 - 13:00',
    [HorarioType.H12to14]: '12:00 - 14:00',
    [HorarioType.H16to18]: '16:00 - 18:00',
    [HorarioType.H18to20]: '18:00 - 20:00',
});

interface OptionValueHour {
    value: HorarioType;
    label: string;
}

export const optionHorarioTypes = (): OptionValueHour[] => [
    { value: HorarioType.H9to11, label: '9:00 - 11:00' },
    { value: HorarioType.H10to12, label: '10:00 - 12:00' },
    { value: HorarioType.H11to13, label: '11:00 - 13:00' },
    { value: HorarioType.H12to14, label: '12:00 - 14:00' },
    { value: HorarioType.H16to18, label: '16:00 - 18:00' },
    { value: HorarioType.H18to20, label: '18:00 - 20:00' },
];

export function convertStringToHorarioType(str: string): HorarioType | number {
    switch (str) {
        case "1":
            return HorarioType.H9to11;
        case "2":
            return HorarioType.H10to12;
        case "3":
            return HorarioType.H11to13;
        case "4":
            return HorarioType.H12to14;
        case "5":
            return HorarioType.H16to18;
        case "6":
            return HorarioType.H18to20;
        default:
            return 0;
    }
}

