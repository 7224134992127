import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { FeedbackData, FeedbackDataQuery } from '../models/FeedbackData'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { FeedbackDataDTO } from '../models/FeedbackDataDTO'
import { FeedbackDataApi } from '../api/FeedbackDataApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
    apiKey: symbol
}

export interface IFeedbackDataService extends IInit {
    getByID(id: string): Observable<FeedbackData | undefined>

    getFilteredList(q: Query<FeedbackDataQuery>): Observable<ItemList<FeedbackData>>

    add(e: FeedbackData[]): Observable<FeedbackData | undefined>

    update(e: FeedbackDataDTO): Observable<FeedbackData | undefined>

    delete(id: string): Observable<boolean>
}

export class FeedbackDataService implements IFeedbackDataService {
    private readonly _apiKey: symbol
    private _container!: Container
    private _api!: FeedbackDataApi
    private _statusService!: IStatusService

    constructor(p: Props) {
        this._apiKey = p.apiKey
    }

    init(c: Container) {
        this._container = c
        this._api = this._container.get<FeedbackDataApi>(this._apiKey)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    }

    add(e: FeedbackDataDTO[]): Observable<FeedbackData | undefined> {
        return this._api.add(e)
    }

    delete(id: string): Observable<boolean> {
        return this._api.delete(id)
    }

    getByID(id: string): Observable<FeedbackData | undefined> {
        return this._api.getByID(id)
    }

    getFilteredList(q: Query<FeedbackDataQuery>): Observable<ItemList<FeedbackData>> {
        return this._api.getFilteredList(q)
    }

    update(e: FeedbackDataDTO): Observable<FeedbackData | undefined> {
        return this._api.update(e)
    }
}
