import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getProperty } from '../../common/utils/ObjectUtils'

type RenderFunction<T> = (item: T) => string

export type EntitySelectProps<T> = {
  pk: keyof T
  name: RenderFunction<T> | keyof T
  options: T[]
  label?: string
  value?: string
  onChange?: (value: T) => void
  error?: string
  disabled?: boolean
  variant?: 'outlined' | 'standard'
}

export function EntitySelect<T>(props: EntitySelectProps<T>) {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<T[]>(props.options || [])
  const [error, setError] = useState<string | undefined>()
  const [selectedValue, setSelectedValue] = useState<string | undefined>(props.value)
  const loading = open && options.length === 0

  useEffect(() => {
    props.value && setSelectedValue(props.value)
    setError(props.error)
    props.options && setOptions(props.options)
    return () => {
      setSelectedValue(undefined)
      setError(undefined)
      setOptions([])
    }
  }, [props])

  const getSelectedValue = (): T | {} => {
    const item = options.find(
      (opt) => (getProperty(opt, props.pk) as unknown as string) === selectedValue
    )
    return item || {}
  }

  const handleChange = (value: T) => {
    props.onChange && props.onChange(value)
  }

  const getOptionLabel = (option: T): string => {
    if (typeof props.name === 'function') {
      return props.name(option)
    }
    const property = getProperty(option, props.name as keyof T) as unknown as string
    return property || ''
  }

  const disableUnderline = () => ({ disableUnderline: props.variant === 'standard' })

  return (
    <Autocomplete
      id={props.label}
      fullWidth
      open={open}
      disabled={props.disabled}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={getSelectedValue() as T}
      getOptionSelected={(option, value) =>
        getProperty(option, props.pk) === getProperty(value, props.pk)
      }
      getOptionLabel={getOptionLabel}
      options={options}
      onChange={(event, value) => handleChange(value as T)}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          error={error !== undefined}
          variant={props.variant || 'outlined'}
          helperText={error}
          InputProps={{
            ...disableUnderline(),
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
